import {useQueries} from '@tanstack/react-query';
import {GET_TREATMENT} from 'data/graphql/queries/treatments';
import {GQLClientContext} from 'providers/gqlClient';
import {useContext, useEffect, useMemo, useState} from 'react';
import {GET_TREATMENTQuery, GET_TREATMENTQueryVariables} from 'types/ApiModel';
import {TreatmentModel} from 'types/DerivedApiModel';

export interface TreatmentProps {
  branchId: string;
  treatmentId: string;
}

export interface UseTreatmentProps {
  businessId: string;
  treatments: TreatmentProps[];
}

export interface UseTreatmentsResponse {
  loading: boolean;
  error?: unknown;
  treatmentsMap?: Record<string, TreatmentModel>;
}

function useTreatments(query?: UseTreatmentProps): UseTreatmentsResponse {
  const {gqlClient} = useContext(GQLClientContext);
  const [treatmentsMap, setTreatmentMap] = useState<UseTreatmentsResponse['treatmentsMap']>();
  const treatmentsDeps = query?.treatments.map(el => `${el.branchId}${el.treatmentId}`).join('') ?? '';
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const [businessId, treatments] = useMemo(() => [query?.businessId, query?.treatments], [treatmentsDeps]);

  const treatmentQueries = useQueries({
    queries:
      businessId && treatments && treatments.length > 0
        ? treatments.map(treatment => ({
            enabled: !!(businessId && treatment.branchId && treatment.treatmentId),
            queryKey: [USE_TREATMENTS_KEY, treatment.branchId + treatment.treatmentId],
            queryFn: async () => {
              const {branchId, treatmentId: serviceId} = treatment;
              const resp = await gqlClient.request<GET_TREATMENTQuery, GET_TREATMENTQueryVariables>(GET_TREATMENT, {
                query: {businessId, branchId, serviceId}
              });
              return resp.data?.getService;
            }
          }))
        : []
  });

  const {success, loading} = useMemo(
    () => ({
      loading: treatmentQueries.some(({isLoading, isFetching}) => isLoading || isFetching),
      success: treatmentQueries.length > 0 && !treatmentQueries.some(query => !query.isSuccess)
    }),
    [treatmentQueries]
  );

  /*
   TODO: Consider refactoring treatmentQueries into a Promise.allSettled() version so that the below useEffect can run smoother with proper dependencies. Passing treatmentQueries as a dep will cause an infinite loop here which is why the linting is disabled.
  */
  useEffect(() => {
    if (success)
      treatmentQueries.forEach(
        ({data}) => data?.id && setTreatmentMap(prevState => ({...prevState, [data.id ?? '']: data}))
      );
    // eslint-disable-next-line
  }, [success, setTreatmentMap]);

  return useMemo(() => ({loading, treatmentsMap}), [loading, treatmentsMap]);
}

export default useTreatments;
export {useTreatments};

export const USE_TREATMENTS_KEY = 'USE_TREATMENTS';
