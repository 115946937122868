import {IDS} from '../../constants/ui';
import {DatePicker, LoadingPlaceholder, Text} from '@therapie-ui';
import {Option} from 'types/common';
import {styledTheme} from 'providers/theme';
import {TextField} from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import React from 'react';
import styled from 'styled-components';
import {useClinicContext} from 'hooks';

interface Props {
  clinicOptions: Option[];
  fromDate?: Date;
  isLoadingClinics?: boolean;
  isLoadingStaff?: boolean;
  showDatepicker?: boolean;
  showClinicFilter?: boolean;
  disableClinicFilter?: boolean;
  disabledClinicFilterHelpText?: string;
  showStaffFilter?: boolean;
  staffId?: string | null;
  staffOptions?: Option[];
  onClinicChange: (id?: string) => void;
  onStaffChange?: (id?: string) => void;
  onFromDateChange?: (newDate: Date | null) => void;
}

const BookingFilters: React.FC<React.PropsWithChildren<Props>> = ({
  fromDate,
  staffId,
  clinicOptions = [],
  staffOptions = [],
  showDatepicker = true,
  showClinicFilter = true,
  disableClinicFilter = false,
  disabledClinicFilterHelpText = 'This booking can only be rescheduled within the same clinic',
  showStaffFilter = true,
  isLoadingClinics = false,
  isLoadingStaff = false,
  onClinicChange,
  onStaffChange,
  onFromDateChange
}) => {
  const {clinic} = useClinicContext();
  return (
    <StyledContainer>
      {showClinicFilter && (
        <>
          <Text style={{gridArea: 'clinicLabel'}} variant="sectionTitle" children="Clinic" />
          {!isLoadingClinics ? (
            <StyledAutocomplete
              disabled={disableClinicFilter}
              disableClearable={true}
              style={{gridArea: 'clinicSelect'}}
              id="clinic-select"
              value={{id: clinic?.id ?? '', label: clinic?.name ?? ''}}
              options={clinicOptions}
              getOptionLabel={option => option.label || ''}
              renderInput={params => (
                <TextField
                  {...params}
                  variant="outlined"
                  size="small"
                  helperText={disableClinicFilter ? disabledClinicFilterHelpText : null}
                />
              )}
              onChange={(_, change) => onClinicChange?.(change?.id)}
              getOptionSelected={({id: optionId}, {id: valueId}) => optionId === valueId}
              // TODO: TextField should be imported from @therapie-ui
            />
          ) : (
            <div style={{gridArea: 'clinicSelect'}}>
              <LoadingPlaceholder shape={[['rect', 40, 0]]} padding={0} />
            </div>
          )}
        </>
      )}

      {showStaffFilter && (
        <>
          <Text style={{gridArea: 'staffLabel'}} variant="sectionTitle" children="Staff" />
          {!isLoadingStaff ? (
            <StyledAutocomplete
              disableClearable={true}
              style={{gridArea: 'staffSelect'}}
              id="staff-select"
              value={
                staffOptions?.find(staff => staff.id === staffId) || {
                  id: IDS.OPTION_ID_FOR_NO_SELECTION,
                  label: 'Any available'
                }
              }
              options={staffOptions || []}
              getOptionLabel={option => option.label || ''}
              renderInput={params => <TextField {...params} variant="outlined" size="small" />}
              onChange={(_, change) => onStaffChange?.(change?.id)}
              getOptionSelected={({id: optionId}, {id: valueId}) => optionId === valueId}
              // TODO: TextField should be imported from @therapie-ui
            />
          ) : (
            <div style={{gridArea: 'staffSelect'}}>
              <LoadingPlaceholder shape={[['rect', 40, 0]]} padding={0} />
            </div>
          )}
        </>
      )}

      {showDatepicker && (
        <>
          <Text style={{gridArea: 'fromLabel'}} variant="sectionTitle" children="From" />
          <AvailabilityDatePicker
            date={fromDate}
            onSelectedDateChange={onFromDateChange ?? (() => console.log('TODO: Handle optional filters properly'))}
          />
        </>
      )}
    </StyledContainer>
  );
};

export default BookingFilters;

const breakPoint = styledTheme.breakpoints.values.sm;

const StyledContainer = styled.div`
  grid-gap: ${({theme}) => theme.spacing(1)};
  display: grid;
  grid-template-rows: auto;
  grid-template-columns: 1fr 0 1fr;
  grid-template-areas:
    'clinicLabel . staffLabel'
    'clinicLabel . staffLabel'
    'clinicSelect . staffSelect'
    'clinicSelect . staffSelect'
    'fromLabel . .'
    'fromSelect . .';

  @media only screen and (max-width: ${`${breakPoint}px`}) {
    grid-template-columns: 1fr;
    grid-template-areas:
      'clinicLabel clinicLabel clinicLabel'
      'clinicSelect clinicSelect clinicSelect'
      'staffLabel staffLabel staffLabel'
      'staffSelect staffSelect staffSelect'
      'fromLabel fromLabel fromLabel'
      'fromSelect fromSelect fromSelect';
  }
`;

const StyledAutocomplete = styled(Autocomplete)`
  margin-bottom: ${({theme}) => theme.spacing(2)};

  .MuiFormHelperText-root {
    line-height: 1.2;
  }
  @media only screen and (max-width: ${`${breakPoint}px`}) {
    width: 100%;
  }
` as typeof Autocomplete;

const AvailabilityDatePicker = styled(DatePicker)`
  grid-area: 'fromSelect';
`;
