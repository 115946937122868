import {UserInterface} from 'state';
import {AnalyticsEventInput} from 'types/ApiModel';
import {SENTRY_FILTERED_ERRORS_SAMPLE_MESSAGES} from 'constants/sentry';
import {BrowserOptions} from '@sentry/react';
import {replaceUnderscoreWithSpaceAndReturnInLowerCase} from './text';

export const getAnalyticsAttributes = (input: {
  actionName: string;
  user?: UserInterface;
  details?: Record<string, unknown>;
}): AnalyticsEventInput => {
  const {actionName, details, user} = input;
  let detailsJSON = '';
  try {
    detailsJSON = JSON.stringify(details);
  } catch {}
  return {
    actionName,
    detailsJSON,
    userName: user?.attributes?.email ?? '',
    userRole: user?.attributes?.roles?.join(',') ?? ''
  };
};

export const beforeSend: BrowserOptions['beforeSend'] = event => {
  const isFiltered = SENTRY_FILTERED_ERRORS_SAMPLE_MESSAGES.some(messageSample =>
    event.exception?.values?.some(({value}) =>
      replaceUnderscoreWithSpaceAndReturnInLowerCase(value)?.includes(
        replaceUnderscoreWithSpaceAndReturnInLowerCase(messageSample) as string
      )
    )
  );
  return isFiltered ? null : event;
};
