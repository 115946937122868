import {InputBase, Paper} from '@material-ui/core';
import Divider from '@material-ui/core/Divider';
import {createStyles, makeStyles, Theme} from '@material-ui/core/styles';
import SearchIcon from '@material-ui/icons/Search';
import {Button} from '@therapie-ui';
import {styledTheme} from 'providers/theme';
import React from 'react';
import styled from 'styled-components';
import {ClientsInput} from 'types/ApiModel';

interface Props {
  onUpdateQuery: (query: ClientsInput) => void;
  query: ClientsInput;
}

const ClientSearch: React.FC<React.PropsWithChildren<Props>> = ({onUpdateQuery, query, children}) => {
  const classes = useStyles();

  return (
    <>
      <StyledForm action="#" method="POST" autoComplete="off">
        <Paper className={classes.inputRowRoot}>
          <InputBase
            id="name"
            className={classes.input}
            placeholder="First name"
            value={query?.firstName || ''}
            inputProps={{'aria-label': 'search google maps'}}
            onChange={evt => onUpdateQuery({...query, firstName: evt.target.value})}
          />

          <Divider className={classes.divider} orientation="vertical" />

          <InputBase
            id="surname"
            className={classes.input}
            placeholder="Surname"
            value={query?.lastName || ''}
            inputProps={{'aria-label': 'Surname'}}
            onChange={evt => onUpdateQuery({...query, lastName: evt.target.value})}
          />

          <Divider className={classes.divider} orientation="vertical" />

          <InputBase
            id="email"
            className={classes.input}
            placeholder="Email"
            value={query?.email || ''}
            inputProps={{'aria-label': 'Email address'}}
            onChange={evt => onUpdateQuery({...query, email: evt.target.value})}
          />

          <Divider className={classes.divider} orientation="vertical" />

          <InputBase
            id="phone"
            className={classes.input}
            placeholder="Phone number"
            value={query?.phone || ''}
            inputProps={{'aria-label': 'Phone number'}}
            onChange={evt => onUpdateQuery({...query, phone: evt.target.value})}
          />

          <Divider className={classes.divider} orientation="vertical" />

          <Button className={classes.iconButton} aria-label="menu" variant="ghost" iconRound icon={<SearchIcon />} />
          {/* TODO: Agree with design on removing this icon as Button has been deprecated */}
        </Paper>
      </StyledForm>
      {children}
    </>
  );
};

export default ClientSearch;

// TODO: Swap this with styled component. Just realised it's also breaking layout in the MUI5 update
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    appBarRoot: {backgroundColor: styledTheme.colors.grey[100], boxShadow: 'none'},
    inputRowRoot: {
      padding: '2px 4px',
      display: 'flex',
      alignItems: 'center',
      width: '100%',
      boxShadow: 'none',
      borderRadius: styledTheme.shape.borderRadius,
      border: `solid 1px ${styledTheme.colors.grey[300]}`,
      backgroundColor: '#ffffff'
    },
    input: {
      marginLeft: theme.spacing(1),
      flex: 1,
      fontSize: '0.8rem'
    },
    iconButton: {
      padding: 10
    },
    divider: {
      height: 28,
      margin: 4
    },
    countrySelect: {
      fontSize: '0.8rem',
      '& fieldset': {
        border: 0
      }
    },
    countryMenuItem: {fontSize: '0.8rem'}
  })
);

const StyledForm = styled.form`
  background: white;
  display: flex;
  padding: 12px;
`;
