import {gql} from 'utils/graphql';

export const UPDATE_APPOINTMENTS = gql`
  mutation UpdateAppointments($payload: UpdateAppointmentsInput!) {
    updateAppointments(payload: $payload) {
      appointments {
        appointmentId
        version
        activationState
        appointmentDate
        bookingId
        clientCourseItemId
        clientId
        depositAmount
        depositDateTime
        endTime
        groupBookingId
        machineId
        preferredStaff
        price
        roomId
        serviceGroupId
        serviceGroupItemOptionId
        serviceId
        serviceRewardId
        source
        staffId
        staffRequest
        startTime
        state
        waitingListDateTime
      }
    }
  }
`;

export const CANCEL_APPOINTMENT = gql`
  mutation CancelAppointment($payload: CancelAppointmentInput!) {
    cancelAppointment(payload: $payload)
  }
`;

export const CHECK_IN_APPOINTMENTS = gql`
  mutation CheckInAppointments($payload: CheckInAppointmentsInput!) {
    checkInAppointments(payload: $payload) {
      appointments {
        activationState
        appointmentDate
        appointmentId
        bookingId
        confirmed
        clientCourseItemId
        clientId
        depositAmount
        depositDateTime
        endTime
        machineId
        preferredStaff
        price
        roomId
        serviceId
        source
        staffId
        staffRequest
        startTime
        state
        version
        waitingListDateTime
      }
    }
  }
`;

export const UNDO_CHECK_IN_APPOINTMENTS = gql`
  mutation UndoCheckInAppointments($payload: UndoCheckInAppointmentsInput!) {
    undoCheckInAppointments(payload: $payload) {
      appointments {
        activationState
        appointmentDate
        appointmentId
        bookingId
        confirmed
        clientCourseItemId
        clientId
        depositAmount
        depositDateTime
        endTime
        machineId
        preferredStaff
        price
        roomId
        serviceId
        source
        staffId
        staffRequest
        startTime
        state
        version
        waitingListDateTime
        client {
          clientId
          firstName
          lastName
          mobile
          email
          gender
        }
      }
    }
  }
`;

export const DELETE_APPOINTMENT = gql`
  mutation DeleteAppointment($payload: DeleteAppointmentInput!) {
    deleteAppointment(payload: $payload)
  }
`;
