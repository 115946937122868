import AddIcon from '@material-ui/icons/Add';
import {Button, EmptyState, LoadingPlaceholder} from '@therapie-ui';
import SectionContent from 'components/Clients/ViewClients/CustomerView/SectionContent';
import {QUERY_KEYS} from 'constants/query';
import {ROUTES} from 'constants/routes';
import {SIZES} from 'constants/sizes';
import {useBusinessContext} from 'hooks';
import useClientAppointments from 'hooks/client/useClientAppointments';
import useClinicContext from 'hooks/clinic/useClinicContext';
import {useAppNavigation} from 'hooks/navigation/useAppNavigation';
import React, {useMemo} from 'react';
import {useRecoilState} from 'recoil';
import {branchesMapState, ServiceInstance} from 'state';
import styled from 'styled-components';
import {formatUTCDate} from 'utils';
import {getAppointmentUniqueKey} from 'utils/appointments';
import {isItAppointmentPaid} from '../../../utils';
import SectionHeader from '../../SectionHeader';
import AppointmentList from './AppointmentList';
import ServiceDetails from './ServiceDetails';
import useActiveClientCourses, {IUseActiveClientCoursesOutput} from 'hooks/courses/useActiveClientCourses';
interface Props {
  clientId: string;
}

const CustomerUpcomingAppointmentsGroupedByDate: React.FC<React.PropsWithChildren<Props>> = ({clientId}) => {
  const {businessId} = useBusinessContext();
  const {setClinicById} = useClinicContext();
  const {navigate} = useAppNavigation();
  const clientCourses = useActiveClientCourses({clientId});
  const clientAppointments = useClientAppointments({clientId});
  const {futureAppointmentsIncludingToday, pastServiceHistories, loading, error} = clientAppointments;
  let futureDate: string | undefined = '';
  const [branchMap] = useRecoilState(branchesMapState);
  const lastVisitedClinicId = pastServiceHistories?.[0]?.branchId;

  const handleCreateNew = () =>
    lastVisitedClinicId
      ? setClinicById({
          id: lastVisitedClinicId,
          nextPath: ROUTES.CLIENTS_SELECT_TREATMENTS,
          urlParams: new URLSearchParams({[QUERY_KEYS.CLIENT_ID]: clientId})
        })
      : navigate({
          toRoute: ROUTES.CLIENTS_SELECT_TREATMENTS,
          urlSearchParams: new URLSearchParams({[QUERY_KEYS.CLIENT_ID]: clientId})
        });

  const appointmentsByDates = useMemo(
    () =>
      futureAppointmentsIncludingToday?.reduce<Record<string, ServiceInstance[]>>((acc, appointment) => {
        const date = formatUTCDate(new Date(appointment?.time ?? ''), 'YEAR_MONTH_DAY');
        if (!acc[date]) acc[date] = [];
        acc[date].push(appointment);
        return acc;
      }, {}) ?? ({} as Record<string, ServiceInstance[]>),
    [futureAppointmentsIncludingToday]
  );

  // TODO: refactor this, remove logic from template, prepare data by grouping by date
  return (
    <div>
      <SectionHeader>
        Appointments
        <Button color="primary" variant="filled" onClick={handleCreateNew} iconLeading={<StyledAddIcon />}>
          New appointment
        </Button>
      </SectionHeader>
      <SectionContent>
        {error ? (
          <EmptyState
            icon="sadface"
            children={"We're sorry, an error happened and was sent to the tech team"}
            size={SIZES.S}
          />
        ) : loading ? (
          <LoadingPlaceholder padding={0} />
        ) : !!futureAppointmentsIncludingToday?.length ? (
          <StyledAppointmentHistoryList>
            {futureAppointmentsIncludingToday.map(appointment => {
              const {
                appointmentId,
                bookingId,
                branchId,
                cancelled,
                duration,
                description,
                staffId,
                time: datetimeISO,
                clientCourseItemId,
                clientId,
                waitingList,
                fee,
                originalPrice
              } = appointment;
              const appointmentDate = formatUTCDate(datetimeISO, 'YEAR_MONTH_DAY');
              const componentKey = getAppointmentUniqueKey(appointment);
              const headerIfFirstInstanceOfDate =
                futureDate === appointmentDate ? null : (
                  <AppointmentList
                    appointmentDate={appointmentDate}
                    clientId={clientId}
                    clinicId={branchId}
                    key={`${componentKey}_header`}
                    isPaid={!appointmentsByDates[appointmentDate]?.find(el => !isItAppointmentPaid(el))}
                  />
                );
              // Anytime we see a new date we print it, else ignore
              futureDate = appointmentDate;
              return (
                <React.Fragment key={componentKey}>
                  {headerIfFirstInstanceOfDate}
                  <ServiceDetails
                    key={componentKey}
                    appointmentId={appointmentId}
                    bookingId={bookingId}
                    branchId={branchId}
                    businessId={businessId}
                    cancelled={cancelled}
                    clinicName={branchMap[branchId as string]?.name}
                    serviceTime={datetimeISO}
                    serviceDuration={duration}
                    serviceName={description}
                    staffId={staffId}
                    courseName={getCourseName(clientCourseItemId ?? '', clientCourses)}
                    isWaitingList={!!waitingList}
                    fee={fee}
                    originalPrice={originalPrice}
                  />
                </React.Fragment>
              );
            })}
          </StyledAppointmentHistoryList>
        ) : (
          <EmptyState
            title={'No upcoming appointments'}
            details={'If a client has any upcoming bookings they will be shown here'}
            darkTitle
          />
        )}
      </SectionContent>
    </div>
  );
};

export {CustomerUpcomingAppointmentsGroupedByDate};
export default CustomerUpcomingAppointmentsGroupedByDate;

const getCourseName = (clientCourseItemId: string, clientCourses: IUseActiveClientCoursesOutput): string => {
  if (!clientCourseItemId) return '';
  const course = clientCourses.data?.find(clientCourse =>
    clientCourse.clientCourseItems?.find(
      clientCourseItems => clientCourseItems.clientCourseItemId === clientCourseItemId
    )
  );
  return course?.courseName ?? 'Course Name not available';
};

const StyledAppointmentHistoryList = styled.dl`
  list-style: none;
  margin: ${({theme}) => theme.spacing(0)};
  padding: ${({theme}) => theme.spacing(0)};
  width: 100%;
`;

const StyledAddIcon = styled(AddIcon)`
  margin-right: ${({theme}) => theme.spacing(1)};
`;
