import {useUrlParams} from 'hooks';
import {parseUrlParams} from 'utils';

interface Output {
  clientId: string;
}

function useCurrentClientId(): Output {
  const urlParams = useUrlParams();
  const {clientId = ''} = parseUrlParams(urlParams.toString()) as Output;
  return {clientId};
}

export {useCurrentClientId};
export default useCurrentClientId;
