// Secondary colors

const COLORS_SECONDARY = {
  aquaIsland: '#B2DFDB',
  blizzardBlue: '#B2EBF2',
  buttermilk: '#FFECB3',
  chantilly: '#F8BBD0',
  chromeWhite: '#DCEDC8',
  frangipani: '#FFE0B2',
  frenchLilac: '#E1BEE7',
  frenchPass: '#B3E5FC',
  lemonChiffon: '#FFF9C4',
  pastelPink: '#FFCDD2',
  pearlBush: '#E6DAD5',
  periwinkle: '#C5CAE9',
  prelude: '#D1C4E9',
  romantic: '#FFCCBC',
  sail: '#BBDEFB',
  swirl: '#D7CCC8',
  tusk: '#F0F4C3',
  zanah: '#C8E6C9'
} as const;

export default COLORS_SECONDARY;
export {COLORS_SECONDARY};
