import {TextFieldProps, TextField} from '@material-ui/core';
import React from 'react';
import styled from 'styled-components';

const TextInput: React.FC<React.PropsWithChildren<TextFieldProps>> = ({variant = 'outlined', ...rest}) => (
  <StyledTextField variant={variant} {...rest} />
);

export default TextInput;

const StyledTextField = styled(TextField)`
  margin-top: ${({theme}) => theme.spacing(2.5)} 0;
  .MuiInputBase-root {
    border-radius: 8px;
  }
  .MuiFormHelperText-root {
    pointer-events: none;
  }
`;
