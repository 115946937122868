import {gql} from 'utils/graphql';

export const GET_ADMIN_ONLINE_GROUPS = gql`
  query GET_ADMIN_ONLINE_GROUPS($query: GetAdminOnlineGroupsInput!, $page: PageInput) {
    getAdminOnlineGroups(query: $query, page: $page) {
      page {
        number
        size
        totalElements
        totalPages
      }
      onlineGroups {
        id
        name
        urlHandle
      }
    }
  }
`;

export const GET_ADMIN_ONLINE_GROUP = gql`
  query GET_ADMIN_ONLINE_GROUP($query: GetAdminOnlineGroupInput!) {
    getAdminOnlineGroup(query: $query) {
      descriptionHTML
      id
      name
      onlineGroupId
      onlineSectionId
      onlineStatus
      onlineSubGroupId
      urlHandle
      parentGroupId
      parentGroupName
    }
  }
`;

export const GET_ADMIN_ONLINE_SUB_SECTION_ITEMS = gql`
  query GET_ADMIN_ONLINE_SUB_SECTION_ITEMS($query: GetAdminOnlineSubSectionItemsInput!, $page: PageInput) {
    getAdminOnlineSubSectionItems(query: $query, page: $page) {
      page {
        number
        size
        totalElements
        totalPages
      }
      items {
        id
        name
        onlinePriority
        itemType
      }
    }
  }
`;
