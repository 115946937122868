import React from 'react';
import {useRecoilValue} from 'recoil';
import {isCallCentreAgentState, userState} from 'state';

interface RenderState {
  isCallCentreAgent: boolean;
}

interface Props {
  render: (state: RenderState) => React.ReactElement | null;
}

const Private: React.FC<React.PropsWithChildren<Props>> = ({render}) => {
  const user = useRecoilValue(userState);
  const isCallCentreAgent = useRecoilValue(isCallCentreAgentState);
  if (!user) return null;
  return render({isCallCentreAgent});
};

export {Private};
export default Private;
