import {QUERY_KEYS} from 'constants/query';
import {useBusinessContext, useClientsAppointmentsOnDate, useTreatments} from 'hooks';
import useClinicContext from 'hooks/clinic/useClinicContext';
import {useAppNavigation} from 'hooks/navigation/useAppNavigation';
import {useCallback, useMemo} from 'react';
import {ClinicAppointmentModelWithServiceModel} from 'types/DerivedApiModel';

export interface IUsePurchaseAppointmentsOutput {
  appointments?: ClinicAppointmentModelWithServiceModel[];
  isLoading: boolean;
  clearAppointmentDateInUrl: () => void;
}
interface Input {
  urlParams: any;
}

const usePurchaseAppointments = ({urlParams}: Input): IUsePurchaseAppointmentsOutput => {
  const {navigate} = useAppNavigation();
  const {businessId} = useBusinessContext();
  const {clinicId} = useClinicContext();
  const clientId = urlParams.get(QUERY_KEYS.CLIENT_ID) ?? '';
  const dateString = urlParams.get(QUERY_KEYS.APPOINTMENT_DATE) ?? '';

  const {appointments, isLoading: isLoadingAppointments} = useClientsAppointmentsOnDate({
    businessId,
    clientId,
    clinicId,
    date: dateString
  });

  const {treatmentsMap, loading: isLoadingServices} = useTreatments({
    businessId,
    treatments: appointments?.map(el => ({branchId: clinicId, treatmentId: el.serviceId ?? ''})) ?? []
  });

  const clearAppointmentDateInUrl = useCallback(() => {
    urlParams.set(QUERY_KEYS.APPOINTMENT_DATE, '');
    navigate({urlSearchParams: urlParams});
  }, [navigate, urlParams]);

  const decoratedAppointments: ClinicAppointmentModelWithServiceModel[] | undefined = useMemo(
    () =>
      !appointments || appointments.length === 0 || !treatmentsMap
        ? undefined
        : appointments?.map(el => ({...el, service: treatmentsMap?.[el.serviceId ?? '']})),
    [appointments, treatmentsMap]
  );
  const isLoading = isLoadingAppointments || isLoadingServices;

  return useMemo(
    () => ({appointments: decoratedAppointments, clearAppointmentDateInUrl, isLoading}),
    [decoratedAppointments, clearAppointmentDateInUrl, isLoading]
  );
};

export {usePurchaseAppointments};
