import {UseQueryResult, useQuery} from '@tanstack/react-query';
import {RQ_CACHE_TIMES, RQ_STALE_TIMES} from 'constants/reactquery';
import {GET_ALL_BRANCH_SERVICE_CATEGORIES} from 'data/graphql/queries/treatments';
import {useClinicContext} from 'hooks';
import {useBusinessContext} from 'hooks/business/useBusinessContext';
import {GQLClientContext} from 'providers/gqlClient';
import {useContext, useMemo} from 'react';
import {GET_ALL_BRANCH_SERVICE_CATEGORIESQuery, GET_ALL_BRANCH_SERVICE_CATEGORIESQueryVariables} from 'types/ApiModel';
import {ServiceCategoryModel} from 'types/DerivedApiModel';
import {TGQLCustomErrorsViaReactQuery} from 'types/errors';

export type IUseAllBranchServiceCategoriesOutput = UseQueryResult<
  ServiceCategoryModel[],
  TGQLCustomErrorsViaReactQuery
>;

function useAllBranchServiceCategories(): IUseAllBranchServiceCategoriesOutput {
  const {gqlClient} = useContext(GQLClientContext);
  const {businessId} = useBusinessContext();
  const {clinicId: branchId} = useClinicContext();
  const query = useMemo(() => ({branchId, businessId}), [branchId, businessId]);
  return useQuery({
    enabled: !!(query.branchId && query.businessId),
    cacheTime: RQ_CACHE_TIMES.useAllBranchServiceCategories,
    staleTime: RQ_STALE_TIMES.useAllBranchServiceCategories,
    queryKey: [USE_ALL_BRANCH_SERVICE_CATEGORIES_KEY, query],
    queryFn: async () => {
      const resp = await gqlClient.request<
        GET_ALL_BRANCH_SERVICE_CATEGORIESQuery,
        GET_ALL_BRANCH_SERVICE_CATEGORIESQueryVariables
      >(GET_ALL_BRANCH_SERVICE_CATEGORIES, {query});
      return resp?.data?.getAllBranchServiceCategories ?? [];
    }
  });
}

export {useAllBranchServiceCategories};
export default useAllBranchServiceCategories;

export const USE_ALL_BRANCH_SERVICE_CATEGORIES_KEY = 'GET_ALL_BRANCH_SERVICE_CATEGORIES';
