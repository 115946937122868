import {captureException} from '@sentry/react';
import {UseQueryResult, useQuery} from '@tanstack/react-query';
import {RQ_CACHE_TIMES} from 'constants/reactquery';
import {GET_APPOINTMENTS} from 'data/graphql/queries/appointments';
import {GQLClientContext} from 'providers/gqlClient';
import {useContext} from 'react';
import {GET_APPOINTMENTSQuery, GET_APPOINTMENTSQueryVariables} from 'types/ApiModel';
import {ClinicAppointmentModel} from 'types/DerivedApiModel';
import {TGQLCustomErrorsViaReactQuery} from 'types/errors';

export type IUseClinicAppointmentsOutput = UseQueryResult<ClinicAppointmentModel[], TGQLCustomErrorsViaReactQuery>;

function useClinicAppointments(variables: GET_APPOINTMENTSQueryVariables | null): IUseClinicAppointmentsOutput {
  const {gqlClient} = useContext(GQLClientContext);

  return useQuery<ClinicAppointmentModel[], TGQLCustomErrorsViaReactQuery>({
    enabled: !!variables,
    cacheTime: RQ_CACHE_TIMES.useClinicAppointments,
    queryKey: [USE_CLINIC_APPOINTMENTS_KEY, variables],
    queryFn: async () => {
      const {data} = await gqlClient.request<GET_APPOINTMENTSQuery, GET_APPOINTMENTSQueryVariables>(
        GET_APPOINTMENTS,
        variables as GET_APPOINTMENTSQueryVariables
      );
      return data?.getAppointments?.appointments ?? [];
    },

    onError: errors => {
      const errorString = errors?.response?.errors?.[0]?.message ?? 'Error getting clinic appointments';
      captureException(new Error(errorString));
    }
  });
}

export {useClinicAppointments};
export default useClinicAppointments;

export const USE_CLINIC_APPOINTMENTS_KEY = 'USE_CLINIC_APPOINTMENTS';
