import {ENV} from 'config';
import {BusinessCode} from './business';

export type TBusinessAppUrls = Record<BusinessCode, Record<ENV, string>>;

const APP_URL_BASE = 'https://';
const APP_URL_COMMON = `.crm.tech.therapieclinic.com`;
export const APP_URL_HOSTNAMES = {
  DEV_ROI: 'dev-roi-therapie-crm',
  DEV_UK: 'dev-uk-therapie-crm',
  DEV_US: 'dev-us-therapie-crm',
  PROD_ROI: 'prod-roi-therapie-crm',
  PROD_UK: 'prod-uk-therapie-crm',
  PROD_US: 'prod-us-therapie-crm'
};

export const BUSINESS_APP_URLS: TBusinessAppUrls = {
  roi: {
    local: APP_URL_BASE + APP_URL_HOSTNAMES.DEV_ROI + APP_URL_COMMON,
    development: APP_URL_BASE + APP_URL_HOSTNAMES.DEV_ROI + APP_URL_COMMON,
    production: APP_URL_BASE + APP_URL_HOSTNAMES.PROD_ROI + APP_URL_COMMON
  },
  uk: {
    local: APP_URL_BASE + APP_URL_HOSTNAMES.DEV_UK + APP_URL_COMMON,
    development: APP_URL_BASE + APP_URL_HOSTNAMES.DEV_UK + APP_URL_COMMON,
    production: APP_URL_BASE + APP_URL_HOSTNAMES.PROD_UK + APP_URL_COMMON
  },
  us: {
    local: APP_URL_BASE + APP_URL_HOSTNAMES.DEV_US + APP_URL_COMMON,
    development: APP_URL_BASE + APP_URL_HOSTNAMES.DEV_US + APP_URL_COMMON,
    production: APP_URL_BASE + APP_URL_HOSTNAMES.PROD_US + APP_URL_COMMON
  }
};
