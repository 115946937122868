export enum DATE_FORMAT {
  // --- Format for 👨
  // 12:30 AM
  AM_PM = 'h:mm aa',

  // 13:30
  HH_MM = 'HH:mm',

  // Friday 13th October
  DAY_DATE_MONTH = 'EEEE do MMMM',

  // Friday 13th October, 2021
  DAY_DATE_MONTH_YEAR = 'EEEE do MMMM, yyyy',

  // 20/01/2021
  DATE_MONTH_YEAR = 'dd/MM/yyyy',

  // March 11th, 2021
  MONTH_DATE_YEAR = 'MMMM do, yyyy',

  // March 11th
  MONTH_DATE = 'MMMM do',

  // Mar 1st, 2022
  MON_DATE_YEAR = 'MMM do, yyyy',

  // Friday 15th January, 11:30 AM
  DAY_DATE_MONTH_AM_PM = 'EEEE do MMMM, h:mm aa',

  // --- Formats Localised for 👨

  // ROI/UK = 20/01/2021 || USA = 01/20/2021
  DATE_MONTH_YEAR_LOCALISED = 'P',

  // ROI/UK = 20/01/2021 2:00 PM || USA = 01/20/2021 2:00 PM
  DATE_MONTH_YEAR_AM_PM_LOCALISED = 'P h:mm aa',

  // Friday, April 29th, 2021
  DAY_DATE_MONTH_YEAR_LOCALISED = 'PPPP',

  // --- Formats for 🖥️

  // 2000-01-01
  YEAR_MONTH_DAY = 'yyyy-MM-dd',

  // 2000-01-01_12
  DATE_TIME_TEST = 'yyyy-MM-dd__h:mm_aa',

  // 12:00:00
  HOURS_MIN_SEC = 'HH:mm:ss',

  // 12:00
  HOURS_MIN = 'HH:mm',

  // 12:00:00.000
  HOURS_MIN_SEC_MS = 'HH:mm:ss.SSS'
}

export const ONE_MINUTE_IN_MILISECONDS = 60000;
