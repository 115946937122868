import RemoveIcon from '@material-ui/icons/Close';
import EditIcon from '@material-ui/icons/EditOutlined';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import {Accordion, AccordionDetails, AccordionSummary, Button, EmptyState, Text} from '@therapie-ui';
import InfoBar from '@therapie-ui/InfoBar/InfoBar';
import {BaseItem, Column, Table} from '@therapie-ui/Table/Table';
import {SEVERITY} from 'constants/notifications';
import {useCurrency} from 'hooks/currency/useCurrency';
import React, {useCallback, useMemo, useState} from 'react';
import styled from 'styled-components';
import {DiscountType} from 'types/ApiModel';
import {ClinicAppointmentModel} from 'types/DerivedApiModel';
import {formatUTCDate, getZonedDate} from 'utils/time';
import {IUsePaymentAmountsCalculations} from './hooks/usePaymentAmountsCalculations';
import {usePurchaseStaff} from './hooks/usePurchaseStaff';
import {usePurchaseSummaryItems} from './hooks/usePurchaseSummaryItems';
import {PurchaseItem} from './types';

interface Props {
  appointments?: ClinicAppointmentModel[];
  isFormDisabled: boolean;
  paymentAmounts: IUsePaymentAmountsCalculations;
  setDiscountDialogPurchaseItem: (item: PurchaseItem) => void;
  setStaffDialogPurchaseItem: (item: PurchaseItem) => void;
}

interface PurchaseItemWithActionColumn extends PurchaseItem, BaseItem {}

var totalToCharge: number;

const SummarySection: React.FC<React.PropsWithChildren<Props>> = React.memo(
  ({appointments, paymentAmounts, isFormDisabled, setDiscountDialogPurchaseItem, setStaffDialogPurchaseItem}) => {
    const {remove: removePurchaseItem, purchaseItems} = usePurchaseSummaryItems();
    const {staffOptions} = usePurchaseStaff();
    const {formatCurrency} = useCurrency();
    const [expanded, setExpanded] = useState(true);
    const {isLoading, amountOfPaymentDueAfterDiscounts, sumOfDiscounts} = paymentAmounts;

    totalToCharge = amountOfPaymentDueAfterDiscounts;

    // Memos

    const totalPriceMessage = useMemo(() => {
      return `Total ${isLoading ? '-' : formatCurrency({value: amountOfPaymentDueAfterDiscounts})}`;
    }, [formatCurrency, amountOfPaymentDueAfterDiscounts, isLoading]);

    const totalDiscountMessage = useMemo(() => {
      return sumOfDiscounts > 0 ? `Discount -${formatCurrency({value: sumOfDiscounts})}` : null;
    }, [formatCurrency, sumOfDiscounts]);

    // Callbacks

    const getDiscount = useCallback(
      (item: PurchaseItem) => {
        if (!item.discount) return 'None';
        if (item.discount?.type === DiscountType.PERCENT) return item.discount.value + '%';
        if (item.discount?.type === DiscountType.AMOUNT) return formatCurrency({value: item.discount.value});
      },
      [formatCurrency]
    );

    const renderCell = useCallback(
      (item: PurchaseItem, column: Column): React.ReactNode => {
        const itemPropIndex = column.id as keyof PurchaseItem;
        switch (column.id) {
          case 'staff':
            return (
              <Button
                variant="outlined"
                size="small"
                onClick={() => setStaffDialogPurchaseItem(item)}
                iconTrailing={<EditIcon />}
                children={staffOptions.find(({id}) => id === item.staffId)?.label}
              />
            );
          case 'price':
            return formatCurrency({value: item.price});
          case 'info':
            if (!item.serviceId) return;
            return item.appointmentId ? (
              <StyledInfoBar status={SEVERITY.INFO}>
                Appointment on {getAppointmentInfo(item.appointmentId, appointments)}
              </StyledInfoBar>
            ) : (
              <StyledInfoBar status={SEVERITY.WARNING}>No appointment booked for this service</StyledInfoBar>
            );
          case 'action':
            return (
              <RemoveButtonContainer>
                <Button
                  iconRound
                  icon={<RemoveIcon />}
                  variant="ghost"
                  onClick={() => removePurchaseItem(item?.id ?? '')}
                />
              </RemoveButtonContainer>
            );
          case 'discount':
            return item.price === 0 ? null : (
              <Button
                variant="outlined"
                size="small"
                onClick={() => setDiscountDialogPurchaseItem(item)}
                disabled={item.price === 0}
                iconTrailing={<EditIcon />}
                children={getDiscount(item)}
              />
            );
          default:
            return item[itemPropIndex] as React.ReactNode;
        }
      },
      [
        appointments,
        formatCurrency,
        getDiscount,
        removePurchaseItem,
        setDiscountDialogPurchaseItem,
        setStaffDialogPurchaseItem,
        staffOptions
      ]
    );

    return (
      <Accordion
        expanded={expanded && !isFormDisabled}
        disabled={isFormDisabled}
        onChange={() => setExpanded(state => !state)}
      >
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Text>Summary</Text>
        </AccordionSummary>
        <AccordionDetails>
          {!!purchaseItems.length ? (
            <ContainerDetails>
              <Table<PurchaseItemWithActionColumn>
                animateRowOnEnter={true}
                columns={columns}
                items={purchaseItems}
                renderCell={renderCell}
              />
              {totalDiscountMessage && <TotalDiscount>{totalDiscountMessage}</TotalDiscount>}
              <TotalCost>{totalPriceMessage}</TotalCost>
            </ContainerDetails>
          ) : (
            <EmptyState title="No items added" details="Items added will be displayed here." darkTitle fillHeight />
          )}
        </AccordionDetails>
      </Accordion>
    );
  }
);

export {totalToCharge}
export {SummarySection};

const ACTION_COLUMN_WIDTH = 112;

const TotalCost = styled.span`
  display: block;
  text-align: right;
  font-size: ${14 / 16}rem;
  padding: ${({theme}) => theme.spacing(1, 1.5)};
  margin-right: ${ACTION_COLUMN_WIDTH}px;
`;

const TotalDiscount = styled.span`
  display: block;
  text-align: right;
  font-size: ${14 / 16}rem;
  padding: ${({theme}) => theme.spacing(1, 1.5)};
  margin-right: ${ACTION_COLUMN_WIDTH}px;
  color: #777;
`;

const ContainerDetails = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

const StyledInfoBar = styled(InfoBar)`
  min-height: '36.5px';
  div {
    padding: 0;
  }
`;

const RemoveButtonContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const columns: Column[] = [
  {id: 'staff', label: 'Staff'},
  {id: 'title', label: 'Description'},
  {id: 'info', label: 'Info', style: {width: 350}},
  {id: 'discount', label: 'Discount', style: {width: 140}},
  {id: 'quantity', label: 'Quantity', style: {textAlign: 'right', width: 20}},
  {id: 'price', label: 'Price', style: {textAlign: 'right', width: 80}},
  {id: 'action', label: 'Action', style: {textAlign: 'center', width: ACTION_COLUMN_WIDTH}}
];

const getAppointmentInfo = (appointmentId: string, appointments?: ClinicAppointmentModel[]) => {
  const appointment = appointments?.find(appt => appt.appointmentId === appointmentId);
  return (
    appointment && formatUTCDate(getZonedDate(appointment.appointmentDate, appointment.startTime), 'MON_DATE_YEAR')
  );
};
