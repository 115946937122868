// http://stackoverflow.com/questions/5623838/rgb-to-hex-and-hex-to-rgb
function componentToHex(c: number) {
  const hex = c.toString(16);
  return hex.length === 1 ? '0' + hex : hex;
}
function rgbToHex(r: number, g: number, b: number) {
  return '#' + componentToHex(r) + componentToHex(g) + componentToHex(b);
}

export const fromColorIntToHex = (color?: number | null): string => {
  if (!color) return ``;
  try {
    // eslint-disable-next-line
    const [, r, g, b] = ((color >>> 0) as any) // Typescript does not play well with unsigned int
      .toString(2)
      .match(/.{1,8}/g)
      .map((el: string) => parseInt(el, 2));
    return rgbToHex(r, g, b);
  } catch {
    return '';
  }
};
