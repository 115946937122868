import React from 'react';
import styled from 'styled-components';
import {Client, VoucherModel} from 'types/DerivedApiModel';
import SectionContent from '../SectionContent';
import CustomerVouchersTable from './CustomerVouchersTable';
import {useBusinessContext} from 'hooks';
import useAllVouchers from 'hooks/vouchers/useAllVouchers';
import SectionHeader from '../SectionHeader';
import {isBefore} from 'date-fns';
import {newDateTranslatedToTenantTZ} from 'utils';

interface Props {
  client: Client;
}

const CustomerVouchers: React.FC<React.PropsWithChildren<Props>> = ({client}) => {
  const {businessId} = useBusinessContext();
  const clientId = client.clientId;
  const {data, isFetching: vouchersLoading} = useAllVouchers({query: {businessId, clientId, includeUsed: true}});
  const {vouchers: clientVouchers} = data ?? {};

  const {expired, remainingBalance, zeroRemainingBalance} = clientVouchers?.reduce(voucherStatusReducer, {
    expired: [],
    remainingBalance: [],
    zeroRemainingBalance: []
  }) ?? {expired: [], remainingBalance: [], zeroRemainingBalance: []};

  return (
    <div>
      <SectionHeader children={'Active Vouchers'} />
      <SectionContent hasSidePadding={false}>
        <Container>
          <CustomerVouchersTable
            vouchers={remainingBalance}
            isLoading={vouchersLoading}
            emptyStateMessage="No active vouchers associated with this client"
          />
        </Container>
      </SectionContent>
      <SectionHeader children={'Consumed Vouchers'} />
      <SectionContent hasSidePadding={false}>
        <Container>
          <CustomerVouchersTable
            vouchers={zeroRemainingBalance}
            isLoading={vouchersLoading}
            emptyStateMessage="No consumed vouchers associated with this client"
          />
        </Container>
      </SectionContent>
      <SectionHeader children={'Expired Vouchers'} />
      <SectionContent hasSidePadding={false}>
        <Container>
          <CustomerVouchersTable
            vouchers={expired}
            isLoading={vouchersLoading}
            emptyStateMessage="No expired vouchers associated with this client"
            remainingBalanceLabel="Expired balance"
          />
        </Container>
      </SectionContent>
    </div>
  );
};

export default CustomerVouchers;

function voucherStatusReducer(
  acc: {expired: VoucherModel[]; remainingBalance: VoucherModel[]; zeroRemainingBalance: VoucherModel[]},
  voucher: VoucherModel
) {
  if (!voucher) return acc;
  if (voucher.expiryDate && isBefore(new Date(voucher.expiryDate), newDateTranslatedToTenantTZ())) {
    acc.expired.push(voucher);
    return acc; // don't add to other arrays if expired
  }
  if ((voucher.remainingBalance ?? 0) > 0) {
    acc.remainingBalance.push(voucher);
  } else {
    acc.zeroRemainingBalance.push(voucher);
  }
  return acc;
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
`;
