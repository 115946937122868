import {FormControlLabel, Popover, Switch} from '@material-ui/core';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import PersonOutlineIcon from '@material-ui/icons/PersonOutline';
import {Typography} from '@therapie-ui/Typography/Typography';
import {Auth} from 'aws-amplify';
import {BUSINESS_CODE} from 'constants/business';
import COLORS from 'constants/colors';
import {ROUTES} from 'constants/routes';
import usePermission from 'hooks/permission/usePermission';
import {useIsAdminContext} from 'hooks/router/useIsAdminContext';
import {useFeatureFlag} from 'hooks/utils/useFeatureFlag';
import React, {useCallback, useRef} from 'react';
import {useNavigate} from 'react-router-dom';
import {useRecoilValue} from 'recoil';
import {userEmailState, userHandleState, userState} from 'state';
import styled from 'styled-components';
import {AppBarButton} from './AppBarButton';

interface Props {
  showUserHandle?: boolean;
}

const UserContext: React.FC<React.PropsWithChildren<Props>> = React.memo(({showUserHandle = true}) => {
  const anchorRef = useRef<HTMLDivElement>(null);
  const [anchorEl, setAnchorEl] = React.useState<HTMLDivElement | null>(null);
  const hasPermission = usePermission();
  const isAdminContext = useIsAdminContext();
  const isAdminSectionFFOn = useFeatureFlag('adminSection');
  const isAdminSwitchVisible = isAdminSectionFFOn && hasPermission('ADMIN');
  const navigate = useNavigate();
  const user = useRecoilValue(userState);
  const userHandle = useRecoilValue(userHandleState);
  const userEmail = useRecoilValue(userEmailState);
  const onClick = useCallback(() => setAnchorEl(anchorRef.current), []);
  const onClose = useCallback(() => setAnchorEl(null), []);
  const onAdminSwitchClick = useCallback(
    (e: React.ChangeEvent<{}>) => {
      e.preventDefault();
      // We have to specify businessCode instead of trusting redirection
      // Redirection as built is App.tsx uses a static link defined on <App /> initial render and gets deprecated if business is switched during the same session
      navigate(
        isAdminContext ? `/${ROUTES.BUSINESS}/${BUSINESS_CODE}` : `/${ROUTES.BUSINESS}/${BUSINESS_CODE}/${ROUTES.ADMIN}`
      );
    },
    [isAdminContext, navigate]
  );
  if (!user) return <Container />;

  return (
    <Container>
      <AppBarButton
        iconLeading={<PersonOutlineIcon />}
        children={showUserHandle ? userHandle : ''}
        onClick={onClick}
        isFocused={!!anchorEl}
        data-test-id={'user-handle'}
      />
      <MenuAnchor ref={anchorRef} />
      <Popover
        anchorEl={anchorEl}
        anchorReference="anchorEl"
        open={Boolean(anchorEl)}
        onClose={onClose}
        // marginThreshold stops the popup getting near the edge of the window
        // and defaults to 16. It can cause a lot of hassle when you don't know
        // about it and you need to position something nearer to the edge
        marginThreshold={8}
        transitionDuration={120}
      >
        <PopupContent>
          <User>
            <Typography children={userHandle} variant="h4" block />
            <Typography children={userEmail} variant="body" block />
          </User>
          {isAdminSwitchVisible && (
            <AdminSwitch
              onChange={onAdminSwitchClick}
              label={<Typography children={'Admin'} variant="h5" />}
              control={<Switch checked={isAdminContext} name="systemAdministrator" />}
              labelPlacement="start"
            />
          )}
        </PopupContent>
        <LogoutFooter onClick={() => Auth.signOut()}>
          <ExitToAppIcon className="icon" fontSize={'small'} htmlColor={COLORS.night100} />
          <Typography children={'Log out'} variant="body" />
        </LogoutFooter>
      </Popover>
    </Container>
  );
});

export {UserContext};

const Container = styled.div`
  position: relative;
`;

const MenuAnchor = styled.div`
  position: absolute;
  right: 0;
  top: 38px; // Needs to vertically align with the region and clinic popups
`;

const PopupContent = styled.main`
  min-width: 320px;
  color: ${COLORS.night400};
`;

const User = styled.div`
  padding: ${({theme}) => theme.spacing(2, 3)};
`;

const AdminSwitch = styled(FormControlLabel)`
  width: 100%;
  margin: ${({theme}) => theme.spacing(0, 0, 2)};
  padding: ${({theme}) => theme.spacing(1, 1.5, 1, 3)};
  justify-content: space-between;
  transition: background 200ms ease;
  &:hover {
    background: ${COLORS.ice600};
  }
`;

const LogoutFooter = styled.footer`
  cursor: pointer;
  display: flex;
  padding: ${({theme}) => theme.spacing(2, 3)};
  background: ${COLORS.ice400};
  gap: ${({theme}) => theme.spacing(1)};
  color: ${COLORS.night400};
  transition: background 200ms ease;
  &:hover {
    background: ${COLORS.ice600};
  }
`;
