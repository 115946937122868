import {useCallback, useMemo} from 'react';
import {useResetRecoilState} from 'recoil';
import {purchaseClientState, purchaseDefaultStaffIdState} from 'state/atoms/purchase';
import {useCardPaymentFlow} from './Stripe/useCardPaymentFlow';
import {usePaymentTypes} from './usePaymentTypes';
import {IUsePurchaseAppointmentsOutput} from './usePurchaseAppointments';
import {usePurchaseStaff} from './usePurchaseStaff';
import {usePurchaseSummaryItems} from './usePurchaseSummaryItems';

interface Output {
  cleanState: (props: {callback: () => void}) => void;
  resetPurchaseAtoms: () => void;
}

interface Input {
  clearAppointmentDateInUrl: IUsePurchaseAppointmentsOutput['clearAppointmentDateInUrl'];
}

const usePurchaseCleanState = ({clearAppointmentDateInUrl}: Input): Output => {
  const {clear: resetPurchaseItemsAtoms} = usePurchaseSummaryItems();
  const resetPurchaseDefaultStaffIdState = useResetRecoilState(purchaseDefaultStaffIdState);

  const {
    list: paymentTypeList,
    updateInstances,
    resetAmounts,
    resetAtoms: resetPaymentTypesAtoms,
    defaultPaymentInstances
  } = usePaymentTypes();
  const {resetAtoms: resetPurchaseStaffAtoms} = usePurchaseStaff();
  const resetPurchaseClientAtom = useResetRecoilState(purchaseClientState);
  const {resetCardPaymentFlow} = useCardPaymentFlow();

  const cleanPurchaseState = useCallback(() => {
    if (!(paymentTypeList && paymentTypeList.length)) return;
    resetPurchaseDefaultStaffIdState();
    resetPurchaseItemsAtoms();
    resetAmounts();
    resetCardPaymentFlow();
    updateInstances(defaultPaymentInstances);
    clearAppointmentDateInUrl();
  }, [
    clearAppointmentDateInUrl,
    defaultPaymentInstances,
    paymentTypeList,
    resetAmounts,
    resetCardPaymentFlow,
    resetPurchaseDefaultStaffIdState,
    resetPurchaseItemsAtoms,
    updateInstances
  ]);

  const cleanState = useCallback(
    (props: {callback: () => void}) => {
      cleanPurchaseState();
      props.callback();
    },
    [cleanPurchaseState]
  );

  const resetPurchaseAtoms = useCallback(() => {
    resetPaymentTypesAtoms();
    resetPurchaseItemsAtoms();
    resetPurchaseStaffAtoms();
    resetPurchaseClientAtom();
    resetCardPaymentFlow();
  }, [
    resetCardPaymentFlow,
    resetPaymentTypesAtoms,
    resetPurchaseClientAtom,
    resetPurchaseItemsAtoms,
    resetPurchaseStaffAtoms
  ]);

  return useMemo(() => ({cleanState, resetPurchaseAtoms}), [cleanState, resetPurchaseAtoms]);
};

export {usePurchaseCleanState};
