import {BUSINESS_CODE} from 'constants/business';
import React, {useEffect} from 'react';
import {useNavigate} from 'react-router';

const BusinessRedirect: React.FC = React.memo(() => {
  const navigate = useNavigate();

  useEffect(() => {
    BUSINESS_CODE && navigate(BUSINESS_CODE); // TODO: Do we need this...
  }, [navigate]);

  return null;
});

export {BusinessRedirect};
