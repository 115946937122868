import {EmptyState} from '@therapie-ui';
import AppBar from '@therapie-ui/AppBar/AppBar';
import {Amplify} from 'aws-amplify';
import Private from 'components/Auth/Private';
import Public from 'components/Auth/Public';
import {BusinessRedirect} from 'components/Business/BusinessRedirect';
import {ClinicRedirect} from 'components/Clinic/ClinicRedirect';
import NavSideBar from 'components/Common/NavSideBar/NavSideBar';
import {FetchUser} from 'components/InitActions/FetchUser';
import {VersionManager} from 'components/VersionManager/VersionManager';
import {AWS_AMPLIFY_CONFIG} from 'config';
import {BUSINESS_CODE} from 'constants/business';
import {TOP_BAR_HEIGHT} from 'constants/layout';
import {ROUTES} from 'constants/routes';
import {SIZES} from 'constants/sizes';
import {RANDOM_LOADING_MESSAGE} from 'constants/status';
import React, {Suspense} from 'react';
import {Navigate, Route, Routes} from 'react-router-dom';
import styled from 'styled-components';
import {checkIsMobileDevice} from 'utils/checkIsMobileDevice';
import {CtxStorage} from 'utils/storage/contextStorage';
import {InitActions} from './components';
import AppErrorBoundary from './components/ErrorBoundaries/AppErrorBoundary';
import './css/index.css';
import Providers from './providers';
import {
  AdminCourseEditRoute,
  AdminCoursesRoute,
  AdminOnlineGroupsRoute,
  AdminOnlineSubGroupEditRoute,
  AdminOnlineSubGroupsRoute,
  AdminProductEditRoute,
  AdminProductsRoute,
  AdminServiceEditRoute,
  AdminServicesRoute,
  ClientsRoute,
  ClinicRoute,
  CreateBookingRoute,
  CreateClientRoute,
  DiaryRoute,
  EditClientRoute,
  LoginPageRoute,
  LoginRedirectRoute,
  MultiRescheduleRoute,
  NotFoundRoute,
  PaymentStripeRoute,
  PurchaseRoute,
  SearchClientRoute,
  SelectTreatmentsRoute
} from './Routes';
import './types/global';

Amplify.configure(AWS_AMPLIFY_CONFIG);

const App: React.FC<React.PropsWithChildren<unknown>> = () => {
  if (checkIsMobileDevice())
    return <EmptyState icon="warning" title={'Error'} details={'Application not supported for mobile phone usage.'} />;

  return (
    <AppErrorBoundary>
      <Providers>
        <FetchUser />
        <VersionManager />

        <Public>
          <Routes>
            <Route path="*" element={<LoginPageRoute />} />
            <Route path={ROUTES.LOGIN_REDIRECT} element={<LoginRedirectRoute />} />
          </Routes>
        </Public>

        <Private
          render={({isCallCentreAgent}) => {
            const HOME_ROUTE = isCallCentreAgent ? ROUTES.AGENTS_HOME_ROUTE : ROUTES.DEFAULT_HOME_ROUTE;
            return (
              <Suspense
                fallback={
                  <EmptyState icon="loading" title={'Please wait'} details={RANDOM_LOADING_MESSAGE} size={SIZES.XL} />
                }
              >
                <InitActions />
                <StyledMainSection>
                  <Routes children={<Route element={<AppBar />} path="*" />} />
                  <Body>
                    <Routes children={<Route element={<NavSideBar />} path="*" />} />
                    <ContentContainer>
                      <Routes>
                        <Route path={ROUTES.SEARCH_CLIENT} element={<SearchClientRoute />} />
                        <Route path={ROUTES.BUSINESS}>
                          <Route index element={<BusinessRedirect />} />
                          <Route path={BUSINESS_CODE}>
                            <Route index element={<Navigate to={ROUTES.CLINIC} />} />

                            <Route path={ROUTES.ADMIN}>
                              <Route index element={<Navigate to={`${ROUTES.ADMIN_PRODUCTS}`} />} />
                              <Route path={ROUTES.ADMIN_PRODUCTS} element={<AdminProductsRoute />} />
                              <Route path={ROUTES.ADMIN_PRODUCT_EDIT} element={<AdminProductEditRoute />} />
                              <Route path={ROUTES.ADMIN_SERVICES} element={<AdminServicesRoute />} />
                              <Route path={ROUTES.ADMIN_SERVICE_EDIT} element={<AdminServiceEditRoute />} />
                              <Route path={ROUTES.ADMIN_COURSES} element={<AdminCoursesRoute />} />
                              <Route path={ROUTES.ADMIN_COURSE_EDIT} element={<AdminCourseEditRoute />} />
                              <Route path={ROUTES.ADMIN_ONLINE_GROUPS} element={<AdminOnlineGroupsRoute />} />
                              <Route path={ROUTES.ADMIN_ONLINE_SUB_GROUPS} element={<AdminOnlineSubGroupsRoute />} />
                              <Route
                                path={ROUTES.ADMIN_ONLINE_SUBGROUP_EDIT}
                                element={<AdminOnlineSubGroupEditRoute />}
                              />
                            </Route>

                            <Route path={ROUTES.CLINIC}>
                              <Route index element={<ClinicRedirect />} />
                              <Route path={':clinicId'} element={<ClinicRoute />}>
                                <Route index element={<Navigate to={HOME_ROUTE} />} />
                                <Route path={ROUTES.DIARY} element={<DiaryRoute />} />
                                <Route path={ROUTES.PURCHASE} element={<PurchaseRoute />} />
                                <Route path={ROUTES.PAYMENT_STRIPE_STANDALONE} element={<PaymentStripeRoute />} />
                                <Route path={ROUTES.CLIENTS}>
                                  <Route index={true} element={<ClientsRoute />} />
                                  <Route path={ROUTES.CREATE_CLIENT} element={<CreateClientRoute />} />
                                  <Route path={ROUTES.EDIT_CLIENT} element={<EditClientRoute />} />
                                  <Route path={ROUTES.CREATE_BOOKING} element={<CreateBookingRoute />} />
                                  <Route path={ROUTES.SELECT_TREATMENTS} element={<SelectTreatmentsRoute />} />
                                  <Route path={ROUTES.MULTI_RESCHEDULE_BOOKING} element={<MultiRescheduleRoute />} />
                                </Route>
                                <Route index element={<Navigate to={HOME_ROUTE} />} />
                              </Route>
                            </Route>
                          </Route>
                        </Route>
                        {/* NOTE:  CtxStorage.clinicId get evaluated only once, for index / is probably ok */}
                        <Route
                          index
                          element={
                            <Navigate
                              to={`/${ROUTES.BUSINESS}/${BUSINESS_CODE}/${ROUTES.CLINIC}/${CtxStorage.clinicId}/${HOME_ROUTE}`}
                            />
                          }
                        />
                        <Route path="*" element={<NotFoundRoute />} />
                      </Routes>
                    </ContentContainer>
                  </Body>
                </StyledMainSection>
              </Suspense>
            );
          }}
        />
      </Providers>
    </AppErrorBoundary>
  );
};

export default App;

const StyledMainSection = styled.main`
  display: grid;
  grid-template-rows: ${TOP_BAR_HEIGHT} auto;
  height: 100%;
  width: 100%;
`;
const Body = styled.div`
  display: grid;
  grid-template-columns: auto 1fr;
`;
const ContentContainer = styled.div`
  width: 100%;
  display: flex;
`;
