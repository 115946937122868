import {gql} from 'utils/graphql';

export const CREATE_PURCHASE = gql`
  mutation CREATE_PURCHASE($payload: CreatePurchaseInput!) {
    createPurchase(payload: $payload) {
      clientId
      purchaseId
      number
      transactionNumber
      payments {
        paymentId
        type
        amount
      }
      items {
        details {
          taxAmount
          taxIncluded
          taxRates {
            name
            rate
          }
          totalAmount
        }
        price
        purchaseItemId
        quantity
        staffId
      }
      details {
        taxAmount
        taxIncluded
        taxRates {
          name
          rate
        }
        totalAmount
        changeAmount
      }
    }
  }
`;

export const CREATE_PURCHASE_TRANSACTION_SYNC = gql`
  mutation CREATE_PURCHASE_TRANSACTION_SYNC($payload: CreatePurchaseTransactionInput!) {
    createPurchaseTransactionSync(payload: $payload) {
      id
      clientId
      createDateISO
      updateDateISO
      status
      clinicId
      currentStep
      currentStepStatus
      errors
      phorest {
        response {
          number
          clientId
          purchaseId
          transactionNumber
          details {
            changeAmount
          }
          payments {
            amount
            customTypeCode
            type
            paymentId
            customTypeName
          }
          voucherBalances {
            voucherId
            oldBalance
            newBalance
          }
          items {
            appointmentId
            branchProductId
            courseId
            description
            outstandingBalancePayment
            price
            purchaseItemId
            quantity
            staffId
          }
        }
      }
      stripe {
        response {
          livemode
          amount_capturable
          currency
          id
          client_secret
          payment_method
          capture_method
          amount
          created
          payment_method_types
          amount_received
          confirmation_method
          customer
          status
        }
      }
    }
  }
`;
