import {atom} from 'recoil';
import {ServiceHistoryModel} from 'types/DerivedApiModel';

const appointments = atom<ServiceHistoryModel[]>({
  key: 'appointments',
  default: []
});

const isNewAppointmentDialogOpen = atom<boolean>({
  key: 'isNewAppointmentDialogOpen',
  default: false
});

export {appointments, isNewAppointmentDialogOpen};
