import config, {DEVELOPMENT} from 'config';

export function confirmCRUDinDEV(clinicId: string): void {
  const isProd = config.ENVIRONMENT === 'production';
  if (isProd || isTestClinic(clinicId)) return;
  const isOK = window.confirm(
    'We are not in production, you probably wanted to update Test clinic, press OK to get fired.'
  );
  if (!isOK) throw new Error('Operation aborted');
}

export const isTestClinic = (clinicId: string): boolean =>
  [DEVELOPMENT.ROI.TEST_CLINIC_ID, DEVELOPMENT.UK.TEST_CLINIC_ID, DEVELOPMENT.US.TEST_CLINIC_ID].includes(clinicId);
