import config from 'config';

function setKlarnaPaymentTypeId() {

const phorestRegion = (config.PHOREST_REGION as string).toLowerCase();

if (phorestRegion == 'ie')
 {
    return 'r7Rm8mzskagkEB2alTxuyA';
 }
 else if (phorestRegion == 'uk')
 {
    return 'mO67zF-8Mn4Gtq4ARsrWbg';
 }
else if (phorestRegion == 'us')
 {
    return 'DOaw1cdtXGnjqxIsWTCgyQ';
 }
 else
 {
    return 'falser7Rm8mzskagkEB2alTxuyA';
 }
}

export {setKlarnaPaymentTypeId}