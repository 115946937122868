import {Tooltip} from '@material-ui/core';
import PersonAddIcon from '@material-ui/icons/PersonAdd';
import {Button, Card, ResultList} from '@therapie-ui';
import CustomerViewContainer from 'components/Clients/ViewClients/CustomerView/CustomerViewContainer';
import useQueryVariablesWithPhonePrefixed from 'components/Clients/ViewClients/useQueryVariablesWithPhonePrefixed';
import useClientSearchParams from 'components/Clients/ViewClients/useSearchParams';
import {PageContainerInnerFixedWidth, PageContainerScrollable} from 'components/Common/Layout/PageContainer';
import {TOP_BAR_HEIGHT} from 'constants/layout';
import {QUERY_KEYS} from 'constants/query';
import {ROUTES} from 'constants/routes';
import useClients from 'hooks/client/useClients';
import {useAppNavigation} from 'hooks/navigation/useAppNavigation';
import useUrlParams from 'hooks/router/useUrlParams';
import useDebouncedValue from 'hooks/utils/useDebouncedValue';
import React, {useEffect, useMemo, useState} from 'react';
import styled from 'styled-components';
import {ClientsInput} from 'types/ApiModel';
import {Client} from 'types/DerivedApiModel';
import {mergeClientsQueryProps} from 'utils';
import ClientSearch from '../../Common/Clients/ClientSearch';

const Clients: React.FC<React.PropsWithChildren<unknown>> = () => {
  // --- Vars
  const urlParams = useUrlParams();
  const {navigate} = useAppNavigation();
  const urlSearchParams = useUrlParams();

  const {clientId, email, firstName, lastName, phone, showOnlyClientView: hideResultList} = useClientSearchParams();
  const queryVariables = useMemo(
    () => mergeClientsQueryProps({email, phone, firstName, lastName}),
    [email, phone, firstName, lastName]
  );
  const {debouncedValue: debouncedQueryVariables, isReady} = useDebouncedValue(queryVariables);
  // We need to prefix numbers in case is missing...very super AI here, be careful
  const debouncedQueryVariablesWithPhonePrefixed = useQueryVariablesWithPhonePrefixed(debouncedQueryVariables);

  // --- Data Fetching
  const {
    isInitialLoading,
    isFetching,
    error,
    data: dataClient
  } = useClients(debouncedQueryVariablesWithPhonePrefixed!.query);

  // this state is needed to avoid carret jumping -> https://dev.to/kwirke/solving-caret-jumping-in-react-inputs-36ic
  const [clientInputValue, setClientInputValue] = useState<ClientsInput>(queryVariables.query);

  // --- Callbacks
  const onUpdateQuery = async (clientInput: ClientsInput) => {
    const {businessId, email, firstName, lastName, phone} = clientInput;
    setClientInputValue({businessId, firstName, lastName, email, phone});
    urlParams.delete(QUERY_KEYS.CLIENT_ID);
    urlParams.delete(QUERY_KEYS.SHOW_ONLY_CLIENT_VIEW);
    Object.entries(clientInput).map(([key, value]) => urlParams.set(key, value ?? ''));
    navigate({urlSearchParams: urlParams});
  };

  const onClientSelect = (client: Client) => {
    if (!client.clientId) return;
    const cleanUrl = new URLSearchParams();
    cleanUrl.set(QUERY_KEYS.SHOW_ONLY_CLIENT_VIEW, 'true');
    cleanUrl.set(QUERY_KEYS.CLIENT_ID, client?.clientId || '');
    navigate({urlSearchParams: cleanUrl});
  };

  // -- Computed Values for render
  const clientIdToShowInTheView = clientId || dataClient?.clients?.[0]?.clientId || '';
  const showResultList =
    isInitialLoading ||
    isFetching ||
    (!error && !hideResultList && dataClient?.clients && dataClient?.clients.length > 1);

  useEffect(() => {
    setClientInputValue(queryVariables.query);
  }, [queryVariables.query]);

  return (
    <PageContainerScrollable>
      <PageContainerInnerFixedWidth>
        <ClientsContainer>
          <HeaderCard>
            <SearchContainer>
              <ClientSearch onUpdateQuery={onUpdateQuery} query={clientInputValue}>
                {showResultList && (
                  <ResultList
                    clients={dataClient?.clients}
                    loading={isInitialLoading || isFetching || !isReady}
                    selectedId={clientIdToShowInTheView}
                    onSelect={onClientSelect}
                  />
                )}
              </ClientSearch>
            </SearchContainer>
            <ButtonsContainer>
              <Tooltip title="Create client">
                <CreateClientButton
                  aria-label="create-client"
                  data-test-id="create-client-btn"
                  variant="ghost"
                  size="medium"
                  iconRound
                  icon={<PersonAddIcon />}
                  onClick={() => navigate({toRoute: ROUTES.CLIENTS_CREATE_CLIENT, urlSearchParams})}
                />
              </Tooltip>
            </ButtonsContainer>
          </HeaderCard>
          <MainCard>
            <CustomerViewContainer
              hasAttemptedSearch={!!(firstName || lastName || email || phone)}
              clientId={clientIdToShowInTheView}
            />
          </MainCard>
        </ClientsContainer>
      </PageContainerInnerFixedWidth>
    </PageContainerScrollable>
  );
};

export default Clients;

const ClientsContainer = styled.div`
  display: flex;
  flex-direction: column;
  min-height: calc(100vh - ${TOP_BAR_HEIGHT});
`;

const HeaderCard = styled(Card)`
  display: flex;
  margin: ${({theme}) => theme.spacing(2, 0)};
  padding: ${({theme}) => theme.spacing(2)};
`;

const MainCard = styled(Card)`
  margin-bottom: ${({theme}) => theme.spacing(5)};
  padding: ${({theme}) => theme.spacing(2)};
  flex-grow: 1;
  display: flex;
  flex-direction: column;
`;

const CreateClientButton = styled(Button)`
  height: 50px;
  min-width: 50px;
`;

const SearchContainer = styled.div`
  width: 100%;
`;

const ButtonsContainer = styled.div`
  padding: ${({theme}) => theme.spacing(1.5, 1.5, 1.5, 0)};
`;
