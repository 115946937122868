import * as Sentry from '@sentry/react';
import {EmptyState} from '@therapie-ui';
import {SIZES} from 'constants/sizes';
import {styledTheme} from 'providers/theme';
import {useState} from 'react';
import {ThemeProvider} from 'styled-components';
import {EmptyStateIconKey} from '../../@therapie-ui/EmptyState/EmptyStateIcon';

const AppErrorBoundary: React.FC<React.PropsWithChildren<unknown>> = ({children}) => {
  const [errorName, setErrorName] = useState<string>('');
  const [details, setDetails] = useState<string | null>(null);
  const [icon, setIcon] = useState<EmptyStateIconKey | undefined>(undefined);
  const [showReloadOption, setShowReloadOption] = useState<boolean>(false);
  const [showGoHomeOption, setShowGoHomeOption] = useState<boolean>(false);

  const testError = (error: Error) => {
    const errorType = error?.toString().split(':')?.[0];
    if (errorType === 'ChunkLoadError') {
      setErrorName('A new release has been detected, please reload the page');
      setDetails(null);
      setIcon('refresh');
      setShowReloadOption(true);
      setShowGoHomeOption(false);
    } else {
      setErrorName("We're sorry, something has gone wrong. Please reload the page");
      setDetails('The error has been sent to the tech team. You can try to reload to get back to what you were doing');
      setIcon('sadface');
      setShowReloadOption(true);
      setShowGoHomeOption(true);
    }
  };

  return (
    <Sentry.ErrorBoundary
      onError={error => testError(error)}
      fallback={
        <ThemeProvider theme={styledTheme}>
          <EmptyState
            icon={icon}
            title={errorName}
            details={details}
            size={SIZES.XL}
            showReloadOption={showReloadOption}
            showGoHomeOption={showGoHomeOption}
          />
        </ThemeProvider>
      }
    >
      {children}
    </Sentry.ErrorBoundary>
  );
};

export default AppErrorBoundary;
