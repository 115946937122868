import {VOUCHER_PAYMENT_TYPE_CODE, KLARNA_PAYMENT_TYPE_CODE} from 'constants/payments';
import {useCallback, useMemo} from 'react';
import {SetterOrUpdater, useRecoilCallback, useRecoilValue, useResetRecoilState, useSetRecoilState} from 'recoil';
import {paymentTypeInstancesState, paymentTypeListState, paymentTypeListStatusState} from 'state/atoms/purchase';
import {
  createPurchaseSelector,
  defaultPaymentInstancesSelector,
  paymentTypeInstancesSelector,
  paymentTypeListSelector,
  stripePaymentInstancesSelector
} from 'state/selectors/purchase';
import {CreatePurchaseInput} from 'types/ApiModel';
import {PaymentType} from 'types/DerivedApiModel';
import {PaymentTypeInstance} from '../types';

interface IUsePaymentTypesOutput {
  instances: PaymentTypeInstance[];
  getCreatePurchaseVariables: () => CreatePurchaseInput;
  defaultPaymentInstances: PaymentTypeInstance[];
  stripePaymentInstances: PaymentTypeInstance[];
  list: PaymentType[];
  loading: boolean;
  resetAmounts: () => void;
  resetAtoms: () => void;
  voucherPaymentType?: PaymentType;
  klarnaPaymentType?: PaymentType;
  updateInstances: SetterOrUpdater<PaymentTypeInstance[]>;
}

function usePaymentTypes(): IUsePaymentTypesOutput {
  const paymentTypeList = useRecoilValue(paymentTypeListSelector);
  const paymentTypeInstances = useRecoilValue(paymentTypeInstancesSelector);
  const setPaymentTypeInstances = useSetRecoilState(paymentTypeInstancesState);
  const resetPaymentTypeListState = useResetRecoilState(paymentTypeListState);
  const resetPaymentTypeListStatusState = useResetRecoilState(paymentTypeListStatusState);
  const resetPaymentTypeInstances = useResetRecoilState(paymentTypeInstancesState);
  const defaultPaymentInstances = useRecoilValue(defaultPaymentInstancesSelector);
  const stripePaymentInstances = useRecoilValue(stripePaymentInstancesSelector);

  const getCreatePurchaseVariables: IUsePaymentTypesOutput['getCreatePurchaseVariables'] = useRecoilCallback(
    ({snapshot}) =>
      () =>
        snapshot.getLoadable(createPurchaseSelector).contents,
    []
  );

  const resetAmounts = useCallback(() => {
    setPaymentTypeInstances(prevState => prevState.map(el => ({...el, amount: undefined})));
  }, [setPaymentTypeInstances]);

  const resetAtoms = useCallback(() => {
    resetPaymentTypeListState();
    resetPaymentTypeListStatusState();
    resetPaymentTypeInstances();
  }, [resetPaymentTypeInstances, resetPaymentTypeListState, resetPaymentTypeListStatusState]);

  const voucherPaymentType = useMemo(
    () => paymentTypeList.data.find(({code}) => code === VOUCHER_PAYMENT_TYPE_CODE),
    [paymentTypeList.data]
  );

  const klarnaPaymentType = useMemo(
    () => paymentTypeList.data.find(({code}) => code === KLARNA_PAYMENT_TYPE_CODE),
    [paymentTypeList.data]
  );

  return useMemo(
    () => ({
      getCreatePurchaseVariables,
      instances: paymentTypeInstances,
      defaultPaymentInstances,
      list: paymentTypeList.data,
      loading: Boolean(paymentTypeList.loading || paymentTypeList.fetching),
      stripePaymentInstances,
      resetAmounts,
      resetAtoms,
      voucherPaymentType,
      klarnaPaymentType,
      updateInstances: setPaymentTypeInstances
    }),
    [
      getCreatePurchaseVariables,
      paymentTypeInstances,
      paymentTypeList,
      resetAmounts,
      resetAtoms,
      setPaymentTypeInstances,
      stripePaymentInstances,
      voucherPaymentType,
      klarnaPaymentType,
      defaultPaymentInstances
    ]
  );
}

export {usePaymentTypes};
