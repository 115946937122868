import {SEVERITY} from 'constants/notifications';
import {OptionsObject, useSnackbar} from 'notistack';
import {useMemo} from 'react';
import {truncateTextAndAddEllipsis} from 'utils/text';

interface IUseNotificationHandlingOutput {
  notifyError: (message: string) => void;
  notifyInfo: (message: string) => void;
  notifySuccess: (message: string) => void;
  notifyWarning: (message: string) => void;
}

export interface INotificationProps {
  message?: string | null | undefined;
}

function useNotifications(): IUseNotificationHandlingOutput {
  const {enqueueSnackbar} = useSnackbar();

  return useMemo(
    () => ({
      notifySuccess: (message: string) =>
        message && enqueueSnackbar(truncateTextAndAddEllipsis(message), {...commonOptions, variant: SEVERITY.SUCCESS}),
      notifyInfo: (message: string) =>
        message && enqueueSnackbar(truncateTextAndAddEllipsis(message), {...commonOptions, variant: SEVERITY.INFO}),
      notifyError: (message: string) =>
        message &&
        enqueueSnackbar(truncateTextAndAddEllipsis(message), {
          ...commonOptions,
          autoHideDuration: 10000,
          variant: SEVERITY.ERROR
        }),
      notifyWarning: (message: string) =>
        message &&
        enqueueSnackbar(truncateTextAndAddEllipsis(message), {
          ...commonOptions,
          autoHideDuration: 100000,
          variant: SEVERITY.WARNING
        })
    }),
    [enqueueSnackbar]
  );
}

export {useNotifications};
export default useNotifications;

const commonOptions: OptionsObject = {
  anchorOrigin: {horizontal: 'center', vertical: 'top'},
  autoHideDuration: 5000,
  preventDuplicate: true
};
