import * as Yup from 'yup';
import {CreateClientObjectInput} from 'types/ApiModel';
import {isAfter, isBefore, isValid} from 'date-fns';

export const MANDATORY_FIELDS: Array<keyof CreateClientObjectInput> = ['firstName', 'lastName', 'email', 'mobile'];

export const maxMobileLength = 20;

const isValidMobilePhone = (phoneNumber?: string): boolean => {
  if (!phoneNumber?.startsWith('353') && !phoneNumber?.startsWith('44')) return true;
  return (
    phoneNumber?.startsWith('3537') ||
    phoneNumber?.startsWith('3538') ||
    phoneNumber?.startsWith('447') ||
    phoneNumber?.startsWith('448')
  );
};

export const minBirthDate = new Date('1920-01-01');
export const maxBirthDate = new Date();

const isValidBirthDate = (date?: string): boolean => {
  if (!date) return true;
  const dateObject = new Date(date);
  return isValid(dateObject) && isAfter(dateObject, minBirthDate) && isBefore(dateObject, maxBirthDate);
};
export const clientFormSchema = Yup.object().shape({
  client: Yup.object().shape({
    firstName: Yup.string().min(2, 'Must be at least 2 letters.').required('Required'),
    lastName: Yup.string().min(2, 'Must be at least 2 letters.').required('Required'),
    email: Yup.string().email('Please enter a valid email address.').required('Required'),
    mobile: Yup.string()
      .min(9, 'Phone number is too short.')
      .max(maxMobileLength, 'Phone number is too long.')
      .matches(/[0-9]+/, {message: 'Must only contain numbers.'})
      .test('isValidMobilePhone', 'Phone number must start with 7 or 8.', phoneNumber =>
        isValidMobilePhone(phoneNumber)
      )
      .required('Required'),
    birthDate: Yup.string()
      .test('isValidBirthDate', 'Date is invalid.', date => isValidBirthDate(date))
      .nullable()
  })
});
