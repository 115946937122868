import React from 'react';
import styled from 'styled-components';

interface Props {
  hasSidePadding?: boolean;
}

const SectionContent: React.FC<React.PropsWithChildren<Props>> = ({children, hasSidePadding = true}) => (
  <SegmentContent children={children} hasSidePadding={hasSidePadding} />
);

export default SectionContent;

const SegmentContent = styled.div<Props>`
  padding: ${({theme, hasSidePadding}) => (hasSidePadding ? theme.spacing(0, 3, 3, 3) : theme.spacing(0, 0, 3))};
`;
