import {ClientModelForPurchase} from 'components/Purchase/types';
import {PURCHASE_ATOM_PREFIX} from 'constants/purchase';
import {atom} from 'recoil';

const purchaseClientState = atom<ClientModelForPurchase>({
  key: `${PURCHASE_ATOM_PREFIX}ClientState`,
  default: undefined
});

export {purchaseClientState};
