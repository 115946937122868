import {useLocation} from 'react-router-dom';
import {AppSection} from 'constants/permissions';
import {ROUTES} from 'constants/routes';
import {useAppNavigation} from 'hooks/navigation/useAppNavigation';
import usePermission from 'hooks/permission/usePermission';
import {useCallback, useEffect, useMemo, useState} from 'react';
import {ADMIN_SECTIONS, SECTIONS} from 'constants/navigation';
import {useIsAdminContext} from 'hooks/router/useIsAdminContext';
import {useFeatureFlag} from 'hooks/utils/useFeatureFlag';

interface Output {
  handleNavClick: (section: AppSection) => void;
  activeSection?: AppSection;
  sections: typeof SECTIONS;
}

export const useNavBarNavigation = (): Output => {
  const {navigate} = useAppNavigation();
  const [activeSection, setActiveSection] = useState<AppSection>();
  const {pathname} = useLocation();
  const hasPermission = usePermission();
  const isAdminContext = useIsAdminContext();
  const paymentStripeStandalone = useFeatureFlag('paymentStripeStandalone');

  const handleNavClick = useCallback(
    (section: AppSection) => {
      // Clinic
      section === 'CLIENTS' && navigate({toRoute: ROUTES.CLIENTS});
      section === 'DIARY' && navigate({toRoute: ROUTES.DIARY});
      section === 'PURCHASE' && navigate({toRoute: ROUTES.PURCHASE});
      section === 'PAYMENT_STRIPE_STANDALONE' && navigate({toRoute: ROUTES.PAYMENT_STRIPE_STANDALONE});

      // Admin
      section === 'ADMIN_PRODUCTS' && navigate({toRoute: ROUTES.ADMIN_PRODUCTS});
      section === 'ADMIN_SERVICES' && navigate({toRoute: ROUTES.ADMIN_SERVICES});
      section === 'ADMIN_COURSES' && navigate({toRoute: ROUTES.ADMIN_COURSES});
      section === 'ADMIN_ONLINE_GROUPS' && navigate({toRoute: ROUTES.ADMIN_ONLINE_GROUPS});
    },
    [navigate]
  );

  useEffect(() => {
    // Clinic
    pathname.includes(`/${ROUTES.DIARY}`) && setActiveSection('DIARY');
    pathname.includes(`/${ROUTES.CLIENTS}`) && setActiveSection('CLIENTS');
    pathname.includes(`/${ROUTES.PURCHASE}`) && setActiveSection('PURCHASE');
    pathname.includes(`/${ROUTES.PAYMENT_STRIPE_STANDALONE}`) && setActiveSection('PAYMENT_STRIPE_STANDALONE');

    // Admin
    pathname.includes(`/${ROUTES.ADMIN_PRODUCTS}`) && setActiveSection('ADMIN_PRODUCTS');
    pathname.includes(`/${ROUTES.ADMIN_SERVICES}`) && setActiveSection('ADMIN_SERVICES');
    pathname.includes(`/${ROUTES.ADMIN_COURSES}`) && setActiveSection('ADMIN_COURSES');
    pathname.includes(`/${ROUTES.ADMIN_ONLINE_GROUPS}`) && setActiveSection('ADMIN_ONLINE_GROUPS');
  }, [pathname]);

  const sections = useMemo(
    () =>
      (isAdminContext ? ADMIN_SECTIONS : SECTIONS)
        .filter(section => hasPermission(section.name))
        // Show PAYMENT_STRIPE_STANDALONE only if FF enabled
        .filter(({name}) => (name === 'PAYMENT_STRIPE_STANDALONE' && !paymentStripeStandalone ? false : true))
        .filter(({name}) => (name === 'PURCHASE' && paymentStripeStandalone ? false : true)),
    [hasPermission, isAdminContext, paymentStripeStandalone]
  );

  return useMemo(() => ({handleNavClick, activeSection, sections}), [handleNavClick, activeSection, sections]);
};
