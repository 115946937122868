import {CognitoUserSession} from 'amazon-cognito-identity-js';
import {atom} from 'recoil';

export interface UserAttributes {
  email: string;
  roles: string[];
}
export interface UserInterface {
  attributes: UserAttributes;
  signInUserSession: CognitoUserSession;
}

const userState = atom<UserInterface | undefined>({key: 'userState', default: undefined});
const userIsLoadingState = atom<boolean>({key: 'userIsLoadingState', default: false});

export {userState, userIsLoadingState};
