import {GET_VOUCHERS} from 'data/graphql/queries';
import {GQLClientContext} from 'providers/gqlClient';
import {useContext, useMemo} from 'react';
import {useQuery, UseQueryResult} from '@tanstack/react-query';
import {GET_VOUCHERSQuery, GET_VOUCHERSQueryVariables} from 'types/ApiModel';
import {Nullable} from 'types/common';

interface Input extends GET_VOUCHERSQueryVariables {
  enabled?: boolean;
}

type Output = UseQueryResult<GET_VOUCHERSQuery['getVouchers'], Error>;

function useVouchers({query: {businessId, clientId, serialNumber}, page, enabled = true}: Input): Output {
  const pageNumber = page?.page;
  const size = page?.size;
  const {gqlClient} = useContext(GQLClientContext);
  const isArgsValid = useMemo(() => !!(businessId && (clientId || serialNumber)), [businessId, clientId, serialNumber]);
  const vouchersQuery = useMemo<Nullable<GET_VOUCHERSQueryVariables>>(
    () => (isArgsValid ? {query: {businessId, clientId, serialNumber}, page: {page: pageNumber, size}} : undefined),
    [businessId, clientId, isArgsValid, pageNumber, serialNumber, size]
  );

  return useQuery<GET_VOUCHERSQuery['getVouchers'], Error>(
    [USE_VOUCHERS_KEY, vouchersQuery],
    async () => {
      const resp = await gqlClient.request<GET_VOUCHERSQuery, Nullable<GET_VOUCHERSQueryVariables>>(
        GET_VOUCHERS,
        vouchersQuery
      );
      return resp.data?.getVouchers ?? null;
    },
    {enabled: !!vouchersQuery && enabled}
  );
}

export type {Output};
export {useVouchers};
export default useVouchers;

export const USE_VOUCHERS_KEY = 'USE_VOUCHERS';
