import {QUERY_KEYS} from 'constants/query';
import {useBusinessContext} from 'hooks';
import {useUrlParams} from 'hooks/router/useUrlParams';
import {ClientsInput} from 'types/ApiModel';

function useClientSearchParams(): Partial<ClientsInput & {clientId: string | null; showOnlyClientView: string | null}> {
  const urlParams = useUrlParams();
  const {businessId} = useBusinessContext();
  const phone = urlParams.get(QUERY_KEYS.PHONE_NUMBER);

  return {
    businessId,
    clientId: urlParams.get(QUERY_KEYS.CLIENT_ID),
    email: urlParams.get(QUERY_KEYS.EMAIL),
    firstName: urlParams.get(QUERY_KEYS.FIRST_NAME),
    lastName: urlParams.get(QUERY_KEYS.LAST_NAME),
    phone,
    showOnlyClientView: urlParams.get(QUERY_KEYS.SHOW_ONLY_CLIENT_VIEW)
  };
}

export default useClientSearchParams;
export {useClientSearchParams};
