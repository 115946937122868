import React from 'react';
import {Dialog as MUIDialog, DialogProps as MuiDialogProps} from '@material-ui/core';

type DialogProps = MuiDialogProps;

const Dialog: React.FC<React.PropsWithChildren<DialogProps>> = props => (
  <MUIDialog PaperProps={{style: {borderRadius: 16}}} {...props} />
);

export default Dialog;
