import Tracing from 'components/Tracing/Tracing';
import {createRoot} from 'react-dom/client';
import App from './App';

const container = document.getElementById('root')!;
const root = createRoot(container);

root.render(
  // <React.StrictMode> Removed for now because of double render: periodical ticket to fix warnings https://therapie.atlassian.net/browse/CC-2085
  <>
    <Tracing />
    <App />
  </>
);
