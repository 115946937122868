import {VOUCHER_PAYMENT_TYPE_CODE} from 'constants/payments';
import {NUA_VOUCHER_PREFIX} from 'constants/purchase';
import {useBusinessContext} from 'hooks/business/useBusinessContext';
import {useFeatureFlag} from 'hooks/utils/useFeatureFlag';
import useVouchers from 'hooks/vouchers/useVouchers';
import {useMemo} from 'react';
import {useRecoilValue} from 'recoil';
import {purchaseClientState} from 'state/atoms/purchase';
import {getRandomInteger} from 'utils/numbers';
import {PaymentTypeInstance} from '../types';
import {usePaymentTypes} from './usePaymentTypes';

interface IUsePurchaseAutoNuaVoucherOutput {
  nuaVoucherInstances: PaymentTypeInstance[];
  loadingClientVouchers: boolean;
}

function useAutoAddNuaVoucher(): IUsePurchaseAutoNuaVoucherOutput {
  const {businessId} = useBusinessContext();
  const client = useRecoilValue(purchaseClientState);
  const isPaymentVoucherNuaAsADefaultEnabled = useFeatureFlag('paymentVoucherNuaAsADefault');
  const clientId = client === 'walkin' ? null : client?.clientId;
  const {list: paymentTypeList} = usePaymentTypes();
  const voucherPaymentTypeId = paymentTypeList.find(type => type.code === VOUCHER_PAYMENT_TYPE_CODE)?.paymentTypeId;
  const {data: vouchers, isInitialLoading: loadingClientVouchers} = useVouchers({
    query: {businessId, clientId},
    enabled: isPaymentVoucherNuaAsADefaultEnabled
  });

  const nuaVoucherInstances = useMemo(() => {
    return (
      vouchers?.vouchers
        //  Only auto-populate NUA credits
        ?.filter(voucher => voucher.serialNumber?.toUpperCase().startsWith(NUA_VOUCHER_PREFIX))
        .map(voucher => ({
          amount: undefined,
          archived: false,
          banked: false,
          builtIn: false,
          code: VOUCHER_PAYMENT_TYPE_CODE,
          id: voucher.voucherId ?? getRandomInteger().toString(),
          name: 'Voucher',
          paymentTypeId: voucherPaymentTypeId ?? null,
          voucherId: voucher.voucherId,
          voucherSerialNumber: voucher.serialNumber!,
          voucherBalance: voucher.remainingBalance ?? 0,
          isVoucherNua: true
        })) ?? []
    );
  }, [voucherPaymentTypeId, vouchers?.vouchers]);

  return useMemo(
    () => ({
      nuaVoucherInstances,
      loadingClientVouchers
    }),
    [loadingClientVouchers, nuaVoucherInstances]
  );
}

export {useAutoAddNuaVoucher};
