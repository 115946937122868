import {captureException} from '@sentry/react';
import {GET_ADMIN_SERVICES} from 'data/graphql/queries/adminServices';
import useNotifications from 'hooks/notifications/useNotifications';
import {GQLClientContext} from 'providers/gqlClient';
import {useContext, useMemo} from 'react';
import {useQuery, UseQueryResult} from '@tanstack/react-query';
import {GET_ADMIN_SERVICESQuery, GET_ADMIN_SERVICESQueryVariables} from 'types/ApiModel';
import {Nullable} from 'types/common';

export type IUseAdminServicesOutput = UseQueryResult<GET_ADMIN_SERVICESQuery['getAdminServices'], Error>;

function useAdminServices(variables: Nullable<GET_ADMIN_SERVICESQueryVariables>): IUseAdminServicesOutput {
  const {gqlClient} = useContext(GQLClientContext);
  const {businessId, name, categoryId, onlineStatus} = variables?.query || {};
  const {notifyError} = useNotifications();

  const adminGetServicesQuery = useMemo<Nullable<GET_ADMIN_SERVICESQueryVariables>>(
    () => ({query: {businessId: businessId!, name, categoryId, onlineStatus}, page: variables?.page}),
    [businessId, name, categoryId, onlineStatus, variables?.page]
  );

  return useQuery<GET_ADMIN_SERVICESQuery['getAdminServices'], Error>(
    [USE_ADMIN_SERVICES_KEY, adminGetServicesQuery],
    async () => {
      const resp = await gqlClient.request<GET_ADMIN_SERVICESQuery, Nullable<GET_ADMIN_SERVICESQueryVariables>>(
        GET_ADMIN_SERVICES,
        adminGetServicesQuery
      );
      return resp.data?.getAdminServices ?? null;
    },
    {
      enabled: !!businessId,
      onError: error => {
        const errorString = error.message;
        notifyError(`Error getting services: ${errorString}`);
        captureException(new Error(errorString));
      }
    }
  );
}

export {useAdminServices};

export const USE_ADMIN_SERVICES_KEY = 'ADMIN_GET_SERVICES';
