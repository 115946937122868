import {captureException} from '@sentry/react';
import {UseQueryResult, useQuery} from '@tanstack/react-query';
import {RQ_CACHE_TIMES, RQ_STALE_TIMES} from 'constants/index';
import {MAX_PAGE_SIZE} from 'constants/pagination';
import {GET_BRANCHES} from 'data/graphql/queries';
import {useBusinessContext} from 'hooks/business/useBusinessContext';
import {GQLClientContext} from 'providers/gqlClient';
import {useContext, useMemo} from 'react';
import {GET_BRANCHESQuery, GET_BRANCHESQueryVariables} from 'types/ApiModel';
import {BranchModel} from 'types/DerivedApiModel';

export type IUseClinicsOutput = UseQueryResult<BranchModel[], Error>;

function useClinics(): IUseClinicsOutput {
  const {businessId} = useBusinessContext();
  const variables = useMemo(() => ({query: {businessId}, page: {size: MAX_PAGE_SIZE}}), [businessId]);
  const {gqlClient} = useContext(GQLClientContext);

  return useQuery<BranchModel[], Error>({
    enabled: !!businessId,
    cacheTime: RQ_CACHE_TIMES.useClinics,
    staleTime: RQ_STALE_TIMES.useClinics,
    queryKey: [USE_CLINICS_KEY, variables],
    queryFn: async () => {
      const {data} = await gqlClient.request<GET_BRANCHESQuery, GET_BRANCHESQueryVariables>(GET_BRANCHES, variables);
      return (
        data?.getBranches?.branches
          ?.map(branch => ({...branch, name: branch.name?.trim() ?? ''}))
          .sort((a, b) => ((b?.name as string) < (a?.name as string) ? 1 : -1)) ?? []
      );
    },
    onError: ({message}) => captureException(new Error(message))
  });
}

export {useClinics};
export default useClinics;

export const USE_CLINICS_KEY = 'GET_CLINICS';
