import COLORS from 'constants/colors';
import styled, {css} from 'styled-components';
import {Button, ButtonProps} from '../Button';

type PrimaryNavButtonProps = Pick<ButtonProps, 'icon' | 'children' | 'onClick'> & {isActive: boolean};

// TODO: Consider making this another variant of the DS Button in future
const PrimaryNavButton: React.FC<React.PropsWithChildren<PrimaryNavButtonProps>> = props => (
  <NavButton variant="ghost" size="small" {...props} /> // We're using size small here to get the closest font size to the design, despite the button looking large
);

export {PrimaryNavButton};
export default PrimaryNavButton;

const NavButton = styled(Button)<{isActive: boolean}>`
  display: flex;
  flex-direction: column;
  height: 84px;
  width: 98px;
  max-width: 100%;
  gap: ${({theme}) => theme.spacing(0.5)};
  color: ${COLORS.iconicGreyD20};
  transition: all 200ms ease-out;
  box-shadow: 0 0 0 3px transparent;

  &:hover {
    color: ${COLORS.iconicGreyD40};
    background: ${COLORS.purpleL9064P};
  }

  &:active {
    color: ${COLORS.therapiePurpleD60};
    background: ${COLORS.therapiePurpleL90};
  }

  &:hover:active {
    color: ${COLORS.therapiePurpleD60};
    background: ${COLORS.therapiePurpleL80};
  }

  &:focus {
    color: ${COLORS.iconicGreyD40};
    background: ${COLORS.ice100};
    box-shadow: 0 0 0 3px ${COLORS.therapiePurpleD10 + 0.35 * 16 * 10}; // TODO: alpha util for DS Colours
  }

  ${({isActive}) =>
    isActive &&
    css`
      color: ${COLORS.therapiePurpleD60};
      background: ${COLORS.therapiePurpleL90};
    `}

  > :first-child {
    // Remove icon margin because it's stacked in this layout
    margin: 0;
  }
`;
