import {gql} from 'utils/graphql';

export const UPDATE_ADMIN_PRODUCT = gql`
  mutation UPDATE_ADMIN_PRODUCT($payload: AdminProductInput!) {
    updateAdminProduct(payload: $payload) {
      archived
      brand {
        id
        name
      }
      category {
        id
        name
      }
      code
      compareAtPrice
      deleted
      descriptionHTML
      id
      imageUrls
      measurementQuantity
      measurementUnit
      name
      onlineName
      onlinePriority
      onlineStatus
      outOfStock
      price
      productTypes {
        id
        name
      }
      skinConcerns {
        id
        name
      }
      skinTypes {
        id
        name
      }
      urlHandle
    }
  }
`;
