import {UseQueryResult, useQuery} from '@tanstack/react-query';
import {MAX_PAGE_SIZE} from 'constants/pagination';
import {GET_ALL_CLIENT_COURSES} from 'data/graphql/queries/courses';
import {useBusinessContext} from 'hooks/business/useBusinessContext';
import {GQLClientContext} from 'providers/gqlClient';
import {useContext, useMemo} from 'react';
import {
  GET_CLIENT_COURSESQueryVariables,
  GetAllClientCoursesQuery,
  GetAllClientCoursesQueryVariables
} from 'types/ApiModel';
import {AllClientCoursesModel} from 'types/DerivedApiModel';
import {Nullable} from 'types/common';

interface IUseAllClientCoursesProps {
  clientId?: string | null;
}

export type IUseAllClientCoursesOutput = UseQueryResult<AllClientCoursesModel[] | undefined | null>;

function useAllClientCourses({clientId}: IUseAllClientCoursesProps): IUseAllClientCoursesOutput {
  const {businessId} = useBusinessContext();
  const allClientCoursesQuery = useMemo<Nullable<GetAllClientCoursesQueryVariables>>(
    () => (businessId ? {query: {businessId, clientId}, page: {size: MAX_PAGE_SIZE}} : undefined),
    [businessId, clientId]
  );
  const {gqlClient} = useContext(GQLClientContext);
  return useQuery<AllClientCoursesModel[] | undefined>(
    [USE_ALL_CLIENT_COURSES_KEY, allClientCoursesQuery],
    async () => {
      const resp = await gqlClient.request<GetAllClientCoursesQuery, Nullable<GET_CLIENT_COURSESQueryVariables>>(
        GET_ALL_CLIENT_COURSES,
        allClientCoursesQuery
      );
      return resp.data?.getAllClientCourses?.clientCourses ?? [];
    },
    {enabled: !!allClientCoursesQuery && !!clientId}
  );
}

export const USE_ALL_CLIENT_COURSES_KEY = 'USE_ALL_CLIENT_COURSES';
export {useAllClientCourses};
export default useAllClientCourses;
