export const URL_PARAMS = {
  CLINIC_ID: 'clinicId',
  ZOOM: 'zoom',
  DATE: 'date'
};

export const DRAG_TIME_STEP = 5;
export const DEFAULT_ZOOM = 1;
export const CALENDAR_ZOOM_LEVELS = [60, 30, 20, 15, 10, 5];
export const CALENDAR_ZOOM_COUPLED_FONT_SIZES = ['.6rem', '.7rem', '.8rem', '.8rem', '.9rem', '.9rem'];
export const CALENDAR_TIMELINE_ITEMS_CSS_SELECTOR = '.mbsc-schedule-time-col .mbsc-schedule-time';

export const DIARY_LABELS = {
  UNKNOWN_TREATMENT: 'Unknown treatment',
  IS_COURSE_PREFIX: '[Course]'
};
