import {captureException} from '@sentry/react';
import {GET_ALL_ADMIN_BRANDS} from 'data/graphql/queries/adminProducts';
import {useBusinessContext, useNotifications} from 'hooks';
import {GQLClientContext} from 'providers/gqlClient';
import {useContext} from 'react';
import {useQuery, UseQueryResult} from '@tanstack/react-query';
import {GET_ALL_ADMIN_BRANDSQuery, GET_ALL_ADMIN_BRANDSQueryVariables} from 'types/ApiModel';

export type IUseAdminProductBrands = UseQueryResult<GET_ALL_ADMIN_BRANDSQuery['getAllAdminBrands'], Error>;

function useAdminProductBrands(): IUseAdminProductBrands {
  const {gqlClient} = useContext(GQLClientContext);
  const {notifyError} = useNotifications();
  const {businessId} = useBusinessContext();

  return useQuery<GET_ALL_ADMIN_BRANDSQuery['getAllAdminBrands'], Error>(
    [USE_ADMIN_PRODUCT_BRANDS_KEY],
    async () => {
      const resp = await gqlClient.request<GET_ALL_ADMIN_BRANDSQuery, GET_ALL_ADMIN_BRANDSQueryVariables>(
        GET_ALL_ADMIN_BRANDS,
        {businessId}
      );

      return resp.data.getAllAdminBrands;
    },
    {
      enabled: !!businessId,
      onError: error => {
        const errorString = error.message;
        notifyError(`Error getting brands: ${errorString}`);
        captureException(new Error(errorString));
      }
    }
  );
}

export {useAdminProductBrands};

export const USE_ADMIN_PRODUCT_BRANDS_KEY = 'GET_ADMIN_PRODUCT_BRANDS';
