import {useLocation, useNavigate} from 'react-router-dom';
import {ROUTES, ROUTE_REGEX} from 'constants/routes';
import {useUrlParams} from 'hooks';
import {useCallback, useMemo} from 'react';
import {useRecoilValue} from 'recoil';
import {clinicListState, clinicState} from 'state/atoms/clinic';
import {BranchModel} from 'types/DerivedApiModel';

export interface ClinicOutput {
  clinicId: string;
  clinic?: BranchModel;
  clinicList?: BranchModel[];
  /**
   * Rename this to navigateToClinic ??
   */
  setClinicById: SetClinicByIdFn;
}

interface SelectByIdProps {
  id?: string;
  nextPath?: string;
  urlParams?: URLSearchParams;
  urlParamsString?: string;
  options?: {state?: Record<string, string>};
}

type SetClinicByIdFn = (params: SelectByIdProps) => void;

function useClinicContext(): ClinicOutput {
  const clinic = useRecoilValue(clinicState);
  const clinicList = useRecoilValue(clinicListState);
  const navigate = useNavigate();
  const {pathname} = useLocation();
  const urlParams = useUrlParams();

  const setClinicById: SetClinicByIdFn = useCallback(
    ({id, nextPath, urlParams: newUrlParams, urlParamsString: newUrlParamsString, options}) => {
      if (!id) console.error('useClinicContext', 'setClinicById', 'Empty clinic id');

      const businessPath = pathname.match(ROUTE_REGEX.BUSINESS)?.[0] ?? '';
      const clinicPath = pathname.match(ROUTE_REGEX.CLINIC)?.[0] ?? '';
      const newClinicPath = clinicPath.replace(ROUTE_REGEX.CLINIC, `/${ROUTES.CLINIC}/${id ?? ''}`);

      const newPath = nextPath
        ? `${businessPath}${newClinicPath}/${nextPath}`
        : pathname.replace(ROUTE_REGEX.CLINIC, newClinicPath);

      const urlsParamString = newUrlParamsString ?? newUrlParams?.toString() ?? urlParams?.toString() ?? '';

      return navigate(`${newPath}?${urlsParamString}`, options);
    },
    [urlParams, navigate, pathname]
  );

  return useMemo(
    () => ({clinic, clinicId: clinic?.id ?? '', clinicList, setClinicById}),
    [clinic, clinicList, setClinicById]
  );
}

export {useClinicContext};
export default useClinicContext;
