const sentryFilteredErrorsSampleMessages = {
  CARD_DECLINED: 'your card was declined',
  CARD_EXPIRED: 'card has expired',
  CARD_EXCEEDED_DECLINES: 'exceeded the maximum number of declines on this card',
  CARD_DOES_NOT_SUPPORT_PURCHASE: 'card does not support this type of purchase',
  INSUFFICIENT_FUNDS: 'insufficient funds',
  CARD_INCORRECT_NUMBER: 'card number is incorrect',
  CARD_SECURITY_CODE: 'security code is incorrect',
  CARD_EXPIRATION_MONTH: 'expiration month is invalid',
  CHUNK_LOAD: 'chunk',
  CREATE_CLIENT_MOBILE_EXISTS: 'mobile phone number already exists',
  CREATE_CLIENT_MOBILE_STARTS: 'mobile number must start with',
  CREATE_TRANSACTION_APPOINTMENT_ALREADY_PAID: 'appointment was already paid',
  DELETE_APPOINTMENT: 'delete appointment',
  EXCEEDED_ROOM_CAPACITY: 'exceeded room capacity',
  NETWORK_ERROR: 'network error occurred.',
  STAFF_NOT_WORKING: 'staff not working',
  STAFF_OR_MACHINE_DOUBLE_BOOKED: 'double booked',
  STAFF_UNQUALIFIED: 'staff unqualified',
  STRIPE_ACTION_CANCELED: 'action canceled',
  VOUCHER_ALREADY_IN_USE: 'is already in use',
  YOUR_CARD_DOES_NOT_SUPPORT_THIS_TYPE_OF_PURCHASE: 'your card does not support this type of purchase'
};

export const SENTRY_FILTERED_ERRORS_SAMPLE_MESSAGES: string[] = Object.values(sentryFilteredErrorsSampleMessages);
