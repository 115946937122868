import {CardContent as MUICardContent, CardContentProps, createStyles, makeStyles} from '@material-ui/core';
import React from 'react';

const CardContent: React.FC<React.PropsWithChildren<CardContentProps>> = props => {
  const classes = useStyles();
  return <MUICardContent classes={classes} {...props} />;
};

export default CardContent;

const useStyles = makeStyles(({spacing}) =>
  createStyles({
    root: {padding: spacing(0, 3)}
  })
);
