import {gql} from 'utils/graphql';

export const GET_ADMIN_PRODUCT = gql`
  query GET_ADMIN_PRODUCT($businessId: String!, $id: String!) {
    getAdminProduct(businessId: $businessId, id: $id) {
      archived
      brand {
        id
        name
      }
      category {
        id
        name
      }
      code
      compareAtPrice
      deleted
      descriptionHTML
      id
      imageUrls
      measurementQuantity
      measurementUnit
      name
      onlineName
      onlinePriority
      onlineStatus
      outOfStock
      price
      productTypes {
        id
        name
      }
      skinConcerns {
        id
        name
      }
      skinTypes {
        id
        name
      }
      urlHandle
    }
  }
`;

export const GET_ALL_ADMIN_BRANDS = gql`
  query GET_ALL_ADMIN_BRANDS($businessId: String!) {
    getAllAdminBrands(businessId: $businessId) {
      id
      name
    }
  }
`;

export const GET_ALL_ADMIN_PRODUCT_TYPES = gql`
  query GET_ALL_ADMIN_PRODUCT_TYPES($businessId: String!) {
    getAllAdminProductTypes(businessId: $businessId) {
      id
      name
    }
  }
`;

export const GET_ALL_ADMIN_SKIN_CONCERNS = gql`
  query GET_ALL_ADMIN_SKIN_CONCERNS($businessId: String!) {
    getAllAdminSkinConcerns(businessId: $businessId) {
      id
      name
    }
  }
`;

export const GET_ALL_ADMIN_SKIN_TYPES = gql`
  query GET_ALL_ADMIN_SKIN_TYPES($businessId: String!) {
    getAllAdminSkinTypes(businessId: $businessId) {
      id
      name
    }
  }
`;

export const GET_ADMIN_PRODUCTS = gql`
  query GET_ADMIN_PRODUCTS($query: GetAdminProductsInput!, $page: PageInput) {
    getAdminProducts(query: $query, page: $page) {
      products {
        archived
        brand {
          id
          name
        }
        category {
          id
          name
        }
        code
        compareAtPrice
        descriptionHTML
        deleted
        id
        imageUrls
        measurementQuantity
        measurementUnit
        name
        onlineName
        onlinePriority
        onlineStatus
        outOfStock
        price
        productTypes {
          id
          name
        }
        skinConcerns {
          id
          name
        }
        skinTypes {
          id
          name
        }
        urlHandle
      }
      page {
        number
        size
        totalElements
        totalPages
      }
    }
  }
`;
