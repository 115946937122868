import {captureException} from '@sentry/react';
import {GET_ALL_ADMIN_SKIN_CONCERNS} from 'data/graphql/queries/adminProducts';
import {useBusinessContext, useNotifications} from 'hooks';
import {GQLClientContext} from 'providers/gqlClient';
import {useContext} from 'react';
import {useQuery, UseQueryResult} from '@tanstack/react-query';
import {GET_ALL_ADMIN_SKIN_CONCERNSQuery, GET_ALL_ADMIN_SKIN_CONCERNSQueryVariables} from 'types/ApiModel';

export type IUseAdminSkinConcernsOutput = UseQueryResult<
  GET_ALL_ADMIN_SKIN_CONCERNSQuery['getAllAdminSkinConcerns'],
  Error
>;

function useAdminSkinConcerns(): IUseAdminSkinConcernsOutput {
  const {gqlClient} = useContext(GQLClientContext);
  const {notifyError} = useNotifications();
  const {businessId} = useBusinessContext();

  return useQuery<GET_ALL_ADMIN_SKIN_CONCERNSQuery['getAllAdminSkinConcerns'], Error>(
    [USE_ADMIN_SKIN_CONCERNS_KEY],
    async () => {
      const resp = await gqlClient.request<GET_ALL_ADMIN_SKIN_CONCERNSQuery, GET_ALL_ADMIN_SKIN_CONCERNSQueryVariables>(
        GET_ALL_ADMIN_SKIN_CONCERNS,
        {businessId}
      );

      return resp.data.getAllAdminSkinConcerns;
    },
    {
      enabled: !!businessId,
      onError: error => {
        const errorString = error.message;
        notifyError(`Error getting Skin Concerns data: ${errorString}`);
        captureException(new Error(errorString));
      }
    }
  );
}

export {useAdminSkinConcerns};

export const USE_ADMIN_SKIN_CONCERNS_KEY = 'GET_ADMIN_SKIN_CONCERNS';
