import {TreatmentFilter} from 'components/BookingAppointment/types';
import useTreatments from 'hooks/treatment/useTreatments';
import {useCallback, useEffect, useMemo, useState} from 'react';
import {BookingTreatment} from 'types/common';

interface Output {
  filterTreatments: TreatmentFilter[];
  loading: boolean;
  error: unknown;
  toggleFilterTreatment: (treatment: TreatmentFilter) => void;
}

interface Props {
  branchId: string;
  businessId: string;
  bookingTreatments: BookingTreatment[];
  mapOfClientCourseItemIdsToCourseNames: Record<string, string>;
}

function useFilterTreatments({
  businessId,
  branchId,
  bookingTreatments,
  mapOfClientCourseItemIdsToCourseNames
}: Props): Output {
  const [filterTreatments, setFilterTreatments] = useState<TreatmentFilter[]>([]);
  const treatmentsIdsString = bookingTreatments.map(el => el.serviceId).join('');

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const memoizedTreatments = useMemo(() => bookingTreatments, [treatmentsIdsString]);

  const {
    treatmentsMap,
    loading: loadingTreatments,
    error
  } = useTreatments({
    businessId,
    treatments: memoizedTreatments.map(el => ({treatmentId: el.serviceId || '', branchId}))
  });

  const toggleFilterTreatment = useCallback(
    (treatment: TreatmentFilter) => {
      const newTreatment: TreatmentFilter = {...treatment, selected: !treatment.selected};
      const indexFound = filterTreatments.findIndex(({id}) => id === newTreatment.id);
      const safeIndex = indexFound === -1 ? 0 : indexFound;
      const newList = [...filterTreatments];
      newList[safeIndex] = newTreatment; // replace
      setFilterTreatments(newList);
    },
    [setFilterTreatments, filterTreatments]
  );

  useEffect(() => {
    const hasEnoughDataToCheckAvailability =
      memoizedTreatments.length > 0 && Object.keys(treatmentsMap || {}).length > 0;

    const filterTreatments: TreatmentFilter[] = memoizedTreatments.map(el => ({
      ...((treatmentsMap ?? {})?.[el?.serviceId || ''] ?? {}),
      id: el.serviceId as string,
      selected: el.selected as boolean,
      available: hasEnoughDataToCheckAvailability ? !!treatmentsMap?.[el.serviceId ?? ''] : true,
      courseName: el.clientCourseItemId ? mapOfClientCourseItemIdsToCourseNames[el.clientCourseItemId] : undefined
    }));

    setFilterTreatments(filterTreatments);
  }, [treatmentsMap, memoizedTreatments, mapOfClientCourseItemIdsToCourseNames]);

  return {
    loading: loadingTreatments,
    error,
    filterTreatments,
    toggleFilterTreatment
  };
}

export {useFilterTreatments};
export default useFilterTreatments;
