import {TableCell as MuiTableCell, TableCellProps as MuiTableCellProps} from '@material-ui/core';
import React from 'react';
import styled from 'styled-components';

type TableCellProps = MuiTableCellProps;

const TableCell: React.FC<React.PropsWithChildren<TableCellProps>> = props => <StyledMuiTableCell {...props} />;

export default TableCell;
export {TableCell};

const StyledMuiTableCell = styled(MuiTableCell)`
  line-height: ${({theme}) => theme.fontSize.md};
  font-size: ${({theme}) => theme.fontSize.md};
  padding: ${({theme}) => theme.spacing(0.6, 0)};
`;
