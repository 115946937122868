import {UserAttributes, UserInterface} from 'state';
import {CognitoUserSession} from 'amazon-cognito-identity-js';

export interface UserData {
  signInUserSession: CognitoUserSession;
  attributes: {
    'custom:roles': string;
    email: string;
  };
}

export const userMapper = (user: UserData): UserInterface => ({
  signInUserSession: user?.signInUserSession,
  attributes: {
    email: user?.attributes?.email,
    roles: mapRoles(user?.attributes?.['custom:roles'], user?.attributes?.email)
  } as UserAttributes
});

// TODO: revisit role list, identify if `system` role is more appropriate for tech team
const mapRoles = (rolesString: string, email: string): string[] =>
  rolesString
    ?.replace('[', '')
    .replace(']', '')
    .split(',')
    .map(el => el.trim().toLowerCase());

export const SSO_LOGIN_OK = 'SSO_LOGIN_OK';
