import {gql} from 'utils/graphql';

export const CREATE_BOOKING = gql`
  mutation CreateBooking(
    $booking: CreateBookingInput!
    $clinicId: String!
    $businessId: String!
    $acceptedIssueTypes: [String!]
  ) {
    createBooking(
      booking: $booking
      clinicId: $clinicId
      businessId: $businessId
      acceptedIssueTypes: $acceptedIssueTypes
    ) {
      bookingId
      clientId
      clientAppointmentSchedules {
        clientId
        serviceSchedules {
          alternativeStaffMember
          appointmentId
          branchServiceGroupId
          clientCourseItemId
          endTime
          machineId
          roomId
          serviceGroupItemOptionId
          serviceId
          staffId
          staffRequest
          startTime
        }
      }
    }
  }
`;

export const CANCEL_BOOKING = gql`
  mutation CANCEL_BOOKING($bookingId: String!, $clinicId: String!, $businessId: String!) {
    cancelBooking(bookingId: $bookingId, clinicId: $clinicId, businessId: $businessId)
  }
`;

export const RESCHEDULE_BOOKING = gql`
  mutation RESCHEDULE_BOOKING($payload: RescheduleBookingInput!) {
    rescheduleBooking(payload: $payload) {
      rescheduledAppointment {
        appointmentDate
        appointmentId
        clientCourseItemId
        endDateTimeISO
        machineId
        price
        roomId
        serviceId
        staffId
        staffRequest
        startDateTimeISO
        version
      }
      errors {
        appointmentDate
        appointmentId
        clientCourseItemId
        endDateTimeISO
        machineId
        price
        roomId
        serviceId
        staffId
        staffRequest
        startDateTimeISO
        version
      }
    }
  }
`;
