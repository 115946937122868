import {Button, Dialog, DialogActions, DialogHeader} from '@therapie-ui';
import {useBusinessContext, useCreateBookingUrlParams} from 'hooks';
import useCurrentClientId from 'hooks/client/useCurrentClientId';
import React, {useCallback, useState} from 'react';
import {useRecoilState} from 'recoil';
import {isNewAppointmentDialogOpen} from 'state';
import AddedTreatmentsTable from './AddedTreatmentsTable';
import AvailableTreatmentsTable from './AvailableTreatmentsTable';
import Filters from './Filters';
import useNewBookingDialogData from './useNewBookingDialogData';

// Deprecated

const NewBookingDialog: React.FC<React.PropsWithChildren<unknown>> = () => {
  const {businessId} = useBusinessContext();
  const {clientId} = useCurrentClientId();
  const [branchId, setBranchId] = useState<string>('');
  const [open, setOpen] = useRecoilState(isNewAppointmentDialogOpen);
  const handleClose = useCallback(() => setOpen(false), [setOpen]);
  const [serviceFilterName, setServiceFilterName] = useState<string>('');
  const dialogData = useNewBookingDialogData({branchId, businessId, serviceFilterName});
  const {navigateToCreateBooking} = useCreateBookingUrlParams();
  const ctaDisabled = dialogData.selectedTreatements?.length === 0;

  const viewAvailability = () => {
    navigateToCreateBooking({
      clientId,
      bookingTreatments: dialogData.selectedTreatements.map(el => ({serviceId: el.id, selected: true}))
    });
    setOpen(false);
  };

  return (
    <Dialog open={open} onClose={handleClose} maxWidth="lg">
      <DialogHeader title="Create new appointment" />
      <div>
        <Filters
          branchId={branchId}
          setBranchId={setBranchId}
          serviceNameFilter={serviceFilterName}
          onServiceNameFilterChange={setServiceFilterName}
          businessId={businessId}
        />
        <AvailableTreatmentsTable
          services={dialogData.treatments}
          isLoading={dialogData.isLoading}
          onServiceClick={dialogData.selectService}
        />
        <AddedTreatmentsTable
          services={dialogData.selectedTreatements}
          isLoading={false}
          onServiceClick={dialogData.unselectService}
        />
      </div>
      <DialogActions>
        <Button variant="ghost" onClick={handleClose}>
          Cancel
        </Button>
        <Button variant="filled" onClick={viewAvailability} disabled={ctaDisabled}>
          View Availability
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export {NewBookingDialog};
export default NewBookingDialog;
