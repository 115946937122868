import React from 'react';
import {List as MuiList, ListProps} from '@material-ui/core';
import styled from 'styled-components';

interface Props {
  withBorders?: boolean;
}

const List: React.FC<React.PropsWithChildren<ListProps & Props>> = props => (
  <MuiListContaner withBorders={props.withBorders}>
    <MuiList {...props} />
  </MuiListContaner>
);

export default List;

const MuiListContaner = styled.div<Props>`
  ul {
    border: ${props => (props.withBorders ? '1px solid #dddddd' : '')};
    border-radius: 8px;
    padding: 0;
  }
  li {
    border-bottom: ${props => (props.withBorders ? '1px solid #dddddd' : '')};
  }
  li:last-child {
    border-bottom: 0;
  }
`;
