import {selector} from 'recoil';
import {AtomStafBreakStatusState, staffBreaksState, staffBreaksStatusState} from 'state/atoms';
import {BreakModel} from 'types/DerivedApiModel';

interface SelectorOutput extends AtomStafBreakStatusState {
  data: BreakModel[];
}

/**
 * This is just a convenience selector allowing us to combine our 'split-atom' into a single hook,
 * while letting our DiaryStateHydrate component only mutate the data Atom when the
 * data from ReactQuery is updated
 *
 * Not in use at the moment until state refresh is fixed
 */
const staffBreaksSelector = selector<SelectorOutput>({
  key: 'staffBreaksSelector',
  get: ({get}) => ({
    data: get(staffBreaksState),
    ...get(staffBreaksStatusState)
  })
});

export {staffBreaksSelector};
