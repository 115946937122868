import {ROLES} from 'constants/permissions';
import {selector} from 'recoil';
import {userState} from 'state/atoms';

export const authTokenState = selector<string>({
  key: 'authTokenState',
  get: ({get}) => get(userState)?.signInUserSession?.getAccessToken().getJwtToken() ?? ''
});

export const userHandleState = selector<string>({
  key: 'userHandle',
  get: ({get}) => get(userState)?.attributes?.email?.split('@')?.[0] ?? 'NoUserName'
});

export const userEmailState = selector<string>({
  key: 'userEmailState',
  get: ({get}) => get(userState)?.attributes?.email ?? ''
});

export const isCallCentreAgentState = selector<boolean>({
  key: 'isCallCentreAgentState',
  get: ({get}) => {
    const roles = get(userState)?.attributes?.roles ?? [];
    return roles.every(el => el === ROLES.agent) && roles.length >= 1;
  }
});
