type UserRole = 'admin' | 'agent' | 'supervisor' | 'clinicAgent';
export type Role = Lowercase<UserRole>;

export enum ROLES {
  admin = 'admin',
  agent = 'agent',
  supervisor = 'supervisor',
  clinicagent = 'clinicagent'
}

export type AppSection =
  | '*'
  | 'ADMIN'
  | 'CLIENTS'
  | 'DIARY'
  | 'PURCHASE'
  | 'PAYMENT_STRIPE_STANDALONE'
  | 'ADMIN_PRODUCTS'
  | 'ADMIN_SERVICES'
  | 'ADMIN_COURSES'
  | 'ADMIN_ONLINE_GROUPS';

type ListOfPermissions = {
  [key in Role]: AppSection[];
};

export const PERMISSIONS: ListOfPermissions = {
  admin: ['*'],

  // CALL CENTER
  agent: ['CLIENTS', 'PURCHASE'],
  supervisor: ['CLIENTS', 'DIARY', 'PURCHASE'],

  // CLINIC
  clinicagent: ['CLIENTS', 'DIARY', 'PURCHASE']
};
