import * as Sentry from '@sentry/react';
import {useMutation, UseMutationResult, useQueryClient} from '@tanstack/react-query';
import config from 'config';
import {DATE_FORMAT} from 'constants/time';
import {CREATE_BOOKING} from 'data/graphql/mutations';
import {GET_ALL_APPOINTMENTS} from 'data/graphql/queries';
import {formatInTimeZone} from 'date-fns-tz';
import {useNotifications} from 'hooks';
import useAnalytics from 'hooks/analytics/useAnalytics';
import {USE_ACTIVE_CLIENT_COURSES_KEY} from 'hooks/courses/useActiveClientCourses';
import {GQLClientContext} from 'providers/gqlClient';
import {useContext} from 'react';
import {useRecoilValue} from 'recoil';
import {userState} from 'state';
import {
  CreateBookingMutation,
  CreateBookingMutationVariables,
  GET_ALL_APPOINTMENTSQuery,
  GET_ALL_APPOINTMENTSQueryVariables
} from 'types/ApiModel';
import {ClinicAllAppointmentModel, CreateBookingResponse} from 'types/DerivedApiModel';
import {ErrorWithMessage} from 'types/errors';
import {ErrorCodesAndMessages, parseCustomGQLErrorsForReactQuery} from 'utils/api';
import {confirmCRUDinDEV} from 'utils/devTools';
import {getAnalyticsAttributes} from 'utils/tracing';

interface Input {
  isDiaryContext: boolean;
  fetchAdditionalInfo?: boolean;
  showNotifications: boolean;
}

interface Response {
  createBookingResponse: CreateBookingResponse;
  appointments?: ClinicAllAppointmentModel[];
}

// TODO: the mutating function should accept show notification and not useCreateBooking, update variable to accept showNotifications
type Variables = CreateBookingMutationVariables;
type Output = UseMutationResult<Response, ErrorCodesAndMessages['overrideableErrorCodes'], Variables>;

function useCreateBooking({isDiaryContext, showNotifications = true, fetchAdditionalInfo}: Input): Output {
  const {gqlClient} = useContext(GQLClientContext);
  const {notifySuccess, notifyError} = useNotifications();
  const {mutateAsync: sendAnalytics} = useAnalytics();
  const user = useRecoilValue(userState);
  const queryClient = useQueryClient();

  return useMutation<Response, ErrorCodesAndMessages['overrideableErrorCodes'], Variables>(
    async variables => {
      try {
        confirmCRUDinDEV(variables?.clinicId);
        const resp = await gqlClient.request<CreateBookingMutation, Variables>(CREATE_BOOKING, variables);
        showNotifications && notifySuccess(`Booking created.`);
        sendAnalytics(
          getAnalyticsAttributes({
            actionName: 'createBooking',
            user,
            details: {hasCourseServices: hasAnyCourseService(variables), isDiaryContext, clinicId: variables?.clinicId}
          })
        );
        const createBooking = resp?.data?.createBooking;
        // Caching last created appointment ID to enable Datadog to look for the appointment it created during CRUD Diary Appointment testing
        if (createBooking?.clientAppointmentSchedules?.[0]?.serviceSchedules?.[0]?.appointmentId)
          localStorage.setItem(
            window.THERAPIE_CRM_LAST_CREATED_APPOINTMENT_ID_KEY,
            createBooking?.clientAppointmentSchedules?.[0]?.serviceSchedules?.[0]?.appointmentId
          );

        // Fetch additional information regard the appointment of the client
        let appointments: ClinicAllAppointmentModel[] | undefined = undefined;
        if (fetchAdditionalInfo) {
          const {businessId, clinicId: branchId} = variables;
          const appointmentsIds = getAppointentIds(createBooking);
          const dateString = getDateStringFromCreateBookingResponse(createBooking);
          if (!dateString) throw new Error('Booking completed but failed to fetch additional info');
          // TODO: 1. Maybe use the clinicAppointments hook here instead of this manual query
          // TODO: 2. Update the clinicAppointments hook to use RQ instead of Apollo
          const appointmentsResp = await gqlClient.request<
            GET_ALL_APPOINTMENTSQuery,
            GET_ALL_APPOINTMENTSQueryVariables
          >(GET_ALL_APPOINTMENTS, {
            query: {
              businessId,
              branchId,
              fromDate: dateString,
              toDate: dateString,
              clientId: getClientId(createBooking)
            }
          });
          appointments = appointmentsResp.data?.getAllAppointments?.appointments?.filter(el =>
            appointmentsIds.includes(el.appointmentId ?? '')
          );
          if (!appointments || appointments.length === 0)
            throw new Error('Booking completed but failed to fetch additional info');
        }
        return {createBookingResponse: createBooking, appointments};
      } catch (error) {
        const errorString = `Error Create Booking: ${(error as ErrorWithMessage).message}`;
        console.error(errorString, error);
        Sentry.captureException(new Error(errorString), {extra: {payload: variables}});
        const {overrideableErrorCodes, errorMessage} = parseCustomGQLErrorsForReactQuery(error as Error);
        showNotifications && notifyError(`${errorString}: ${errorMessage}`);
        return Promise.reject(overrideableErrorCodes);
      }
    },
    {
      mutationKey: [USE_CREATE_BOOKING_KEY],
      onSuccess: (_, variables) => {
        hasAnyCourseService(variables) && queryClient.invalidateQueries([USE_ACTIVE_CLIENT_COURSES_KEY]);
      }
    }
  );
}

export {useCreateBooking};
export default useCreateBooking;

export const USE_CREATE_BOOKING_KEY = 'USE_CREATE_BOOKING';

const hasAnyCourseService = (variables: CreateBookingMutationVariables | undefined): boolean =>
  !!variables?.booking?.clientAppointmentSchedules?.find(clientApp =>
    clientApp.serviceSchedules?.find(schedule => !!schedule.clientCourseItemId)
  );

const getClientId = (variables: CreateBookingResponse): string => variables?.clientId ?? '';
const getDateStringFromCreateBookingResponse = (variables: CreateBookingResponse): string | null => {
  const startTimeISO = variables?.clientAppointmentSchedules?.[0].serviceSchedules?.[0]?.startTime;
  if (!startTimeISO) return null;
  // TODO: update our time utils to centralise this formatter - something like formatInTenantTimeZone
  return formatInTimeZone(new Date(startTimeISO), config.TIMEZONE, DATE_FORMAT.YEAR_MONTH_DAY);
};
const getAppointentIds = (variables: CreateBookingResponse): string[] =>
  variables?.clientAppointmentSchedules?.[0].serviceSchedules?.map(el => el?.appointmentId ?? '') ?? [];
