import LoginPage from 'components/Auth/LoginPage';
import LoginRedirect from 'components/Auth/LoginRedirect';
import RouteHasPermission from 'components/Auth/RouteHasPermission';
import {lazy, ReactElement} from 'react';

const AdminOnlineGroups = lazy(
  () => import(/* webpackChunkName: "route-admin-online-groups" */ './components/Admin/OnlineGroups/OnlineGroups')
);
const AdminOnlineSubGroups = lazy(
  () =>
    import(
      /* webpackChunkName: "route-admin-online-sub-groups" */ './components/Admin/OnlineGroups/OnlineSubGroups/OnlineSubGroups'
    )
);
const AdminOnlineSubGroupEdit = lazy(
  () =>
    import(
      /* webpackChunkName: "route-admin-online-subgroups" */ './components/Admin/OnlineGroups/OnlineSubGroups/OnlineSubGroupEdit/OnlineSubGroupEdit'
    )
);
const AdminCourses = lazy(
  () => import(/* webpackChunkName: "route-admin-courses" */ './components/Admin/Courses/Courses')
);
const AdminCourseEdit = lazy(
  () => import(/* webpackChunkName: "route-admin-course-item" */ './components/Admin/Courses/CourseEdit/CourseEdit')
);
const AdminProductsContainer = lazy(
  () => import(/* webpackChunkName: "route-admin-products" */ './components/Admin/Products/Products')
);
const AdminProductEdit = lazy(
  () => import(/* webpackChunkName: "route-admin-product-item" */ './components/Admin/Products/ProductEdit/ProductEdit')
);
const AdminServices = lazy(
  () => import(/* webpackChunkName: "route-admin-services" */ './components/Admin/Services/Services')
);
const AdminServiceEdit = lazy(
  () => import(/* webpackChunkName: "route-admin-service-item" */ './components/Admin/Services/ServiceEdit/ServiceEdit')
);
const Clients = lazy(() => import(/* webpackChunkName: "route-clients" */ './components/Clients/ViewClients/Clients'));
const Clinic = lazy(() => import(/* webpackChunkName: "route-clinic" */ './components/Clinic/ClinicContainer'));
const CreateBooking = lazy(
  () => import(/* webpackChunkName: "route-create-booking" */ './components/BookingAppointment/CreateBooking')
);
const CreateClient = lazy(
  () => import(/* webpackChunkName: "route-create-client" */ './components/Clients/CreateClient/CreateClient')
);
const Diary = lazy(() => import(/* webpackChunkName: "route-diary" */ './components/Diary/Diary'));
const EditClient = lazy(
  () => import(/* webpackChunkName: "route-edit-client" */ './components/Clients/EditClient/EditClient')
);
const MultiRescheduleBooking = lazy(
  () =>
    import(
      /* webpackChunkName: "route-multi-reschedule-booking" */ './components/BookingAppointment/MultiRescheduleBooking'
    )
);
const PurchaseContainer = lazy(
  () => import(/* webpackChunkName: "route-purchase" */ './components/Purchase/PurchaseContainer')
);
const PaymentStripeContainer = lazy(
  () => import(/* webpackChunkName: "route-payment-stripe" */ './components/PaymentStripeStandalone/PaymentStripe')
);
const SearchClient = lazy(
  () => import(/* webpackChunkName: "route-search-client" */ './components/Clients/ViewClients/SearchClient')
);
const SelectTreatments = lazy(
  () =>
    import(
      /* webpackChunkName: "route-select-treatments" */ './components/BookingAppointment/SelectTreatments/SelectTreatments'
    )
);
const NotFound = lazy(() => import(/* webpackChunkName: "route-select-treatments" */ './components/Routes/NotFound'));

const ClientsRoute = (): ReactElement => <Clients />;
const ClinicRoute = (): ReactElement => <Clinic />;
const CreateBookingRoute = (): ReactElement => <RouteHasPermission permission="CLIENTS" children={<CreateBooking />} />;
const CreateClientRoute = (): ReactElement => <RouteHasPermission permission="CLIENTS" children={<CreateClient />} />;
const DiaryRoute = (): ReactElement => <RouteHasPermission permission="DIARY" children={<Diary />} />;
const EditClientRoute = (): ReactElement => <RouteHasPermission permission="CLIENTS" children={<EditClient />} />;
const LoginPageRoute = (): ReactElement => <LoginPage />;
const LoginRedirectRoute = (): ReactElement => <LoginRedirect />;
const MultiRescheduleRoute = (): ReactElement => (
  <RouteHasPermission permission="CLIENTS" children={<MultiRescheduleBooking />} />
);
const AdminOnlineGroupsRoute = (): ReactElement => (
  <RouteHasPermission permission="ADMIN" children={<AdminOnlineGroups />} />
);
const AdminOnlineSubGroupsRoute = (): ReactElement => (
  <RouteHasPermission permission="ADMIN" children={<AdminOnlineSubGroups />} />
);
const AdminOnlineSubGroupEditRoute = (): ReactElement => (
  <RouteHasPermission permission="ADMIN" children={<AdminOnlineSubGroupEdit />} />
);
const AdminCoursesRoute = (): ReactElement => <RouteHasPermission permission="ADMIN" children={<AdminCourses />} />;
const AdminCourseEditRoute = (): ReactElement => (
  <RouteHasPermission permission="ADMIN" children={<AdminCourseEdit />} />
);
const AdminProductsRoute = (): ReactElement => (
  <RouteHasPermission permission="ADMIN" children={<AdminProductsContainer />} />
);
const AdminProductEditRoute = (): ReactElement => (
  <RouteHasPermission permission="ADMIN" children={<AdminProductEdit />} />
);
const AdminServicesRoute = (): ReactElement => <RouteHasPermission permission="ADMIN" children={<AdminServices />} />;
const AdminServiceEditRoute = (): ReactElement => (
  <RouteHasPermission permission="ADMIN" children={<AdminServiceEdit />} />
);
const PurchaseRoute = (): ReactElement => <RouteHasPermission permission="PURCHASE" children={<PurchaseContainer />} />;

const PaymentStripeRoute = (): ReactElement => (
  <RouteHasPermission permission="PURCHASE" children={<PaymentStripeContainer />} />
);
const SearchClientRoute = (): ReactElement => <SearchClient />;
const SelectTreatmentsRoute = (): ReactElement => (
  <RouteHasPermission permission="CLIENTS" children={<SelectTreatments />} />
);
const NotFoundRoute = (): ReactElement => <NotFound />;

export {
  AdminOnlineGroupsRoute,
  AdminOnlineSubGroupEditRoute,
  AdminOnlineSubGroupsRoute,
  AdminCoursesRoute,
  AdminCourseEditRoute,
  AdminProductsRoute,
  AdminProductEditRoute,
  AdminServicesRoute,
  AdminServiceEditRoute,
  ClientsRoute,
  ClinicRoute,
  CreateBookingRoute,
  CreateClientRoute,
  DiaryRoute,
  EditClientRoute,
  LoginPageRoute,
  LoginRedirectRoute,
  MultiRescheduleRoute,
  NotFoundRoute,
  PurchaseRoute,
  PaymentStripeRoute,
  SearchClientRoute,
  SelectTreatmentsRoute
};
