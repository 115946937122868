import {useEffect, useState} from 'react';
import useDebounce from 'react-use/lib/useDebounce';

function useDebouncedValue<T>(value?: T, ms?: number): {debouncedValue: T | undefined; isReady: boolean} {
  const [debouncedValue, setDebouncedValue] = useState(value);
  const [isReady, setReady] = useState(false);

  useDebounce(
    () => {
      setReady(true);
      setDebouncedValue(value);
    },
    ms ?? 500,
    [value]
  );

  useEffect(() => {
    setReady(false);
  }, [value]);

  return {
    debouncedValue,
    isReady
  };
}

export {useDebouncedValue};
export default useDebouncedValue;
