import DateFnsUtils from '@date-io/date-fns';
import {MuiPickersUtilsProvider} from '@material-ui/pickers';
import React, {useContext} from 'react';
import {LocaleContext} from './locale';

/**
 * Simple wrapper for MUI Pickers Provider to allow us to pass in the locale from our locale provider
 */
const MuiProvider: React.FC<React.PropsWithChildren> = ({children}) => {
  const {locale} = useContext(LocaleContext);
  return <MuiPickersUtilsProvider utils={DateFnsUtils} locale={locale} children={children} />;
};

export {MuiProvider};
export default MuiProvider;
