import {gql} from 'utils/graphql';

export const GET_BRANCHES = gql`
  query GET_BRANCHES($query: BranchesInput!, $page: PageInput) {
    getBranches(query: $query, page: $page) {
      branches {
        id
        address1
        address2
        city
        country
        latitude
        longitude
        name
        state
        timeZone
      }
    }
  }
`;

export const GET_CLINIC_WORK_TIMETABLES = gql`
  query GET_CLINIC_WORK_TIMETABLES($query: ClinicWorkTimetableInput!) {
    getClinicWorkTimetables(query: $query) {
      timeSlots {
        date
        startTime
        endTime
        type
      }
    }
  }
`;

export const GET_CLINIC_ROSTER = gql`
  query GET_CLINIC_ROSTER($query: ClinicRosterInput!) {
    getClinicRoster(query: $query) {
      timeSlots {
        date
        startTime
        endTime
        type
      }
    }
  }
`;

export const GET_BRANCH_REVIEW = gql`
  query GetBranchReview($query: ReviewByIdInput!) {
    getBranchReview(query: $query) {
      id
      clientId
      clientFirstName
      clientLastName
      staffId
      staffFirstName
      staffLastName
      rating
      date
      text
      twitterReview
      facebookReview
    }
  }
`;

export const GET_BRANCH_REVIEWS = gql`
  query GetBranchReviews($query: ReviewsInput!, $page: PageInput) {
    getBranchReviews(query: $query, page: $page) {
      reviews {
        id
        clientId
        clientFirstName
        clientLastName
        staffId
        staffFirstName
        staffLastName
        rating
        date
        text
        twitterReview
        facebookReview
      }
      page {
        number
        size
        totalElements
        totalPages
      }
    }
  }
`;
