// Strip emojiis from a string...
// https://developer.mozilla.org/en-US/docs/Web/JavaScript/Guide/Regular_Expressions/Unicode_Property_Escapes
//
// This is not a silver bullet but it will cover a lot of common emojis.
export const stripEmoji = (value: string): string => {
  const emojiRegex = /\p{Emoji_Presentation}/gu;
  return value.replace(emojiRegex, '');
};

const DEFAULT_TRUNCATION_LENGTH = 160;
export const truncateTextAndAddEllipsis = (text: string, limit: number = DEFAULT_TRUNCATION_LENGTH): string =>
  text && text.length > limit ? text.substring(0, limit) + '…' : text;

export const stripSpaceChars = (value: string) => value.replace(/\s/g, '');

export const replaceUnderscoreWithSpaceAndReturnInLowerCase = (element?: string) =>
  element?.toLowerCase().split('_').join(' ');

/**
 * Break a string into chunks.
 * like a credit card number 4319222233334444 > 4319 2222 3333 4444
 * or a SKU 1234512345 > 12345 12345
 * String can be any length - the delimiter will be injected at specified intervals.
 * (The Delimiter can also be any string you like)
 *
 * @param input A string such as a credit card number or SKU
 * @param chunkLength How many chars per chunk? E.g Credit card would use 4
 * @param delimiter What to put between chunks? Space is the default but you might want dashes etc
 * @returns a chunky string
 */
export const chunkify = (input: string, chunkLength: number = 5, delimiter: string = ' '): string | undefined => {
  if (!input || chunkLength > input.length) return input;
  return Array.from(input).reduce(
    (newString: string, currentChar: string, index: number): string =>
      newString +
      currentChar +
      // "Append delimiter if index is a multiple of chunkLength and we're not at the end of the string"
      ((index + 1) % chunkLength === 0 && index + 1 < input.length ? delimiter : ''),
    ''
  );
};
