import {ROUTES} from 'constants/routes';
import {endToEndUtils} from 'test/e2e/utils';
import {windowIsIFramed} from 'utils/window';
import {CfgStorage} from './utils/storage/configStorage';
const baseUrl = window.location.origin ?? 'http://localhost:3000';
const appSyncUrl = CfgStorage.apiUrl ?? process.env.REACT_APP_APP_SYNC_URL;

export const AWS_AMPLIFY_CONFIG = {
  Auth: {
    region: process.env.REACT_APP_AWS_REGION,
    userPoolId: process.env.REACT_APP_USER_POOL_ID,
    userPoolWebClientId: process.env.REACT_APP_USER_POOL_WEB_CLIENT_ID,
    cookieStorage: {
      domain: process.env.REACT_APP_DOMAIN,
      path: '/',
      expires: 365,
      sameSite: 'none',
      secure: true,
      partitioned: false
    },
    oauth: {
      domain: process.env.REACT_APP_OATH_DOMAIN,
      scope: ['email', 'openid', 'profile', 'aws.cognito.signin.user.admin'],
      redirectSignIn: `${baseUrl}/${ROUTES.LOGIN_REDIRECT}`,
      redirectSignOut: baseUrl,
      responseType: 'code'
    },
    federationTarget: 'COGNITO_USER_POOLS'
  },
  aws_appsync_graphqlEndpoint: appSyncUrl,
  aws_appsync_region: process.env.REACT_APP_AWS_REGION,
  aws_appsync_authenticationType: 'AMAZON_COGNITO_USER_POOLS'
};
// (window as any).LOG_LEVEL = 'DEBUG'; //->> to enable aws amplify debug logs

export const DEVELOPMENT = {
  USE_TEST_CLINIC: false,
  ROI: {
    TEST_CLINIC_ID: 'fJcCsw6MQlyj1UnsWs1C6Q',
    TEST_CLIENT_ID: 'ud8pYiN3Im-lJnK0GWLWePnYVQwcfZ9GOXqyGMxZkNM',
    TEST_CLINIC_SERVICE_ID: '_dHHLczwgrHLz_cAaCMGow',
    TEST_STAFF_ID: 'xedYoEaum0BsErjA7bYKnQ'
  },
  UK: {TEST_CLINIC_ID: 'W7b5GRmegfNJ5Sml4GMYdw'},
  US: {TEST_CLINIC_ID: 'DOaw1cdtXGnjqxIsWTCgyQ'},
  USE_SIMULATED_STRIPE_TERMINAL:
    process.env.REACT_APP_STRIPE_IS_SIMULATED_TERMINAL === 'true' || endToEndUtils.isUsingSimulatedTerminal
};

const PAYMENT_CONFIG: Record<'url' | 'apiKey' | 'pgApiKey', string> = {
  apiKey: process.env.REACT_APP_STRIPE_API_KEY ?? '',
  url: process.env.REACT_APP_PAYMENT_GATEWAY ?? '',
  pgApiKey: process.env.REACT_APP_PAYMENT_GATEWAY_API_KEY ?? ''
};

export type ENV = 'production' | 'development' | 'local';

export const KLARNA_TRIAL_PERIOD = true;

export const KLARNA_CLINICS = [
  'fJcCsw6MQlyj1UnsWs1C6Q', // ROI Test
  'zngUPd_gOKPkm9Veg7mdnw', // ROI Dundrum
  'YJUiTfGUF7mw7inWuru5eg', // ROI Galway
  'W7b5GRmegfNJ5Sml4GMYdw', // UK
  'lt6g5onLppHz3dMBU5z4wQ', // London Stratford
  'z4K6nLIdUzUxhbn5kyEo9A', // London Cabot Place
  'DOaw1cdtXGnjqxIsWTCgyQ' // US Test clinic
];

const config = {
  BUSINESS_ID: process.env.REACT_APP_PHOREST_BUSINESS_ID,
  BUSINESS_CODE: process.env.REACT_APP_PHOREST_BUSINESS_CODE,
  APP_COMMIT_ID: (process.env.REACT_APP_COMMIT_ID ?? '').slice(0, 8), // commit ID from pipeline
  BUILD_TIMESTAMP: process.env.REACT_APP_BUILD_TIMESTAMP ?? '',
  APP_SYNC_URL: AWS_AMPLIFY_CONFIG.aws_appsync_graphqlEndpoint,
  BASE_URL: baseUrl,
  ENVIRONMENT: process.env.REACT_APP_ENVIRONMENT as ENV,
  FULLSTORY_ORG_ID: process.env.REACT_APP_FULLSTORY_ORG_ID ?? '114T85', // Therapie Clinic ID -> 114T85, Therapie Test ID -> 150AC3
  LAUNCH_DARKLY_CLIENT_SIDE_ID: process.env.REACT_APP_LAUNCH_DARKLY_CLIENT_SIDE_ID ?? '',
  PRODUCT_FRUITS_WORKSPACE_CODE: process.env.REACT_APP_PRODUCT_FRUITS_WORKSPACE_CODE,
  PAYMENT_CONFIG,
  REGION: AWS_AMPLIFY_CONFIG.aws_appsync_region,
  SENTRY_DNS: 'https://9c39fcfbf2744709bbbe663877e5464e@o918266.ingest.sentry.io/5903965',
  CURRENCY_SYMBOL: process.env.REACT_APP_CURRENCY_SYMBOL,
  CURRENCY: process.env.REACT_APP_CURRENCY,
  TIMEZONE: process.env.REACT_APP_TIMEZONE!,
  LOCALE: process.env.REACT_APP_LOCALE,
  DEFAULT_CLINIC_ID: process.env.REACT_APP_DEFAULT_CLINIC_ID!,
  IS_CALL_CENTRE: windowIsIFramed,
  IS_PRICE_TAX_INCLUSIVE: process.env.REACT_APP_IS_PRICE_TAX_INCLUSIVE === 'true',
  VOUCHER_PAYMENT_TYPE_CODE: process.env.REACT_APP_VOUCHER_PAYMENT_TYPE_CODE,
  ALLOW_PURCHASE_OF_MEDICAL_COURSES: process.env.REACT_APP_ALLOW_PURCHASE_OF_MEDICAL_COURSES !== 'false', // Allowed by default unless explicitly set to false
  KLARNA_SERVICE_URL: process.env.REACT_APP_KLARNA_SERVICE_URL,
  PHOREST_REGION: process.env.REACT_APP_PHOREST_REGION
};

if (!config.VOUCHER_PAYMENT_TYPE_CODE) throw new Error('VOUCHER_PAYMENT_TYPE_CODE is not defined.');

console.log(`Therapie CRM build: ${config.APP_COMMIT_ID}`);

export default config;
