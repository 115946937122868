import {APP_URL_HOSTNAMES} from 'constants/urls';

const {hostname} = window.location;
const {DEV_ROI, PROD_ROI, DEV_UK, PROD_UK, DEV_US, PROD_US} = APP_URL_HOSTNAMES;

export const windowIsIFramed = window.self !== window.top;

// Environment
export const isDevEnv = [DEV_ROI, DEV_UK, DEV_US].includes(hostname);
export const isProdEnv = [PROD_ROI, PROD_UK, PROD_US].includes(hostname);
export const isLocalEnv = 'localhost' === hostname;
export const isPREnv = hostname.startsWith('pr-');

// Developers
export const isDeveloper = () => {
  const urlParams = new URLSearchParams(window.location.search);
  return urlParams.get('isDev') || window.location.origin.includes('localhost');
};
