import {Button, EmptyState} from '@therapie-ui';
import {Auth} from 'aws-amplify';
import {AWS_AMPLIFY_CONFIG} from 'config';
import {BUSINESS_CODE} from 'constants/business';
import COLORS from 'constants/colors';
import {SIZES} from 'constants/sizes';
import {useNotifications} from 'hooks';
import {useNavigateToBusiness} from 'hooks/navigation/useNavigateToBusiness';
import React, {useCallback} from 'react';
import {useRecoilState, useSetRecoilState} from 'recoil';
import {userIsLoadingState, userState} from 'state';
import styled from 'styled-components';
import {ErrorWithMessage} from 'types/errors';
import {visibleBusinesses} from 'utils/business';
import {isDeveloper} from 'utils/window';
import DevFormLogin, {DevAuthState} from './DevFormLogin';
import {SSO_LOGIN_OK, UserData, userMapper} from './utils';
// prettier-ignore
const LoginPage: React.FC<React.PropsWithChildren<unknown>> = React.memo(() => {
  const {notifyError} = useNotifications();
  const {navigateToBusinessById} = useNavigateToBusiness();
  const setUser = useSetRecoilState(userState);
  const [isLoading, setLoading] = useRecoilState(userIsLoadingState);
  const mapAndSetUser = useCallback(
    (user: UserData) => (user ? setUser(userMapper(user)) : setUser(undefined)),
    [setUser]
  );

  const onDevLogin = async (event: DevAuthState) => {
    setLoading(true);
    try {
      const user: UserData = await Auth.signIn(event.username, event.password);
      mapAndSetUser(user);
    } catch (e) {
      notifyError((e as ErrorWithMessage).message ?? 'Something went wrong.');
    }
    setLoading(false);
  };

  const openSSOPage = () => {
    const {
      Auth: {
        userPoolWebClientId,
        oauth: {domain, responseType, redirectSignIn}
      }
    } = AWS_AMPLIFY_CONFIG;
    const newUrl = `https://${domain}/login?response_type=${responseType}&client_id=${userPoolWebClientId}&redirect_uri=${redirectSignIn}`;
    popupCenter(newUrl, 'AWS SSO', 480, 480);
  };


  // Show dealy button if user get stup due AWS amplify storage bug.
  // On go home: logout user and clean up storage
  if (isLoading)
    return (
      <EmptyState
        icon="loading"
        title="Login: Loading..."
        size={SIZES.XL}
        showReloadOption
        delayShowButtons
        actionButton={
          <Button
            variant="outlined"
            children={'Go back home'}
            onClick={async () => {
              await Auth.signOut();
              window.location.href = '/';
            }}
            className={'reload-button'}
            size={'large'}
          />
        }
      />
    );

  return (
    <Container>
      <BusinessSelector>
        {visibleBusinesses.map(({businessId, businessCode, businessAcronym}) => (
          <Button
            children={businessAcronym}
            variant={BUSINESS_CODE === businessCode ? 'outlined' : 'ghost'}
            onClick={() => navigateToBusinessById({businessId})}
          />
        ))}
      </BusinessSelector>
      <Button variant="filled" onClick={openSSOPage} size="large" fullWidth>
        Login
      </Button>

      {isDeveloper() && <DevFormLogin onLogin={onDevLogin} />}
    </Container>
  );
});

export {LoginPage};
export default LoginPage;

const LOGIN_WIDTH = '320px';

const Container = styled.div`
  padding: 0 calc((100vw - ${LOGIN_WIDTH}) / 2);
  background-color: ${COLORS.ice400};
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: auto;
`;

const BusinessSelector = styled.div`
  display: flex;
  gap: ${({theme}) => theme.spacing(2)};
  margin-bottom: ${({theme}) => theme.spacing(4)};
  justify-content: space-between;
`;

// This will be called by child window
window.processChildMessage = (message: string) => message === SSO_LOGIN_OK && window.location.reload();
function popupCenter(pageURL: string, title: string, w: number, h: number) {
  const left = window.screen.width / 2 - w / 2;
  const top = window.screen.height / 2 - h / 2;
  const targetWin = window.open(
    pageURL,
    title,
    `toolbar=no,location=no,directories=no,status=no,menubar=no,scrollbars=no,resizable=no,copyhistory=no,` +
      `width=${w},height=${h},top=${top},left=${left}`
  );
  return targetWin;
}
