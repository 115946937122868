import {GET_CLIENT} from 'data/graphql/queries';
import {GQLClientContext} from 'providers/gqlClient';
import {useContext, useMemo} from 'react';
import {useQuery, UseQueryResult} from '@tanstack/react-query';
import {GET_CLIENTQuery, GET_CLIENTQueryVariables} from 'types/ApiModel';
import {Nullable} from 'types/common';

interface IUseClientProps {
  businessId: string;
  clientId?: string;
  enabled?: boolean;
}

type IUseClientOutput = UseQueryResult<NonNullable<GET_CLIENTQuery['getClient']>, Error>;

function useClient({businessId, clientId, enabled = true}: IUseClientProps): IUseClientOutput {
  const clientQuery = useMemo<Nullable<GET_CLIENTQueryVariables>>(
    () => (businessId && clientId ? {businessId, clientId} : null),
    [businessId, clientId]
  );
  const {gqlClient} = useContext(GQLClientContext);

  return useQuery<NonNullable<GET_CLIENTQuery['getClient']>, Error>(
    [USE_GET_CLIENT_KEY, clientQuery],
    async () => {
      const resp = await gqlClient.request<GET_CLIENTQuery, Nullable<GET_CLIENTQueryVariables>>(
        GET_CLIENT,
        clientQuery
      );
      if (!resp.data?.getClient) throw new Error('Could not get client');
      return resp.data.getClient;
    },
    {enabled: !!clientQuery && enabled}
  );
}

export {useClient};
export default useClient;
export const USE_GET_CLIENT_KEY = 'USE_GET_CLIENT';
