import {Checkbox, Tooltip} from '@material-ui/core'; /* TODO this Checkbox should be coming from @therapie-ui */
import AddIcon from '@material-ui/icons/Add';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import {Button, Divider, EmptyState, LoadingPlaceholder, Text} from '@therapie-ui';
import {QUERY_KEYS} from 'constants/query';
import {ROUTES} from 'constants/routes';
import {useBusinessContext} from 'hooks';
import useCreateBookingUrlParams from 'hooks/booking/useCreateBookingUrlParams';
import useEntityMapsHack from 'hooks/client/useEntityMapsHack';
import useAllClientCourses from 'hooks/courses/useAllClientCourses';
import {useCurrency} from 'hooks/currency/useCurrency';
import {useAppNavigation} from 'hooks/navigation/useAppNavigation';
import usePermission from 'hooks/permission/usePermission';
import {styledTheme} from 'providers/theme';
import React, {useMemo} from 'react';
import {useRecoilState} from 'recoil';
import {branchesMapState} from 'state/atoms/branch';
import styled from 'styled-components';
import {AllClientCoursesModel} from 'types/DerivedApiModel';
import {formatUTCDate, newDateTranslatedToTenantTZ} from 'utils';
import SectionContent from '../SectionContent';
import SectionHeader from '../SectionHeader';
import {isPast} from 'date-fns';

interface Props {
  clientId: string;
}

interface FilteredCourse {
  activeCourses: AllClientCoursesModel[];
  inactiveCourses: AllClientCoursesModel[];
}

const CustomerCourses: React.FC<React.PropsWithChildren<Props>> = ({clientId}) => {
  const {data, isFetching: loading, error} = useAllClientCourses({clientId});
  const courses = useMemo(() => data ?? [], [data]);
  const {businessId} = useBusinessContext();
  const {formatCurrency} = useCurrency();
  const [branchMap] = useRecoilState(branchesMapState);
  const {navigate} = useAppNavigation();
  const {navigateToCreateBooking, localCreateBookingParams, toggleTreatmentToLocalParams} = useCreateBookingUrlParams();
  const hasPermission = usePermission();
  const showNewCourseButton = hasPermission('PURCHASE');

  const {activeCourses, inactiveCourses} = useMemo(
    () =>
      courses.reduce(
        (acc: FilteredCourse, cur) => {
          const filteredCoursesAcc = {...acc};
          const isCurrentCourseActive = !!cur.clientCourseItems?.find(({remainingUnits}) =>
            remainingUnits ? remainingUnits > 0 : false
          );
          const isCurrentCourseInactive = cur.clientCourseItems?.every(({remainingUnits}) =>
            remainingUnits ? remainingUnits < 1 : true
          );
          if (isCurrentCourseActive) filteredCoursesAcc.activeCourses.push(cur);
          if (isCurrentCourseInactive) filteredCoursesAcc.inactiveCourses.push(cur);

          return filteredCoursesAcc;
        },
        {activeCourses: [], inactiveCourses: []}
      ),
    [courses]
  );

  // START - this is an hack we should get courseName and treatmentName from course api
  const {treatmentsMap, loading: loadingMaps} = useEntityMapsHack({businessId, clientCourses: courses});
  // END - this is an hack we should get courseName and treatmentName from course api

  const redirectToPurchaseCourse = () =>
    navigate({
      toRoute: ROUTES.PURCHASE,
      urlSearchParams: new URLSearchParams({[QUERY_KEYS.CLIENT_ID]: clientId, [QUERY_KEYS.OPEN_COURSES_TAB]: 'true'})
    });

  const navigateToAvailability = () =>
    navigateToCreateBooking({
      ...localCreateBookingParams,
      staffId: localCreateBookingParams?.bookingTreatments?.[0]?.staffId,
      clientId,
      clinicId: localCreateBookingParams?.bookingTreatments?.[0]?.clinicId
    });

  if (loading || loadingMaps)
    return (
      <div>
        <SectionHeader>Active Courses</SectionHeader>
        <SectionContent>
          <LoadingPlaceholder padding={0} />
        </SectionContent>
        <SectionHeader>Consumed Courses</SectionHeader>
        <SectionContent>
          <LoadingPlaceholder padding={0} />
        </SectionContent>
      </div>
    );

  if (error)
    return (
      <div>
        <SectionHeader>Active Courses</SectionHeader>
        <SectionContent>
          <EmptyState
            icon="warning"
            title={'Error getting active courses'}
            details={"We can't find this clients courses at the moment."}
            darkTitle
          />
        </SectionContent>
        <SectionHeader>Consumed Courses</SectionHeader>
        <SectionContent>
          <EmptyState
            icon="warning"
            title={'Error getting consumed courses'}
            details={"We can't find this clients courses at the moment."}
            darkTitle
          />
        </SectionContent>
      </div>
    );

  return (
    <div>
      <SectionHeader>
        Active Courses
        <ButtonSection>
          {showNewCourseButton && (
            <Button
              disabled={!!localCreateBookingParams?.bookingTreatments?.length}
              color="primary"
              variant="filled"
              onClick={redirectToPurchaseCourse}
              iconLeading={<AddIcon />}
            >
              New course
            </Button>
          )}
          {activeCourses.length > 0 && (
            <Tooltip
              title="To view availability, at least one treatment must be selected."
              placement="top"
              disableHoverListener={!!localCreateBookingParams?.bookingTreatments?.length}
            >
              <span>
                <AvailabilityButton
                  disabled={!localCreateBookingParams?.bookingTreatments?.length}
                  color="primary"
                  variant="filled"
                  children="View availability"
                  onClick={navigateToAvailability}
                  iconLeading={<ChevronRightIcon />}
                />
              </span>
            </Tooltip>
          )}
        </ButtonSection>
      </SectionHeader>
      <SectionContent>
        {activeCourses.length === 0 ? (
          <EmptyState
            title={'No active courses'}
            details={'If a client has any active courses they will be shown here'}
            darkTitle
          />
        ) : (
          activeCourses.map((course, indexCourses) => (
            <div key={course.courseId}>
              <StyledCourse key={course.clientCourseId}>
                <StyledRow>
                  <StyledCourseTitleText variant="title">
                    {course.courseName ?? 'Course Name Not available'}
                  </StyledCourseTitleText>
                </StyledRow>
                <StyledRow>
                  <Text variant="pill" style={{opacity: '0.6'}}>
                    {branchMap[course.purchasingBranchId as string]?.name} ·{' '}
                    {formatUTCDate(newDateTranslatedToTenantTZ(course.purchaseDate), 'DAY_DATE_MONTH_YEAR')} ·{' '}
                    {/* ^^^^^^^^^ TODO: CC-3220 See comment in formatUTCDate - I think this can cause headaches. */}
                    {/* It causes course purchasedate (yyyymmdd) to show as previous day when viewed from Milan, for example  */}
                    {formatCurrency({value: course.grossPrice})}
                    {course.expiryDate ? isPast(new Date(course.expiryDate)) && `${' '}· Expired` : null}
                  </Text>
                </StyledRow>

                <StyledTreatmentList>
                  {course.clientCourseItems?.map((courseItem, index) => (
                    <StyledTreatmentItem
                      isActive={
                        !isTreatmentDisabled({
                          remainingUnits: courseItem.remainingUnits,
                          isExpired: course.expiryDate ? isPast(new Date(course.expiryDate)) : undefined
                        })
                      }
                      key={`${courseItem.clientCourseItemId} ${index}`}
                      onClick={_ => {
                        if (
                          isTreatmentDisabled({
                            remainingUnits: courseItem.remainingUnits,
                            isExpired: course.expiryDate ? isPast(new Date(course.expiryDate)) : undefined
                          })
                        )
                          return;
                        toggleTreatmentToLocalParams({
                          selected: true,
                          serviceId: courseItem.serviceId,
                          courseId: course.courseId,
                          clientCourseItemId: courseItem.clientCourseItemId,
                          clinicId: course.purchasingBranchId
                        });
                      }}
                    >
                      <TreatmentContainer>
                        {!isTreatmentDisabled({
                          remainingUnits: courseItem.remainingUnits,
                          isExpired: course.expiryDate ? isPast(new Date(course.expiryDate)) : undefined
                        }) ? (
                          <StyledCheckbox
                            checked={
                              !!localCreateBookingParams?.bookingTreatments?.find(
                                item => item.clientCourseItemId === courseItem.clientCourseItemId
                              )
                            }
                          />
                        ) : (
                          <StyledCheckbox disabled defaultChecked indeterminate />
                        )}
                        <TreatmentDetailsContainer>
                          <StyledCourseDescriptionItem>
                            {treatmentsMap?.[courseItem.serviceId as string]?.name}
                          </StyledCourseDescriptionItem>
                          <StyledCourseDetailsRow>
                            <Text variant="pill" children={`Purchased: ${courseItem.initialUnits}`} />
                            <Text variant="pill" children={`Remaining: ${courseItem.remainingUnits}`} />
                          </StyledCourseDetailsRow>
                        </TreatmentDetailsContainer>
                      </TreatmentContainer>
                    </StyledTreatmentItem>
                  ))}
                </StyledTreatmentList>
              </StyledCourse>
              {indexCourses < (courses?.length || 0) - 1 && <Divider />}
            </div>
          ))
        )}
      </SectionContent>

      <SectionHeader>Consumed Courses</SectionHeader>
      <SectionContent>
        {inactiveCourses.length === 0 ? (
          <EmptyState
            title={'No consumed courses'}
            details={'If a client has any consumed courses they will be shown here'}
            darkTitle
          />
        ) : (
          inactiveCourses.map((course, indexCourses) => (
            <div key={course.courseId}>
              <StyledCourse key={course.clientCourseId}>
                <StyledRow>
                  <StyledCourseTitleText variant="title">
                    {course.courseName ?? 'Course Name Not available'}
                  </StyledCourseTitleText>
                </StyledRow>
                <StyledRow>
                  <Text variant="pill" style={{opacity: '0.6'}}>
                    {branchMap[course.purchasingBranchId as string]?.name} ·{' '}
                    {formatUTCDate(newDateTranslatedToTenantTZ(course.purchaseDate), 'DAY_DATE_MONTH_YEAR')} ·{' '}
                    {/* ^^^^^^^^^ TODO: CC-3220 See comment in formatUTCDate - I think this can cause headaches. */}
                    {/* It causes course purchasedate (yyyymmdd) to show as previous day when viewed from Milan, for example  */}
                    {formatCurrency({value: course.grossPrice})}
                  </Text>
                </StyledRow>

                <StyledTreatmentList>
                  {course.clientCourseItems?.map((courseItem, index) => (
                    <StyledTreatmentItem
                      isActive={!isTreatmentDisabled(courseItem)}
                      key={`${courseItem.clientCourseItemId} ${index}`}
                      onClick={_ => {
                        if (isTreatmentDisabled(courseItem)) return;
                        toggleTreatmentToLocalParams({
                          selected: true,
                          serviceId: courseItem.serviceId,
                          courseId: course.courseId,
                          clientCourseItemId: courseItem.clientCourseItemId,
                          clinicId: course.purchasingBranchId
                        });
                      }}
                    >
                      <TreatmentContainer>
                        <TreatmentDetailsContainer>
                          <StyledCourseDescriptionItem>
                            {treatmentsMap?.[courseItem.serviceId as string]?.name}
                          </StyledCourseDescriptionItem>
                          <StyledCourseDetailsRow>
                            <Text variant="pill" children={`Purchased: ${courseItem.initialUnits}`} />
                            <Text variant="pill" children={`Remaining: ${courseItem.remainingUnits}`} />
                          </StyledCourseDetailsRow>
                        </TreatmentDetailsContainer>
                      </TreatmentContainer>
                    </StyledTreatmentItem>
                  ))}
                </StyledTreatmentList>
              </StyledCourse>
              {indexCourses < (courses?.length || 0) - 1 && <Divider />}
            </div>
          ))
        )}
      </SectionContent>
    </div>
  );
};

export {CustomerCourses};
export default CustomerCourses;

interface StyledCourseProps {
  isActive: boolean;
}

const {md: breakPointMd} = styledTheme.breakpoints.values;

const StyledCourse = styled.div`
  /* padding: ${({theme}) => theme.spacing(0, 3, 0, 3)}; */
`;

const ButtonSection = styled.div`
  display: flex;
  align-items: center;
`;

const AvailabilityButton = styled(Button)`
  margin-left: ${({theme}) => theme.spacing(1)};
`;

const StyledCheckbox = styled(Checkbox)`
  margin-left: ${({theme}) => theme.spacing(-1.25)};
`;

const StyledCourseTitleText = styled(Text)`
  font-size: ${20 / 16}rem;
`;

const StyledCourseDescriptionItem = styled.div`
  font-size: ${16 / 16}rem;
  padding-top: ${({theme}) => theme.spacing(0.6)};
`;

const StyledRow = styled.div`
  display: flex;
  margin-top: ${({theme}) => theme.spacing(0.7)};
`;

const StyledCourseDetailsRow = styled(StyledRow)`
  color: #7a7a7a; // Night 100;
  justify-content: space-between;
  width: 220px;
  @media only screen and (max-width: ${`${breakPointMd}px`}) {
    width: 190px;
  }
`;

const StyledTreatmentList = styled.ul`
  margin-top: ${({theme}) => theme.spacing(1)};
  padding-left: ${({theme}) => theme.spacing(0)};
`;

const TreatmentContainer = styled.div`
  display: flex;
  width: 100%;
`;

const TreatmentDetailsContainer = styled.div`
  padding-top: ${({theme}) => theme.spacing(0.8)};
  width: 100%;
  display: flex;
  justify-content: space-between;

  @media only screen and (max-width: ${`${breakPointMd}px`}) {
    padding-top: ${({theme}) => theme.spacing(1.7)};
    flex-direction: column;
  }
`;

const StyledTreatmentItem = styled.li<StyledCourseProps>`
  display: flex;
  justify-content: space-between;
  cursor: ${({isActive}) => isActive && 'pointer'};

  + li {
    margin-top: ${({theme}) => theme.spacing(1)};
  }
`;

const isTreatmentDisabled = (props?: {remainingUnits?: number | null; isExpired?: boolean}): boolean =>
  props?.remainingUnits === 0 || (props?.isExpired ?? false);
