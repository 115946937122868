import config from 'config';
import {KEYS} from 'constants/storage';

class ContextStorageClass {
  private storage: Storage = window.localStorage;

  get clinicIdKey() {
    return KEYS.CLINIC_ID;
  }
  get clinicId() {
    return this.storage.getItem(this.clinicIdKey) || config.DEFAULT_CLINIC_ID!;
  }
  set clinicId(id: string) {
    this.storage.setItem(this.clinicIdKey, id);
  }
}

const CtxStorage = new ContextStorageClass();
export {CtxStorage};
