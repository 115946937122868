import {useLocation, useNavigate} from 'react-router-dom';
import {ROUTES} from 'constants/routes';
import {useBusinessContext} from 'hooks/business/useBusinessContext';
import useClinicContext from 'hooks/clinic/useClinicContext';
import {useCallback, useMemo} from 'react';
import {useIsAdminContext} from 'hooks/router/useIsAdminContext';

/**
 * @param {string} toRoute pass the next route, undefined if you want to stay in the same route
 * @param {string} urlSearchParams pass the next params, by default query are kept in the url
 */
type NavigateFn = (params: {
  toRoute?: string;
  urlSearchParamsString?: string;
  urlSearchParams?: URLSearchParams;
  options?: {
    state?: Record<string, string>;
  };
}) => void;

interface Output {
  navigate: NavigateFn;
}

export function useAppNavigation(): Output {
  const {businessCode} = useBusinessContext();
  const {clinicId} = useClinicContext();
  const {pathname} = useLocation();
  const routerNavigate = useNavigate();
  const isAdminContext = useIsAdminContext();

  const basePath = isAdminContext
    ? `/${ROUTES.BUSINESS}/${businessCode}/${ROUTES.ADMIN}`
    : `/${ROUTES.BUSINESS}/${businessCode}/${ROUTES.CLINIC}/${clinicId}`;

  const navigate: NavigateFn = useCallback(
    ({toRoute, urlSearchParamsString, urlSearchParams, options}) => {
      // if app context is undefined, wait
      if ((!isAdminContext && !clinicId) || !businessCode) {
        console.warn('Business or clinic undefined');
        return Promise.resolve();
      }

      const paramsString = urlSearchParams?.toString() || urlSearchParamsString;
      const path = !toRoute ? pathname : `${basePath}/${toRoute}`;
      return routerNavigate(`${path}${paramsString ? `?${paramsString}` : ''}`, options);
    },
    [isAdminContext, clinicId, businessCode, pathname, basePath, routerNavigate]
  );

  return useMemo(() => ({navigate}), [navigate]);
}
