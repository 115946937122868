import {captureException} from '@sentry/react';
import {GET_ADMIN_PRODUCT} from 'data/graphql/queries/adminProducts';
import {GQLClientContext} from 'providers/gqlClient';
import {useContext} from 'react';
import {useQuery, UseQueryResult} from '@tanstack/react-query';
import {GET_ADMIN_PRODUCTQuery, GET_ADMIN_PRODUCTQueryVariables} from 'types/ApiModel';
import {TGQLCustomErrorsViaReactQuery} from 'types/errors';

export type IUseAdminProductOutput = UseQueryResult<
  GET_ADMIN_PRODUCTQuery['getAdminProduct'],
  TGQLCustomErrorsViaReactQuery
>;

function useAdminProduct(variables: GET_ADMIN_PRODUCTQueryVariables): IUseAdminProductOutput {
  const {gqlClient} = useContext(GQLClientContext);
  const {businessId, id} = variables;

  return useQuery<GET_ADMIN_PRODUCTQuery['getAdminProduct'], TGQLCustomErrorsViaReactQuery>(
    [GET_ADMIN_PRODUCT_KEY, variables],
    async () => {
      const resp = await gqlClient.request<GET_ADMIN_PRODUCTQuery, GET_ADMIN_PRODUCTQueryVariables>(
        GET_ADMIN_PRODUCT,
        variables
      );
      return resp.data?.getAdminProduct ?? null;
    },
    {
      enabled: !!(id && businessId),
      onError: errors => {
        const errorString = errors?.response?.errors?.[0]?.message ?? 'Error getting the product';
        captureException(new Error(errorString));
      }
    }
  );
}

export {useAdminProduct};

export const GET_ADMIN_PRODUCT_KEY = 'GET_ADMIN_PRODUCT';
