import {Hub} from 'aws-amplify';
import config from 'config';
import {useClinics} from 'hooks';
import useAnalytics from 'hooks/analytics/useAnalytics';
import React, {useEffect} from 'react';
import {useRecoilValue, useSetRecoilState} from 'recoil';
import {branchesMapState, userState} from 'state';
import {BranchModel} from 'types/DerivedApiModel';
import {getAnalyticsAttributes} from 'utils/tracing';
import {ProductFruits} from 'react-product-fruits';
import {useFeatureFlag} from 'hooks/utils/useFeatureFlag';

const InitActions: React.FC<React.PropsWithChildren<unknown>> = () => {
  const setBranchMap = useSetRecoilState(branchesMapState);
  const setUser = useSetRecoilState(userState);
  const clinics = useClinics();
  const {mutateAsync: sendAnalytics} = useAnalytics();
  const user = useRecoilValue(userState);
  const isProductFruitsEnabled = useFeatureFlag('productFruits');

  useEffect(() => {
    user &&
      sendAnalytics &&
      sendAnalytics(
        getAnalyticsAttributes({
          actionName: 'crmFeVersion',
          user,
          details: {
            crmFeBuildNumber: config.APP_COMMIT_ID,
            crmFeBuildTimestamp: config.BUILD_TIMESTAMP,
            stage: config.ENVIRONMENT
          }
        })
      );
  }, [sendAnalytics, user]);

  // Fetch Clinics, TODO: remove this action
  useEffect(() => {
    if (clinics.error || clinics.isLoading || clinics.isFetching) return;
    const clinicList = clinics.data ?? [];
    if (!clinicList?.length) return;
    const branchMap: Record<string, BranchModel> = {};
    clinicList.forEach(el => (branchMap[el?.id as string] = el));
    setBranchMap(branchMap);
  }, [clinics.data, clinics.error, clinics.isFetching, clinics.isLoading, setBranchMap]);

  // Listen for SignOut
  useEffect(() => {
    Hub.listen('auth', ({payload}) => payload.event === 'signOut' && setUser(undefined));
  }, [setUser]);

  return isProductFruitsEnabled && user && config.PRODUCT_FRUITS_WORKSPACE_CODE ? (
    <ProductFruits
      workspaceCode={config.PRODUCT_FRUITS_WORKSPACE_CODE}
      language="en"
      user={{username: user.attributes.email, email: user.attributes.email}}
    />
  ) : null;
};

export default InitActions;
