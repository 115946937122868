import {BaseItem, Column, Table} from '@therapie-ui/Table/Table';
import {useClinics} from 'hooks';
import {useCurrency} from 'hooks/currency/useCurrency';
import React, {useCallback, useContext, useMemo} from 'react';
import {VoucherModel} from 'types/DerivedApiModel';
import {formatDate} from 'utils';
import {LocaleContext} from 'providers/locale';
import {DEFAULT_PAGE_SIZE} from 'constants/pagination';

interface Props {
  vouchers?: VoucherModel[] | null;
  isLoading: boolean;
  emptyStateMessage: string;
  remainingBalanceLabel?: string;
}

const CustomerVouchersTable: React.FC<React.PropsWithChildren<Props>> = ({
  vouchers = [],
  isLoading,
  emptyStateMessage,
  remainingBalanceLabel = 'Remaining balance'
}) => {
  const {locale} = useContext(LocaleContext);
  const {formatCurrency} = useCurrency();
  const {data: branches} = useClinics();
  const [page, setPage] = React.useState(0);
  const pageSize = DEFAULT_PAGE_SIZE;
  const start = page * pageSize;
  const end = start + pageSize;

  const findCorrectBranchForVoucher = useCallback(
    (branchId: string) => branches?.find(branch => branch.id === branchId),
    [branches]
  );

  const tableItems: VoucherTypeTableItem[] = useMemo(
    () => vouchers?.map(voucher => ({...voucher, id: voucher.voucherId})).slice(start, end) ?? [],
    [end, start, vouchers]
  );

  const renderCell = useCallback(
    (item: VoucherTypeTableItem, column: Column) => {
      const voucherIndex = column.id as keyof VoucherTypeTableItem;
      const voucherIssueDate = formatDate(item.issueDate as string, 'DATE_MONTH_YEAR_LOCALISED', locale);
      const voucherBranchName = findCorrectBranchForVoucher(item.creatingBranchId as string)?.name;
      const voucherOriginalBalance = formatCurrency({value: item.originalBalance});
      const voucherRemainingBalance = formatCurrency({value: item.remainingBalance});

      switch (column.id) {
        case 'issueDate':
          return <>{voucherIssueDate}</>;
        case 'clinic':
          return <>{voucherBranchName}</>;
        case 'voucherNumber':
          return <>{item.serialNumber}</>;
        case 'originalBalance':
          return <>{voucherOriginalBalance}</>;
        case 'remainingBalance':
          return <>{voucherRemainingBalance}</>;
        default:
          return <>{item[voucherIndex]}</>;
      }
    },
    [findCorrectBranchForVoucher, formatCurrency, locale]
  );

  const handleChangePage = (_event: React.MouseEvent<HTMLButtonElement> | null, page: number) => {
    setPage(page);
  };

  const columns: Column[] = [
    {id: 'issueDate', label: 'Issue date', style: {width: '100px'}},
    {id: 'clinic', label: 'Clinic'},
    {id: 'voucherNumber', label: 'Voucher number'},
    {id: 'originalBalance', label: 'Original balance', style: {width: '200px', textAlign: 'right'}},
    {id: 'remainingBalance', label: remainingBalanceLabel, style: {width: '200px', textAlign: 'right'}}
  ];

  return (
    <Table<VoucherTypeTableItem>
      columns={columns}
      emptyStateProps={{title: emptyStateMessage}}
      items={tableItems}
      renderCell={renderCell}
      isLoading={isLoading}
      paginationProps={{
        page: page,
        rowsPerPage: DEFAULT_PAGE_SIZE,
        count: vouchers?.length ?? 0,
        rowsPerPageOptions: [],
        onPageChange: handleChangePage
      }}
    />
  );
};

export default CustomerVouchersTable;

interface VoucherTypeTableItem extends VoucherModel, BaseItem {}
