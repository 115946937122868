import {QUERY_KEYS} from 'constants/index';
import {useAppNavigation} from 'hooks/navigation/useAppNavigation';
import {useUrlParams} from 'hooks/router/useUrlParams';
import {useCallback, useMemo} from 'react';

export interface IUseSearchParamsOutput<T> {
  onUpdate: (keysMapping: Partial<Record<QUERY_KEYS, string | null>>) => void;
  searchParams: T;
}

function useSearchParams<T>(): IUseSearchParamsOutput<T> {
  const urlParams = useUrlParams();
  const {navigate} = useAppNavigation();

  const onUpdate: IUseSearchParamsOutput<T>['onUpdate'] = useCallback(
    keysMapping => {
      for (const key in keysMapping) {
        const queryKey = key as QUERY_KEYS;
        const value = keysMapping[queryKey];
        if (!value) urlParams.delete(key);
        if (value) urlParams.set(queryKey, value);
      }
      navigate({urlSearchParams: urlParams});
    },
    [navigate, urlParams]
  );

  const searchParams = useMemo(() => Object.fromEntries(urlParams.entries()) as unknown as T, [urlParams]);

  return {
    onUpdate,
    searchParams
  };
}

export {useSearchParams};
