import {useDebouncedValue} from 'hooks';
import useAllBranchServices from 'hooks/treatment/useAllBranchServices';
import {useCallback, useMemo, useState} from 'react';
import {TreatmentModel} from 'types/DerivedApiModel';

interface Props {
  branchId: string;
  businessId: string;
  serviceFilterName: string;
}

interface Output {
  selectedTreatements: TreatmentModel[];
  treatments: TreatmentModel[];
  selectService(treatment: TreatmentModel): void;
  unselectService(treatment: TreatmentModel): void;
  isLoading: boolean;
}

function useNewBookingDialogData(props: Props): Output {
  const {branchId, businessId, serviceFilterName} = props;
  const [selected, setSelected] = useState<TreatmentModel[]>([]);
  const {services, isLoading} = useAllBranchServices({branchId, businessId});
  const {debouncedValue: debouncedFilterName = ''} = useDebouncedValue(serviceFilterName, 250);

  const selectService = useCallback(
    (treatment: TreatmentModel) => setSelected([...selected, treatment]),
    [setSelected, selected]
  );
  const unselectService = useCallback(
    (treatment: TreatmentModel) => setSelected(selected.filter(el => el.id !== treatment.id)),
    [setSelected, selected]
  );

  const treatments = useMemo(
    () =>
      services
        .filter(({name}) => name?.toLowerCase().includes(debouncedFilterName.toLowerCase()))
        .filter(({id}) => !selected.find(select => select.id === id)),
    [services, debouncedFilterName, selected]
  );

  return {selectedTreatements: selected, treatments, selectService, unselectService, isLoading};
}

export {useNewBookingDialogData};
export default useNewBookingDialogData;
