import {Tooltip} from '@material-ui/core';
import {RescheduleBookingSearchParams} from 'components/BookingAppointment/types';
import {QUERY_KEYS} from 'constants/query';
import {ROUTES} from 'constants/routes';
import {useClinicContext} from 'hooks/clinic/useClinicContext';
import {usePermission} from 'hooks/permission/usePermission';
import React, {useMemo} from 'react';
import styled from 'styled-components';
import {formatDate, stringifyUrlParams, newDateTranslatedToTenantTZ} from 'utils';
import PaymentIcon from '@material-ui/icons/Payment';
import UpdateIcon from '@material-ui/icons/Update';
import {Button} from '@therapie-ui';

interface Props {
  appointmentDate?: string | null;
  clientId?: string | null;
  clinicId?: string | null;
  isPaid?: boolean;
}

const AppointmentList: React.FC<React.PropsWithChildren<Props>> = ({appointmentDate, clientId, clinicId, isPaid}) => {
  const hasPermission = usePermission();
  const {setClinicById} = useClinicContext();
  const translatedAppointmentDate = useMemo(() => newDateTranslatedToTenantTZ(appointmentDate), [appointmentDate]);

  if (!appointmentDate) return null;

  const appointmentDay = formatDate(translatedAppointmentDate, 'DAY_DATE_MONTH_YEAR');

  const redirectToPurchase = () =>
    setClinicById({
      id: clinicId ?? '',
      nextPath: ROUTES.PURCHASE,
      urlParams: new URLSearchParams({
        [QUERY_KEYS.CLIENT_ID]: clientId ?? '',
        [QUERY_KEYS.APPOINTMENT_DATE]: appointmentDate,
        [QUERY_KEYS.ORIGIN]: ROUTES.CLIENTS
      })
    });

  const navigateToRescheduleBooking = (rescheduleBookingParams: RescheduleBookingSearchParams) =>
    setClinicById({
      id: clinicId ?? '',
      nextPath: ROUTES.CLIENTS_MULTI_RESCHEDULE_BOOKING,
      urlParamsString: stringifyUrlParams(rescheduleBookingParams)
    });

  return (
    <>
      <StyledDateHeading data-date={appointmentDate}>
        <h3>{appointmentDay}</h3>
        <ButtonContainer>
          {hasPermission('PURCHASE') && !isPaid && (
            <Tooltip title={'Pay'} aria-label={'Pay'} placement="top">
              <span>
                <Button
                  size="xxlarge"
                  variant="outlined"
                  icon={<PaymentIcon />}
                  data-test-id="pay-btn"
                  onClick={redirectToPurchase}
                />
              </span>
            </Tooltip>
          )}
          <Tooltip title={'Reschedule appointment'} aria-label={'Reschedule appointment'} placement="top">
            <span>
              <Button
                size="xxlarge"
                variant="outlined"
                icon={<UpdateIcon />}
                data-test-id="reschedule-btn"
                onClick={() =>
                  navigateToRescheduleBooking({
                    appointmentDate: appointmentDate,
                    fromDate: appointmentDate,
                    appointmentClinicId: clinicId ?? '',
                    clientId: clientId ?? ''
                  })
                }
              />
            </span>
          </Tooltip>
        </ButtonContainer>
      </StyledDateHeading>
    </>
  );
};

export {AppointmentList};
export default AppointmentList;

const StyledDateHeading = styled.div`
  margin-top: ${({theme}) => theme.spacing(3)};
  padding-top: ${({theme}) => theme.spacing(2.5)};
  font-size: ${14 / 16}rem;
  line-height: 1.36;
  border-top: solid 1px #e6e6e6;
  display: flex;
  justify-content: space-between;
  &:first-child {
    margin-top: 1em;
    padding-top: 0;
    border-top: none;
  }
  h3 {
    font-size: ${20 / 16}rem;
    margin: 0 0 0.25rem; // 4px
    line-height: 1.5;
  }
`;
// ---- Temporary hiding Clinic Name before Multirescheduling is fixed for same day appointments in different clinics -----
// const StyledClinic = styled.span`
//   opacity: 0.6;
// `;

const ButtonContainer = styled.div`
  align-items: center;
  display: flex;
  gap: ${({theme}) => theme.spacing(1)};
`;
