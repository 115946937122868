import SectionContent from 'components/Clients/ViewClients/CustomerView/SectionContent';
import ServiceRecordsTable from 'components/Tables/ClientTables/ServiceRecordsTable';

import React from 'react';
import SectionHeader from '../../SectionHeader';

interface Props {
  clientId: string;
}

const CustomerHistory: React.FC<React.PropsWithChildren<Props>> = React.memo(({clientId}) => {
  return (
    <div>
      <SectionHeader children="Service History" />
      <SectionContent hasSidePadding={false}>
        <ServiceRecordsTable clientId={clientId} />
      </SectionContent>
    </div>
  );
});

export {CustomerHistory};
export default CustomerHistory;
