import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';
import {Button} from '@therapie-ui';
import {Table, TableBody, TableCell, TableContainer, TableHead, TableRow} from '@therapie-ui/DeprecatedTable';
import LoadingPlaceholder, {ShapeTableRows} from '@therapie-ui/LoadingPlaceholder/LoadingPlaceholder';
import {useCurrency} from 'hooks/currency/useCurrency';
import {styledTheme} from 'providers/theme';
import React, {CSSProperties, useCallback} from 'react';
import styled from 'styled-components';
import {TreatmentModel} from 'types/DerivedApiModel';

interface Props {
  services: TreatmentModel[];
  columns: IColumn[];
  isLoading: boolean;
  onServiceClick: (service: TreatmentModel) => void;
  EmptyState: React.ReactNode;
}

const ServicesTable: React.FC<React.PropsWithChildren<Props>> = ({
  services,
  columns,
  isLoading,
  onServiceClick,
  EmptyState
}) => {
  const {formatCurrency} = useCurrency();
  const isEmpty = !isLoading && services?.length === 0;

  // TODO: this component crashes sometimes when selecting trts. Need to reproduce and fix

  const formatTime = useCallback((time: string): string => (time ? `${time} min` : ``), []);
  const formatValue = useCallback(
    (value: string, type: keyof TreatmentModel): string =>
      type === 'duration'
        ? formatTime(value)
        : type === 'price'
        ? formatCurrency({value: value as unknown as number})
        : value,
    [formatCurrency, formatTime]
  );

  return (
    <StyledTableContainer>
      <Table aria-label="Services table" stickyHeader>
        <TableHead>
          <TableRow>
            {columns.map(column => (
              <TableCell
                key={column.id}
                style={{minWidth: column.minWidth, width: column.width, ...column.style}}
                align={column.align}
              >
                {column.label}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        {!isEmpty && (
          <TableBody>
            {services.map(service => {
              return (
                <TableRow role="checkbox" tabIndex={-1} key={service.id}>
                  {columns.map(column => {
                    if (column.id === 'CTA')
                      return (
                        <TableCell key="cta" align={column.align} style={column.style}>
                          <Button
                            iconLeading={column.ctaLabel === 'Add' ? <AddIcon /> : <RemoveIcon />}
                            size="small"
                            onClick={() => onServiceClick(service)}
                            children={column.ctaLabel}
                          />
                        </TableCell>
                      );
                    return (
                      <TableCell key={column.id} align={column.align} style={column.style}>
                        {formatValue(`${service[column.id]}`, column.id)}
                      </TableCell>
                    );
                  })}
                </TableRow>
              );
            })}
          </TableBody>
        )}
      </Table>
      {isLoading && <StyledLoadingContainer children={<LoadingPlaceholder shape={ShapeTableRows} />} />}
      {isEmpty && EmptyState}
    </StyledTableContainer>
  );
};

const MemoServicesTable = React.memo(ServicesTable);
export {MemoServicesTable as ServicesTable};
export default MemoServicesTable;

const StyledTableContainer = styled(TableContainer)`
  max-height: 220px;
  min-height: 220px;
  width: 100%;

  @media only screen and (min-width: ${`${styledTheme.breakpoints.values.sm}px`}) {
    width: 550px;
  }
  @media only screen and (min-width: ${`${styledTheme.breakpoints.values.md}px`}) {
    width: 650px;
  }
`;

export interface IColumn {
  id: keyof TreatmentModel | 'CTA';
  label: string;
  minWidth?: number;
  width?: number;
  align?: 'left' | 'center' | 'right' | 'justify' | 'inherit';
  ctaLabel?: string;
  style?: CSSProperties;
}
export enum ServiceFields {
  id = 'id',
  name = 'name',
  duration = 'duration',
  price = 'price'
}

const StyledLoadingContainer = styled.div`
  width: 100%;
  display: flex;
`;
