import {useMutation, UseMutationResult, useQueryClient} from '@tanstack/react-query';
import {RESCHEDULE_BOOKING} from 'data/graphql/mutations';
import {useNotifications, USE_ALL_CLINIC_APPOINTMENTS_KEY} from 'hooks';
import useAnalytics from 'hooks/analytics/useAnalytics';
import {GQLClientContext} from 'providers/gqlClient';
import {useContext} from 'react';
import {useRecoilValue} from 'recoil';
import {userState} from 'state';
import {RESCHEDULE_BOOKINGMutationVariables} from 'types/ApiModel';
import {RESCHEDULE_BOOKINGMutationResponse} from 'types/DerivedApiModel';
import {getAnalyticsAttributes} from 'utils/tracing';

type IUseRescheduleBookingOutput = UseMutationResult<
  RESCHEDULE_BOOKINGMutationResponse,
  Error,
  RESCHEDULE_BOOKINGMutationVariables
>;
interface RescheduleError {
  errorType: 'RESCHEDULE_ERROR' | '24H_CANCELLATION_POLICY_ERROR';
  payload: {request: unknown; error: string}[];
}

function useRescheduleBooking(): IUseRescheduleBookingOutput {
  const {notifySuccess, notifyError} = useNotifications();
  const queryClient = useQueryClient();
  const {gqlClient} = useContext(GQLClientContext);
  const {mutateAsync: sendAnalytics} = useAnalytics();
  const user = useRecoilValue(userState);

  return useMutation<RESCHEDULE_BOOKINGMutationResponse, Error, RESCHEDULE_BOOKINGMutationVariables>({
    mutationFn: async (variables: RESCHEDULE_BOOKINGMutationVariables) => {
      const {data} = await gqlClient.request<RESCHEDULE_BOOKINGMutationResponse, RESCHEDULE_BOOKINGMutationVariables>(
        RESCHEDULE_BOOKING,
        cleanUpVars(variables)
      );
      return data;
    },
    mutationKey: [USE_RESCHEDULE_BOOKING_KEY],
    onError: (error, variables) => {
      console.error('Error rescheduling a booking', error);
      const rescheduleError = parseRescheduleError(error);

      if (rescheduleError?.errorType === '24H_CANCELLATION_POLICY_ERROR')
        return notifyError(
          `Failed to reschedule. The 24H cancellation policy prevents us from moving the original booking out of this clinic.`
        );

      if (rescheduleError?.errorType === 'RESCHEDULE_ERROR') {
        const reqReschedules =
          variables?.payload?.newBooking?.clientAppointmentSchedules?.[0].serviceSchedules?.length ?? 0;
        const failedReschedules = rescheduleError?.payload?.length ?? 0;
        const errorMessages = Array.from(new Set(rescheduleError?.payload?.map(el => el.error))).join(', '); // De-dupe error codes

        if (failedReschedules === reqReschedules)
          return notifyError(
            `Failed to reschedule booking. Please try another date or time. [ErrorCode: ${errorMessages}]`
          );

        if (failedReschedules > 0 && failedReschedules < reqReschedules)
          return notifyError(
            `Failed to update ${failedReschedules} of ${reqReschedules} appointments from this booking - please check this client's appointment list. [ErrorCode: ${errorMessages}]`
          );
      }

      return notifyError('Failed to reschedule.');
    },
    onSuccess: (_, variables) => {
      queryClient.invalidateQueries([USE_ALL_CLINIC_APPOINTMENTS_KEY]);
      notifySuccess('This booking has been successfully rescheduled.');
      sendAnalytics(
        getAnalyticsAttributes({
          actionName: 'rescheduleBooking',
          user,
          details: {isMulti: isMulti(variables), isSameClinic: isSameClinic(variables)}
        })
      );
    }
  });
}

export {useRescheduleBooking};
export default useRescheduleBooking;

// -- analytics
const isMulti = (variables?: RESCHEDULE_BOOKINGMutationVariables): boolean => {
  const length = variables?.payload?.newBooking?.clientAppointmentSchedules?.[0]?.serviceSchedules?.length || 0;
  return length > 1;
};

const isSameClinic = (variables?: RESCHEDULE_BOOKINGMutationVariables): boolean =>
  variables?.payload.oldBooking.clinicId === variables?.payload.newBooking.clinicId;

const parseRescheduleError = (error: Error): RescheduleError | undefined => {
  try {
    return JSON.parse(error.message);
  } catch {
    return undefined;
  }
};

// HACK this, is needed to clean up state added for move feature in the diary.
// this will go away when we refactor movecopy appointment
// CC-2120
const cleanUpVars = (vars: RESCHEDULE_BOOKINGMutationVariables): RESCHEDULE_BOOKINGMutationVariables => {
  return {
    payload: {
      ...vars.payload,
      oldBooking: {
        ...vars.payload.oldBooking,
        clientAppointmentSchedules: vars.payload.oldBooking.clientAppointmentSchedules?.map(appSchedule => ({
          ...appSchedule,
          serviceSchedules: appSchedule.serviceSchedules?.map(servSchedule => ({...servSchedule, state: undefined}))
        }))
      },
      newBooking: {
        ...vars.payload.newBooking,
        clientAppointmentSchedules: vars.payload.newBooking.clientAppointmentSchedules?.map(appSchedule => ({
          ...appSchedule,
          serviceSchedules: appSchedule.serviceSchedules?.map(servSchedule => ({...servSchedule, state: undefined}))
        }))
      }
    }
  };
};

export const USE_RESCHEDULE_BOOKING_KEY = 'RESCHEDULE_BOOKING';
