import * as FullStory from '@fullstory/browser';
import {useFeatureFlag} from 'hooks/utils/useFeatureFlag';
import {useEffect} from 'react';
import {useRecoilValue} from 'recoil';
import {userHandleState, userState} from 'state';
import {windowIsIFramed} from 'utils/window';
import config from '../../config';

function useFSClient(): void {
  const ffFlag = useFeatureFlag('fullstoryRecording');
  const user = useRecoilValue(userState);
  const userHandle = useRecoilValue(userHandleState);

  useEffect(() => {
    if (ffFlag === true && user?.attributes.email) {
      try {
        window._fs_is_outer_script = windowIsIFramed; // https://help.fullstory.com/hc/en-us/articles/360020622514-Can-FullStory-capture-content-that-is-presented-in-iframes-#h_01F1G33B40Q2TPQA8MA7SF8Y5P
        window._fs_initialized && FullStory.shutdown();
        FullStory.init({orgId: config.FULLSTORY_ORG_ID});
        FullStory.identify(user?.attributes.email, {
          displayName: userHandle,
          email: user?.attributes.email
        });
      } catch (e) {
        console.warn('FS error init', e);
      }
    }
    if (ffFlag === false || !user) {
      try {
        window._fs_initialized && FullStory.shutdown();
      } catch (e) {
        console.warn('FS error shutdown', e);
      }
    }
  }, [ffFlag, user, userHandle]);
}

export {useFSClient};
export default useFSClient;
