import React from 'react';
import config from 'config';
import {Locale} from 'date-fns';
import {enIE, enGB, enUS} from 'date-fns/locale';

const LocaleContext = React.createContext<TLocaleContext>({locale: undefined});

const LocaleProvider: React.FC<React.PropsWithChildren> = ({children}) => (
  <LocaleContext.Provider value={{locale: config.LOCALE ? localeMap[config.LOCALE] : enIE}} children={children} />
);

export {LocaleProvider, LocaleContext};
export default LocaleProvider;

const localeMap: Record<string, Locale> = {
  enIE: enIE,
  enGB: enGB,
  enUS: enUS
};

type TLocaleContext = {locale?: Locale};
