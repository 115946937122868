import {gql} from 'utils/graphql';

export const GET_TREATMENT = gql`
  query GET_TREATMENT($query: ServiceInput!) {
    getService(query: $query) {
      id
      name
      price
      duration
      categoryId
      categoryName
      department {
        id
        name
      }
      archived
      branchId
      internetEnabled
      disqualifiedStaff
      machines
      rooms
      taxRates
    }
  }
`;

export const GET_ALL_TREATMENTS = gql`
  query GetServices($query: ServicesInput!, $page: PageInput) {
    getServices(query: $query, page: $page) {
      page {
        number
        size
        totalElements
        totalPages
      }
      services {
        id
        name
        price
        duration
        categoryId
        categoryName
        department {
          id
          name
        }
        archived
        branchId
        internetEnabled
        disqualifiedStaff
        machines
        rooms
        taxRates
      }
    }
  }
`;

export const GET_ALL_BRANCH_SERVICES = gql`
  query GET_ALL_BRANCH_SERVICES($query: ServicesInput!) {
    getAllBranchServices(query: $query) {
      id
      name
      price
      duration
      categoryId
      categoryName
      department {
        id
        name
      }
      archived
      branchId
      internetEnabled
      disqualifiedStaff
      machines
      rooms
      taxRates
    }
  }
`;

export const GET_ALL_BRANCH_SERVICE_CATEGORIES = gql`
  query GET_ALL_BRANCH_SERVICE_CATEGORIES($query: ServiceCategoriesInput!) {
    getAllBranchServiceCategories(query: $query) {
      id
      name
      description
      color
    }
  }
`;

export const GET_SERVICE_CATEGORIES = gql`
  query GET_SERVICE_CATEGORIES($query: ServiceCategoriesInput!, $page: PageInput) {
    getServiceCategories(query: $query, page: $page) {
      page {
        number
        size
        totalElements
        totalPages
      }
      serviceCategories {
        id
        description
        name
        color
      }
    }
  }
`;
