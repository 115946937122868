import React from 'react';
import styled from 'styled-components';
import {Button, Text} from '@therapie-ui';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';

interface Props {
  navigateBack: () => void;
  title: string;
}

const TopBar: React.FC<React.PropsWithChildren<Props>> = ({navigateBack, title}) => {
  // TODO: handling navigateBack logic in this component
  return (
    <StyledTopBarContainer>
      <Button onClick={navigateBack} variant="ghost" iconRound icon={<ArrowBackIcon />} />
      <StyledAppBarTitle variant="title">{title}</StyledAppBarTitle>
    </StyledTopBarContainer>
  );
};

export default TopBar;

const StyledTopBarContainer = styled.div`
  display: flex;
  background: #fff;
  align-items: center;
  padding: ${({theme}) => theme.spacing(3, 2)};
  box-sizing: border-box;
  height: 72px;
`;
const StyledAppBarTitle = styled(Text)`
  color: ${({theme}) => theme.colors.grey['900']};
  margin-left: ${({theme}) => theme.spacing(3)};
`;
