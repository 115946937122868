import * as Sentry from '@sentry/react';
import {UPDATE_ADMIN_PRODUCT} from 'data/graphql/mutations/adminProduct';
import useNotifications from 'hooks/notifications/useNotifications';
import {GQLClientContext} from 'providers/gqlClient';
import {useContext} from 'react';
import {useMutation, UseMutationResult, useQueryClient} from '@tanstack/react-query';
import {UPDATE_ADMIN_PRODUCTMutation, UPDATE_ADMIN_PRODUCTMutationVariables} from 'types/ApiModel';
import {TGQLCustomErrorsViaReactQuery} from 'types/errors';
import {GET_ADMIN_PRODUCT_KEY} from './useAdminProduct';

type MutationResult = UPDATE_ADMIN_PRODUCTMutation['updateAdminProduct'];
type IUseUpdateAdminProductOutput = UseMutationResult<
  MutationResult,
  TGQLCustomErrorsViaReactQuery,
  UPDATE_ADMIN_PRODUCTMutationVariables
>;

interface Input {
  showNotifications: boolean;
}

function useUpdateAdminProduct({showNotifications}: Input): IUseUpdateAdminProductOutput {
  const {gqlClient} = useContext(GQLClientContext);
  const {notifySuccess, notifyError} = useNotifications();
  const queryClient = useQueryClient();

  return useMutation<MutationResult, TGQLCustomErrorsViaReactQuery, UPDATE_ADMIN_PRODUCTMutationVariables, unknown>(
    async variables => {
      if (!variables) return null;
      const resp = await gqlClient.request<UPDATE_ADMIN_PRODUCTMutation, UPDATE_ADMIN_PRODUCTMutationVariables>(
        UPDATE_ADMIN_PRODUCT,
        variables
      );
      return resp.data?.updateAdminProduct;
    },
    {
      mutationKey: [USE_UPDATE_ADMIN_PRODUCT_KEY],
      onError: (error, variables) => {
        const errorMessage = `Error Updating Product: ${error?.response?.errors?.[0].message}`;
        notifyError(errorMessage);
        Sentry.captureException(new Error(errorMessage), {extra: {payload: variables}});
        console.error(errorMessage, error);
      },
      onSuccess: () => {
        showNotifications && notifySuccess(`Product updated`);
        queryClient.invalidateQueries([GET_ADMIN_PRODUCT_KEY]);
      }
    }
  );
}

export {useUpdateAdminProduct};

export const USE_UPDATE_ADMIN_PRODUCT_KEY = 'UPDATE_ADMIN_PRODUCT';
