import {atom} from 'recoil';
import {StaffCalendarModel} from 'types/DerivedApiModel';

interface AtomStatusState {
  loading: boolean;
  fetching: boolean;
}

const clinicStaffState = atom<StaffCalendarModel[]>({
  key: 'clinicStaffState',
  default: []
});

const clinicStaffStatusState = atom<AtomStatusState>({
  key: 'clinicStaffStatusState',
  default: {loading: false, fetching: false}
});

export {clinicStaffState, clinicStaffStatusState};
