import React from 'react';
import styled from 'styled-components';
import {Text} from '@therapie-ui';

interface Props {
  title: string;
  hasBorder?: boolean;
}

const DialogHeader: React.FC<React.PropsWithChildren<Props>> = props => (
  <StyledHeader border={props.hasBorder ?? false}>
    <Text variant="sectionTitle" children={props.title} style={{color: '#828282'}} />
  </StyledHeader>
);

export default DialogHeader;

const StyledHeader = styled.header<{border: boolean}>`
  padding: ${({theme}) => theme.spacing(3)};
  color: '#828282';
  border-bottom: ${({border}) => (border ? '1px solid #0000002b' : 'none')};
`;
