import {useMemo} from 'react';
import {IUseAdminProductTypes, useAdminProductTypes} from '../useAdminProductTypes';
import {IUseAdminSkinConcernsOutput, useAdminSkinConcerns} from '../useAdminSkinConcerns';
import {IUseAdminSkinTypesOutput, useAdminSkinTypes} from '../useAdminSkinTypes';
import {IUseAdminProductBrands, useAdminProductBrands} from './useAdminProductBrands';

export interface IUseAdminProductPageData {
  brands: IUseAdminProductBrands;
  skinConcerns: IUseAdminSkinConcernsOutput;
  skinTypes: IUseAdminSkinTypesOutput;
  productTypes: IUseAdminProductTypes;
}

const useAdminProductPageData = (): IUseAdminProductPageData => {
  const brands = useAdminProductBrands();
  const skinConcerns = useAdminSkinConcerns();
  const skinTypes = useAdminSkinTypes();
  const productTypes = useAdminProductTypes();

  return useMemo(
    () => ({
      brands,
      skinConcerns,
      skinTypes,
      productTypes
    }),
    [brands, productTypes, skinConcerns, skinTypes]
  );
};

export {useAdminProductPageData};
