import {DATE_FORMAT} from 'constants/time';
import {KeyboardDatePicker} from '@material-ui/pickers';
import {MaterialUiPickersDate} from '@material-ui/pickers/typings/date';
import {TextField} from '@material-ui/core';
import React from 'react';
import {formatDate} from 'utils';
import {isValid} from 'date-fns';

interface Props {
  date: Date | undefined;
  availableDays?: string[];
  loading?: boolean;
  onSelectedDateChange: (newDate: Date | null) => void;
  onMonthChange?: (date: Date) => void | Promise<void>;
}

const DatePicker: React.FC<React.PropsWithChildren<Props>> = ({
  date,
  availableDays,
  loading,
  onSelectedDateChange,
  onMonthChange
}) => {
  const handleMonthChange = (date: MaterialUiPickersDate) => {
    if (date && isValid(date) && onMonthChange) onMonthChange(date);
  };

  const handleSelectedDateChange = (date: MaterialUiPickersDate) => {
    if (date && isValid(date)) onSelectedDateChange(date);
  };

  // Each day is checked against this to see if it should be disabled
  const disabledDateHandler = (date: MaterialUiPickersDate): boolean => {
    const DAY_IS_DISABLED = true;
    const DAY_IS_ENABLED = !DAY_IS_DISABLED;

    if (!availableDays || availableDays.includes(formatDate(date as Date, 'YEAR_MONTH_DAY'))) return DAY_IS_ENABLED;
    return DAY_IS_DISABLED;
  };

  return (
    <KeyboardDatePicker
      autoOk
      disableToolbar
      variant="inline"
      style={{gridArea: 'fromSelect'}}
      TextFieldComponent={MemoTextFieldComponent}
      format={DATE_FORMAT.DATE_MONTH_YEAR_LOCALISED}
      id="date-picker"
      minDate={new Date()}
      value={date}
      KeyboardButtonProps={{'aria-label': 'change date'}}
      PopoverProps={{
        anchorOrigin: {
          vertical: 'top',
          horizontal: 'right'
        }
      }}
      shouldDisableDate={disabledDateHandler}
      onChange={handleSelectedDateChange}
      onMonthChange={handleMonthChange}
    />
  );
};

export {DatePicker};
export default DatePicker;

const MemoTextFieldComponent = React.memo(props => <TextField {...props} variant="outlined" size="small" />);
