import {UseMutationResult, useMutation} from '@tanstack/react-query';
import {SEND_ANALYTICS_EVENT} from 'data/graphql/mutations/analytics';
import {GQLClientContext} from 'providers/gqlClient';
import {useContext} from 'react';
import {SEND_ANALYTICS_EVENTMutation, SEND_ANALYTICS_EVENTMutationVariables} from 'types/ApiModel';
import {TGQLCustomErrorsViaReactQuery} from 'types/errors';

type IUseSendAnalyticsOutput = UseMutationResult<
  SEND_ANALYTICS_EVENTMutation['sendAnalyticsEvent'],
  TGQLCustomErrorsViaReactQuery,
  MutatedSendAnalyticsInputVariables
>;
type MutatedSendAnalyticsInputVariables = SEND_ANALYTICS_EVENTMutationVariables['payload'];

function useAnalytics(): IUseSendAnalyticsOutput {
  const {gqlClient} = useContext(GQLClientContext);

  return useMutation<
    SEND_ANALYTICS_EVENTMutation['sendAnalyticsEvent'],
    TGQLCustomErrorsViaReactQuery,
    MutatedSendAnalyticsInputVariables
  >({
    mutationFn: async payload => {
      if (!payload) return null;
      const resp = await gqlClient.request<SEND_ANALYTICS_EVENTMutation, SEND_ANALYTICS_EVENTMutationVariables>(
        SEND_ANALYTICS_EVENT,
        {payload}
      );
      return resp.data.sendAnalyticsEvent;
    },
    mutationKey: [USE_ANALYTICS_KEY]
  });
}

export {useAnalytics};
export default useAnalytics;

export const USE_ANALYTICS_KEY = 'SEND_ANALYTICS';
