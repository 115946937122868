import {captureException} from '@sentry/react';
import {useQuery, UseQueryResult} from '@tanstack/react-query';
import {RQ_CACHE_TIMES, RQ_STALE_TIMES} from 'constants/reactquery';
import {GET_STAFF} from 'data/graphql/queries/staff';
import {GQLClientContext} from 'providers/gqlClient';
import {useContext} from 'react';
import {GET_STAFFQuery, GET_STAFFQueryVariables} from 'types/ApiModel';
import {StaffModel} from 'types/DerivedApiModel';

interface IUseStaffInput {
  query: GET_STAFFQueryVariables;
}

export type IUseStaffsOutput = UseQueryResult<QueryResult, Error>;

type QueryResult = StaffModel | null;

function useStaff({query}: IUseStaffInput): IUseStaffsOutput {
  const {gqlClient} = useContext(GQLClientContext);

  return useQuery<QueryResult, Error>({
    enabled: !!(query.branchId && query.businessId && query.staffId),
    cacheTime: RQ_CACHE_TIMES.useStaff,
    staleTime: RQ_STALE_TIMES.useStaff,
    queryKey: [USE_STAFF_KEY, query],
    queryFn: async () => {
      const resp = await gqlClient.request<GET_STAFFQuery, GET_STAFFQueryVariables>(GET_STAFF, query);
      return resp.data.getStaff;
    },
    onError: ({message}) => captureException(new Error(message))
  });
}

export {useStaff};
export default useStaff;

export const USE_STAFF_KEY = 'GET_STAFF';
