import {getRandomInteger} from 'utils/numbers';
import {PaymentTypeInstance} from '../types';

const formatKlarnaForPayment = (
  voucherAmount?: number,
  number?: string,
  klarnaPaymentTypeId?: string,
): PaymentTypeInstance => ({
  amount: voucherAmount as number,
  archived: null,
  banked: null,
  builtIn: null,
  code: 'CP' as string,
  id: getRandomInteger().toString(),
  name: 'Klarna' as string,
  paymentTypeId: klarnaPaymentTypeId as string,
  voucherSerialNumber: ("+" + (number as string)),
  //voucherId: '00',
  voucherBalance: voucherAmount as number
});

export default formatKlarnaForPayment;
export {formatKlarnaForPayment};
