import {atom} from 'recoil';
import {ServiceCategoryModel} from 'types/DerivedApiModel';

interface AtomState {
  loading: boolean;
  data: ServiceCategoryModel[];
  error?: unknown;
}

const clinicServiceCategoriesState = atom<AtomState>({
  key: 'clinicServiceCategoriesState',
  default: {loading: false, data: []}
});

export {clinicServiceCategoriesState};
