// Primary colors

const COLORS_PRIMARY = {
  amber: '#f2994a',
  apple: '#219653',
  apple300: '#36d37a',
  azure: '#1a73e8',
  azure300: '#6aa4f0',
  canary: '#f2c94c',
  grape: '#7655ce',
  grape100: '#e5dff6',
  grape300: '#b2a0e3',
  grape600: '#5b37be',
  ice100: '#ffffff',
  ice300: '#fafafc',
  ice400: '#f8f8fb',
  mist100: '#fafafa',
  mist200: '#ebebeb',
  mist300: '#d6d6d6',
  mist400: '#c2c2c2',
  mist600: '#8f8f8f',
  night: '#222222',
  night300: '#525252',
  scarlet: '#eb5757'
} as const;

export default COLORS_PRIMARY;
export {COLORS_PRIMARY};
