import {gql} from 'utils/graphql';

export const GET_CLIENTS = gql`
  query GET_CLIENTS($query: ClientsInput!, $page: PageInput) {
    getClients(query: $query, page: $page) {
      clients {
        address {
          city
          country
          postalCode
          state
          streetAddress1
          streetAddress2
        }
        archived
        banned
        birthDate
        clientCategoryIds
        clientId
        id
        clientSince
        createdAt
        creatingBranchId
        creditAccount {
          creditDays
          creditLimit
          outstandingBalance
        }
        deleted
        email
        emailMarketingConsent
        emailReminderConsent
        externalId
        firstName
        firstVisit
        gender
        landLine
        lastName
        loyaltyCard {
          points
          serial
        }
        mergedToClientId
        mobile
        notes
        photoUrl
        preferredStaffId
        smsMarketingConsent
        smsReminderConsent
        updatedAt
        version
      }
      page {
        number
        size
        totalElements
        totalPages
      }
    }
  }
`;

export const GET_CLIENT = gql`
  query GET_CLIENT($businessId: String!, $clientId: String!) {
    getClient(businessId: $businessId, clientId: $clientId) {
      address {
        city
        country
        postalCode
        state
        streetAddress1
        streetAddress2
      }
      archived
      banned
      birthDate
      clientCategoryIds
      clientId
      id
      clientSince
      createdAt
      creatingBranchId
      creditAccount {
        creditDays
        creditLimit
        outstandingBalance
      }
      deleted
      email
      emailMarketingConsent
      emailReminderConsent
      externalId
      firstName
      firstVisit
      gender
      landLine
      lastName
      loyaltyCard {
        points
        serial
      }
      mergedToClientId
      mobile
      notes
      photoUrl
      preferredStaffId
      smsMarketingConsent
      smsReminderConsent
      updatedAt
      version
    }
  }
`;

export const GET_SERVICE_HISTORY = gql`
  query GET_SERVICE_HISTORY($query: ClientServiceHistoryInput!, $page: PageInput) {
    getServiceHistory(query: $query, page: $page) {
      clientServiceHistories {
        bookingId
        branchId
        clientId
        date
        notes
        services {
          appointmentId
          cancelled
          checkedIn
          clientCourseItemId
          depositAmount
          description
          discount
          duration
          fee
          groupBookingId
          originalPrice
          serviceCategoryId
          serviceGroupId
          serviceGroupItemOptionId
          serviceId
          serviceRewardId
          staffId
          staff {
            branchId
            email
            disqualifiedServices
            firstName
            hideFromAppointmentScreen
            hideFromOnlineBookings
            id
            lastName
            mobile
            selfEmployed
            staffCategoryId
            staffCategoryName
            userId
          }
          time
          waitingList
        }
      }
      page {
        number
        size
        totalElements
        totalPages
      }
    }
  }
`;

export const GET_VOUCHERS = gql`
  query GET_VOUCHERS($query: VouchersInput!, $page: PageInput) {
    getVouchers(query: $query, page: $page) {
      vouchers {
        voucherId
        serialNumber
        clientId
        creatingBranchId
        expiryDate
        issueDate
        originalBalance
        remainingBalance
      }
      page {
        number
        size
        totalElements
        totalPages
      }
    }
  }
`;

export const GET_CLIENT_PRODUCT_HISTORY = gql`
  query GET_CLIENT_PRODUCT_HISTORY($query: ClientProductsInput!, $page: PageInput) {
    getClientProductHistory(query: $query, page: $page) {
      products {
        purchaseDate
        id
        branchId
        description
        quantity
        price
        staffFirstName
        staffLastName
        branchProductId
        purchaseType
        purchaseItemType
        staffId
        userId
        originalPrice
        totalAmount
        discountAmount
        taxAmount
        taxName
        taxRate
        taxIncluded
        clientCoursePurchase
        courseSessionRequest
      }
      page {
        number
        size
        totalElements
        totalPages
      }
    }
  }
`;
