import config from 'config';
import {differenceInDays, isBefore, Locale, parse, startOfDay} from 'date-fns';
import {format as formatTZ, getTimezoneOffset, utcToZonedTime, zonedTimeToUtc} from 'date-fns-tz';
import format from 'date-fns/format';
import {DATE_FORMAT} from './../constants';

const DAYS_IN_ONE_WEEK = 7;

interface IGetDatesForUpcomingDaysProps {
  fromDate: Date;
  toDate?: Date | null;
  daysAhead?: number | null;
  includeToday?: boolean;
}

/**
 * Returns a simple array of new Dates, with their time set to 00:00:00, for the next N days.
 
 * @param fromDate - What date to start at - defaults to now
 * @param toDate - What date to end at - optional but will need to pass daysAheadInstead
 * @param daysAhead - How many days ahead do you want to get dates - optional but will need to pass toDate instead
 * @param includeToday - If true (default), dates will start with today, else tomorrow
 */
const getDatesForUpcomingDays = ({
  fromDate,
  toDate,
  daysAhead,
  includeToday = true
}: IGetDatesForUpcomingDaysProps): Date[] => {
  if (!toDate && !daysAhead) return [];
  const days = [];
  const startsAt = includeToday ? 0 : 1;
  const numberOfDays: number = toDate ? differenceInDays(toDate, fromDate) : daysAhead || DAYS_IN_ONE_WEEK;

  for (let index = startsAt; index < numberOfDays; index++) {
    const date = new Date(fromDate);
    date.setDate(date.getDate() + index);
    date.setHours(0);
    date.setMinutes(0);
    date.setSeconds(0);
    date.setMilliseconds(0);
    days.push(date);
  }

  return days;
};

/**
 * We'll possibly need to enforce the input date here to be in UTC.
 * When we're solely passing YYYY-MM-DD formatted dates into it and the
 * client is not at GMT, new Date can end up showing a previous day due
 * to negative timezone offsets. Relates to CC-3213.
 * https://date-fns.org/v2.17.0/docs/Time-Zones#date-fns-tz
 * @param date
 * @param format_key
 * @param locale
 * @returns string: formatted date
 */
const formatUTCDate = (
  date: Date | string | null,
  format_key: keyof typeof DATE_FORMAT = 'DAY_DATE_MONTH',
  locale?: Locale
): string => {
  if (!date) return '';
  const timeZone = config.TIMEZONE; // config.TIMEZONE relates to tenant, not client
  const localDate = new Date(date); // Client timezone can affect new Date() and cause issues
  const zonedDate = utcToZonedTime(localDate, timeZone); // We may need to de-zone localDate first
  return formatTZ(zonedDate, DATE_FORMAT[format_key], {timeZone, ...(locale ? {locale} : {})});
};

const setZonedDate = (date: string | Date): Date => {
  // Replace Time Zone, Keeping the same Data Object. ex: YYYY-MM-DD +0 (European Time) ->> ex: YYYY-MM-DD +0 (Local TimeZone)
  const initialDate = new Date(date);
  const timeZone = config.TIMEZONE;
  return utcToZonedTime(initialDate, timeZone);
};

/**
 *
 * @param date
 * @param format_key
 * @param locale
 * @returns string: formatted date
 */
const formatDate = (date: Date | string, format_key: keyof typeof DATE_FORMAT, locale?: Locale): string =>
  format(new Date(date), DATE_FORMAT[format_key], locale ? {locale} : undefined);

const formatZonedToUTCISO = (date: string | null, time: string | null): string =>
  zonedTimeToUtc(date + ' ' + time, config.TIMEZONE).toISOString();

const getZonedDate = (date: string | null, time: string | null): Date =>
  zonedTimeToUtc(date + ' ' + time, config.TIMEZONE);

/**
 * Checks if two times are in the correct order
 * Assumes that both times are on the same day and are in the format HH:MM*
 * @param startTime Timestamp like '12:34' or '12:34:56' or '12:34:56.789'
 * @param endTime Timestamp like '12:34' or '12:34:56' or '12:34:56.789'
 * @returns
 */
const startTimeStringIsBeforeEndTimeStringInSameDay = (startTime: string, endTime: string): boolean => {
  const start = parse(startTime, DATE_FORMAT.HOURS_MIN_SEC_MS, new Date());
  const end = parse(endTime, DATE_FORMAT.HOURS_MIN_SEC_MS, new Date());
  return isBefore(start, end);
};

// 11:00 - 11:30
const getTimeRangeText = (startTimeISO: string, endTimeISO: string) =>
  `${formatUTCDate(startTimeISO, 'HOURS_MIN')} - ${formatUTCDate(endTimeISO, 'HOURS_MIN')}`;

/**
 * newDateTranslatedToTenantTZ
 *
 * Takes a date as a string and creates a new Date object with the time
 * matching 00:00:00 in the tenants timezone for that same date.
 *
 * Example: Client in NYC, tenant at GMT. NYC is at -5, so this will
 * create a Date at 05:00 in NYC time === 00:00 in GMT, so that we can
 * ensure that the actual date doesn't drop into yesterday.
 *
 * We need to cater for the fact that our application config Timezone
 * could be anywhere in the world, as well as the client/browser also
 * being anywhere in the world. When we call new Date() with a particular
 * date string in the format YYYY-MM-DD, if the timezone offset of the
 * client is negative then the local version of the created date will be
 * a day behind the string that was actually passed in. For example, if
 * we try new Date('2023-02-17') on a browser in New York, it will be -5h
 * (or -4h during DST), which will result in the local version of the date
 * looking like 2023-02-16T19:00:00 (note 16th instead of 17th).
 * When we want to use this for things such as date-pickers or formatted
 * dates, we end up a day prior to the day that we expected. This shifts
 * that new date by the difference between the tenant and the client,
 * so that it's 00:00 at the client location. Should only be used when
 * we don't care about the time of day, only the specific date.
 *
 * @param dateString: optional
 * @returns Date
 */
const newDateTranslatedToTenantTZ = (dateString?: string | null): Date => {
  if (dateString && (dateString?.length !== 10 || dateString.split('-').length !== 3)) {
    console.warn('Invalid format for date translation - YYYY-MM-DD format requred');
    // Note: time can be passed too, the startOfDay below will strip it, but ideally we'd only pass YYYY-MM-DD.
  }
  // If there's a string passed we base it on that, else we start with a new Date in the clients TZ
  const date = dateString ? parse(dateString, DATE_FORMAT.YEAR_MONTH_DAY, new Date()) : startOfDay(new Date());
  // We return a new date, set to the start of the day and then offset by the diff between us and tenant
  return new Date(startOfDay(date).getTime() + getClientToTenantOffsetDifference());
};

/**
 * getClientTenantOffsetDifference
 * Calling getTimezoneOffset on a normal date gives you the GMT offset.
 * When we can have a client in any TZ and an application in any TZ, we
 * need to know the diff between them so we can translate dates.
 *
 * @returns number: minutes between the client and tenant's timezones
 */
const getClientToTenantOffsetDifference = (): number => {
  const clientTZ = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const tenantTZ = config.TIMEZONE;
  const clientOffset = getTimezoneOffset(clientTZ, new Date());
  const tenantOffset = getTimezoneOffset(tenantTZ);
  const offsetDiff = Math.abs(clientOffset - tenantOffset);
  return offsetDiff;
};

export {
  formatDate,
  formatUTCDate,
  formatZonedToUTCISO,
  getDatesForUpcomingDays,
  getTimeRangeText,
  getZonedDate,
  setZonedDate,
  startTimeStringIsBeforeEndTimeStringInSameDay,
  newDateTranslatedToTenantTZ
};
