import {Tooltip} from '@material-ui/core';
import CreateOutlinedIcon from '@material-ui/icons/CreateOutlined';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import {Button, Text} from '@therapie-ui';
import {Typography} from '@therapie-ui/Typography/Typography';
import COLORS_BASE from 'constants/colors/base';
import {ROUTES} from 'constants/routes';
import {useAppNavigation} from 'hooks/navigation/useAppNavigation';
import React, {useCallback} from 'react';
import styled from 'styled-components';
import {Client} from 'types/DerivedApiModel';
import {formatPhoneNumber} from 'utils';

interface Props {
  client: Client;
  isEditable: boolean;
  compact?: boolean;
}

var klarnaCustomerName: string | null;
var klarnaCustomerPhoneNumber: string | null;

const CustomerDetails: React.FC<React.PropsWithChildren<Props>> = React.memo(({client, isEditable, compact}) => {
  const {navigate} = useAppNavigation();
  const onEditClientClick = useCallback(
    () => navigate({toRoute: `${ROUTES.CLIENTS}/${client.clientId}/${ROUTES.EDIT}`}),
    [client.clientId, navigate]
  );

  function setKlarnaClientDetails (firstName: string | null, lastName: string | null, mobile: string |null)

  {
    if (firstName && lastName && mobile)
      {
       klarnaCustomerName = (firstName + ' ' + lastName);
       klarnaCustomerPhoneNumber = mobile;
      }
   return true;
  }

  return (
    <div>
      {client.banned && (
        <Banner>
          <ErrorIcon fontSize="small" /> Attention! This client is banned.
        </Banner>
      )}
      <div>
        {/* TODO: Update this to use DS Typography */}
        <ClientNameSection compact={compact}>
          <ClientName variant="heading" data-test-id="header-client-name" compact={compact}>
            {client.firstName} {client.lastName}
          </ClientName>
          {isEditable && (
            <Tooltip title="Edit client" aria-label="edit-client-tooltip" placement="top">
              <Button
                aria-label="edit-client-button"
                onClick={onEditClientClick}
                size="large"
                variant="ghost"
                iconRound
                icon={<CreateOutlinedIcon />}
              />
            </Tooltip>
          )}
        </ClientNameSection>

        <ClientInfoSection compact={compact}>
          {client.email && (
            <Typography variant="h5" data-test-id="header-client-email" weight="semibold" color={COLORS_BASE.night400}>
              {client.email}
            </Typography>
          )}
          {client.mobile && (
            <Typography variant="h5" data-test-id="header-client-mobile" weight="semibold" color={COLORS_BASE.night400}>
              {formatPhoneNumber(client.mobile)}
            </Typography>
          )}
        </ClientInfoSection>
        <script>{setKlarnaClientDetails(client.firstName, client.lastName, client.mobile)}</script>
      </div>
    </div>
  );
});

export {klarnaCustomerName}
export {klarnaCustomerPhoneNumber}

export default CustomerDetails;

interface StyleProps {
  readonly compact?: boolean;
  readonly greyText?: boolean;
}

const ClientNameSection = styled.div<StyleProps>`
  display: flex;
  align-items: center;
  color: #222;
  padding-bottom: ${({theme, compact}) => (compact ? 0 : theme.spacing(2))};
`;

const ClientInfoSection = styled.div<StyleProps>`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

const ClientName = styled(Text)<StyleProps>`
  font-size: ${({compact}) => (compact ? '1.5rem' : '2rem')};
  padding-right: ${({theme}) => theme.spacing(0.5)};
`;

const Banner = styled.div`
  display: flex;
  align-items: center;
  height: 40px;
  margin-bottom: ${({theme}) => theme.spacing(1.5)};
  background-color: #fbecec;
  border-radius: 4px;
  padding: ${({theme}) => theme.spacing(0.5)} ${({theme}) => theme.spacing(1)};
  font-size: 0.875rem;
`;

const ErrorIcon = styled(ErrorOutlineIcon)`
  margin-right: ${({theme}) => theme.spacing(0.5)};
  color: #c53618;
`;
