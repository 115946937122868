import {
  Table as MuiTable,
  TableFooter,
  TablePagination,
  TablePaginationProps,
  TableProps as MuiTableProps
} from '@material-ui/core';
import EmptyState, {EmptyStateProps} from '@therapie-ui/EmptyState/EmptyState';
import LoadingPlaceholder, {SkeletonTuple} from '@therapie-ui/LoadingPlaceholder/LoadingPlaceholder';
import COLORS from 'constants/colors/index';
import React, {CSSProperties, useMemo, useState} from 'react';
import {useEffect} from 'react';
import styled from 'styled-components';
import TableBody from './TableBody';
import TableCell from './TableCell';
import TableContainer from './TableContainer';
import TableHead from './TableHead';
import TableRow from './TableRow';

export interface BaseItem {
  id: string;
}

interface Props<T extends BaseItem> {
  animateRowOnEnter?: boolean;
  columns: Column[];
  size?: MuiTableProps['size'];
  emptyStateProps?: EmptyStateProps;
  isLoading?: boolean;
  loadingRowHeight?: number;
  items: T[];
  paginationProps?: TablePaginationProps;
  stickyHeader?: boolean;
  stickyFooter?: boolean;
  renderCell?: (item: T, column: Column, rowIndex: number, columnIndex: number) => React.ReactNode;
}

export interface Column {
  id: string;
  label: string;
  style?: CSSProperties;
}

const Table = <T extends BaseItem>({
  animateRowOnEnter,
  columns,
  emptyStateProps,
  loadingRowHeight,
  paginationProps,
  isLoading,
  items,
  size,
  stickyHeader = false,
  stickyFooter = false,
  renderCell = (item: T, column: Column) => <>{item[column.id as keyof T]}</>
}: Props<T>): JSX.Element => {
  const [paginationState, setPaginationState] = useState<TablePaginationProps>();
  const {
    count = 0,
    page = 0,
    rowsPerPage = paginationProps?.rowsPerPage ?? GENERIC_LOADING_PLACEHOLDER_AMOUNT_OF_ROWS,
    onPageChange = () => null
  } = paginationState ?? {};
  const isEmpty = !isLoading && items?.length === 0;
  const loadingRows: SkeletonTuple[] = useMemo(
    () =>
      Array(rowsPerPage).fill([
        'rect',
        loadingRowHeight ? loadingRowHeight : size === 'small' ? SMALL_ROW_HEIGHT : MEDIUM_ROW_HEIGHT,
        2
      ]),
    [loadingRowHeight, rowsPerPage, size]
  );

  // Stateful pagination lets us persist the digits as we load subsequent pages.
  // By only updating it when we have a positive count we don't reset it to zero between loads
  useEffect(() => {
    (paginationProps?.count ?? 0) > 0 && setPaginationState(paginationProps);
  }, [paginationProps]);

  if (isEmpty)
    return (
      <Container hasPadding>
        <EmptyState
          {...emptyStateProps}
          title={emptyStateProps?.title ?? 'No data'}
          details={emptyStateProps?.details ?? 'This table does not have any data to display'}
          darkTitle
          fillHeight
        />
      </Container>
    );

  return (
    <Container>
      <StyledTable size={size} stickyHeader={stickyHeader}>
        <TableHead border={false} rounded={true}>
          <TableRow>
            {columns.map(column => (
              <TableCell key={column.id as string} style={column.style} children={column.label} />
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {isLoading ? (
            <TableRow className="no-hover-effect">
              <TableCell colSpan={columns.length}>
                <LoadingPlaceholder shape={loadingRows} padding={0} />
              </TableCell>
            </TableRow>
          ) : (
            items?.map((item, rowIndex) => (
              <TableRow animateOnEnter={animateRowOnEnter} tabIndex={-1} key={item.id}>
                {columns.map((column, columnIndex) => (
                  <TableCell key={column.id + item.id} style={column.style}>
                    {renderCell(item, column, rowIndex, columnIndex)}
                  </TableCell>
                ))}
              </TableRow>
            ))
          )}
        </TableBody>
        {!stickyFooter && paginationState && onPageChange && !isEmpty && (
          <TableFooter>
            <TablePaginationRow size={size}>
              <TablePagination
                count={count}
                page={page}
                rowsPerPage={rowsPerPage}
                rowsPerPageOptions={[]}
                onPageChange={onPageChange}
              />
            </TablePaginationRow>
          </TableFooter>
        )}
      </StyledTable>

      {stickyFooter && paginationState && (
        <StickyPagination>
          <TablePagination
            component={'div'}
            size={size}
            count={count}
            page={page}
            rowsPerPage={rowsPerPage}
            rowsPerPageOptions={[]}
            onPageChange={onPageChange}
          />
        </StickyPagination>
      )}
    </Container>
  );
};

export default Table;
export {Table};

const SMALL_ROW_HEIGHT = 32;
const MEDIUM_ROW_HEIGHT = 48;
const GENERIC_LOADING_PLACEHOLDER_AMOUNT_OF_ROWS = 5;

const Container = styled(TableContainer)<{hasPadding?: boolean}>`
  width: 100%;
  height: 100%;
  min-height: inherit;
  display: flex;
  flex-direction: column;
  position: relative;
  padding: ${({hasPadding, theme}) => (hasPadding ? theme.spacing(4) : null)};
`;

const StyledTable = styled(MuiTable)<{size?: MuiTableProps['size']}>`
  // Header
  th {
    background: white;
    border: none;
  }

  // Cells
  th,
  td {
    box-sizing: border-box;
    height: ${({size}) => (size === 'small' ? SMALL_ROW_HEIGHT : MEDIUM_ROW_HEIGHT)}px;
    padding: ${({theme, size}) => (size === 'small' ? theme.spacing(0.5, 1) : theme.spacing(1, 1.5))};
    line-height: ${20 / 16}rem;
    font-size: ${14 / 16}rem;
    color: ${COLORS.night400};
    &:first-child {
      padding-left: ${({theme}) => theme.spacing(3)};
    }
    &:last-child {
      padding-right: ${({theme}) => theme.spacing(3)};
    }
  }

  // Rows
  tbody tr:hover:not(.no-hover-effect) {
    background-color: ${COLORS.ice600};
  }
  tr:last-child td {
    border-bottom: none;
  }
`;

const StickyPagination = styled.footer`
  position: sticky;
  bottom: 0;
  background: white;
`;

// Temporary style until Pagination is refactored in next MR
const TablePaginationRow = styled(TableRow)<{size?: MuiTableProps['size']}>`
  &:first-child td {
    padding: ${({theme, size}) => (size === 'small' ? theme.spacing(0, 1.5) : theme.spacing(1, 1.5))};
  }
`;
