import * as Sentry from '@sentry/react';
import {Auth, Hub} from 'aws-amplify';
import React, {useCallback, useEffect, useState} from 'react';
import {SSO_LOGIN_OK} from './utils';

const LoginRedirect: React.FC<React.PropsWithChildren<unknown>> = React.memo(() => {
  const [error, setError] = useState<string>();

  const fetchUser = useCallback(async () => {
    try {
      await Auth.currentAuthenticatedUser();
      window.opener?.processChildMessage(SSO_LOGIN_OK); // Defined in LoginPage.tsx
      window.close();
    } catch {}
  }, []);

  useEffect(() => {
    Hub.listen('auth', event => {
      switch (event.payload.event) {
        case 'cognitoHostedUI':
          fetchUser();
          break;
        case 'signIn_failure':
        case 'cognitoHostedUI_failure':
          setError(`${event.payload.event} ${event.payload.message ?? ''}`);
      }
    });
  }, [fetchUser]);

  useEffect(() => {
    error && Sentry.captureException(new Error(error));
  }, [error]);

  if (error)
    return (
      <div>
        An error occurred, support team has been notified. Please close this window and try login again
        <hr />
        <details open>
          <summary>Error</summary>
          <p>{error}</p>
        </details>
      </div>
    );

  return <>Redirecting...</>;
});

export default LoginRedirect;
