import {useCallback} from 'react';
import {useRecoilState, useResetRecoilState} from 'recoil';
import {purchaseItemsState} from 'state/atoms/purchase';
import {PurchaseItem} from '../types';
import {usePurchaseStaff} from './usePurchaseStaff';

export interface IUsePurchaseSummaryItemsOutput {
  add: (purchaseItems: PurchaseItem[]) => void;
  clear: () => void;
  purchaseItems: PurchaseItem[];
  remove: (id: string) => void;
  update: (updatedPurchaseItem: PurchaseItem) => void;
}

function usePurchaseSummaryItems(): IUsePurchaseSummaryItemsOutput {
  const [purchaseItems, setPurchaseItems] = useRecoilState(purchaseItemsState);
  const {defaultStaffId} = usePurchaseStaff();

  const add = useCallback(
    (newPurchaseItems: PurchaseItem[]) => {
      setPurchaseItems(prevPurchaseItems => [
        ...prevPurchaseItems,
        ...newPurchaseItems
          .filter(fixLocalHotModuleReload(prevPurchaseItems))
          .map(item => ({...item, staffId: item.staffId ?? defaultStaffId}))
      ]);
    },
    [defaultStaffId, setPurchaseItems]
  );

  const update = useCallback(
    (updatedPurchaseItem: PurchaseItem) =>
      setPurchaseItems(prevState =>
        prevState.map(purchaseItem => (updatedPurchaseItem.id === purchaseItem.id ? updatedPurchaseItem : purchaseItem))
      ),
    [setPurchaseItems]
  );

  const remove = useCallback(
    (id: string) => {
      setPurchaseItems(prevState => prevState.filter(el => el.id !== id));
    },
    [setPurchaseItems]
  );

  const clear = useResetRecoilState(purchaseItemsState);

  return {
    add,
    clear,
    purchaseItems,
    remove,
    update
  };
}

export {usePurchaseSummaryItems};

// In Dev hot module Reloading will re-render the container component re-fething and adding same appointments, remove them
const fixLocalHotModuleReload = (prevPurchaseItems: PurchaseItem[]) => (newEl: PurchaseItem) =>
  !prevPurchaseItems.some(({appointmentId}) => newEl.appointmentId && appointmentId === newEl.appointmentId);
