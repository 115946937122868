import {DocumentNode, OperationDefinitionNode, parse} from 'graphql';
import {gql as graphqlRequestGql} from 'graphql-request';

export const getOperationUrlQueryInformation = (query: DocumentNode) => {
  const operationName = (query.definitions?.[0] as OperationDefinitionNode)?.name?.value;
  const operationType = (query.definitions?.[0] as OperationDefinitionNode)?.operation;
  return operationName && operationType && `?operationName=${operationName}&type=${operationType}`;
};

type TGqlOperationBuilder = (chunks: TemplateStringsArray, ...variables: any[]) => DocumentNode;
export const gql: TGqlOperationBuilder = args => parse(graphqlRequestGql(args));
