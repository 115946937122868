import {MenuItem, Select} from '@material-ui/core';
import config from 'config';
import {BusinessId} from 'constants/business';
import COLORS from 'constants/colors';
import {TOP_BAR_INNER_HEIGHT} from 'constants/layout';
import {useBusinessContext, useClinicFilterOptions} from 'hooks';
import useClinicContext from 'hooks/clinic/useClinicContext';
import {useNavigateToBusiness} from 'hooks/navigation/useNavigateToBusiness';
import {useIsAdminContext} from 'hooks/router/useIsAdminContext';
import React, {ChangeEvent, useMemo} from 'react';
import styled from 'styled-components';
import {Option} from 'types/common';
import {visibleBusinesses} from 'utils/business';
import {ClinicSelect} from './ClinicSelect';

type TBusinessSelectChangeEvent = ChangeEvent<{name?: string | undefined; value: unknown}>;

const BusinessClinicSelector: React.FC = React.memo(() => {
  const isAdminContext = useIsAdminContext();
  const {businessId} = useBusinessContext();
  const {navigateToBusinessById} = useNavigateToBusiness();
  const {clinic, setClinicById} = useClinicContext();
  const {clinicOptions} = useClinicFilterOptions();
  const clinicValue: Option = useMemo(() => ({id: clinic?.id ?? '', label: clinic?.name ?? ''}), [clinic]);
  const shouldShowBusinessSelection = config.IS_CALL_CENTRE;
  const shouldShowClinicSelection = !isAdminContext && clinicOptions && clinicOptions.length > 0;

  return (
    <Container>
      {shouldShowBusinessSelection && (
        <BusinessSelect
          value={businessId}
          onChange={(event: TBusinessSelectChangeEvent) =>
            navigateToBusinessById({businessId: event.target.value as BusinessId})
          }
          MenuProps={{
            anchorReference: 'anchorPosition',
            anchorPosition: POPUP_ANCHOR_POSITION,
            marginThreshold: 0 // This stops the popup getting near the edge of window and defaults to 16. Can cause hassle when you don't know about it and you need to position something
          }}
        >
          {visibleBusinesses.map(({businessId, businessAcronym}) => (
            <BusinessMenuItem value={businessId}>{businessAcronym}</BusinessMenuItem>
          ))}
        </BusinessSelect>
      )}
      {shouldShowClinicSelection && (
        <ClinicSelect
          clinicOptions={clinicOptions}
          clinicValue={clinicValue}
          onChangeClinic={(clinicId: string) => setClinicById({id: clinicId})}
        />
      )}
    </Container>
  );
});

export {BusinessClinicSelector};

const POPUP_ANCHOR_POSITION = {top: 46, left: 7};

const Container = styled.nav`
  height: 100%;
  display: flex;
  flex: 1 1 auto;
  gap: ${({theme}) => theme.spacing(1.5)};
  align-items: center;
`;

const BusinessSelect = styled(Select)`
  border-radius: ${({theme}) => theme.shape.borderRadius * 1.5}px;
  box-shadow: 0 0 0 3px transparent;
  transition: box-shadow 150ms ease;

  &.Mui-focused {
    background: ${COLORS.therapiePurpleD10};
    box-shadow: 0 0 0 3px ${COLORS.therapiePurpleL20};
  }

  .MuiSelect-select {
    color: ${COLORS.therapiePurpleL90};
    line-height: ${TOP_BAR_INNER_HEIGHT};
    padding: ${({theme}) => theme.spacing(0, 3, 0, 1)};
    transition: background 150ms ease;
    border-radius: ${({theme}) => theme.shape.borderRadius}px;
    &:focus,
    &:hover {
      background: ${COLORS.therapiePurple};
      border-radius: ${({theme}) => theme.shape.borderRadius * 2}px; // Need to override MUI focus setting it to 0
    }
    &:active {
      background: ${COLORS.therapiePurpleL10};
    }
  }

  // The little up/down arrow
  svg {
    fill: ${COLORS.therapiePurpleL90};
  }

  // Hide MUI underline on this variant of Selects
  &:before,
  &:after {
    display: none;
  }
`;

const BusinessMenuItem = styled(MenuItem)`
  min-width: 100px;
`;
