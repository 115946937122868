import {atom} from 'recoil';
import {BranchModel} from 'types/DerivedApiModel';

export type BranchMap = Record<string, BranchModel>;

const branchesMapState = atom<BranchMap>({
  key: 'branchesMapState',
  default: {}
});

export {branchesMapState};
