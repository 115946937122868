import config from 'config';
import {PHOREST_ROUNDING_AMOUNT_ERROR_STRING, PHOREST_VOUCHER_ERROR_STRING} from 'constants/payments';

export const isPhorestRoundingError = (errorMessage: string) =>
  errorMessage.includes(PHOREST_ROUNDING_AMOUNT_ERROR_STRING) || errorMessage.includes(PHOREST_VOUCHER_ERROR_STRING);

export const generateErrorMessageForPhorestRoundingError = (errorString: string) => {
  const regex = /\d+\.\d{0,2}/;
  const currencySymbol = config.CURRENCY_SYMBOL!;
  const amount = errorString.match(regex) ?? '0.01';
  const errorMessage = `Please add ${currencySymbol + amount} to “Cash” to successfully process payment.`;
  return `Error: ${errorMessage}`;
};
