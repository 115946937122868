import {gql} from 'utils/graphql';

export const GET_APPOINTMENT_AVAILABILITY = gql`
  query GET_APPOINTMENT_AVAILABILITY(
    $businessId: String!
    $branchId: String!
    $fromDateTime: String!
    $toDateTime: String!
    $clientServiceSelections: [ClientServiceSelectionsInput]
  ) {
    getAppointmentAvailability(
      businessId: $businessId
      branchId: $branchId
      fromDateTime: $fromDateTime
      toDateTime: $toDateTime
      clientServiceSelections: $clientServiceSelections
    ) {
      startTime
      clientSchedules {
        clientId
        serviceSchedules {
          alternativeStaffMember
          appointmentId
          branchServiceGroupId
          clientCourseItemId
          endTime
          machineId
          roomId
          serviceGroupItemOptionId
          serviceId
          staffId
          staffRequest
          startTime
        }
      }
    }
  }
`;

export const GET_APPOINTMENTS = gql`
  query GET_APPOINTMENTS($query: BusinessBranchAppointmentsInput!, $page: PageInput) {
    getAppointments(query: $query, page: $page) {
      appointments {
        activationState
        appointmentDate
        appointmentId
        bookingId
        branchId
        businessId
        client {
          address {
            city
            country
            postalCode
            state
            streetAddress1
            streetAddress2
          }
          archived
          banned
          birthDate
          clientCategoryIds
          clientId
          id
          clientSince
          createdAt
          creatingBranchId
          creditAccount {
            creditDays
            creditLimit
            outstandingBalance
          }
          deleted
          email
          emailMarketingConsent
          emailReminderConsent
          externalId
          firstName
          firstVisit
          gender
          landLine
          lastName
          loyaltyCard {
            points
            serial
          }
          mergedToClientId
          mobile
          notes
          photoUrl
          preferredStaffId
          smsMarketingConsent
          smsReminderConsent
          updatedAt
          version
        }
        clientCourseId
        clientCourseItemId
        clientId
        confirmed
        courseId
        courseName
        createdAt
        depositAmount
        depositDateTime
        endTime
        groupBookingId
        id
        machineId
        preferredStaff
        price
        purchasingBranchId
        roomId
        serviceGroupId
        serviceGroupItemOptionId
        serviceId
        serviceName
        serviceRewardId
        source
        staff {
          branchId
          email
          disqualifiedServices
          firstName
          hideFromAppointmentScreen
          hideFromOnlineBookings
          id
          lastName
          mobile
          selfEmployed
          staffCategoryId
          staffCategoryName
          userId
        }
        staffId
        staffRequest
        startTime
        state
        version
        waitingListDateTime
        updatedAt
      }
      page {
        number
        size
        totalElements
        totalPages
      }
    }
  }
`;

export const GET_ALL_APPOINTMENTS = gql`
  query GET_ALL_APPOINTMENTS($query: BusinessBranchAppointmentsInput!) {
    getAllAppointments(query: $query) {
      appointments {
        activationState
        appointmentDate
        appointmentId
        bookingId
        branchId
        businessId
        client {
          address {
            city
            country
            postalCode
            state
            streetAddress1
            streetAddress2
          }
          archived
          banned
          birthDate
          clientCategoryIds
          clientId
          id
          clientSince
          createdAt
          creatingBranchId
          creditAccount {
            creditDays
            creditLimit
            outstandingBalance
          }
          deleted
          email
          emailMarketingConsent
          emailReminderConsent
          externalId
          firstName
          firstVisit
          gender
          landLine
          lastName
          loyaltyCard {
            points
            serial
          }
          mergedToClientId
          mobile
          notes
          photoUrl
          preferredStaffId
          smsMarketingConsent
          smsReminderConsent
          updatedAt
          version
        }
        clientCourseId
        clientCourseItemId
        clientId
        confirmed
        courseId
        courseName
        createdAt
        depositAmount
        depositDateTime
        endTime
        groupBookingId
        id
        machineId
        preferredStaff
        price
        purchasingBranchId
        roomId
        serviceGroupId
        serviceGroupItemOptionId
        serviceId
        serviceName
        serviceRewardId
        source
        staff {
          branchId
          email
          disqualifiedServices
          firstName
          hideFromAppointmentScreen
          hideFromOnlineBookings
          id
          lastName
          mobile
          selfEmployed
          staffCategoryId
          staffCategoryName
          userId
        }
        staffId
        staffRequest
        startTime
        state
        version
        waitingListDateTime
        updatedAt
      }
    }
  }
`;
