import {selector} from 'recoil';
import {clinicStaffState} from 'state/atoms/clinic/clinicStaff';
import {StaffCalendarModel} from 'types/DerivedApiModel';

const clinicStaffMapSelector = selector({
  key: 'staffMapSelector',
  get: ({get}) => new Map<string | null, StaffCalendarModel>(get(clinicStaffState)?.map(staff => [staff.id, staff]))
});

export {clinicStaffMapSelector};
