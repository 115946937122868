import {InputAdornment, TextField} from '@material-ui/core';
import SearchOutlinedIcon from '@material-ui/icons/SearchOutlined';
import BookingFilters from 'components/BookingAppointment/BookingFilters';
import useClinicFilterOptions from 'hooks/booking/useClinicFilterOptions';
import React from 'react';
import styled from 'styled-components';

interface Props {
  businessId: string;
  setBranchId: (id: string) => void;
  branchId: string;
  serviceNameFilter: string;
  onServiceNameFilterChange: (name: string) => void;
}

const Filters: React.FC<React.PropsWithChildren<Props>> = ({
  serviceNameFilter,
  onServiceNameFilterChange,
  businessId,
  branchId,
  setBranchId
}) => {
  const {clinicOptions} = useClinicFilterOptions();
  const onClinicChange = (id?: string) => setBranchId(id ?? '');

  return (
    <div>
      <StyledFiltersContainer>
        <BookingFilters
          clinicOptions={clinicOptions}
          fromDate={new Date()}
          onClinicChange={onClinicChange}
          showDatepicker={false}
          showStaffFilter={false}
        />
      </StyledFiltersContainer>
      <StyledServiceNameInputContainer>
        <StyledTextField
          fullWidth
          variant="outlined"
          size="small"
          placeholder="Search"
          autoFocus
          value={serviceNameFilter}
          onChange={evt => onServiceNameFilterChange(evt.target.value)}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchOutlinedIcon />
              </InputAdornment>
            )
          }}
        />
      </StyledServiceNameInputContainer>
    </div>
  );
};

export {Filters};
export default Filters;

const StyledFiltersContainer = styled.div`
  padding: 0 24px 0 24px;
`;
const StyledServiceNameInputContainer = styled.div`
  padding: 12px 24px;
  background: #f2f2f2;
`;
const StyledTextField = styled(TextField)`
  background: white;
`;
