import {BusinessCode, BusinessId} from 'constants/business';
import {selector} from 'recoil';
import {businessState} from 'state/atoms';

const getBusinessCode = selector({
  key: 'getBusinessCode',
  get: ({get}): BusinessCode => get(businessState).code
});

const getBusinessId = selector({
  key: 'getBusinessId',
  get: ({get}): BusinessId => get(businessState).id
});

export {getBusinessCode, getBusinessId};
