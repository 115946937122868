import {gql} from 'utils/graphql';

export const GET_ADMIN_SERVICES = gql`
  query GET_ADMIN_SERVICES($query: GetAdminServicesInput!, $page: PageInput) {
    getAdminServices(query: $query, page: $page) {
      page {
        number
        size
        totalElements
        totalPages
      }
      services {
        archived
        category {
          id
          name
        }
        compareAtPrice
        descriptionHTML
        deleted
        id
        name
        onlineName
        onlineStatus
        price
        saveClientCard
        urlHandle
      }
    }
  }
`;

export const GET_ALL_ADMIN_SERVICE_CATEGORIES = gql`
  query GET_ALL_ADMIN_SERVICE_CATEGORIES($businessId: String!) {
    getAllAdminServiceCategories(businessId: $businessId) {
      id
      name
    }
  }
`;

export const GET_ADMIN_SERVICE = gql`
  query GET_ADMIN_SERVICE($businessId: String!, $id: String!) {
    getAdminService(businessId: $businessId, id: $id) {
      archived
      category {
        id
        name
      }
      compareAtPrice
      deleted
      descriptionHTML
      id
      name
      onlineName
      onlineStatus
      price
      saveClientCard
      urlHandle
    }
  }
`;
