import {captureException} from '@sentry/react';
import {useQuery, UseQueryResult} from '@tanstack/react-query';
import {RQ_CACHE_TIMES, RQ_STALE_TIMES} from 'constants/reactquery';
import {GET_STAFF_ROSTER} from 'data/graphql/queries';
import {GQLClientContext} from 'providers/gqlClient';
import {useContext, useMemo} from 'react';
import {useRecoilValue} from 'recoil';
import {staffIdsFromClinicAppointmentsSelector} from 'state/selectors/clinic/staffIdsFromClinicAppointments';
import {GET_STAFF_ROSTERQuery, GET_STAFF_ROSTERQueryVariables, TimeSlotType} from 'types/ApiModel';
import {StaffTimeSlotsModel} from 'types/DerivedApiModel';

type TUseStaffRosterOutput = Pick<UseQueryResult<QueryResponse, Error>, 'data' | 'isFetching' | 'isLoading' | 'error'>;
type QueryResponse = StaffTimeSlotsModel[] | null;

function useStaffRoster(variables: GET_STAFF_ROSTERQueryVariables): TUseStaffRosterOutput {
  const {data: dataStaffIdsWithAppointmentsAtDate} = useRecoilValue(staffIdsFromClinicAppointmentsSelector);
  const {gqlClient} = useContext(GQLClientContext);
  const {clinicId, businessId, fromDate} = variables.query || {};
  const rosterQuery = useMemo(
    () => ({query: {businessId: businessId, clinicId: clinicId, fromDate, toDate: fromDate}}),
    [clinicId, businessId, fromDate]
  );

  const enabled = !!(
    rosterQuery.query.businessId &&
    rosterQuery.query.clinicId &&
    rosterQuery.query.fromDate &&
    rosterQuery.query.toDate
  );

  const {
    data: dataFullRoster,
    isLoading,
    isFetching,
    error
  } = useQuery<QueryResponse, Error>({
    enabled,
    cacheTime: RQ_CACHE_TIMES.useStaffRoster,
    staleTime: RQ_STALE_TIMES.useStaffRoster,
    queryKey: [USE_STAFF_ROSTER, rosterQuery],
    queryFn: async () => {
      const resp = await gqlClient.request<GET_STAFF_ROSTERQuery, GET_STAFF_ROSTERQueryVariables>(
        GET_STAFF_ROSTER,
        rosterQuery
      );
      return resp.data?.getStaffRoster?.roster ?? null;
    },
    onError: ({message}) => captureException(new Error(message))
  });

  const rosteredStaffAndUnrosteredStaffWithApptsOnThisDate = useMemo(
    () =>
      dataFullRoster?.filter(
        ({timeSlots, staffId}) =>
          dataStaffIdsWithAppointmentsAtDate.has(staffId) || timeSlots?.some(({type}) => type === TimeSlotType.WORKING)
      ),
    [dataFullRoster, dataStaffIdsWithAppointmentsAtDate]
  );

  return {
    data: rosteredStaffAndUnrosteredStaffWithApptsOnThisDate,
    isLoading,
    isFetching,
    error
  };
}

export {useStaffRoster};
export default useStaffRoster;

export const USE_STAFF_ROSTER = 'GET_STAFF_ROSTER';
