import {InputAdornmentProps} from '@material-ui/core';
import {DatePickerProps, KeyboardDatePicker} from '@material-ui/pickers';
import {DATE_FORMAT} from 'constants/time';
import React from 'react';
import styled from 'styled-components';

interface Props extends DatePickerProps {
  validationError?: string;
  InputAdornmentProps?: Partial<InputAdornmentProps>;
}

const FormDatePicker: React.FC<React.PropsWithChildren<Props>> = ({
  autoOk = true,
  disableFuture,
  format = DATE_FORMAT.DATE_MONTH_YEAR_LOCALISED,
  InputAdornmentProps,
  inputProps,
  inputVariant = 'outlined',
  label,
  maxDate,
  minDate,
  onChange,
  openTo,
  value,
  validationError,
  variant = 'inline',
  views
}) => {
  return (
    <StyledKeyboardDatePicker
      autoOk={autoOk}
      disableFuture={disableFuture}
      autoComplete="off"
      format={format}
      id="create-client-date-picker"
      InputAdornmentProps={InputAdornmentProps}
      inputProps={inputProps}
      inputVariant={inputVariant}
      KeyboardButtonProps={{'aria-label': 'change date'}}
      label={label}
      maxDate={maxDate}
      minDate={minDate}
      onChange={onChange}
      openTo={openTo}
      value={value}
      variant={variant}
      views={views}
      error={!!validationError}
      helperText={validationError ? validationError : ''}
    />
  );
};

export default FormDatePicker;

const StyledKeyboardDatePicker = styled(KeyboardDatePicker)`
  .MuiInputBase-root {
    border-radius: ${({theme}) => theme.shape.borderRadius * 2}px;
  }
  button {
    transform: translateX(${({theme}) => theme.spacing(1.125)});
  }
`;
