/* tslint:disable */
/* eslint-disable */
//  This file was automatically generated and should not be edited.

export type AdminCourseInput = {
  businessId: string;
  compareAtPrice?: number | null;
  descriptionHTML?: string | null;
  id: string;
  onlineName?: string | null;
  onlineStatus?: AdminOnlineStatus | null;
  urlHandle?: string | null;
};

export enum AdminOnlineStatus {
  DRAFT = 'DRAFT',
  PUBLISHED = 'PUBLISHED'
}

export enum AdminCourseServiceUnitType {
  SESSIONS = 'SESSIONS'
}

export type CreateAdminOnlineGroupInput = {
  businessId: string;
  name: string;
  parentGroupId?: string | null;
  type: AdminOnlineGroupType;
  urlHandle?: string | null;
};

export enum AdminOnlineGroupType {
  ONLINE_GROUP = 'ONLINE_GROUP',
  ONLINE_SUB_GROUP = 'ONLINE_SUB_GROUP',
  ONLINE_SECTION = 'ONLINE_SECTION',
  ONLINE_SUB_SECTION = 'ONLINE_SUB_SECTION'
}

export type DeleteAdminOnlineGroupInput = {
  businessId: string;
  id: string;
  type: AdminOnlineGroupType;
};

export type CreateAdminOnlineSubGroupItemInput = {
  businessId: string;
  subSectionId: string;
  onlinePriority: number;
  serviceId?: string | null;
  courseId?: string | null;
};

export type DeleteAdminOnlineSubGroupItemInput = {
  businessId: string;
  id: string;
};

export type AdminProductInput = {
  businessId: string;
  compareAtPrice?: number | null;
  descriptionHTML?: string | null;
  id: string;
  onlineName?: string | null;
  onlinePriority?: number | null;
  onlineStatus?: AdminOnlineStatus | null;
  outOfStock?: boolean | null;
  productTypeIds?: Array<string> | null;
  skinConcernIds?: Array<string> | null;
  skinTypeIds?: Array<string> | null;
  urlHandle?: string | null;
};

export type AdminServiceInput = {
  businessId: string;
  compareAtPrice?: number | null;
  descriptionHTML?: string | null;
  id: string;
  onlineName?: string | null;
  onlineStatus?: AdminOnlineStatus | null;
  saveClientCard?: boolean | null;
  urlHandle?: string | null;
};

export type AnalyticsEventInput = {
  actionName: string;
  userRole?: string | null;
  userName?: string | null;
  resultCode?: string | null;
  detailsJSON?: string | null;
};

export type UpdateAppointmentsInput = {
  appointments: Array<AppointmentsInput>;
  acceptedIssueTypes?: Array<string> | null;
  businessId: string;
  clientId: string;
  clinicId: string;
  shouldCommit?: boolean | null;
};

export type AppointmentsInput = {
  appointmentDate: string;
  appointmentId: string;
  clientCourseItemId?: string | null;
  endTime?: string | null;
  machineId?: string | null;
  price?: number | null;
  roomId?: string | null;
  serviceId: string;
  staffId?: string | null;
  startTime?: string | null;
  state: BookingState;
  version: number;
};

export enum BookingState {
  BOOKED = 'BOOKED',
  CHECKED_IN = 'CHECKED_IN',
  PAID = 'PAID'
}

export enum ActivationState {
  ACTIVE = 'ACTIVE',
  CANCELED = 'CANCELED',
  RESERVED = 'RESERVED'
}

export enum BookingSource {
  TERMINAL = 'TERMINAL',
  THIRD_PARTY = 'THIRD_PARTY',
  WEB_APP = 'WEB_APP'
}

export type CancelAppointmentInput = {
  businessId: string;
  clinicId: string;
  appointmentId: string;
};

export type CheckInAppointmentsInput = {
  date: string;
  branchId: string;
  businessId: string;
  clientId: string;
};

export type UndoCheckInAppointmentsInput = {
  date: string;
  branchId: string;
  businessId: string;
  clientId: string;
};

export type DeleteAppointmentInput = {
  businessId: string;
  branchId: string;
  appointmentId: string;
};

export type CreateBookingInput = {
  bookingStatus: ActivationState;
  clientId: string;
  clientAppointmentSchedules?: Array<ClientAppointmentScheduleInput> | null;
};

export type ClientAppointmentScheduleInput = {
  clientId: string;
  serviceSchedules?: Array<ServiceScheduleInput> | null;
};

export type ServiceScheduleInput = {
  startTime: string;
  endTime: string;
  clientCourseItemId?: string | null;
  serviceId: string;
  staffId: string;
  staffRequest: boolean;
};

export type RescheduleBookingInput = {
  businessId: string;
  clientId: string;
  oldBooking: RescheduleBookingObjectInput;
  newBooking: RescheduleBookingObjectInput;
};

export type RescheduleBookingObjectInput = {
  bookingStatus: ActivationState;
  clientId: string;
  clinicId: string;
  clientAppointmentSchedules?: Array<RescheduleClientAppointmentScheduleInput> | null;
};

export type RescheduleClientAppointmentScheduleInput = {
  clientId: string;
  serviceSchedules?: Array<RescheduleServiceScheduleInput> | null;
};

export type RescheduleServiceScheduleInput = {
  appointmentDate: string;
  appointmentId: string;
  clientCourseItemId?: string | null;
  endDateTimeISO: string;
  machineId?: string | null;
  price?: number | null;
  roomId?: string | null;
  serviceId: string;
  staffId: string;
  staffRequest: boolean;
  startDateTimeISO: string;
  version: number;
};

export type CreateStaffBreakInput = {
  businessId: string;
  branchId: string;
  staffId: string;
  startTimeISO: string;
  endTimeISO: string;
  label: string;
};

export type UpdateStaffBreakInput = {
  branchId: string;
  businessId: string;
  endTimeISO: string;
  label: string;
  staffBreakId: string;
  staffId: string;
  startTimeISO: string;
};

export type DeleteStaffBreakInput = {
  branchId: string;
  businessId: string;
  staffBreakId: string;
};

export type ClientInput = {
  address?: AddressInput | null;
  archived?: boolean | null;
  banned?: boolean | null;
  birthDate?: string | null;
  clientCategoryIds?: Array<string> | null;
  clientId: string;
  clientSince?: string | null;
  createdAt?: string | null;
  creatingBranchId?: string | null;
  creditAccount?: CreditAccountInput | null;
  deleted?: boolean | null;
  email?: string | null;
  emailMarketingConsent?: boolean | null;
  emailReminderConsent?: boolean | null;
  externalId?: string | null;
  firstName: string;
  gender?: string | null;
  landLine?: string | null;
  lastName: string;
  mergedToClientId?: string | null;
  mobile?: string | null;
  notes?: string | null;
  photoUrl?: string | null;
  preferredStaffId?: string | null;
  smsMarketingConsent?: boolean | null;
  smsReminderConsent?: boolean | null;
  updatedAt?: string | null;
  version: number;
};

export type AddressInput = {
  city?: string | null;
  country?: string | null;
  postalCode?: string | null;
  state?: string | null;
  streetAddress1?: string | null;
  streetAddress2?: string | null;
};

export type CreditAccountInput = {
  creditDays?: number | null;
  creditLimit?: number | null;
  outstandingBalance?: number | null;
};

export type CreateClientInput = {
  client: CreateClientObjectInput;
  businessId: string;
};

export type CreateClientObjectInput = {
  address?: AddressInput | null;
  banned?: boolean | null;
  birthDate?: string | null;
  clientCategoryIds?: Array<string | null> | null;
  clientId?: string | null;
  clientSince?: string | null;
  creatingBranchId?: string | null;
  creditAccount?: CreditAccountInput | null;
  email: string;
  emailMarketingConsent?: boolean | null;
  emailReminderConsent?: boolean | null;
  externalId?: string | null;
  firstName: string;
  gender?: string | null;
  landLine?: string | null;
  lastName: string;
  mobile: string;
  notes?: string | null;
  photoUrl?: string | null;
  preferredStaffId?: string | null;
  smsMarketingConsent?: boolean | null;
  smsReminderConsent?: boolean | null;
};

export type DeleteClientInput = {
  businessId: string;
  clientId: string;
};

export type CreatePurchaseInput = {
  businessId: string;
  clinicId: string;
  purchase: PurchaseInput;
  source: ChannelSource;
};

export type PurchaseInput = {
  clientId?: string | null;
  items?: Array<PurchaseItemInput> | null;
  number: string;
  payments?: Array<PurchasePaymentInput> | null;
};

export type PurchaseItemInput = {
  appointmentId?: string | null;
  branchProductId?: string | null;
  courseId?: string | null;
  description?: string | null;
  discount?: DiscountInput | null;
  price: number;
  quantity: number;
  serviceId?: string | null;
  staffId: string;
  taxRates?: PurchaseTaxRateInput | null;
  voucher?: CreateVoucherObjectInput | null;
};

export type DiscountInput = {
  fixedDiscountId?: string | null;
  type: DiscountType;
  value: number;
};

export enum DiscountType {
  AMOUNT = 'AMOUNT',
  PERCENT = 'PERCENT'
}

export type PurchaseTaxRateInput = {
  name?: string | null;
  rate?: number | null;
  taxId: string;
  type?: string | null;
};

export type CreateVoucherObjectInput = {
  clientId?: string | null;
  creatingBranchId: string;
  originalBalance: number;
  serialNumber: string;
};

export type PurchasePaymentInput = {
  amount: number;
  paymentTypeId: string;
  // This field will be removed soon only paymentTypeId is mandatory, can't depracate input fields
  name?: string | null;
  voucherId?: string | null;
};

export enum ChannelSource {
  call_centre = 'call_centre',
  clinic = 'clinic',
  mobile_app = 'mobile_app',
  web = 'web'
}

export type CreatePurchaseTransactionInput = {
  phorest: CreatePurchaseInput;
  stripe: StripeCreateTransactionInput;
};

export type StripeCreateTransactionInput = {
  paymentIntents: Array<string>;
};

export enum TransactionStatus {
  pending = 'pending',
  succeeded = 'succeeded',
  failed = 'failed'
}

export type GetAdminCoursesInput = {
  businessId: string;
  includeArchived?: boolean | null;
  includeDeleted?: boolean | null;
  name?: string | null;
  onlineStatus?: AdminOnlineStatus | null;
};

export type PageInput = {
  page?: number | null;
  size?: number | null;
};

export type GetAdminOnlineGroupsInput = {
  businessId: string;
  name?: string | null;
  parentGroupId?: string | null;
  type: AdminOnlineGroupType;
};

export type GetAdminOnlineGroupInput = {
  businessId: string;
  id: string;
  type: AdminOnlineGroupType;
};

export type GetAdminOnlineSubSectionItemsInput = {
  businessId: string;
  subSectionId: string;
};

export enum SubSectionItemType {
  COURSE = 'COURSE',
  SERVICE = 'SERVICE'
}

export type GetAdminProductsInput = {
  businessId: string;
  includeArchived?: boolean | null;
  includeDeleted?: boolean | null;
  brandId?: string | null;
  nameOrSkuCode?: string | null;
  onlineStatus?: AdminOnlineStatus | null;
};

export type GetAdminServicesInput = {
  businessId: string;
  includeArchived?: boolean | null;
  includeDeleted?: boolean | null;
  name?: string | null;
  categoryId?: string | null;
  onlineStatus?: AdminOnlineStatus | null;
};

export type ClientServiceSelectionsInput = {
  serviceSelections?: Array<ServiceSelectionInput | null> | null;
  clientId?: string | null;
};

export type ServiceSelectionInput = {
  serviceId?: string | null;
  staffId?: string | null;
  branchServiceGroupId?: string | null;
};

export type BusinessBranchAppointmentsInput = {
  branchId: string;
  businessId: string;
  fromDate: string;
  toDate: string;
  clientId?: string | null;
  staffId?: string | null;
  roomId?: string | null;
  machineId?: string | null;
  fetchCancelled?: boolean | null;
  updatedFrom?: string | null;
  updatedTo?: string | null;
};

export type BranchesInput = {
  businessId: string;
};

export type ClinicWorkTimetableInput = {
  businessId: string;
  clinicId: string;
  fromDate: string;
  toDate: string;
};

export enum TimeSlotType {
  NON_WORKING = 'NON_WORKING',
  WORKING = 'WORKING',
  NOT_SPECIFIED = 'NOT_SPECIFIED'
}

export type ClinicRosterInput = {
  businessId: string;
  clinicId: string;
  fromDate: string;
  toDate: string;
};

export type ReviewByIdInput = {
  reviewId: string;
  branchId: string;
  businessId: string;
};

export type ReviewsInput = {
  branchId: string;
  businessId: string;
  clientId?: string | null;
  clientName?: string | null;
  reviewDate?: string | null;
  staffName?: string | null;
};

export type BreaksInput = {
  businessId: string;
  clinicId: string;
  fromDate: string;
  toDate: string;
  roomId?: string | null;
  staffId?: string | null;
  machineId?: string | null;
};

export type AllBreaksInput = {
  businessId: string;
  clinicId: string;
  date: string;
};

export type ClientsInput = {
  businessId: string;
  phone?: string | null;
  firstName?: string | null;
  lastName?: string | null;
  email?: string | null;
  updatedAfter?: string | null;
};

export type ClientServiceHistoryInput = {
  businessId: string;
  clientId: string;
};

export type VouchersInput = {
  businessId: string;
  clientId?: string | null;
  serialNumber?: string | null;
  includeUsed?: boolean | null;
};

export type ClientProductsInput = {
  clientId: string;
  businessId: string;
};

export type ClientCoursesInput = {
  clientId?: string | null;
  businessId: string;
  includeArchived?: boolean | null;
};

export type BusinessBranchInput = {
  businessId: string;
  branchId: string;
  includeArchived?: boolean | null;
};

export type DiscountsQueryInput = {
  businessId: string;
  includeArchived?: boolean | null;
};

export type PaymentTypesInput = {
  businessId: string;
};

export type ProductsInput = {
  businessId: string;
  branchId: string;
  archivedOnly?: boolean | null;
  lowStock?: boolean | null;
  outOfStock?: boolean | null;
  searchQuery?: string | null;
  productType?: string | null;
};

export type GetOrCreateTillSessionInput = {
  businessId: string;
  clinicId: string;
};

export type StaffRosterInput = {
  businessId: string;
  clinicId: string;
  fromDate: string;
  toDate: string;
};

export type ServiceInput = {
  businessId: string;
  branchId: string;
  serviceId: string;
};

export type ServicesInput = {
  businessId: string;
  branchId: string;
  includeArchived?: boolean | null;
};

export type ServiceCategoriesInput = {
  businessId: string;
  branchId: string;
};

export type UPDATE_ADMIN_COURSEMutationVariables = {
  payload: AdminCourseInput;
};

export type UPDATE_ADMIN_COURSEMutation = {
  // This Mutation is relate to business context
  updateAdminCourse: {
    __typename: 'AdminCourse';
    archived: boolean | null;
    compareAtPrice: number | null;
    deleted: boolean | null;
    descriptionHTML: string | null;
    id: string | null;
    name: string | null;
    onlineName: string | null;
    onlineStatus: AdminOnlineStatus | null;
    totalPrice: number | null;
    items: Array<{
      __typename: 'CourseItemDetails';
      id: string | null;
      name: string | null;
      price: number | null;
      serviceId: string | null;
      totalUnits: number | null;
      unitType: AdminCourseServiceUnitType | null;
    } | null> | null;
    urlHandle: string | null;
  } | null;
};

export type CREATE_ADMIN_ONLINE_GROUPMutationVariables = {
  payload: CreateAdminOnlineGroupInput;
};

export type CREATE_ADMIN_ONLINE_GROUPMutation = {
  createAdminOnlineGroup: {
    __typename: 'CreateAdminOnlineGroupResponse';
    businessId: string | null;
    id: string | null;
    name: string | null;
    parentGroupId: string | null;
    type: AdminOnlineGroupType | null;
    urlHandle: string | null;
  } | null;
};

export type DELETE_ADMIN_ONLINE_GROUPMutationVariables = {
  payload: DeleteAdminOnlineGroupInput;
};

export type DELETE_ADMIN_ONLINE_GROUPMutation = {
  deleteAdminOnlineGroup: boolean | null;
};

export type CREATE_ADMIN_ONLINE_SUB_GROUP_ITEMMutationVariables = {
  payload: CreateAdminOnlineSubGroupItemInput;
};

export type CREATE_ADMIN_ONLINE_SUB_GROUP_ITEMMutation = {
  createAdminOnlineSubGroupItem: {
    __typename: 'CreateAdminOnlineSubGroupItemResponse';
    businessId: string | null;
    id: string | null;
    subSectionId: string | null;
    onlinePriority: number | null;
    serviceId: string | null;
    courseId: string | null;
  } | null;
};

export type DELETE_ADMIN_ONLINE_SUB_GROUP_ITEMMutationVariables = {
  payload: DeleteAdminOnlineSubGroupItemInput;
};

export type DELETE_ADMIN_ONLINE_SUB_GROUP_ITEMMutation = {
  deleteAdminOnlineSubGroupItem: boolean | null;
};

export type UPDATE_ADMIN_PRODUCTMutationVariables = {
  payload: AdminProductInput;
};

export type UPDATE_ADMIN_PRODUCTMutation = {
  updateAdminProduct: {
    __typename: 'AdminProduct';
    archived: boolean | null;
    brand: {
      __typename: 'Option';
      id: string | null;
      name: string | null;
    } | null;
    category: {
      __typename: 'Option';
      id: string | null;
      name: string | null;
    } | null;
    code: string | null;
    compareAtPrice: number | null;
    deleted: boolean | null;
    descriptionHTML: string | null;
    id: string | null;
    imageUrls: Array<string> | null;
    measurementQuantity: number | null;
    measurementUnit: string | null;
    name: string | null;
    onlineName: string | null;
    onlinePriority: number | null;
    onlineStatus: AdminOnlineStatus | null;
    outOfStock: boolean | null;
    price: number | null;
    productTypes: Array<{
      __typename: 'Option';
      id: string | null;
      name: string | null;
    }> | null;
    skinConcerns: Array<{
      __typename: 'Option';
      id: string | null;
      name: string | null;
    }> | null;
    skinTypes: Array<{
      __typename: 'Option';
      id: string | null;
      name: string | null;
    }> | null;
    urlHandle: string | null;
  } | null;
};

export type UPDATE_ADMIN_SERVICEMutationVariables = {
  payload: AdminServiceInput;
};

export type UPDATE_ADMIN_SERVICEMutation = {
  updateAdminService: {
    __typename: 'AdminService';
    archived: boolean | null;
    category: {
      __typename: 'Option';
      id: string | null;
      name: string | null;
    } | null;
    compareAtPrice: number | null;
    deleted: boolean | null;
    descriptionHTML: string | null;
    id: string | null;
    name: string | null;
    onlineName: string | null;
    onlineStatus: AdminOnlineStatus | null;
    price: number | null;
    saveClientCard: boolean | null;
    urlHandle: string | null;
  } | null;
};

export type SEND_ANALYTICS_EVENTMutationVariables = {
  payload: AnalyticsEventInput;
};

export type SEND_ANALYTICS_EVENTMutation = {
  sendAnalyticsEvent: string | null;
};

export type UpdateAppointmentsMutationVariables = {
  payload: UpdateAppointmentsInput;
};

export type UpdateAppointmentsMutation = {
  updateAppointments: {
    __typename: 'UpdateAppointmentsResponse';
    appointments: Array<{
      __typename: 'UpdateAppointmentResponse';
      appointmentId: string;
      version: number;
      activationState: ActivationState | null;
      appointmentDate: string | null;
      bookingId: string | null;
      clientCourseItemId: string | null;
      clientId: string | null;
      depositAmount: number | null;
      depositDateTime: string | null;
      endTime: string | null;
      groupBookingId: string | null;
      machineId: string | null;
      preferredStaff: boolean | null;
      price: number | null;
      roomId: string | null;
      serviceGroupId: string | null;
      serviceGroupItemOptionId: string | null;
      serviceId: string | null;
      serviceRewardId: string | null;
      source: BookingSource | null;
      staffId: string | null;
      staffRequest: boolean | null;
      startTime: string | null;
      state: BookingState | null;
      waitingListDateTime: string | null;
    }> | null;
  } | null;
};

export type CancelAppointmentMutationVariables = {
  payload: CancelAppointmentInput;
};

export type CancelAppointmentMutation = {
  // This mutation is soft delete, client history will have appointment with status cancelled
  cancelAppointment: string | null;
};

export type CheckInAppointmentsMutationVariables = {
  payload: CheckInAppointmentsInput;
};

export type CheckInAppointmentsMutation = {
  checkInAppointments: {
    __typename: 'getAllDiaryAppointmentsResponseModel';
    appointments: Array<{
      __typename: 'DiaryAppointment';
      activationState: ActivationState | null;
      appointmentDate: string | null;
      appointmentId: string | null;
      bookingId: string | null;
      confirmed: boolean | null;
      clientCourseItemId: string | null;
      clientId: string | null;
      depositAmount: number | null;
      depositDateTime: string | null;
      endTime: string | null;
      machineId: string | null;
      preferredStaff: boolean | null;
      price: number | null;
      roomId: string | null;
      serviceId: string | null;
      source: BookingSource | null;
      staffId: string | null;
      staffRequest: boolean | null;
      startTime: string | null;
      state: BookingState | null;
      version: number | null;
      waitingListDateTime: string | null;
    }> | null;
  } | null;
};

export type UndoCheckInAppointmentsMutationVariables = {
  payload: UndoCheckInAppointmentsInput;
};

export type UndoCheckInAppointmentsMutation = {
  undoCheckInAppointments: {
    __typename: 'getAllDiaryAppointmentsResponseModel';
    appointments: Array<{
      __typename: 'DiaryAppointment';
      activationState: ActivationState | null;
      appointmentDate: string | null;
      appointmentId: string | null;
      bookingId: string | null;
      confirmed: boolean | null;
      clientCourseItemId: string | null;
      clientId: string | null;
      depositAmount: number | null;
      depositDateTime: string | null;
      endTime: string | null;
      machineId: string | null;
      preferredStaff: boolean | null;
      price: number | null;
      roomId: string | null;
      serviceId: string | null;
      source: BookingSource | null;
      staffId: string | null;
      staffRequest: boolean | null;
      startTime: string | null;
      state: BookingState | null;
      version: number | null;
      waitingListDateTime: string | null;
      client: {
        __typename: 'DiaryClient';
        clientId: string | null;
        firstName: string | null;
        lastName: string | null;
        mobile: string | null;
        email: string | null;
        gender: string | null;
      } | null;
    }> | null;
  } | null;
};

export type DeleteAppointmentMutationVariables = {
  payload: DeleteAppointmentInput;
};

export type DeleteAppointmentMutation = {
  // This mutation is an hard delete, won't leave traces of the appointment
  deleteAppointment: boolean | null;
};

export type CreateBookingMutationVariables = {
  booking: CreateBookingInput;
  clinicId: string;
  businessId: string;
  acceptedIssueTypes?: Array<string> | null;
};

export type CreateBookingMutation = {
  createBooking: {
    __typename: 'CreateBookingResponse';
    bookingId: string | null;
    clientId: string;
    clientAppointmentSchedules: Array<{
      __typename: 'ClientAppointmentSchedule';
      clientId: string | null;
      serviceSchedules: Array<{
        __typename: 'ServiceSchedule';
        alternativeStaffMember: boolean | null;
        appointmentId: string | null;
        branchServiceGroupId: string | null;
        clientCourseItemId: string | null;
        endTime: string | null;
        machineId: string | null;
        roomId: string | null;
        serviceGroupItemOptionId: string | null;
        serviceId: string | null;
        staffId: string | null;
        staffRequest: boolean | null;
        startTime: string | null;
      } | null> | null;
    }> | null;
  } | null;
};

export type CANCEL_BOOKINGMutationVariables = {
  bookingId: string;
  clinicId: string;
  businessId: string;
};

export type CANCEL_BOOKINGMutation = {
  // This mutation is soft delete, client history will have booking with status cancelled
  cancelBooking: string | null;
};

export type RESCHEDULE_BOOKINGMutationVariables = {
  payload: RescheduleBookingInput;
};

export type RESCHEDULE_BOOKINGMutation = {
  rescheduleBooking: {
    __typename: 'RescheduleBookingResponse';
    rescheduledAppointment: Array<{
      __typename: 'ServiceReschedule';
      appointmentDate: string | null;
      appointmentId: string;
      clientCourseItemId: string | null;
      endDateTimeISO: string;
      machineId: string | null;
      price: number | null;
      roomId: string | null;
      serviceId: string | null;
      staffId: string | null;
      staffRequest: boolean | null;
      startDateTimeISO: string;
      version: number;
    }> | null;
    errors: Array<{
      __typename: 'ServiceReschedule';
      appointmentDate: string | null;
      appointmentId: string;
      clientCourseItemId: string | null;
      endDateTimeISO: string;
      machineId: string | null;
      price: number | null;
      roomId: string | null;
      serviceId: string | null;
      staffId: string | null;
      staffRequest: boolean | null;
      startDateTimeISO: string;
      version: number;
    }> | null;
  } | null;
};

export type CREATE_STAFF_BREAKMutationVariables = {
  query: CreateStaffBreakInput;
};

export type CREATE_STAFF_BREAKMutation = {
  createStaffBreak: {
    __typename: 'CreateStaffBreakResponse';
    endTimeISO: string;
    label: string;
    staffBreakId: string;
    staffId: string;
    startTimeISO: string;
  } | null;
};

export type UPDATE_STAFF_BREAKMutationVariables = {
  payload: UpdateStaffBreakInput;
};

export type UPDATE_STAFF_BREAKMutation = {
  updateStaffBreak: {
    __typename: 'CreateStaffBreakResponse';
    staffBreakId: string;
    staffId: string;
    startTimeISO: string;
    endTimeISO: string;
    label: string;
  } | null;
};

export type DELETE_STAFF_BREAKMutationVariables = {
  payload: DeleteStaffBreakInput;
};

export type DELETE_STAFF_BREAKMutation = {
  deleteStaffBreak: boolean | null;
};

export type UPDATE_CLIENTMutationVariables = {
  client: ClientInput;
  businessId: string;
};

export type UPDATE_CLIENTMutation = {
  updateClient: {
    __typename: 'Client';
    address: {
      __typename: 'Address';
      city: string | null;
      country: string | null;
      postalCode: string | null;
      state: string | null;
      streetAddress1: string | null;
      streetAddress2: string | null;
    } | null;
    archived: boolean | null;
    banned: boolean | null;
    birthDate: string | null;
    clientCategoryIds: Array<string> | null;
    clientId: string | null;
    id: string | null;
    clientSince: string | null;
    createdAt: string | null;
    creatingBranchId: string | null;
    creditAccount: {
      __typename: 'CreditAccount';
      creditDays: number | null;
      creditLimit: number | null;
      outstandingBalance: number | null;
    } | null;
    deleted: boolean | null;
    email: string | null;
    emailMarketingConsent: boolean | null;
    emailReminderConsent: boolean | null;
    externalId: string | null;
    firstName: string | null;
    firstVisit: string | null;
    gender: string | null;
    landLine: string | null;
    lastName: string | null;
    loyaltyCard: {
      __typename: 'LoyaltyCard';
      points: number | null;
      serial: string | null;
    } | null;
    mergedToClientId: string | null;
    mobile: string | null;
    notes: string | null;
    photoUrl: string | null;
    preferredStaffId: string | null;
    smsMarketingConsent: boolean | null;
    smsReminderConsent: boolean | null;
    updatedAt: string | null;
    version: number | null;
  } | null;
};

export type CREATE_CLIENTMutationVariables = {
  payload: CreateClientInput;
};

export type CREATE_CLIENTMutation = {
  createClient: {
    __typename: 'Client';
    address: {
      __typename: 'Address';
      city: string | null;
      country: string | null;
      postalCode: string | null;
      state: string | null;
      streetAddress1: string | null;
      streetAddress2: string | null;
    } | null;
    banned: boolean | null;
    birthDate: string | null;
    clientCategoryIds: Array<string> | null;
    clientId: string | null;
    clientSince: string | null;
    creatingBranchId: string | null;
    creditAccount: {
      __typename: 'CreditAccount';
      creditDays: number | null;
      creditLimit: number | null;
      outstandingBalance: number | null;
    } | null;
    email: string | null;
    emailMarketingConsent: boolean | null;
    emailReminderConsent: boolean | null;
    externalId: string | null;
    firstName: string | null;
    gender: string | null;
    landLine: string | null;
    lastName: string | null;
    mobile: string | null;
    notes: string | null;
    photoUrl: string | null;
    preferredStaffId: string | null;
    smsMarketingConsent: boolean | null;
    smsReminderConsent: boolean | null;
  } | null;
};

export type DELETE_CLIENTMutationVariables = {
  payload: DeleteClientInput;
};

export type DELETE_CLIENTMutation = {
  deleteClient: boolean | null;
};

export type CREATE_PURCHASEMutationVariables = {
  payload: CreatePurchaseInput;
};

export type CREATE_PURCHASEMutation = {
  createPurchase: {
    __typename: 'CreatePurchaseResponse';
    clientId: string;
    purchaseId: string;
    number: string;
    transactionNumber: string;
    payments: Array<{
      __typename: 'CreatePurchasePayment';
      paymentId: string;
      type: string;
      amount: number;
    }> | null;
    items: Array<{
      __typename: 'CreatePurchaseItem';
      details: {
        __typename: 'CreatePurchaseDetail';
        taxAmount: number | null;
        taxIncluded: boolean | null;
        taxRates: Array<{
          __typename: 'CreatePurchaseTaxRates';
          name: string | null;
          rate: number | null;
        }> | null;
        totalAmount: number | null;
      } | null;
      price: number;
      purchaseItemId: string;
      quantity: string;
      staffId: string;
    }> | null;
    details: {
      __typename: 'CreatePurchaseDetail';
      taxAmount: number | null;
      taxIncluded: boolean | null;
      taxRates: Array<{
        __typename: 'CreatePurchaseTaxRates';
        name: string | null;
        rate: number | null;
      }> | null;
      totalAmount: number | null;
      changeAmount: number | null;
    } | null;
  } | null;
};

export type CREATE_PURCHASE_TRANSACTION_SYNCMutationVariables = {
  payload: CreatePurchaseTransactionInput;
};

export type CREATE_PURCHASE_TRANSACTION_SYNCMutation = {
  createPurchaseTransactionSync: {
    __typename: 'CreatePurchaseTransactionResponse';
    id: string;
    clientId: string | null;
    createDateISO: string | null;
    updateDateISO: string | null;
    status: TransactionStatus | null;
    clinicId: string | null;
    currentStep: string | null;
    currentStepStatus: TransactionStatus | null;
    errors: Array<string> | null;
    phorest: {
      __typename: 'Phorest';
      response: {
        __typename: 'PhorestResponse';
        number: string | null;
        clientId: string | null;
        purchaseId: string | null;
        transactionNumber: string | null;
        details: {
          __typename: 'CreatePurchaseDetail';
          changeAmount: number | null;
        } | null;
        payments: Array<{
          __typename: 'PhorestPurchasePayment';
          amount: number | null;
          customTypeCode: string | null;
          type: string | null;
          paymentId: string | null;
          customTypeName: string | null;
        } | null> | null;
        voucherBalances: Array<{
          __typename: 'PhorestVoucherBalance';
          voucherId: string | null;
          oldBalance: number | null;
          newBalance: number | null;
        } | null> | null;
        items: Array<{
          __typename: 'PhorestPurchaseItem';
          appointmentId: string | null;
          branchProductId: string | null;
          courseId: string | null;
          description: string | null;
          outstandingBalancePayment: boolean | null;
          price: number | null;
          purchaseItemId: string | null;
          quantity: number | null;
          staffId: string | null;
        } | null> | null;
      } | null;
    } | null;
    stripe: {
      __typename: 'Stripe';
      response: {
        __typename: 'StripeResponse';
        livemode: boolean | null;
        amount_capturable: number | null;
        currency: string | null;
        id: string | null;
        client_secret: string | null;
        payment_method: string | null;
        capture_method: string | null;
        amount: number | null;
        created: number | null;
        payment_method_types: Array<string | null> | null;
        amount_received: number | null;
        confirmation_method: string | null;
        customer: string | null;
        status: string | null;
      } | null;
    } | null;
  } | null;
};

export type GET_ADMIN_COURSESQueryVariables = {
  query?: GetAdminCoursesInput | null;
  page?: PageInput | null;
};

export type GET_ADMIN_COURSESQuery = {
  getAdminCourses: {
    __typename: 'GetAdminCoursesResponseModel';
    page: {
      __typename: 'Page';
      number: number | null;
      size: number | null;
      totalElements: number | null;
      totalPages: number | null;
    } | null;
    courses: Array<{
      __typename: 'AdminCourse';
      archived: boolean | null;
      compareAtPrice: number | null;
      deleted: boolean | null;
      descriptionHTML: string | null;
      id: string | null;
      name: string | null;
      onlineName: string | null;
      onlineStatus: AdminOnlineStatus | null;
      totalPrice: number | null;
      items: Array<{
        __typename: 'CourseItemDetails';
        id: string | null;
        name: string | null;
        price: number | null;
        serviceId: string | null;
        totalUnits: number | null;
        unitType: AdminCourseServiceUnitType | null;
      } | null> | null;
      urlHandle: string | null;
    }> | null;
  } | null;
};

export type GET_ADMIN_COURSEQueryVariables = {
  businessId: string;
  id: string;
};

export type GET_ADMIN_COURSEQuery = {
  getAdminCourse: {
    __typename: 'AdminCourse';
    archived: boolean | null;
    compareAtPrice: number | null;
    deleted: boolean | null;
    descriptionHTML: string | null;
    id: string | null;
    name: string | null;
    onlineName: string | null;
    onlineStatus: AdminOnlineStatus | null;
    totalPrice: number | null;
    items: Array<{
      __typename: 'CourseItemDetails';
      id: string | null;
      name: string | null;
      price: number | null;
      serviceId: string | null;
      totalUnits: number | null;
      unitType: AdminCourseServiceUnitType | null;
    } | null> | null;
    urlHandle: string | null;
  } | null;
};

export type GET_ADMIN_ONLINE_GROUPSQueryVariables = {
  query: GetAdminOnlineGroupsInput;
  page?: PageInput | null;
};

export type GET_ADMIN_ONLINE_GROUPSQuery = {
  getAdminOnlineGroups: {
    __typename: 'GetAdminOnlineGroupsResponseModel';
    page: {
      __typename: 'Page';
      number: number | null;
      size: number | null;
      totalElements: number | null;
      totalPages: number | null;
    } | null;
    onlineGroups: Array<{
      __typename: 'AdminOnlineGroup';
      id: string | null;
      name: string | null;
      urlHandle: string | null;
    }> | null;
  } | null;
};

export type GET_ADMIN_ONLINE_GROUPQueryVariables = {
  query: GetAdminOnlineGroupInput;
};

export type GET_ADMIN_ONLINE_GROUPQuery = {
  getAdminOnlineGroup: {
    __typename: 'GetAdminOnlineGroupResponseModel';
    descriptionHTML: string | null;
    id: string | null;
    name: string | null;
    onlineGroupId: string | null;
    onlineSectionId: string | null;
    onlineStatus: AdminOnlineStatus | null;
    onlineSubGroupId: string | null;
    urlHandle: string | null;
    parentGroupId: string | null;
    parentGroupName: string | null;
  } | null;
};

export type GET_ADMIN_ONLINE_SUB_SECTION_ITEMSQueryVariables = {
  query: GetAdminOnlineSubSectionItemsInput;
  page?: PageInput | null;
};

export type GET_ADMIN_ONLINE_SUB_SECTION_ITEMSQuery = {
  getAdminOnlineSubSectionItems: {
    __typename: 'GetAdminOnlineSubSectionItemsResponseModel';
    page: {
      __typename: 'Page';
      number: number | null;
      size: number | null;
      totalElements: number | null;
      totalPages: number | null;
    } | null;
    items: Array<{
      __typename: 'AdminOnlineSubSectionItem';
      id: string | null;
      name: string | null;
      onlinePriority: number | null;
      itemType: SubSectionItemType | null;
    }> | null;
  } | null;
};

export type GET_ADMIN_PRODUCTQueryVariables = {
  businessId: string;
  id: string;
};

export type GET_ADMIN_PRODUCTQuery = {
  getAdminProduct: {
    __typename: 'AdminProduct';
    archived: boolean | null;
    brand: {
      __typename: 'Option';
      id: string | null;
      name: string | null;
    } | null;
    category: {
      __typename: 'Option';
      id: string | null;
      name: string | null;
    } | null;
    code: string | null;
    compareAtPrice: number | null;
    deleted: boolean | null;
    descriptionHTML: string | null;
    id: string | null;
    imageUrls: Array<string> | null;
    measurementQuantity: number | null;
    measurementUnit: string | null;
    name: string | null;
    onlineName: string | null;
    onlinePriority: number | null;
    onlineStatus: AdminOnlineStatus | null;
    outOfStock: boolean | null;
    price: number | null;
    productTypes: Array<{
      __typename: 'Option';
      id: string | null;
      name: string | null;
    }> | null;
    skinConcerns: Array<{
      __typename: 'Option';
      id: string | null;
      name: string | null;
    }> | null;
    skinTypes: Array<{
      __typename: 'Option';
      id: string | null;
      name: string | null;
    }> | null;
    urlHandle: string | null;
  } | null;
};

export type GET_ALL_ADMIN_BRANDSQueryVariables = {
  businessId: string;
};

export type GET_ALL_ADMIN_BRANDSQuery = {
  getAllAdminBrands: Array<{
    __typename: 'Option';
    id: string | null;
    name: string | null;
  }> | null;
};

export type GET_ALL_ADMIN_PRODUCT_TYPESQueryVariables = {
  businessId: string;
};

export type GET_ALL_ADMIN_PRODUCT_TYPESQuery = {
  getAllAdminProductTypes: Array<{
    __typename: 'Option';
    id: string | null;
    name: string | null;
  }> | null;
};

export type GET_ALL_ADMIN_SKIN_CONCERNSQueryVariables = {
  businessId: string;
};

export type GET_ALL_ADMIN_SKIN_CONCERNSQuery = {
  getAllAdminSkinConcerns: Array<{
    __typename: 'Option';
    id: string | null;
    name: string | null;
  }> | null;
};

export type GET_ALL_ADMIN_SKIN_TYPESQueryVariables = {
  businessId: string;
};

export type GET_ALL_ADMIN_SKIN_TYPESQuery = {
  getAllAdminSkinTypes: Array<{
    __typename: 'Option';
    id: string | null;
    name: string | null;
  }> | null;
};

export type GET_ADMIN_PRODUCTSQueryVariables = {
  query: GetAdminProductsInput;
  page?: PageInput | null;
};

export type GET_ADMIN_PRODUCTSQuery = {
  getAdminProducts: {
    __typename: 'GetAdminProductsResponseModel';
    products: Array<{
      __typename: 'AdminProduct';
      archived: boolean | null;
      brand: {
        __typename: 'Option';
        id: string | null;
        name: string | null;
      } | null;
      category: {
        __typename: 'Option';
        id: string | null;
        name: string | null;
      } | null;
      code: string | null;
      compareAtPrice: number | null;
      descriptionHTML: string | null;
      deleted: boolean | null;
      id: string | null;
      imageUrls: Array<string> | null;
      measurementQuantity: number | null;
      measurementUnit: string | null;
      name: string | null;
      onlineName: string | null;
      onlinePriority: number | null;
      onlineStatus: AdminOnlineStatus | null;
      outOfStock: boolean | null;
      price: number | null;
      productTypes: Array<{
        __typename: 'Option';
        id: string | null;
        name: string | null;
      }> | null;
      skinConcerns: Array<{
        __typename: 'Option';
        id: string | null;
        name: string | null;
      }> | null;
      skinTypes: Array<{
        __typename: 'Option';
        id: string | null;
        name: string | null;
      }> | null;
      urlHandle: string | null;
    }> | null;
    page: {
      __typename: 'Page';
      number: number | null;
      size: number | null;
      totalElements: number | null;
      totalPages: number | null;
    } | null;
  } | null;
};

export type GET_ADMIN_SERVICESQueryVariables = {
  query: GetAdminServicesInput;
  page?: PageInput | null;
};

export type GET_ADMIN_SERVICESQuery = {
  getAdminServices: {
    __typename: 'GetAdminServicesResponseModel';
    page: {
      __typename: 'Page';
      number: number | null;
      size: number | null;
      totalElements: number | null;
      totalPages: number | null;
    } | null;
    services: Array<{
      __typename: 'AdminService';
      archived: boolean | null;
      category: {
        __typename: 'Option';
        id: string | null;
        name: string | null;
      } | null;
      compareAtPrice: number | null;
      descriptionHTML: string | null;
      deleted: boolean | null;
      id: string | null;
      name: string | null;
      onlineName: string | null;
      onlineStatus: AdminOnlineStatus | null;
      price: number | null;
      saveClientCard: boolean | null;
      urlHandle: string | null;
    }> | null;
  } | null;
};

export type GET_ALL_ADMIN_SERVICE_CATEGORIESQueryVariables = {
  businessId: string;
};

export type GET_ALL_ADMIN_SERVICE_CATEGORIESQuery = {
  getAllAdminServiceCategories: Array<{
    __typename: 'Option';
    id: string | null;
    name: string | null;
  }> | null;
};

export type GET_ADMIN_SERVICEQueryVariables = {
  businessId: string;
  id: string;
};

export type GET_ADMIN_SERVICEQuery = {
  getAdminService: {
    __typename: 'AdminService';
    archived: boolean | null;
    category: {
      __typename: 'Option';
      id: string | null;
      name: string | null;
    } | null;
    compareAtPrice: number | null;
    deleted: boolean | null;
    descriptionHTML: string | null;
    id: string | null;
    name: string | null;
    onlineName: string | null;
    onlineStatus: AdminOnlineStatus | null;
    price: number | null;
    saveClientCard: boolean | null;
    urlHandle: string | null;
  } | null;
};

export type GET_APPOINTMENT_AVAILABILITYQueryVariables = {
  businessId: string;
  branchId: string;
  fromDateTime: string;
  toDateTime: string;
  clientServiceSelections?: Array<ClientServiceSelectionsInput | null> | null;
};

export type GET_APPOINTMENT_AVAILABILITYQuery = {
  getAppointmentAvailability: Array<{
    __typename: 'AvailableAppointment';
    startTime: string | null;
    clientSchedules: Array<{
      __typename: 'ClientAppointmentSchedule';
      clientId: string | null;
      serviceSchedules: Array<{
        __typename: 'ServiceSchedule';
        alternativeStaffMember: boolean | null;
        appointmentId: string | null;
        branchServiceGroupId: string | null;
        clientCourseItemId: string | null;
        endTime: string | null;
        machineId: string | null;
        roomId: string | null;
        serviceGroupItemOptionId: string | null;
        serviceId: string | null;
        staffId: string | null;
        staffRequest: boolean | null;
        startTime: string | null;
      } | null> | null;
    } | null> | null;
  } | null> | null;
};

export type GET_APPOINTMENTSQueryVariables = {
  query: BusinessBranchAppointmentsInput;
  page?: PageInput | null;
};

export type GET_APPOINTMENTSQuery = {
  getAppointments: {
    __typename: 'getAppointmentsResponseModel';
    appointments: Array<{
      __typename: 'Appointment';
      activationState: ActivationState | null;
      appointmentDate: string | null;
      appointmentId: string | null;
      bookingId: string | null;
      branchId: string | null;
      businessId: string | null;
      client: {
        __typename: 'Client';
        address: {
          __typename: 'Address';
          city: string | null;
          country: string | null;
          postalCode: string | null;
          state: string | null;
          streetAddress1: string | null;
          streetAddress2: string | null;
        } | null;
        archived: boolean | null;
        banned: boolean | null;
        birthDate: string | null;
        clientCategoryIds: Array<string> | null;
        clientId: string | null;
        id: string | null;
        clientSince: string | null;
        createdAt: string | null;
        creatingBranchId: string | null;
        creditAccount: {
          __typename: 'CreditAccount';
          creditDays: number | null;
          creditLimit: number | null;
          outstandingBalance: number | null;
        } | null;
        deleted: boolean | null;
        email: string | null;
        emailMarketingConsent: boolean | null;
        emailReminderConsent: boolean | null;
        externalId: string | null;
        firstName: string | null;
        firstVisit: string | null;
        gender: string | null;
        landLine: string | null;
        lastName: string | null;
        loyaltyCard: {
          __typename: 'LoyaltyCard';
          points: number | null;
          serial: string | null;
        } | null;
        mergedToClientId: string | null;
        mobile: string | null;
        notes: string | null;
        photoUrl: string | null;
        preferredStaffId: string | null;
        smsMarketingConsent: boolean | null;
        smsReminderConsent: boolean | null;
        updatedAt: string | null;
        version: number | null;
      } | null;
      clientCourseId: string | null;
      clientCourseItemId: string | null;
      clientId: string | null;
      confirmed: boolean | null;
      courseId: string | null;
      courseName: string | null;
      createdAt: string | null;
      depositAmount: number | null;
      depositDateTime: string | null;
      endTime: string | null;
      groupBookingId: string | null;
      id: string | null;
      machineId: string | null;
      preferredStaff: boolean | null;
      price: number | null;
      purchasingBranchId: string | null;
      roomId: string | null;
      serviceGroupId: string | null;
      serviceGroupItemOptionId: string | null;
      serviceId: string | null;
      serviceName: string | null;
      serviceRewardId: string | null;
      source: BookingSource | null;
      staff: {
        __typename: 'Staff';
        branchId: string | null;
        email: string | null;
        disqualifiedServices: Array<string> | null;
        firstName: string | null;
        hideFromAppointmentScreen: boolean | null;
        hideFromOnlineBookings: boolean | null;
        id: string | null;
        lastName: string | null;
        mobile: string | null;
        selfEmployed: boolean | null;
        staffCategoryId: string | null;
        staffCategoryName: string | null;
        userId: string | null;
      } | null;
      staffId: string | null;
      staffRequest: boolean | null;
      startTime: string | null;
      state: BookingState | null;
      version: number | null;
      waitingListDateTime: string | null;
      updatedAt: string | null;
    }> | null;
    page: {
      __typename: 'Page';
      number: number | null;
      size: number | null;
      totalElements: number | null;
      totalPages: number | null;
    } | null;
  } | null;
};

export type GET_ALL_APPOINTMENTSQueryVariables = {
  query: BusinessBranchAppointmentsInput;
};

export type GET_ALL_APPOINTMENTSQuery = {
  getAllAppointments: {
    __typename: 'getAllAppointmentsResponseModel';
    appointments: Array<{
      __typename: 'Appointment';
      activationState: ActivationState | null;
      appointmentDate: string | null;
      appointmentId: string | null;
      bookingId: string | null;
      branchId: string | null;
      businessId: string | null;
      client: {
        __typename: 'Client';
        address: {
          __typename: 'Address';
          city: string | null;
          country: string | null;
          postalCode: string | null;
          state: string | null;
          streetAddress1: string | null;
          streetAddress2: string | null;
        } | null;
        archived: boolean | null;
        banned: boolean | null;
        birthDate: string | null;
        clientCategoryIds: Array<string> | null;
        clientId: string | null;
        id: string | null;
        clientSince: string | null;
        createdAt: string | null;
        creatingBranchId: string | null;
        creditAccount: {
          __typename: 'CreditAccount';
          creditDays: number | null;
          creditLimit: number | null;
          outstandingBalance: number | null;
        } | null;
        deleted: boolean | null;
        email: string | null;
        emailMarketingConsent: boolean | null;
        emailReminderConsent: boolean | null;
        externalId: string | null;
        firstName: string | null;
        firstVisit: string | null;
        gender: string | null;
        landLine: string | null;
        lastName: string | null;
        loyaltyCard: {
          __typename: 'LoyaltyCard';
          points: number | null;
          serial: string | null;
        } | null;
        mergedToClientId: string | null;
        mobile: string | null;
        notes: string | null;
        photoUrl: string | null;
        preferredStaffId: string | null;
        smsMarketingConsent: boolean | null;
        smsReminderConsent: boolean | null;
        updatedAt: string | null;
        version: number | null;
      } | null;
      clientCourseId: string | null;
      clientCourseItemId: string | null;
      clientId: string | null;
      confirmed: boolean | null;
      courseId: string | null;
      courseName: string | null;
      createdAt: string | null;
      depositAmount: number | null;
      depositDateTime: string | null;
      endTime: string | null;
      groupBookingId: string | null;
      id: string | null;
      machineId: string | null;
      preferredStaff: boolean | null;
      price: number | null;
      purchasingBranchId: string | null;
      roomId: string | null;
      serviceGroupId: string | null;
      serviceGroupItemOptionId: string | null;
      serviceId: string | null;
      serviceName: string | null;
      serviceRewardId: string | null;
      source: BookingSource | null;
      staff: {
        __typename: 'Staff';
        branchId: string | null;
        email: string | null;
        disqualifiedServices: Array<string> | null;
        firstName: string | null;
        hideFromAppointmentScreen: boolean | null;
        hideFromOnlineBookings: boolean | null;
        id: string | null;
        lastName: string | null;
        mobile: string | null;
        selfEmployed: boolean | null;
        staffCategoryId: string | null;
        staffCategoryName: string | null;
        userId: string | null;
      } | null;
      staffId: string | null;
      staffRequest: boolean | null;
      startTime: string | null;
      state: BookingState | null;
      version: number | null;
      waitingListDateTime: string | null;
      updatedAt: string | null;
    }> | null;
  } | null;
};

export type GET_BRANCHESQueryVariables = {
  query: BranchesInput;
  page?: PageInput | null;
};

export type GET_BRANCHESQuery = {
  getBranches: {
    __typename: 'getBranchesResponseModel';
    branches: Array<{
      __typename: 'Branch';
      id: string | null;
      address1: string | null;
      address2: string | null;
      city: string | null;
      country: string | null;
      latitude: string | null;
      longitude: string | null;
      name: string | null;
      state: string | null;
      timeZone: string | null;
    }> | null;
  } | null;
};

export type GET_CLINIC_WORK_TIMETABLESQueryVariables = {
  query: ClinicWorkTimetableInput;
};

export type GET_CLINIC_WORK_TIMETABLESQuery = {
  getClinicWorkTimetables: {
    __typename: 'getClinicWorkTimetablesResponseModel';
    timeSlots: Array<{
      __typename: 'TimeSlot';
      date: string | null;
      startTime: string | null;
      endTime: string | null;
      type: TimeSlotType | null;
    }> | null;
  } | null;
};

export type GET_CLINIC_ROSTERQueryVariables = {
  query: ClinicRosterInput;
};

export type GET_CLINIC_ROSTERQuery = {
  getClinicRoster: {
    __typename: 'getClinicRosterResponseModel';
    timeSlots: Array<{
      __typename: 'TimeSlot';
      date: string | null;
      startTime: string | null;
      endTime: string | null;
      type: TimeSlotType | null;
    }> | null;
  } | null;
};

export type GetBranchReviewQueryVariables = {
  query: ReviewByIdInput;
};

export type GetBranchReviewQuery = {
  getBranchReview: {
    __typename: 'Review';
    id: string;
    clientId: string;
    clientFirstName: string | null;
    clientLastName: string | null;
    staffId: string | null;
    staffFirstName: string | null;
    staffLastName: string | null;
    rating: number | null;
    date: string | null;
    text: string | null;
    twitterReview: boolean | null;
    facebookReview: boolean | null;
  } | null;
};

export type GetBranchReviewsQueryVariables = {
  query: ReviewsInput;
  page?: PageInput | null;
};

export type GetBranchReviewsQuery = {
  getBranchReviews: {
    __typename: 'GetBranchReviewsResponseModel';
    reviews: Array<{
      __typename: 'Review';
      id: string;
      clientId: string;
      clientFirstName: string | null;
      clientLastName: string | null;
      staffId: string | null;
      staffFirstName: string | null;
      staffLastName: string | null;
      rating: number | null;
      date: string | null;
      text: string | null;
      twitterReview: boolean | null;
      facebookReview: boolean | null;
    }> | null;
    page: {
      __typename: 'Page';
      number: number | null;
      size: number | null;
      totalElements: number | null;
      totalPages: number | null;
    } | null;
  } | null;
};

export type GET_BREAKSQueryVariables = {
  query: BreaksInput;
  page?: PageInput | null;
};

export type GET_BREAKSQuery = {
  getBreaks: {
    __typename: 'getBreaksResponseModel';
    breaks: Array<{
      __typename: 'Break';
      breakId: string;
      breakDate: string | null;
      startTime: string | null;
      endTime: string | null;
      label: string | null;
      staffId: string | null;
      machineId: string | null;
      roomId: string | null;
      paidBreak: boolean | null;
    }> | null;
    page: {
      __typename: 'Page';
      number: number | null;
      size: number | null;
      totalElements: number | null;
      totalPages: number | null;
    } | null;
  } | null;
};

export type GET_ALL_BREAKSQueryVariables = {
  query: AllBreaksInput;
};

export type GET_ALL_BREAKSQuery = {
  getAllBreaks: Array<{
    __typename: 'Break';
    breakId: string;
    breakDate: string | null;
    startTime: string | null;
    endTime: string | null;
    label: string | null;
    staffId: string | null;
    machineId: string | null;
    roomId: string | null;
    paidBreak: boolean | null;
  }> | null;
};

export type GET_BUSINESSESQuery = {
  getBusinesses: Array<{
    __typename: 'Business';
    name: string;
    id: string;
  }> | null;
};

export type GET_CLIENTSQueryVariables = {
  query: ClientsInput;
  page?: PageInput | null;
};

export type GET_CLIENTSQuery = {
  getClients: {
    __typename: 'getClientsResponseModel';
    clients: Array<{
      __typename: 'Client';
      address: {
        __typename: 'Address';
        city: string | null;
        country: string | null;
        postalCode: string | null;
        state: string | null;
        streetAddress1: string | null;
        streetAddress2: string | null;
      } | null;
      archived: boolean | null;
      banned: boolean | null;
      birthDate: string | null;
      clientCategoryIds: Array<string> | null;
      clientId: string | null;
      id: string | null;
      clientSince: string | null;
      createdAt: string | null;
      creatingBranchId: string | null;
      creditAccount: {
        __typename: 'CreditAccount';
        creditDays: number | null;
        creditLimit: number | null;
        outstandingBalance: number | null;
      } | null;
      deleted: boolean | null;
      email: string | null;
      emailMarketingConsent: boolean | null;
      emailReminderConsent: boolean | null;
      externalId: string | null;
      firstName: string | null;
      firstVisit: string | null;
      gender: string | null;
      landLine: string | null;
      lastName: string | null;
      loyaltyCard: {
        __typename: 'LoyaltyCard';
        points: number | null;
        serial: string | null;
      } | null;
      mergedToClientId: string | null;
      mobile: string | null;
      notes: string | null;
      photoUrl: string | null;
      preferredStaffId: string | null;
      smsMarketingConsent: boolean | null;
      smsReminderConsent: boolean | null;
      updatedAt: string | null;
      version: number | null;
    }> | null;
    page: {
      __typename: 'Page';
      number: number | null;
      size: number | null;
      totalElements: number | null;
      totalPages: number | null;
    } | null;
  } | null;
};

export type GET_CLIENTQueryVariables = {
  businessId: string;
  clientId: string;
};

export type GET_CLIENTQuery = {
  getClient: {
    __typename: 'Client';
    address: {
      __typename: 'Address';
      city: string | null;
      country: string | null;
      postalCode: string | null;
      state: string | null;
      streetAddress1: string | null;
      streetAddress2: string | null;
    } | null;
    archived: boolean | null;
    banned: boolean | null;
    birthDate: string | null;
    clientCategoryIds: Array<string> | null;
    clientId: string | null;
    id: string | null;
    clientSince: string | null;
    createdAt: string | null;
    creatingBranchId: string | null;
    creditAccount: {
      __typename: 'CreditAccount';
      creditDays: number | null;
      creditLimit: number | null;
      outstandingBalance: number | null;
    } | null;
    deleted: boolean | null;
    email: string | null;
    emailMarketingConsent: boolean | null;
    emailReminderConsent: boolean | null;
    externalId: string | null;
    firstName: string | null;
    firstVisit: string | null;
    gender: string | null;
    landLine: string | null;
    lastName: string | null;
    loyaltyCard: {
      __typename: 'LoyaltyCard';
      points: number | null;
      serial: string | null;
    } | null;
    mergedToClientId: string | null;
    mobile: string | null;
    notes: string | null;
    photoUrl: string | null;
    preferredStaffId: string | null;
    smsMarketingConsent: boolean | null;
    smsReminderConsent: boolean | null;
    updatedAt: string | null;
    version: number | null;
  } | null;
};

export type GET_SERVICE_HISTORYQueryVariables = {
  query: ClientServiceHistoryInput;
  page?: PageInput | null;
};

export type GET_SERVICE_HISTORYQuery = {
  getServiceHistory: {
    __typename: 'getClientServiceHistoryResponseModel';
    clientServiceHistories: Array<{
      __typename: 'ClientServiceHistoriesResource';
      bookingId: string | null;
      branchId: string | null;
      clientId: string | null;
      date: string | null;
      notes: string | null;
      services: Array<{
        __typename: 'SingleServiceHistory';
        appointmentId: string | null;
        cancelled: boolean | null;
        checkedIn: boolean | null;
        clientCourseItemId: string | null;
        depositAmount: number | null;
        description: string | null;
        discount: number | null;
        duration: number | null;
        fee: number | null;
        groupBookingId: string | null;
        originalPrice: number | null;
        serviceCategoryId: string | null;
        serviceGroupId: string | null;
        serviceGroupItemOptionId: string | null;
        serviceId: string | null;
        serviceRewardId: string | null;
        staffId: string | null;
        staff: {
          __typename: 'Staff';
          branchId: string | null;
          email: string | null;
          disqualifiedServices: Array<string> | null;
          firstName: string | null;
          hideFromAppointmentScreen: boolean | null;
          hideFromOnlineBookings: boolean | null;
          id: string | null;
          lastName: string | null;
          mobile: string | null;
          selfEmployed: boolean | null;
          staffCategoryId: string | null;
          staffCategoryName: string | null;
          userId: string | null;
        } | null;
        time: string | null;
        waitingList: boolean | null;
      } | null> | null;
    }> | null;
    page: {
      __typename: 'Page';
      number: number | null;
      size: number | null;
      totalElements: number | null;
      totalPages: number | null;
    } | null;
  } | null;
};

export type GET_VOUCHERSQueryVariables = {
  query: VouchersInput;
  page?: PageInput | null;
};

export type GET_VOUCHERSQuery = {
  getVouchers: {
    __typename: 'getVouchersResponseModel';
    vouchers: Array<{
      __typename: 'Voucher';
      voucherId: string;
      serialNumber: string | null;
      clientId: string | null;
      creatingBranchId: string | null;
      expiryDate: string | null;
      issueDate: string | null;
      originalBalance: number | null;
      remainingBalance: number | null;
    }> | null;
    page: {
      __typename: 'Page';
      number: number | null;
      size: number | null;
      totalElements: number | null;
      totalPages: number | null;
    };
  } | null;
};

export type GET_CLIENT_PRODUCT_HISTORYQueryVariables = {
  query: ClientProductsInput;
  page?: PageInput | null;
};

export type GET_CLIENT_PRODUCT_HISTORYQuery = {
  getClientProductHistory: {
    __typename: 'getClientProductHistoryResponseModel';
    products: Array<{
      __typename: 'ClientProduct';
      purchaseDate: string;
      id: string;
      branchId: string | null;
      description: string;
      quantity: number | null;
      price: number | null;
      staffFirstName: string | null;
      staffLastName: string | null;
      branchProductId: string | null;
      purchaseType: string;
      purchaseItemType: string;
      staffId: string | null;
      userId: string | null;
      originalPrice: number | null;
      totalAmount: number | null;
      discountAmount: number | null;
      taxAmount: number | null;
      taxName: string | null;
      taxRate: number | null;
      taxIncluded: boolean | null;
      clientCoursePurchase: boolean | null;
      courseSessionRequest: boolean | null;
    }> | null;
    page: {
      __typename: 'Page';
      number: number | null;
      size: number | null;
      totalElements: number | null;
      totalPages: number | null;
    };
  } | null;
};

export type GetAllClientCoursesQueryVariables = {
  query: ClientCoursesInput;
  page?: PageInput | null;
};

export type GetAllClientCoursesQuery = {
  getAllClientCourses: {
    __typename: 'getAllClientCoursesResponseModel';
    clientCourses: Array<{
      __typename: 'ClientCourse';
      archived: boolean | null;
      clientCourseId: string | null;
      courseName: string | null;
      clientId: string | null;
      purchasingBranchId: string | null;
      courseId: string | null;
      purchaseDate: string | null;
      expiryDate: string | null;
      grossPrice: number | null;
      netPrice: number | null;
      clientCourseItems: Array<{
        __typename: 'ClientCourseItem';
        clientCourseItemId: string | null;
        courseItemId: string | null;
        serviceId: string | null;
        initialUnits: number | null;
        remainingUnits: number | null;
        grossPrice: number | null;
        netPrice: number | null;
      }> | null;
    }> | null;
    page: {
      __typename: 'Page';
      number: number | null;
      size: number | null;
      totalElements: number | null;
      totalPages: number | null;
    } | null;
  } | null;
};

export type GET_CLIENT_COURSESQueryVariables = {
  query: ClientCoursesInput;
  page?: PageInput | null;
};

export type GET_CLIENT_COURSESQuery = {
  getClientCourses: {
    __typename: 'getClientCoursesResponseModel';
    clientCourses: Array<{
      __typename: 'ClientCourse';
      clientCourseId: string | null;
      clientId: string | null;
      courseName: string | null;
      purchasingBranchId: string | null;
      courseId: string | null;
      purchaseDate: string | null;
      expiryDate: string | null;
      grossPrice: number | null;
      netPrice: number | null;
      clientCourseItems: Array<{
        __typename: 'ClientCourseItem';
        clientCourseItemId: string | null;
        courseItemId: string | null;
        serviceId: string | null;
        initialUnits: number | null;
        remainingUnits: number | null;
        grossPrice: number | null;
        netPrice: number | null;
      }> | null;
    }> | null;
    page: {
      __typename: 'Page';
      number: number | null;
      size: number | null;
      totalElements: number | null;
      totalPages: number | null;
    } | null;
  } | null;
};

export type GET_COURSESQueryVariables = {
  query: BusinessBranchInput;
  page?: PageInput | null;
};

export type GET_COURSESQuery = {
  getCourses: {
    __typename: 'getCoursesResponseModel';
    courses: Array<{
      __typename: 'Course';
      courseId: string | null;
      id: string | null;
      courseName: string | null;
      name: string | null;
      availableOnline: boolean | null;
      multiBranchCourse: boolean | null;
      totalPrice: number | null;
      items: Array<{
        __typename: 'CourseItem';
        id: string | null;
        unitType: string | null;
        totalUnits: number | null;
        totalPrice: number | null;
        serviceId: string | null;
      }> | null;
      courseItems: Array<{
        __typename: 'CourseItem';
        courseItemId: string | null;
        id: string | null;
        totalPrice: number | null;
        serviceId: string | null;
      }> | null;
    }> | null;
    page: {
      __typename: 'Page';
      number: number | null;
      size: number | null;
      totalElements: number | null;
      totalPages: number | null;
    } | null;
  } | null;
};

export type GET_ALL_BRANCH_COURSESQueryVariables = {
  query: BusinessBranchInput;
};

export type GET_ALL_BRANCH_COURSESQuery = {
  getAllBranchCourses: Array<{
    __typename: 'Course';
    archived: boolean | null;
    availableOnline: boolean | null;
    branchId: string | null;
    totalPrice: number | null;
    name: string | null;
    multiBranchCourse: boolean | null;
    items: Array<{
      __typename: 'CourseItem';
      courseItemId: string | null;
      totalPrice: number | null;
      totalUnits: number | null;
      unitType: string | null;
      serviceId: string | null;
      id: string | null;
    }> | null;
    id: string | null;
    courseName: string | null;
    courseItems: Array<{
      __typename: 'CourseItem';
      courseItemId: string | null;
      id: string | null;
      totalUnits: number | null;
      unitType: string | null;
      totalPrice: number | null;
      serviceId: string | null;
    }> | null;
    courseId: string | null;
  }> | null;
};

export type GET_DISCOUNTSQueryVariables = {
  query: DiscountsQueryInput;
};

export type GET_DISCOUNTSQuery = {
  getDiscounts: Array<{
    __typename: 'Discount';
    archived: boolean | null;
    id: string | null;
    name: string | null;
    type: DiscountType | null;
    value: number | null;
  }> | null;
};

export type GET_PAYMENT_TYPESQueryVariables = {
  query: PaymentTypesInput;
};

export type GET_PAYMENT_TYPESQuery = {
  getPaymentTypes: {
    __typename: 'PaymentTypesResponseModel';
    paymentTypes: Array<{
      __typename: 'PaymentType';
      archived: boolean | null;
      banked: boolean | null;
      builtIn: boolean | null;
      code: string | null;
      name: string | null;
      paymentTypeId: string | null;
    }> | null;
  } | null;
};

export type GET_PRODUCTSQueryVariables = {
  query: ProductsInput;
  page?: PageInput | null;
};

export type GET_PRODUCTSQuery = {
  getProducts: {
    __typename: 'getProductsResponseModel';
    page: {
      __typename: 'Page';
      number: number | null;
      size: number | null;
      totalElements: number | null;
      totalPages: number | null;
    };
    products: Array<{
      __typename: 'Product';
      archived: boolean | null;
      barcode: string | null;
      brandName: string | null;
      categoryName: string | null;
      code: string | null;
      id: string | null;
      maxQuantity: number | null;
      measurementQuantity: number | null;
      measurementUnit: string | null;
      minQuantity: number | null;
      name: string | null;
      price: number | null;
      quantityInStock: number | null;
      reorderCost: number | null;
      reorderCount: number | null;
      type: string | null;
      branchId: string | null;
    }> | null;
  } | null;
};

export type GET_ALL_BRANCH_PRODUCTSQueryVariables = {
  query: BusinessBranchInput;
};

export type GET_ALL_BRANCH_PRODUCTSQuery = {
  getAllBranchProducts: Array<{
    __typename: 'Product';
    archived: boolean | null;
    barcode: string | null;
    brandId: string | null;
    brandName: string | null;
    categoryId: string | null;
    categoryName: string | null;
    code: string | null;
    id: string | null;
    maxQuantity: number | null;
    measurementQuantity: number | null;
    measurementUnit: string | null;
    minQuantity: number | null;
    name: string | null;
    price: number | null;
    parentId: string | null;
    quantityInStock: number | null;
    reorderCost: number | null;
    reorderCount: number | null;
    type: string | null;
    branchId: string | null;
  }> | null;
};

export type GET_OR_CREATE_TILL_SESSIONQueryVariables = {
  query: GetOrCreateTillSessionInput;
};

export type GET_OR_CREATE_TILL_SESSIONQuery = {
  getOrCreateTillSession: {
    __typename: 'TillSession';
    id: string;
    startTime: string;
    endTime: string | null;
  } | null;
};

export type GET_BRANCH_STAFF_LISTQueryVariables = {
  query: BusinessBranchInput;
  page?: PageInput | null;
};

export type GET_BRANCH_STAFF_LISTQuery = {
  getBranchStaffList: {
    __typename: 'getBranchStaffListResponseModel';
    staff: Array<{
      __typename: 'Staff';
      id: string | null;
      firstName: string | null;
      lastName: string | null;
      selfEmployed: boolean | null;
      mobile: string | null;
      email: string | null;
      staffCategoryId: string | null;
      staffCategoryName: string | null;
      hideFromAppointmentScreen: boolean | null;
      hideFromOnlineBookings: boolean | null;
    }> | null;
    page: {
      __typename: 'Page';
      number: number | null;
      size: number | null;
      totalElements: number | null;
      totalPages: number | null;
    };
  };
};

export type GET_STAFFQueryVariables = {
  businessId: string;
  branchId: string;
  staffId: string;
};

export type GET_STAFFQuery = {
  getStaff: {
    __typename: 'Staff';
    id: string | null;
    firstName: string | null;
    lastName: string | null;
    selfEmployed: boolean | null;
    mobile: string | null;
    email: string | null;
    staffCategoryId: string | null;
    staffCategoryName: string | null;
    hideFromAppointmentScreen: boolean | null;
    hideFromOnlineBookings: boolean | null;
  } | null;
};

export type GET_ALL_BRANCH_STAFFQueryVariables = {
  query: BusinessBranchInput;
};

export type GET_ALL_BRANCH_STAFFQuery = {
  getAllBranchStaff: Array<{
    __typename: 'Staff';
    id: string | null;
    firstName: string | null;
    lastName: string | null;
    selfEmployed: boolean | null;
    mobile: string | null;
    email: string | null;
    staffCategoryId: string | null;
    staffCategoryName: string | null;
    hideFromAppointmentScreen: boolean | null;
    hideFromOnlineBookings: boolean | null;
  }> | null;
};

export type GET_ALL_BRANCH_STAFF_CALENDARQueryVariables = {
  query: BusinessBranchInput;
};

export type GET_ALL_BRANCH_STAFF_CALENDARQuery = {
  // This query uses an internal Phorest API to fetch also color & calendarPosition from staff model, we can't upgrade Staff Model because the batch-api is used in get appointments and forces to use interal API fot getAppoinemt
  getAllBranchStaffCalendar: Array<{
    __typename: 'StaffCalendar';
    id: string | null;
    firstName: string | null;
    lastName: string | null;
    selfEmployed: boolean | null;
    mobile: string | null;
    email: string | null;
    staffCategoryId: string | null;
    staffCategoryName: string | null;
    hideFromAppointmentScreen: boolean | null;
    hideFromOnlineBookings: boolean | null;
    color: number | null;
    calendarPosition: number | null;
  }> | null;
};

export type GET_STAFF_ROSTERQueryVariables = {
  query: StaffRosterInput;
  page?: PageInput | null;
};

export type GET_STAFF_ROSTERQuery = {
  getStaffRoster: {
    __typename: 'getStaffRosterResponseModel';
    roster: Array<{
      __typename: 'StaffTimeSlots';
      staffId: string;
      timeSlots: Array<{
        __typename: 'TimeSlot';
        date: string | null;
        startTime: string | null;
        endTime: string | null;
        type: TimeSlotType | null;
      }> | null;
    }> | null;
    page: {
      __typename: 'Page';
      number: number | null;
      size: number | null;
      totalElements: number | null;
      totalPages: number | null;
    };
  } | null;
};

export type GET_TREATMENTQueryVariables = {
  query: ServiceInput;
};

export type GET_TREATMENTQuery = {
  getService: {
    __typename: 'Service';
    id: string | null;
    name: string | null;
    price: number | null;
    duration: number | null;
    categoryId: string | null;
    categoryName: string | null;
    department: {
      __typename: 'Department';
      id: string | null;
      name: string | null;
    } | null;
    archived: boolean | null;
    branchId: string | null;
    internetEnabled: boolean | null;
    disqualifiedStaff: Array<string> | null;
    machines: Array<string> | null;
    rooms: Array<string> | null;
    taxRates: Array<string> | null;
  } | null;
};

export type GetServicesQueryVariables = {
  query: ServicesInput;
  page?: PageInput | null;
};

export type GetServicesQuery = {
  getServices: {
    __typename: 'GetServicesResponse';
    page: {
      __typename: 'Page';
      number: number | null;
      size: number | null;
      totalElements: number | null;
      totalPages: number | null;
    };
    services: Array<{
      __typename: 'Service';
      id: string | null;
      name: string | null;
      price: number | null;
      duration: number | null;
      categoryId: string | null;
      categoryName: string | null;
      department: {
        __typename: 'Department';
        id: string | null;
        name: string | null;
      } | null;
      archived: boolean | null;
      branchId: string | null;
      internetEnabled: boolean | null;
      disqualifiedStaff: Array<string> | null;
      machines: Array<string> | null;
      rooms: Array<string> | null;
      taxRates: Array<string> | null;
    }> | null;
  } | null;
};

export type GET_ALL_BRANCH_SERVICESQueryVariables = {
  query: ServicesInput;
};

export type GET_ALL_BRANCH_SERVICESQuery = {
  getAllBranchServices: Array<{
    __typename: 'Service';
    id: string | null;
    name: string | null;
    price: number | null;
    duration: number | null;
    categoryId: string | null;
    categoryName: string | null;
    department: {
      __typename: 'Department';
      id: string | null;
      name: string | null;
    } | null;
    archived: boolean | null;
    branchId: string | null;
    internetEnabled: boolean | null;
    disqualifiedStaff: Array<string> | null;
    machines: Array<string> | null;
    rooms: Array<string> | null;
    taxRates: Array<string> | null;
  }> | null;
};

export type GET_ALL_BRANCH_SERVICE_CATEGORIESQueryVariables = {
  query: ServiceCategoriesInput;
};

export type GET_ALL_BRANCH_SERVICE_CATEGORIESQuery = {
  getAllBranchServiceCategories: Array<{
    __typename: 'ServiceCategory';
    id: string | null;
    name: string | null;
    description: string | null;
    color: number | null;
  }> | null;
};

export type GET_SERVICE_CATEGORIESQueryVariables = {
  query: ServiceCategoriesInput;
  page?: PageInput | null;
};

export type GET_SERVICE_CATEGORIESQuery = {
  getServiceCategories: {
    __typename: 'GetServiceCategoriesResponse';
    page: {
      __typename: 'Page';
      number: number | null;
      size: number | null;
      totalElements: number | null;
      totalPages: number | null;
    };
    serviceCategories: Array<{
      __typename: 'ServiceCategory';
      id: string | null;
      description: string | null;
      name: string | null;
      color: number | null;
    }> | null;
  } | null;
};
