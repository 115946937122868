import config from 'config';
import {BusinessId, BUSINESS_UIDS} from 'constants/business';
import {BUSINESS_APP_URLS} from 'constants/urls';
import {useBusinessContext} from 'hooks/business/useBusinessContext';

interface NavigateToBusinessByIdProps {
  businessId: BusinessId;
}

interface Output {
  navigateToBusinessById: (props: NavigateToBusinessByIdProps) => void;
}

export function useNavigateToBusiness(): Output {
  const {roi, uk, us} = BUSINESS_UIDS;
  const {businessId: currentBusinessId} = useBusinessContext();

  const navigateToBusinessById = ({businessId}: NavigateToBusinessByIdProps) => {
    if (businessId === currentBusinessId) return;
    switch (businessId) {
      case roi:
        window.location.href = BUSINESS_APP_URLS.roi[config.ENVIRONMENT];
        break;
      case uk:
        window.location.href = BUSINESS_APP_URLS.uk[config.ENVIRONMENT];
        break;
      case us:
        window.location.href = BUSINESS_APP_URLS.us[config.ENVIRONMENT];
        break;
    }
  };

  return {navigateToBusinessById};
}
