import {captureException} from '@sentry/react';
import {GET_ALL_ADMIN_SKIN_TYPES} from 'data/graphql/queries/adminProducts';
import {useBusinessContext, useNotifications} from 'hooks';
import {GQLClientContext} from 'providers/gqlClient';
import {useContext} from 'react';
import {useQuery, UseQueryResult} from '@tanstack/react-query';
import {GET_ALL_ADMIN_SKIN_TYPESQuery, GET_ALL_ADMIN_SKIN_TYPESQueryVariables} from 'types/ApiModel';

export type IUseAdminSkinTypesOutput = UseQueryResult<GET_ALL_ADMIN_SKIN_TYPESQuery['getAllAdminSkinTypes'], Error>;

function useAdminSkinTypes(): IUseAdminSkinTypesOutput {
  const {gqlClient} = useContext(GQLClientContext);
  const {notifyError} = useNotifications();
  const {businessId} = useBusinessContext();

  return useQuery<GET_ALL_ADMIN_SKIN_TYPESQuery['getAllAdminSkinTypes'], Error>(
    [USE_ADMIN_SKIN_TYPES_KEY],
    async () => {
      const resp = await gqlClient.request<GET_ALL_ADMIN_SKIN_TYPESQuery, GET_ALL_ADMIN_SKIN_TYPESQueryVariables>(
        GET_ALL_ADMIN_SKIN_TYPES,
        {businessId}
      );

      return resp.data.getAllAdminSkinTypes;
    },
    {
      enabled: !!businessId,
      onError: error => {
        const errorString = error.message;
        notifyError(`Error getting Skin Types data: ${errorString}`);
        captureException(new Error(errorString));
      }
    }
  );
}

export {useAdminSkinTypes};

export const USE_ADMIN_SKIN_TYPES_KEY = 'GET_ADMIN_SKIN_TYPES';
