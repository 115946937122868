import React from 'react';
import styled from 'styled-components';

const SectionHeader: React.FC<React.PropsWithChildren<unknown>> = ({children}) => <SegmentHeader children={children} />;

export default SectionHeader;

const SegmentHeader = styled.div`
  padding: ${({theme}) => theme.spacing(3)};
  max-height: 70px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: rgba(0, 0, 0, 0.5);
  font-size: 1.25rem;
  font-weight: 600;
`;
