import {NavButtons} from '@therapie-ui/AppBar/controls/NavButtons';
import COLORS from 'constants/colors';
import {TOP_BAR_HEIGHT} from 'constants/layout';
import React from 'react';
import styled from 'styled-components';
import {windowIsIFramed} from 'utils/window';
import {BusinessClinicSelector} from './controls/BusinessClinicSelector';
import {UserContext} from './controls/UserContext';

interface Props {
  showNavigation?: boolean;
  showUserHandle?: boolean;
}

const AppBar: React.FC<React.PropsWithChildren<Props>> = React.memo(
  ({showNavigation = windowIsIFramed, showUserHandle = !windowIsIFramed}: Props) => (
    <AppBarContainer>
      <BusinessClinicSelector />
      <RightSide>
        {showNavigation && <NavButtons />}
        <UserContext showUserHandle={showUserHandle} />
      </RightSide>
    </AppBarContainer>
  )
);

export type APP_BAR_VARIANTS = 'default' | 'framed';
export default AppBar;
export {AppBar};

const AppBarContainer = styled.header`
  background: ${COLORS.therapiePurpleD20};
  width: 100%;
  height: ${TOP_BAR_HEIGHT};
  padding: ${({theme}) => theme.spacing(1)};
  gap: ${({theme}) => theme.spacing(1)};
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const RightSide = styled.div`
  display: flex;
  height: 100%;
  gap: ${({theme}) => theme.spacing(1)};
`;
