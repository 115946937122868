import {useLocation} from 'react-router-dom';
import {ROUTES} from 'constants/routes';
import {useBusinessContext} from 'hooks/business/useBusinessContext';
import useClinicContext from 'hooks/clinic/useClinicContext';
import {useAppNavigation} from 'hooks/navigation/useAppNavigation';
import useUrlParams from 'hooks/router/useUrlParams';
import {useCallback, useMemo, useState} from 'react';
import {BookingTreatment, CreateUrlParams} from 'types/common';
import {parseUrlParams, stringifyUrlParams} from 'utils/query';

interface Response {
  params: CreateUrlParams;
  navigateToCreateBooking(params: CreateUrlParams): void;
  setLocalCreateBookingParams(params: Partial<CreateUrlParams>): void;
  toggleTreatmentToLocalParams(treatment: BookingTreatment): void;
  initTreatmentToLocalParams(treatment: BookingTreatment): void;
  localCreateBookingParams: CreateUrlParams;
}

function useCreateBookingUrlParams(): Response {
  const urlParams = useUrlParams();
  const {businessId} = useBusinessContext();
  const {setClinicById} = useClinicContext();
  const {navigate} = useAppNavigation();
  const location = useLocation();
  const [localCreateBookingParams, setLocalCreateBookingParams] = useState<CreateUrlParams>({});
  const urlParamsString = urlParams.toString();
  const params = useMemo(() => ({...parse(urlParamsString), businessId}), [urlParamsString, businessId]);

  const navigateToCreateBooking = useCallback(
    (params: CreateUrlParams = localCreateBookingParams) => {
      const partial = {
        ...params,
        bookingTreatments: params.bookingTreatments?.map(el => stringifyUrlParams(el as Record<string, unknown>)) ?? []
      };
      const urlSearchParamsString = stringifyUrlParams(partial);

      params.clinicId
        ? setClinicById({
            id: params.clinicId,
            nextPath: ROUTES.CLIENTS_CREATE_BOOKING,
            urlParamsString: urlSearchParamsString,
            options: {state: {backRoute: location.pathname + location.search}}
          })
        : navigate({
            toRoute: ROUTES.CLIENTS_CREATE_BOOKING,
            urlSearchParamsString,
            options: {state: {backRoute: location.pathname + location.search}}
          });
    },
    [location, localCreateBookingParams, setClinicById, navigate]
  );

  const toggleTreatmentToLocalParams = (treatment: BookingTreatment) => {
    const newTreatments = [...(localCreateBookingParams?.bookingTreatments ?? [])];
    const indexFound = newTreatments.findIndex(el => el.serviceId === treatment.serviceId);
    indexFound === -1 ? newTreatments.push(treatment) : newTreatments.splice(indexFound, 1);
    setLocalCreateBookingParams({...localCreateBookingParams, bookingTreatments: newTreatments});
  };
  const initTreatmentToLocalParams = (treatment: BookingTreatment) => {
    setLocalCreateBookingParams({...localCreateBookingParams, bookingTreatments: [treatment]});
  };

  return {
    params,
    navigateToCreateBooking,
    setLocalCreateBookingParams,
    toggleTreatmentToLocalParams,
    initTreatmentToLocalParams,
    localCreateBookingParams
  };
}

export {useCreateBookingUrlParams};
export default useCreateBookingUrlParams;

const parse = (urlParamsString: string): CreateUrlParams => {
  const params = parseUrlParams(urlParamsString) as CreateUrlParams;
  return {
    ...params,
    bookingTreatments: params?.bookingTreatments?.map(el => parseUrlParams(el as string)) as BookingTreatment[]
  };
};
