import {GET_CLIENTS} from 'data/graphql/queries';
import {GQLClientContext} from 'providers/gqlClient';
import {useContext, useMemo} from 'react';
import {useQuery, UseQueryResult} from '@tanstack/react-query';
import {GET_CLIENTSQuery, GET_CLIENTSQueryVariables, ClientsInput} from 'types/ApiModel';
import {useBusinessContext} from 'hooks/business/useBusinessContext';
import {RQ_CACHE_TIMES} from 'constants/reactquery';
import {TGQLCustomErrorsViaReactQuery} from 'types/errors';

export type ClientSearchInput = Omit<ClientsInput, 'businessId'>;

export type IUseClientsOutput = UseQueryResult<GET_CLIENTSQuery['getClients'], TGQLCustomErrorsViaReactQuery>;

function useClients(input: ClientSearchInput): IUseClientsOutput {
  const {businessId} = useBusinessContext();
  const {gqlClient} = useContext(GQLClientContext);

  const query = useMemo(
    () => ({
      businessId,
      email: input.email?.trim(),
      firstName: input.firstName?.trim(),
      lastName: input.lastName?.trim(),
      phone: input.phone?.trim()
    }),
    [businessId, input]
  );

  return useQuery<GET_CLIENTSQuery['getClients'], TGQLCustomErrorsViaReactQuery>({
    enabled: !!(query.firstName || query.lastName || query.email || query.phone),
    cacheTime: RQ_CACHE_TIMES.useClients,
    queryKey: [USE_CLIENTS_KEY, query],
    queryFn: async () => {
      const {data} = await gqlClient.request<GET_CLIENTSQuery, GET_CLIENTSQueryVariables>(GET_CLIENTS, {query});
      return data?.getClients;
    }
  });
}

export {useClients};
export default useClients;

export const USE_CLIENTS_KEY = 'GET_CLIENTS';
