import {atom} from 'recoil';
import {TreatmentModel} from 'types/DerivedApiModel';

interface AtomState {
  loading: boolean;
  data: TreatmentModel[];
  error?: unknown;
}

const clinicTreatmentsState = atom<AtomState>({key: 'clinicTreatmentsState', default: {loading: false, data: []}});

export {clinicTreatmentsState};
