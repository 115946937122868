import React from 'react';
import {AccordionSummary as MuiAccordionSummary, AccordionSummaryProps} from '@material-ui/core';
import styled from 'styled-components';

const AccordionSummary: React.FC<React.PropsWithChildren<AccordionSummaryProps>> = props => (
  <MinimalAccordionSummary {...props} />
);

export default AccordionSummary;

const MinimalAccordionSummary = styled(MuiAccordionSummary)`
  background: transparent;
  box-shadow: 0 -4px #fff, 0 -12px #f4f4f4;
  margin-top: ${({theme}) => theme.spacing(1.5)};
`;
