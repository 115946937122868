import React from 'react';
import {Card as MUICard, CardProps} from '@material-ui/core';
import {styledTheme} from 'providers/theme';
import {COLORS} from 'constants/colors';

const defaultStyle: React.CSSProperties = {
  padding: 0,
  border: `1px solid ${COLORS.ice600}`,
  borderRadius: styledTheme.shape.borderRadius * 3
};

const Card: React.FC<React.PropsWithChildren<CardProps>> = ({style, ...props}) => (
  <MUICard elevation={0} {...props} style={{...defaultStyle, ...style}}>
    {props.children}
  </MUICard>
);

export {Card};
export default Card;
