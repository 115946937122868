import {selector} from 'recoil';
import {clinicAppointmentsState} from 'state/atoms';

interface SelectorOutput {
  data: Set<string>;
}

const staffIdsFromClinicAppointmentsSelector = selector<SelectorOutput>({
  key: 'staffIdsFromClinicAppointmentsSelector',
  get: ({get}) => ({
    data: get(clinicAppointmentsState).reduce((acc, {staffId}) => {
      staffId && acc.add(staffId);
      return acc;
    }, new Set<string>())
  })
});

export {staffIdsFromClinicAppointmentsSelector};
