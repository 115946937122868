// Base colors

const COLORS_BASE = {
  amber100: '#fef4ec',
  amber200: '#fbdfc6',
  amber300: '#f8c9a0',
  amber400: '#f6b379',
  amber500: '#f2994a',
  amber600: '#ef7e1a',
  apple100: '#79e2a6',
  apple200: '#58da90',
  apple300: '#36d37a',
  apple400: '#28b867',
  apple500: '#219653',
  apple600: '#166438',
  azure100: '#b5d2f8',
  azure200: '#90bbf4',
  azure300: '#6aa4f0',
  azure400: '#458eed',
  azure500: '#1a73e8',
  azure600: '#125bba',
  canary100: '#fef9ec',
  canary200: '#fbeec6',
  canary300: '#f8e2a0',
  canary400: '#f5d67a',
  canary500: '#f2c94c',
  canary600: '#eeb91b',
  grape100: '#e5dff6',
  grape200: '#ccc0ed',
  grape300: '#b2a0e3',
  grape400: '#9881da',
  grape500: '#7655ce',
  grape600: '#5b37be',
  ice100: '#ffffff',
  ice200: '#fcfcfe',
  ice300: '#fafafc',
  ice400: '#f8f8fb',
  ice500: '#f4f4f9',
  ice600: '#eeeef4',
  mist100: '#fafafa',
  mist200: '#ebebeb',
  mist300: '#d6d6d6',
  mist400: '#c2c2c2',
  mist500: '#adadad',
  mist600: '#8f8f8f',
  night100: '#7a7a7a',
  night200: '#666666',
  night300: '#525252',
  night400: '#3d3d3d',
  night500: '#222222',
  night600: '#0a0a0a',
  scarlet100: '#fdeded',
  scarlet200: '#f9c8c8',
  scarlet300: '#f4a4a4',
  scarlet400: '#f07f7f',
  scarlet500: '#eb5757',
  scarlet600: '#e52424'
} as const;

export default COLORS_BASE;
export {COLORS_BASE};
