import ProductHistory from 'components/Tables/ClientTables/ProductHistoryTable';
import React from 'react';
import SectionContent from './SectionContent';
import SectionHeader from './SectionHeader';

interface Props {
  clientId: string;
}

const CustomerProductHistory: React.FC<React.PropsWithChildren<Props>> = ({clientId}) => {
  return (
    <>
      <SectionHeader children={'Product History'} />
      <SectionContent hasSidePadding={false}>
        <ProductHistory clientId={clientId} />
      </SectionContent>
    </>
  );
};

export default CustomerProductHistory;
