import {captureException} from '@sentry/react';
import {GET_ADMIN_ONLINE_GROUPS} from 'data/graphql/queries/adminOnlineGroups';
import useNotifications from 'hooks/notifications/useNotifications';
import {GQLClientContext} from 'providers/gqlClient';
import {useContext, useMemo} from 'react';
import {useQuery, UseQueryResult} from '@tanstack/react-query';
import {GET_ADMIN_ONLINE_GROUPSQuery, GET_ADMIN_ONLINE_GROUPSQueryVariables} from 'types/ApiModel';
import {Nullable} from 'types/common';

export type IUseAdminOnlineGroupsOutput = UseQueryResult<GET_ADMIN_ONLINE_GROUPSQuery['getAdminOnlineGroups'], Error>;

function useAdminOnlineGroups(variables: GET_ADMIN_ONLINE_GROUPSQueryVariables): IUseAdminOnlineGroupsOutput {
  const {gqlClient} = useContext(GQLClientContext);
  const {businessId, name, parentGroupId, type} = variables.query;
  const {notifyError} = useNotifications();

  const adminGetOnlineGroupsQuery = useMemo<Nullable<GET_ADMIN_ONLINE_GROUPSQueryVariables>>(
    () => ({query: {businessId: businessId!, name, parentGroupId, type}, page: variables?.page}),
    [businessId, name, parentGroupId, type, variables?.page]
  );

  return useQuery<GET_ADMIN_ONLINE_GROUPSQuery['getAdminOnlineGroups'], Error>({
    enabled: !!(businessId && type),
    queryKey: [USE_ADMIN_ONLINE_GROUPS_KEY, adminGetOnlineGroupsQuery],
    queryFn: async () => {
      const resp = await gqlClient.request<
        GET_ADMIN_ONLINE_GROUPSQuery,
        Nullable<GET_ADMIN_ONLINE_GROUPSQueryVariables>
      >(GET_ADMIN_ONLINE_GROUPS, adminGetOnlineGroupsQuery);
      return resp.data.getAdminOnlineGroups;
    },
    onError: error => {
      const errorString = error.message;
      notifyError(`Error getting online groups: ${errorString}`);
      captureException(new Error(errorString));
    }
  });
}

export {useAdminOnlineGroups};

export const USE_ADMIN_ONLINE_GROUPS_KEY = 'GET_ADMIN_ONLINE_GROUPS';
