import React from 'react';
import {Client} from 'types/DerivedApiModel';
import {Card, List, ListItem, ListItemText} from '@material-ui/core';
import styled from 'styled-components';
import {formatPhoneNumber} from 'utils';
import {LoadingPlaceholder} from '@therapie-ui';
import {ShapeSearchResults} from '@therapie-ui/LoadingPlaceholder/LoadingPlaceholder';

interface Props {
  clients?: Client[] | null;
  selectedId: string;
  loading: boolean;
  onSelect: (client: Client) => void;
}

// TODO: this component is not standard, knows about Client
const ResultList: React.FC<React.PropsWithChildren<Props>> = ({clients, onSelect, selectedId, loading}) => {
  const isSelected = (client?: Client): boolean => client?.clientId === selectedId;

  return loading ? (
    <LoadingPlaceholder shape={ShapeSearchResults} />
  ) : (
    <Card style={{margin: 12}}>
      <StyledList>
        {clients?.map(client => (
          <ListItem
            key={client?.clientId}
            onClick={() => onSelect(client)}
            button
            style={{backgroundColor: isSelected(client) ? '#c3c2ff' : ''}}
          >
            <ListItemText
              primary={`${client?.firstName} ${client?.lastName}`}
              secondary={[formatPhoneNumber(client?.mobile), client?.email].join(
                `${client?.mobile && client?.email ? ' - ' : ''}`
              )}
            />
          </ListItem>
        ))}
      </StyledList>
    </Card>
  );
};

export default ResultList;

const StyledList = styled(List)`
  padding: 0;
`;
