import {useQuery} from '@tanstack/react-query';
import {useContext, useMemo} from 'react';
import {GET_APPOINTMENTS} from 'data/graphql/queries/appointments';
import {GQLClientContext} from 'providers/gqlClient';
import {GET_APPOINTMENTSQuery, GET_APPOINTMENTSQueryVariables} from 'types/ApiModel';
import {Nullable} from 'types/common';
import {ClinicAppointmentModel} from 'types/DerivedApiModel';

interface Output {
  data: ClinicAppointmentModel[];
  isInitialLoading: boolean;
  isFetching: boolean;
}

interface Props {
  variables: GET_APPOINTMENTSQueryVariables | null;
  enabled?: boolean;
}

function useAllClinicAppointments({variables, enabled = true}: Props): Output {
  const {gqlClient} = useContext(GQLClientContext);
  const {branchId = '', businessId = '', fromDate = '', toDate = ''} = variables?.query || {};

  const clinicAppointmentsQuery = useMemo<Nullable<GET_APPOINTMENTSQueryVariables>>(
    () =>
      businessId && branchId && fromDate && toDate
        ? {query: {businessId, branchId, fromDate, toDate, fetchCancelled: false}}
        : null,
    [branchId, businessId, fromDate, toDate]
  );

  const allAppointmentsQuery = useQuery<GET_APPOINTMENTSQuery['getAppointments'], Error>(
    [USE_ALL_CLINIC_APPOINTMENTS_KEY, clinicAppointmentsQuery],
    async () => {
      if (!clinicAppointmentsQuery?.query) throw new Error('Missing clinicAppointmentsQuery');

      // Get the first page of appointments...
      const firstPage = await gqlClient.request<GET_APPOINTMENTSQuery, Nullable<GET_APPOINTMENTSQueryVariables>>(
        GET_APPOINTMENTS,
        {query: clinicAppointmentsQuery.query, page: {size: MAX_PAGE_SIZE_WITH_PHOREST_CONSTRAINT, page: 0}}
      );

      // But then if there are more pages, get them too, all inside the one useQuery. Magical!
      const pageData = firstPage.data?.getAppointments?.page;
      const totalPages = pageData?.totalPages ?? 0;
      const queryFns = [];

      for (let pageNumber = 1; pageNumber < totalPages; pageNumber++) {
        queryFns.push(
          gqlClient.request<GET_APPOINTMENTSQuery, Nullable<GET_APPOINTMENTSQueryVariables>>(GET_APPOINTMENTS, {
            query: clinicAppointmentsQuery?.query!,
            page: {size: MAX_PAGE_SIZE_WITH_PHOREST_CONSTRAINT, page: pageNumber}
          })
        );
      }

      const firstPageAppointments = firstPage.data.getAppointments?.appointments ?? [];
      const otherPageAppointments =
        queryFns.length > 0
          ? (await Promise.all(queryFns)).flatMap(({data}) => data?.getAppointments?.appointments ?? [])
          : [];

      return {
        appointments: [...firstPageAppointments, ...otherPageAppointments],
        page: pageData
      } as GET_APPOINTMENTSQuery['getAppointments'];
    },
    {enabled: enabled && !!clinicAppointmentsQuery}
  );

  return {
    data: allAppointmentsQuery.data?.appointments ?? [],
    isFetching: allAppointmentsQuery.isFetching,
    isInitialLoading: allAppointmentsQuery.isInitialLoading
  };
}

export {useAllClinicAppointments};
export default useAllClinicAppointments;

export const USE_ALL_CLINIC_APPOINTMENTS_KEY = 'GET_ALL_APPOINTMENTS';
export const MAX_PAGE_SIZE_WITH_PHOREST_CONSTRAINT = 50;
