import {TextFieldProps} from '@material-ui/core';
import React from 'react';
import styled from 'styled-components';
import TextInput from './TextInput';

const NumberInput: React.FC<React.PropsWithChildren<TextFieldProps>> = props => (
  <NumberField {...props} type="number" />
);
export default NumberInput;

const NumberField = styled(TextInput)<{disabled?: boolean}>`
  .MuiInputBase-root {
    border-radius: 8px;
    p {
      color: inherit;
    }
  }
  .MuiInputBase-root.Mui-disabled {
    fieldset {
      border-color: ${({disabled}) => (disabled ? 'rgba(0, 0, 0, 0.12)' : undefined)};
    }
  }

  /* Chrome, Safari, Edge, Opera */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }

  /* Firefox */
  input[type='number'] {
    -moz-appearance: textfield;
  }
`;
