import React from 'react';
import BulletSeparator from './BulletSeparator';

interface Props {
  parts: (null | undefined | string)[] | null;
}

const BulletSeparatedString: React.FC<React.PropsWithChildren<Props>> = ({parts}) => {
  const bulletSeparatedString = parts
    ?.filter(part => !!part)
    .map((part, i) => (
      <React.Fragment key={part}>
        {!!i && <BulletSeparator />}
        {part}
      </React.Fragment>
    ));

  return <span>{bulletSeparatedString}</span>;
};

export default BulletSeparatedString;
