import {useCallback, useMemo} from 'react';
import {SetterOrUpdater, useRecoilState, useRecoilValue, useResetRecoilState} from 'recoil';
import {purchaseDefaultStaffIdState, purchaseStaffListState, purchaseStaffListStatusState} from 'state/atoms/purchase';
import {purchaseStaffOptionsSelector} from 'state/selectors/purchase';
import {Option} from 'types/common';

export interface IUsePurchaseStaffOutput {
  defaultStaffId: string;
  staffOptions: Option[];
  loadingStaff: boolean;
  resetAtoms: () => void;
  updateDefaultStaffId: SetterOrUpdater<string>;
}

function usePurchaseStaff(): IUsePurchaseStaffOutput {
  const {data: staffOptions, loading: loadingStaff} = useRecoilValue(purchaseStaffOptionsSelector);
  const [defaultStaffId, setDefaultStaffId] = useRecoilState(purchaseDefaultStaffIdState);
  const resetPurchaseStaffListState = useResetRecoilState(purchaseStaffListState);
  const reseturchaseStaffListStatusState = useResetRecoilState(purchaseStaffListStatusState);
  const resetPurchaseDefaultStaffIdState = useResetRecoilState(purchaseDefaultStaffIdState);

  const resetAtoms = useCallback(() => {
    resetPurchaseStaffListState();
    reseturchaseStaffListStatusState();
    resetPurchaseDefaultStaffIdState();
  }, [resetPurchaseStaffListState, reseturchaseStaffListStatusState, resetPurchaseDefaultStaffIdState]);

  return useMemo(
    () => ({
      defaultStaffId,
      staffOptions,
      loadingStaff,
      resetAtoms,
      updateDefaultStaffId: setDefaultStaffId
    }),
    [defaultStaffId, loadingStaff, resetAtoms, setDefaultStaffId, staffOptions]
  );
}

export {usePurchaseStaff};
