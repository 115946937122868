import {gql} from 'utils/graphql';

export const UPDATE_CLIENT = gql`
  mutation UPDATE_CLIENT($client: ClientInput!, $businessId: String!) {
    updateClient(client: $client, businessId: $businessId) {
      address {
        city
        country
        postalCode
        state
        streetAddress1
        streetAddress2
      }
      archived
      banned
      birthDate
      clientCategoryIds
      clientId
      id
      clientSince
      createdAt
      creatingBranchId
      creditAccount {
        creditDays
        creditLimit
        outstandingBalance
      }
      deleted
      email
      emailMarketingConsent
      emailReminderConsent
      externalId
      firstName
      firstVisit
      gender
      landLine
      lastName
      loyaltyCard {
        points
        serial
      }
      mergedToClientId
      mobile
      notes
      photoUrl
      preferredStaffId
      smsMarketingConsent
      smsReminderConsent
      updatedAt
      version
    }
  }
`;

export const CREATE_CLIENT = gql`
  mutation CREATE_CLIENT($payload: CreateClientInput!) {
    createClient(payload: $payload) {
      address {
        city
        country
        postalCode
        state
        streetAddress1
        streetAddress2
      }
      banned
      birthDate
      clientCategoryIds
      clientId
      clientSince
      creatingBranchId
      creditAccount {
        creditDays
        creditLimit
        outstandingBalance
      }
      email
      emailMarketingConsent
      emailReminderConsent
      externalId
      firstName
      gender
      landLine
      lastName
      mobile
      notes
      photoUrl
      preferredStaffId
      smsMarketingConsent
      smsReminderConsent
    }
  }
`;

export const DELETE_CLIENT = gql`
  mutation DELETE_CLIENT($payload: DeleteClientInput!) {
    deleteClient(payload: $payload)
  }
`;
