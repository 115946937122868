import {PURCHASE_ATOM_PREFIX, WALKIN_CLIENT} from 'constants/purchase';
import {selector} from 'recoil';
import {businessState, clinicState} from 'state/atoms';
import {cardPaymentFlowState, purchaseClientState} from 'state/atoms/purchase';
import {ChannelSource, CreatePurchaseInput} from 'types/ApiModel';
import {getRandomInteger} from 'utils/numbers';
import {paymentTypesPayloadSelector} from './paymentTypes';
import {purchaseItemsPayloadSelector} from './purchaseItems';
import config from 'config';

const createPurchaseSelector = selector<CreatePurchaseInput>({
  key: `${PURCHASE_ATOM_PREFIX}CreatePurchaseSelector`,
  get: ({get}) => {
    const client = get(purchaseClientState);
    return {
      businessId: get(businessState)?.id as string,
      clinicId: get(clinicState)?.id as string,
      source: config.IS_CALL_CENTRE ? ChannelSource.call_centre : ChannelSource.clinic,
      purchase: {
        clientId: client !== WALKIN_CLIENT ? client?.clientId : undefined,
        number: get(cardPaymentFlowState)?.steps?.[0]?.id ?? getRandomInteger().toString(),
        items: get(purchaseItemsPayloadSelector),
        payments: get(paymentTypesPayloadSelector)
      }
    };
  }
});

export {createPurchaseSelector};
