import CreateOutlinedIcon from '@material-ui/icons/CreateOutlined';
import {Button, EmptyState} from '@therapie-ui';
import {COLORS_BASE} from 'constants/colors/base';
import {COLORS_PASTELS} from 'constants/colors/pastels';
import {COLORS_PRIMARY} from 'constants/colors/primary';
import {LocaleContext} from 'providers/locale';
import React, {useCallback, useContext, useMemo} from 'react';
import styled from 'styled-components';
import {stripEmoji} from 'utils';
import {formatUTCDate} from 'utils/time';

interface Props {
  clientNotes?: string;
  resizeable?: boolean;
  hasPadding?: boolean;
  updateNotes: (newNotes: string) => void;
}

const ClientNotes: React.FC<React.PropsWithChildren<Props>> = ({clientNotes, updateNotes, resizeable, hasPadding}) => {
  const {locale} = useContext(LocaleContext);

  const insertTimestamp = useCallback(() => {
    updateNotes(noteTimeStamp(locale) + '\n\n' + clientNotes);
  }, [clientNotes, locale, updateNotes]);

  const handleNotesChange = useCallback(
    (event: React.ChangeEvent<HTMLTextAreaElement>) => updateNotes(stripEmoji(event?.target?.value)),
    [updateNotes]
  );

  const hasNotes = useMemo(() => clientNotes && clientNotes.trim().length > 0, [clientNotes]);

  return (
    <ClientNotesArea>
      {hasNotes ? (
        <>
          <TimeStampTopSection hasPadding={hasPadding}>
            <Button
              color="secondary"
              variant="outlined"
              key="Timestamp"
              children="Timestamp"
              onClick={insertTimestamp}
            />
          </TimeStampTopSection>
          <NotesMain hasPadding={hasPadding}>
            <NotesTextArea value={clientNotes ?? ''} onChange={handleNotesChange} resizeable={resizeable} />
          </NotesMain>
        </>
      ) : (
        <EmptyState
          title="No notes"
          details="If you add any notes for this client they will be shown here"
          darkTitle
          fillHeight
          actionButton={
            <Button
              children="Create Note"
              color="secondary"
              variant="outlined"
              onClick={insertTimestamp}
              iconLeading={<CreateOutlinedIcon />}
            />
          }
        />
      )}
    </ClientNotesArea>
  );
};

export {ClientNotes};
export default ClientNotes;

const noteTimeStamp = (locale?: Locale) => {
  const dateTag = formatUTCDate(new Date(), 'DATE_MONTH_YEAR_LOCALISED', locale);
  const timeTag = formatUTCDate(new Date(), 'AM_PM');
  return `${dateTag} - ${timeTag}`;
};

const ClientNotesArea = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

const NotesMain = styled.main<NoteEditorStyleProps>`
  width: ${({theme, hasPadding}) => (hasPadding ? `calc(100% - ${theme.spacing(2)})` : '100%')};
  margin: ${({theme, hasPadding}) => theme.spacing(0, hasPadding ? 1 : 0, 1)};
  background: ${COLORS_PRIMARY.mist100};
  min-height: ${({hasPadding}) => (hasPadding ? '0px' : '300px')};
  display: flex;
  flex-direction: column;
  flex-grow: 1;
`;

const TimeStampTopSection = styled.div<NoteEditorStyleProps>`
  padding: ${({theme, hasPadding}) => theme.spacing(hasPadding ? 2 : 0, hasPadding ? 3 : 0, 2, hasPadding ? 3 : 0)};
`; // TODO: Refactor once Notes header is removed.

const NotesTextArea = styled.textarea<NoteEditorStyleProps>`
  background: ${COLORS_PASTELS.mist400P};
  border-color: transparent;
  border-radius: ${({theme}) => theme.shape.borderRadius}px;
  color: ${COLORS_BASE.night200};
  flex-grow: 1;
  font-family: inherit;
  font-size: ${14 / 16}rem;
  line-height: 1.5;
  outline-color: transparent;
  padding: ${({theme}) => theme.spacing(2)};
  resize: ${({resizeable}) => (resizeable ? 'vertical' : 'none')};
  transition: outline-color 120ms ease;
  white-space: pre-wrap;
  word-break: keep-all;
  &:focus,
  &:focus-visible {
    background-color: #fff;
    outline-color: ${({theme}) => theme.palette.primary};
  }
`;

interface NoteEditorStyleProps {
  resizeable?: boolean;
  hasPadding?: boolean;
}
