import {createTheme as createMuiTheme} from '@material-ui/core/styles';
import * as muiColors from '@material-ui/core/colors';
import {COLORS} from 'constants/colors';

const BASE_FONT_SIZE = 16; // TODO: CC-2425
const SPACE_BASE = 8;

// Theming Note:
// Keep MUI as bare as possible and extend it from styledTheme

const styledTheme = {
  spacing: (...spacing: number[]): string => spacing.map(unit => `${unit * SPACE_BASE}px`).join(' '),
  shape: {borderRadius: 4},
  fontFamily: ['Open Sans', 'sans-serif'].join(','),
  baseFontSize: BASE_FONT_SIZE,
  fontSize: {
    // TODO: Refactor all of these to match the DS
    // TODO: If the base font size in the DS is 14, we should set that as the root font-size https://material-ui.com/customization/typography/#font-size

    // Base document font size - keep native default for now
    // base: BASE_FONT_SIZE + 'px', // TODO: CC-2425

    // Design System...
    default: '.875rem', // 14
    body: '.875rem', ///// 14

    // Legacy
    xsm: '.7rem', /// 11.2
    sm: '.8rem', //// 12.8
    md: '1rem', ///// 16
    lg: '1.2rem', /// 19.2
    xl: '1.75rem', // 28
    xxl: '2rem' ///// 32
  },
  breakpoints: {
    // TODO: These are common defaults, but our app is usually ~50% width, so might be more useful to use a range up to ~1000 instead of 1920
    values: {
      xs: 0,
      sm: 600,
      md: 960,
      lg: 1280,
      xl: 1920
    }
  },
  colors: muiColors,
  palette: {primary: COLORS.therapiePurple, secondary: COLORS.therapiePurple}
};

const muiTheme = createMuiTheme({
  breakpoints: styledTheme.breakpoints,
  palette: {
    primary: {
      main: styledTheme.palette.primary
      // light: will be calculated from palette.primary.main,
      // dark: will be calculated from palette.primary.main,
      // contrastText: will be calculated to contrast with palette.primary.main
    },
    secondary: {main: styledTheme.palette.primary},
    contrastThreshold: 3,
    tonalOffset: 0.2
  },
  typography: {fontFamily: styledTheme.fontFamily} // , fontSize: BASE_FONT_SIZE TODO: CC-2425
});

type ThemeInterface = typeof styledTheme;

// Override Theme Interface
declare module 'styled-components' {
  // eslint-disable-next-line
  export interface DefaultTheme extends ThemeInterface {}
}

export {styledTheme, muiTheme};
