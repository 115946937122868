// Therapie colors

const COLORS_THERAPIE = {
  therapiePurple: '#7073B6',
  therapieSoftLilac: '#D8D5EA',
  therapieGrey: '#C5CAD0',
  therapieSoftGrey: '#EBEDF2',
  therapieSkin: '#F7EBE5',
  therapieSoftSkin: '#F9F5F0',
  therapiePurpleL10: '#7E81BD',
  therapiePurpleL20: '#8D8FC5',
  therapiePurpleL30: '#9B9DCC',
  therapiePurpleL40: '#A9ABD3',
  therapiePurpleL50: '#B7B9DA',
  therapiePurpleL60: '#C6C7E2',
  therapiePurpleL70: '#D4D5E9',
  therapiePurpleL80: '#E2E3F0',
  therapiePurpleL90: '#F1F1F8',
  therapiePurpleD10: '#6567A4',
  therapiePurpleD20: '#5A5C92',
  therapiePurpleD30: '#4E517F',
  therapiePurpleD40: '#43456D',
  therapiePurpleD50: '#38395B',
  therapiePurpleD60: '#2D2E49',
  therapiePurpleD70: '#222337',
  therapiePurpleD80: '#161724',
  therapiePurpleD90: '#0B0C12'
} as const;

export default COLORS_THERAPIE;
export {COLORS_THERAPIE};
