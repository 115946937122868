import React from 'react';
import styled from 'styled-components';
import {Avatar as MUIAvatar} from '@material-ui/core';

interface Props {
  firstName?: string | null;
  lastName?: string | null;
  size?: 'S' | 'M';
}

type Size = 'S' | 'M';

const Avatar: React.FC<React.PropsWithChildren<Props>> = ({firstName, lastName, size = 'S'}) => {
  const text = `${firstName?.trim()?.[0] ?? ' '}${lastName?.trim()?.[0] ?? ' '}`;
  return <StyledAvatar size={size}>{text}</StyledAvatar>;
};

export default Avatar;

const StyledAvatar = styled(MUIAvatar)<{size: Size}>`
  background-color: lightgreen;
  width: ${({size, theme}) => (size === 'S' ? theme.spacing(4) : theme.spacing(5))};
  height: ${({size, theme}) => (size === 'S' ? theme.spacing(4) : theme.spacing(5))};
`;
