import {PURCHASE_ATOM_PREFIX} from 'constants/purchase';
import {atom} from 'recoil';
import {CardPaymentFlow} from './types';

const cardPaymentFlowState = atom<CardPaymentFlow>({
  key: `${PURCHASE_ATOM_PREFIX}CardPaymentFlowState`,
  default: {
    steps: [],
    flowState: {status: undefined, errorMessage: undefined}
  }
});

export {cardPaymentFlowState};
