import {mapStripeAmountToAmount} from 'components/Purchase/utils/purchase';
import {useCurrency} from 'hooks/currency/useCurrency';
import {useCallback, useMemo} from 'react';
import {useRecoilCallback, useRecoilValue, useResetRecoilState, useSetRecoilState} from 'recoil';
import {cardPaymentFlowState} from 'state/atoms/purchase';
import {CardPayment, CardPaymentSteps, FlowState, PaymentStep, TStripeDialogState} from 'state/atoms/purchase/types';
import {cardPaymentFlowSelector, ICardPaymentFlowSelector} from 'state/selectors/purchase/cardPaymentFlow';
import {FLOW_TYPE, IStripeTerminalDialogState} from '../../Stripe/types';

interface IUseCardPaymentFlowOutput extends ICardPaymentFlowSelector {
  addCardPaymentStep: (step: CardPayment) => void;
  amountOfCurrentStep: string;
  getCardPaymentFlowInfo: () => ICardPaymentFlowSelector;
  resetCardPaymentFlow: () => void;
  synchronizePaymentIntentsOnSuccess: () => void;
  updateCurrentCardPaymentDialogState: (state: TStripeDialogState) => void;
  updateCurrentCardPaymentIntent: (paymentIntent: PaymentStep['paymentIntent']) => void;
  updateCurrentCardPaymentType: (type: FLOW_TYPE) => void;
  updateFlowState: (flowState: FlowState) => void;
  resetCurrentCardPaymentState: () => void;
}

export const INITIAL_STEP_STATE: IStripeTerminalDialogState = {
  status: 'LOADING',
  loadingMessage: 'Initialising payment'
};

const useCardPaymentFlow = (): IUseCardPaymentFlowOutput => {
  const {formatCurrency} = useCurrency();

  const setCardPaymentFlow = useSetRecoilState(cardPaymentFlowState);
  const cardPaymentFlowInfo = useRecoilValue(cardPaymentFlowSelector);
  const resetCardPaymentFlow = useResetRecoilState(cardPaymentFlowState);

  // https://recoiljs.org/docs/api-reference/core/Snapshot/#example
  // We need to read the state across functions without wating for the next render cycle, this is the way to read directly into a recoil snapshot
  const getCardPaymentFlowInfo: IUseCardPaymentFlowOutput['getCardPaymentFlowInfo'] = useRecoilCallback(
    ({snapshot}) =>
      () =>
        snapshot.getLoadable(cardPaymentFlowSelector).contents,
    []
  );

  const setCardPaymentSteps = useCallback(
    (steps: CardPaymentSteps) => setCardPaymentFlow(prev => ({...prev, steps})),
    [setCardPaymentFlow]
  );

  const addCardPaymentStep: IUseCardPaymentFlowOutput['addCardPaymentStep'] = useCallback(
    step => {
      setCardPaymentFlow(prev => ({...prev, steps: [...prev.steps, step]}));
    },
    [setCardPaymentFlow]
  );

  const amountOfCurrentStep = useMemo(
    () =>
      formatCurrency({
        value: mapStripeAmountToAmount(cardPaymentFlowInfo.currentStep?.paymentIntent?.amount as number)
      }),
    [cardPaymentFlowInfo, formatCurrency]
  );

  const synchronizePaymentIntentsOnSuccess = useCallback(() => {
    const {cardPaymentSteps} = getCardPaymentFlowInfo();
    setCardPaymentSteps(
      cardPaymentSteps.map(step => ({...step, paymentIntent: {...step.paymentIntent, status: 'succeeded'}})) //TODO: Use status returned from createTransaction instead of hardcoding here
    );
  }, [getCardPaymentFlowInfo, setCardPaymentSteps]);

  const updateCurrentCardPaymentIntent: IUseCardPaymentFlowOutput['updateCurrentCardPaymentIntent'] = useCallback(
    paymentIntent => {
      const {cardPaymentSteps, currentStepIndex, currentStep} = getCardPaymentFlowInfo();
      if (!currentStep) return;
      if (!paymentIntent?.id) throw new Error('Trying to update with invalid paymentIntent');
      const newSteps = [...cardPaymentSteps];
      newSteps[currentStepIndex] = {...newSteps[currentStepIndex], paymentIntent};
      setCardPaymentSteps(newSteps);
    },
    [getCardPaymentFlowInfo, setCardPaymentSteps]
  );

  const resetCurrentCardPaymentState: IUseCardPaymentFlowOutput['resetCurrentCardPaymentState'] = useCallback(() => {
    const {cardPaymentSteps, currentStepIndex, currentStep} = getCardPaymentFlowInfo();
    if (!currentStep) return;
    const newSteps = [...cardPaymentSteps];
    newSteps[currentStepIndex] = {...newSteps[currentStepIndex], state: INITIAL_STEP_STATE};
    setCardPaymentSteps(newSteps);
  }, [getCardPaymentFlowInfo, setCardPaymentSteps]);

  const updateCurrentCardPaymentType: IUseCardPaymentFlowOutput['updateCurrentCardPaymentType'] = useCallback(
    type => {
      const {cardPaymentSteps, currentStepIndex, currentStep} = getCardPaymentFlowInfo();
      if (!currentStep) return;
      const newSteps = [...cardPaymentSteps];
      newSteps[currentStepIndex] = {...newSteps[currentStepIndex], type};
      setCardPaymentSteps(newSteps);
    },
    [getCardPaymentFlowInfo, setCardPaymentSteps]
  );

  const updateFlowState: IUseCardPaymentFlowOutput['updateFlowState'] = useCallback(
    flowState => {
      setCardPaymentFlow(prev => ({...prev, flowState}));
    },
    [setCardPaymentFlow]
  );

  const updateCurrentCardPaymentDialogState = useCallback(
    (state: TStripeDialogState) => {
      const {cardPaymentSteps, currentStep} = getCardPaymentFlowInfo();
      if (!currentStep) return;
      setCardPaymentSteps(
        cardPaymentSteps.map(step => ({
          ...step,
          state: step.id === currentStep.id ? {...step.state, ...state} : step.state
        }))
      );
    },
    [getCardPaymentFlowInfo, setCardPaymentSteps]
  );

  return {
    ...cardPaymentFlowInfo,
    addCardPaymentStep,
    amountOfCurrentStep,
    getCardPaymentFlowInfo,
    resetCardPaymentFlow,
    synchronizePaymentIntentsOnSuccess,
    resetCurrentCardPaymentState,
    updateCurrentCardPaymentDialogState,
    updateCurrentCardPaymentIntent,
    updateCurrentCardPaymentType,
    updateFlowState
  };
};

export {useCardPaymentFlow};
