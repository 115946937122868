import config from '../../config';
import * as Sentry from '@sentry/react';
import {Integrations} from '@sentry/tracing';
import {beforeSend} from 'utils';
import {isPREnv} from 'utils/window';

const environment = isPREnv ? 'preview' : config.ENVIRONMENT + '-' + config.BUSINESS_CODE;

Sentry.init({
  dsn: config.SENTRY_DNS,
  release: config.APP_COMMIT_ID,
  integrations: [new Integrations.BrowserTracing()],
  environment,
  tracesSampleRate: 0,
  beforeSend
});

const Tracing: React.FC<React.PropsWithChildren<unknown>> = () => null;
export {Tracing};
export default Tracing;
