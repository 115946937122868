import axios, {AxiosError} from 'axios';

// Sometimes we might not be able to get typed error response data, e.g error.response.data.message. Using generics here allows us to do this. See link below
// https://github.com/axios/axios/issues/3612#issuecomment-1198490390

function isAxiosError<T>(error: unknown): error is AxiosError<T> {
  return axios.isAxiosError(error);
}

export {isAxiosError};
