import {Chip} from '@material-ui/core';
import DoneIcon from '@material-ui/icons/Done';
import {BulletSeparatedString} from '@therapie-ui';
import addMinutes from 'date-fns/addMinutes';
import useStaff from 'hooks/staff/useStaff';
import React from 'react';
import styled from 'styled-components';
import {formatUTCDate} from 'utils';
import {isItAppointmentPaid} from '../../../utils';

interface Props {
  appointmentId: string | null;
  bookingId: string | null;
  branchId: string | null;
  businessId: string | null;
  cancelled: boolean | null;
  clinicName: string | null;
  serviceTime: string | null;
  serviceDuration: number | null;
  serviceName: string | null;
  staffId: string | null;
  courseName: string | null;
  isWaitingList?: boolean;
  fee: number | null;
  originalPrice: number | null;
}

const ServiceDetails: React.FC<React.PropsWithChildren<Props>> = props => {
  const {
    branchId,
    businessId,
    cancelled,
    clinicName,
    serviceTime,
    serviceDuration,
    serviceName,
    staffId,
    courseName,
    isWaitingList
  } = props;
  const {data: staffMember} = useStaff({
    query: {businessId: businessId ?? '', branchId: branchId ?? '', staffId: staffId ?? ''}
  });
  const staffMemberName = [staffMember?.firstName, staffMember?.lastName].join(' ');
  const serviceEndDate = serviceTime ? addMinutes(new Date(serviceTime ?? ''), serviceDuration ?? 0) : '';
  const serviceEndTime = serviceEndDate ? formatUTCDate(serviceEndDate, 'AM_PM') : '-';
  const serviceStartTime = serviceTime ? formatUTCDate(serviceTime, 'AM_PM') : '-';
  const isAppointmentPaid = isItAppointmentPaid(props);

  return (
    <StyledCourseDescriptionArea>
      <AppointmentTimeAndPaid>
        {`${serviceStartTime} - ${serviceEndTime}`}
        {isAppointmentPaid && <PaidChip size="small" label="Paid" icon={<PaidIcon />} />}
      </AppointmentTimeAndPaid>
      <StyledServiceName>{serviceName}</StyledServiceName>

      <StyledDetailContainer>
        {isWaitingList && <Chip label="Waiting List" className="chip" />}
        <BulletSeparatedString parts={[clinicName, courseName, staffMemberName, cancelled ? 'Cancelled' : null]} />
      </StyledDetailContainer>
    </StyledCourseDescriptionArea>
  );
};

export {ServiceDetails};
export default ServiceDetails;

const StyledCourseDescriptionArea = styled.div`
  padding-top: ${({theme}) =>
    theme.spacing(0)}; /* Theme.spacing(1) when multireschedule for different clinics at same day will be fixed */
  font-size: ${14 / 16}rem;
  line-height: 1.36;
  span {
    opacity: 0.6;
  }
  + div {
    padding-top: ${({theme}) => theme.spacing(3)};
  }
`;
const StyledServiceName = styled.div`
  font-size: 1rem;
  padding: ${({theme}) => theme.spacing(0.25, 0, 0.5, 0)};
  font-weight: 500;
`;

const StyledDetailContainer = styled.div`
  display: flex;
  align-items: center;
  .chip {
    margin: ${({theme}) => theme.spacing(0, 1, 0, 0)};
    span {
      opacity: 1;
    }
  }
`;

const AppointmentTimeAndPaid = styled.div`
  display: flex;
  align-items: center;
`;

const PaidChip = styled(Chip)`
  margin-left: ${({theme}) => theme.spacing(1)};
  padding: ${({theme}) => theme.spacing(0, 0.5)};
  background-color: green;
  color: white;
  > .MuiChip-label {
    opacity: 1;
  }
`;

const PaidIcon = styled(DoneIcon)`
  color: white;
`;
