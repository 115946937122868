import useQueryVariablesWithPhonePrefixed from 'components/Clients/ViewClients/useQueryVariablesWithPhonePrefixed';
import {WALKIN_CLIENT} from 'constants/purchase';
import {QUERY_KEYS} from 'constants/query';
import {useBusinessContext, useDebouncedValue} from 'hooks';
import useClients, {IUseClientsOutput} from 'hooks/client/useClients';
import {useAppNavigation} from 'hooks/navigation/useAppNavigation';
import {useCallback, useMemo, useState} from 'react';
import {useRecoilState} from 'recoil';
import {purchaseClientState} from 'state/atoms/purchase';
import {ClientsInput, GET_CLIENTSQueryVariables} from 'types/ApiModel';
import {Client} from 'types/DerivedApiModel';
import {mergeClientsQueryProps} from 'utils';
import {ClientModelForPurchase} from '../types';

interface Output {
  client?: ClientModelForPurchase;
  dataClients?: IUseClientsOutput['data'];
  error?: IUseClientsOutput['error'];
  isReady: boolean;
  loading: boolean;
  queryVariables: GET_CLIENTSQueryVariables;
  clearSelectedClient: () => void;
  onClientSearchUpdate: (clientInput: ClientsInput) => void;
  onClientSelect: (client: Client) => void;
  setClient: (client: ClientModelForPurchase) => void;
  onWalkinClientSelect: () => void;
}

interface Input {
  urlParams: any;
}

const usePurchaseClient = ({urlParams}: Input): Output => {
  const {businessId} = useBusinessContext();
  const {navigate} = useAppNavigation();
  const updateUrl = useCallback(() => navigate({urlSearchParams: urlParams}), [navigate, urlParams]);
  const [client, setClient] = useRecoilState(purchaseClientState);

  // Client Search
  const [clientSearchQuery, setClientSearchQuery] = useState<ClientsInput>();
  const queryVariables = useMemo(
    () => mergeClientsQueryProps({businessId, ...clientSearchQuery}),
    [businessId, clientSearchQuery]
  );
  const {debouncedValue: debouncedQueryVariables, isReady} = useDebouncedValue(queryVariables);
  const debouncedQueryVariablesWithPhonePrefixed = useQueryVariablesWithPhonePrefixed(debouncedQueryVariables);
  const {isLoading: loading, error, data: dataClients} = useClients(debouncedQueryVariablesWithPhonePrefixed!.query);

  // Callbacks
  const updateSelectedClientId = useCallback(
    (id: string) => {
      urlParams.set(QUERY_KEYS.CLIENT_ID, id);
      updateUrl();
    },
    [updateUrl, urlParams]
  );

  const cleanClientIdInUrl = useCallback(() => {
    urlParams.set(QUERY_KEYS.CLIENT_ID, '');
    updateUrl();
  }, [updateUrl, urlParams]);

  const clearSelectedClient = useCallback(() => {
    cleanClientIdInUrl();
    setClientSearchQuery(undefined);
  }, [cleanClientIdInUrl]);

  const onWalkinClientSelect = useCallback(() => {
    updateSelectedClientId(WALKIN_CLIENT);
    setClientSearchQuery(undefined);
  }, [setClientSearchQuery, updateSelectedClientId]);

  const onClientSelect = useCallback(
    (client: Client) => client.id && updateSelectedClientId(client.id),
    [updateSelectedClientId]
  );

  const onClientSearchUpdate = useCallback(
    (clientInput: ClientsInput) => {
      setClientSearchQuery(clientInput);
      cleanClientIdInUrl();
    },
    [cleanClientIdInUrl]
  );

  return useMemo(
    () => ({
      client,
      clearSelectedClient,
      dataClients,
      error,
      isReady,
      loading,
      onClientSearchUpdate,
      onClientSelect,
      onWalkinClientSelect,
      setClient,
      queryVariables
    }),
    [
      client,
      clearSelectedClient,
      dataClients,
      error,
      isReady,
      loading,
      onClientSearchUpdate,
      onClientSelect,
      onWalkinClientSelect,
      setClient,
      queryVariables
    ]
  );
};

export {usePurchaseClient};
