import {FormControl, FormControlProps, InputLabel, MenuItem, Select, SelectProps} from '@material-ui/core';
import React from 'react';
import styled from 'styled-components';
import {SelectInputOption} from 'types/common';
import FormHelperText from '@material-ui/core/FormHelperText';

interface Props {
  options: SelectInputOption[];
  formControlProps?: FormControlProps;
  selectProps?: SelectProps;
  helperText?: string;
}

const SelectInput: React.FC<React.PropsWithChildren<Props>> = ({
  options,
  formControlProps,
  selectProps,
  helperText
}) => {
  return (
    <StyledFormControl {...formControlProps}>
      <InputLabel>{selectProps?.label}</InputLabel>
      <StyledSelect {...selectProps} disableUnderline>
        {options.map(option => (
          <MenuItem value={option.value} key={option.value}>
            {option.label}
          </MenuItem>
        ))}
      </StyledSelect>
      {helperText && <FormHelperText children={helperText} />}
    </StyledFormControl>
  );
};

export default SelectInput;

const StyledFormControl = styled(FormControl)<{variant?: FormControlProps['variant']}>`
  .MuiInputBase-root {
    border-radius: 8px;
    font-size: inherit;
    margin: inherit;
  }
  svg {
    margin-right: ${({variant}) => (variant === 'outlined' ? '8px' : '0px')};
    transform: translateY(-1px); // very subtle alignment tweak
  }
`;

const StyledSelect = styled(Select)`
  div:first-child:focus {
    background-color: inherit;
  }
`;

/**
 * Pass in an object, get back values and labels for your Select (with optional label casing)
 * @param optionData Object/Enum/Record<string, string>
 * @param labelCase 'uppercase' | 'lowercase' | 'capitalise' - omit to just leave unchanged
 * @returns
 */
export const makeOptionsFromObject = (
  optionData: Record<string, string>,
  labelCase?: 'uppercase' | 'lowercase' | 'capitalise'
): SelectInputOption[] =>
  Object.entries(optionData).map(([value, label]) => {
    switch (labelCase) {
      case 'uppercase':
        return {value, label: label.toUpperCase()};
      case 'lowercase':
        return {value, label: label.toLowerCase()};
      case 'capitalise':
        return {value, label: label[0].toUpperCase() + label.substring(1).toLowerCase()};
      default:
        return {value, label};
    }
  });
