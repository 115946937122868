import config from 'config';
import * as Sentry from '@sentry/react';
import {CardNumberElement} from '@stripe/react-stripe-js';
import {PaymentIntent, PaymentMethod, Stripe, StripeElements} from '@stripe/stripe-js';
import {useMutation} from '@tanstack/react-query';
import {AxiosError} from 'axios';
import {useCustomerStripeId, usePaymentTypes} from 'components/Purchase/hooks';
import {
  FLOW_TYPE,
  IStripePaymentHandler,
  IStripePaymentHandlerProps,
  PAYMENT_METHOD_TYPE
} from 'components/Purchase/Stripe/types';
import {mapAmountToStripeAmount} from 'components/Purchase/utils/purchase';
import {STRIPE_COLLECT_ERROR_CANCELED_CODE} from 'constants/purchase';
import {useNotifications} from 'hooks';
import usePaymentGatewayClient from 'hooks/axios/usePaymentGatewayClient';
import useCreateTransaction from 'hooks/payments/useCreateTransaction';
import {useFeatureFlag} from 'hooks/utils/useFeatureFlag';
import {useCallback, useMemo} from 'react';
import {useRecoilValue} from 'recoil';
import {stripeLocationIdState} from 'state/atoms/purchase';
import {CardPayment, CardPaymentSteps} from 'state/atoms/purchase/types';
import {ChannelSource} from 'types/ApiModel';
import {ErrorWithMessage, TGQLCustomErrorsViaReactQuery} from 'types/errors';
import {isAxiosError} from 'utils/axios';
import {generateErrorMessageForPhorestRoundingError, isPhorestRoundingError} from 'utils/errors';
import {INITIAL_STEP_STATE, useCardPaymentFlow} from './useCardPaymentFlow';
import {useCreatePaymentIntent} from './useCreatePaymentIntent';
import {useStripeMotoFlow} from './useStripeMotoFlow';
import {useStripeTerminalFlow} from './useStripeTerminalFlow';

interface StepLogs {
  cardPaymentSteps: CardPaymentSteps;
  totalSteps: number;
  currentStepIndex: number;
  currentStep?: CardPayment;
}

interface IUpdatePaymentMethodTypes {
  paymentIntentId: string;
  paymentMethodTypes: (keyof PaymentMethod)[];
}

function useStripePaymentHandler(props: IStripePaymentHandlerProps): IStripePaymentHandler {
  const {paymentGatewayClient} = usePaymentGatewayClient();
  const {mutateAsync: getOrCreateCustomerStripeId} = useCustomerStripeId();
  const {getCreatePurchaseVariables, stripePaymentInstances} = usePaymentTypes();
  const {data: stripeLocationId} = useRecoilValue(stripeLocationIdState);
  const {mutateAsync: createPaymentIntent} = useCreatePaymentIntent();

  const {
    addCardPaymentStep,
    getCardPaymentFlowInfo,
    updateCurrentCardPaymentIntent,
    updateCurrentCardPaymentDialogState,
    updateFlowState,
    synchronizePaymentIntentsOnSuccess,
    resetCardPaymentFlow,
    resetCurrentCardPaymentState,
    updateCurrentCardPaymentType
  } = useCardPaymentFlow();
  const {isLoading: isLoadingCreateTransaction, mutateAsync: createTransaction} = useCreateTransaction();
  const {notifyError} = useNotifications();

  const isPaymentStripeTerminalEnabled = useFeatureFlag('paymentStripeTerminal');

  const createPaymentMethod: IStripePaymentHandler['createPaymentMethod'] = useCallback(
    async (stripe: Stripe, elements: StripeElements) => {
      const {error, paymentMethod} = await stripe.createPaymentMethod({
        type: 'card',
        card: elements.getElement(CardNumberElement)!
      });
      if (error) throw new Error(error.message);
      return paymentMethod?.id;
    },
    []
  );

  const {mutateAsync: updatePaymentMethodTypes} = useMutation({
    mutationFn: async ({paymentIntentId, paymentMethodTypes}: IUpdatePaymentMethodTypes) => {
      const response = await paymentGatewayClient.put(
        `/payments/${paymentIntentId}`,
        JSON.stringify({paymentMethodTypes})
      );
      const paymentIntent = response.data;
      updateCurrentCardPaymentIntent(paymentIntent);
    }
  });

  const {mutateAsync: cancelPayment} = useMutation({
    mutationFn: async (paymentIntentId: string) => {
      await paymentGatewayClient.delete(`/payments/${paymentIntentId}`);
    }
  });

  const cancelFlowPayments = useCallback(async () => {
    try {
      const {cardPaymentSteps, hasCardPaymentSteps} = getCardPaymentFlowInfo();
      if (!hasCardPaymentSteps) {
        resetCardPaymentFlow();
        return;
      }
      const cancelIntentsPromises = cardPaymentSteps.map(({paymentIntent}) => cancelPayment(paymentIntent.id));
      const results = await Promise.allSettled(cancelIntentsPromises);
      const cancellationError = getAsyncError(results);
      if (cancellationError) throw new Error(cancellationError?.reason);
    } catch (error) {
      const errorMsg = (error as ErrorWithMessage)?.message.split(':')[1];
      const errorString = `Error Cancel Transaction: ${errorMsg}`;
      console.error(errorString, error);
      notifyError(errorString);
      Sentry.captureException(new Error(errorString));
    } finally {
      resetCardPaymentFlow();
    }
  }, [cancelPayment, getCardPaymentFlowInfo, notifyError, resetCardPaymentFlow]);

  const getErrorMessage = useCallback(
    (error: ErrorWithMessage | AxiosError | TGQLCustomErrorsViaReactQuery) =>
      (error as TGQLCustomErrorsViaReactQuery)?.response?.errors?.[0].message ||
      (isAxiosError<AxiosError>(error) && error.response?.data?.message) ||
      (error as ErrorWithMessage).message ||
      'Unkown error',
    []
  );

  const handleErrors = useCallback(
    (error: ErrorWithMessage | AxiosError | TGQLCustomErrorsViaReactQuery) => {
      const {currentStep, hasCardPaymentSteps} = getCardPaymentFlowInfo();
      const message = getErrorMessage(error);
      if (message === STRIPE_COLLECT_ERROR_CANCELED_CODE) return; // Don't show error dialogs when Stripe throws Canceled

      const errorMessage = isPhorestRoundingError(message)
        ? generateErrorMessageForPhorestRoundingError(message)
        : `Error: ${message}`;

      Sentry.captureException(new Error(errorMessage), {extra: {paymentIntentId: currentStep?.paymentIntent?.id}});
      if (hasCardPaymentSteps && !!currentStep) {
        // Update state of the "real" payment steps
        updateCurrentCardPaymentDialogState({status: 'ERROR', errorMessage: message});
        return;
      }

      updateFlowState({
        // update state of "fake" payment steps when no current step
        status: 'ERROR',
        errorMessage
      });
    },
    [getCardPaymentFlowInfo, getErrorMessage, updateCurrentCardPaymentDialogState, updateFlowState]
  );

  const stripeTerminalFlow = useStripeTerminalFlow({cancelFlowPayments});
  const stripeMotoFlow = useStripeMotoFlow({cancelFlowPayments, client: props.client});

  const {runTerminalPayment, resetReaderIfConnected} = stripeTerminalFlow;
  const {runMotoPayment} = stripeMotoFlow;

  const createPaymentIntents = useCallback(
    async (result: FLOW_TYPE): Promise<PaymentIntent[]> => {
      const stripeCustomer = await getOrCreateCustomerStripeId(props.client);
      return await Promise.all(
        stripePaymentInstances.map(instance =>
          createPaymentIntent({
            stripeCustomer,
            amount: mapAmountToStripeAmount(instance.amount ?? 0),
            paymentMethodTypes: [PAYMENT_METHOD_TYPE[result]],
            metadata: {
              clinicId: props.clinicId,
              clinicName: props.clinicName,
              source: config.IS_CALL_CENTRE ? ChannelSource.call_centre : ChannelSource.clinic
            }
          })
        )
      );
    },
    [getOrCreateCustomerStripeId, props, stripePaymentInstances, createPaymentIntent]
  );

  const chooseFlow = useCallback(
    async (): Promise<FLOW_TYPE> =>
      stripeLocationId && isPaymentStripeTerminalEnabled && !props.isVirtualTerminalPayment
        ? FLOW_TYPE.TERMINAL
        : FLOW_TYPE.MOTO,
    [stripeLocationId, isPaymentStripeTerminalEnabled, props]
  );

  const initialiseFlow = useCallback(
    async (result: FLOW_TYPE) => {
      const paymentIntents = await createPaymentIntents(result);
      paymentIntents.forEach((paymentIntent, index) =>
        addCardPaymentStep({
          id: paymentIntent.id!,
          state: INITIAL_STEP_STATE,
          paymentIntent,
          type: result,
          paymentInstanceId: stripePaymentInstances[index].id
        })
      );
    },
    [addCardPaymentStep, createPaymentIntents, stripePaymentInstances]
  );

  const runPayment = useCallback(
    async ({type}: CardPayment) => (type === FLOW_TYPE.MOTO ? await runMotoPayment() : await runTerminalPayment()),
    [runMotoPayment, runTerminalPayment]
  );

  const logStepInformation = useCallback((stepLogs: StepLogs) => {
    const {cardPaymentSteps, totalSteps, currentStepIndex, currentStep} = stepLogs;
    const stepsStatuses = JSON.stringify(cardPaymentSteps.map(({paymentIntent}) => paymentIntent.status));
    console.log('fn:processFlow', totalSteps, currentStepIndex, stepsStatuses, currentStep?.type);
  }, []);

  const processFlow = useCallback(async (): Promise<void> => {
    let {cardPaymentSteps, currentStep, isReadyToCreateTransaction, totalSteps, currentStepIndex} =
      getCardPaymentFlowInfo();
    try {
      while (currentStep) {
        logStepInformation({cardPaymentSteps, totalSteps, currentStepIndex, currentStep});
        await runPayment(currentStep);
        const flowInfo = getCardPaymentFlowInfo();
        currentStep = flowInfo.currentStep;
        isReadyToCreateTransaction = flowInfo.isReadyToCreateTransaction;
        currentStepIndex = flowInfo.currentStepIndex;
      }
      if (isReadyToCreateTransaction) {
        updateFlowState({status: 'COLLECTING'});
        const paymentIntents = cardPaymentSteps.map(({paymentIntent}) => paymentIntent.id);
        await createTransaction({payload: {phorest: getCreatePurchaseVariables(), stripe: {paymentIntents}}});
        // TODO: Get list of payment intents from here when avalable from BE and use to sync with flow
        synchronizePaymentIntentsOnSuccess();
        updateFlowState({status: 'COMPLETED'});
      }
    } catch (error) {
      if (currentStep?.type === FLOW_TYPE.TERMINAL) await resetReaderIfConnected();
      handleErrors(error as ErrorWithMessage | AxiosError | TGQLCustomErrorsViaReactQuery);
    }
  }, [
    getCardPaymentFlowInfo,
    logStepInformation,
    runPayment,
    updateFlowState,
    resetReaderIfConnected,
    createTransaction,
    getCreatePurchaseVariables,
    synchronizePaymentIntentsOnSuccess,
    handleErrors
  ]);

  const restartFlow: IStripePaymentHandler['restartFlow'] = useCallback(
    () =>
      new Promise(() => {
        resetCurrentCardPaymentState();
        processFlow();
      }),
    [processFlow, resetCurrentCardPaymentState]
  );

  const switchToMotoPayment: IStripePaymentHandler['switchToMotoPayment'] = useCallback(async () => {
    updateCurrentCardPaymentDialogState({status: 'LOADING', loadingMessage: 'Switching to virtual terminal...'});
    try {
      const {paymentIntent} = getCardPaymentFlowInfo().currentStep!;
      await updatePaymentMethodTypes({
        paymentIntentId: paymentIntent.id,
        paymentMethodTypes: [PAYMENT_METHOD_TYPE.MOTO]
      });
      updateCurrentCardPaymentType(FLOW_TYPE.MOTO);
      await restartFlow();
    } catch (error) {
      handleErrors(error as ErrorWithMessage);
    }
  }, [
    getCardPaymentFlowInfo,
    handleErrors,
    restartFlow,
    updateCurrentCardPaymentDialogState,
    updateCurrentCardPaymentType,
    updatePaymentMethodTypes
  ]);

  const pay = useCallback(async () => {
    try {
      updateFlowState({status: 'CREATING'});
      const chooseResult = await chooseFlow();
      if (!chooseResult) return Promise.resolve(false);
      await initialiseFlow(chooseResult);
      updateFlowState({status: 'READY'});
      await processFlow();
      return Promise.resolve(false);
    } catch (error) {
      handleErrors(error as ErrorWithMessage | AxiosError | TGQLCustomErrorsViaReactQuery);
    }
  }, [chooseFlow, handleErrors, initialiseFlow, processFlow, updateFlowState]);

  return useMemo(
    () => ({
      cancelFlowPayments,
      createPaymentMethod,
      isLoadingCreateTransaction,
      restartFlow,
      switchToMotoPayment,
      pay,
      stripeTerminalFlow,
      stripeMotoFlow
    }),
    [
      cancelFlowPayments,
      createPaymentMethod,
      isLoadingCreateTransaction,
      restartFlow,
      switchToMotoPayment,
      pay,
      stripeTerminalFlow,
      stripeMotoFlow
    ]
  );
}

export {useStripePaymentHandler};

const PROMISE_REJECTED_STATUS = 'rejected';

export const getAsyncError = (promises: PromiseSettledResult<void>[]) =>
  promises.find(({status}) => status === PROMISE_REJECTED_STATUS) as PromiseRejectedResult;
