import {PurchaseItem} from 'components/Purchase/types';
import {PURCHASE_ATOM_PREFIX} from 'constants/purchase';
import {atom} from 'recoil';

const purchaseItemsState = atom<PurchaseItem[]>({
  key: `${PURCHASE_ATOM_PREFIX}PurchaseItemsState`,
  default: []
});

export {purchaseItemsState};
