import {Auth} from 'aws-amplify';
import axios, {AxiosError, AxiosInstance, AxiosResponse} from 'axios';
import config from 'config';
import {INTERACTIVE_HEADER_NAME} from 'constants/api';
import {DocumentNode, OperationDefinitionNode} from 'graphql';
import React, {useMemo} from 'react';
import {useRecoilValue} from 'recoil';
import {authTokenState} from 'state';
import {AxiosGQLArgs} from 'types/common';
import {getOperationUrlQueryInformation} from 'utils/graphql';

// ------ Types
type RequestFn = <Response, Variables>(query: DocumentNode, variables: Variables) => Promise<AxiosResponse<Response>>;
type GQLClient = {
  request: RequestFn;
};
type Context = {gqlClient: GQLClient};

// ------ Interceptors
const attachInterceptors = (axiosInstance: AxiosInstance): AxiosInstance => {
  axiosInstance.interceptors.response.use(
    resp => {
      if (resp.data.errors) return Promise.reject({response: {errors: resp.data.errors}}); // compatible with current parsers of gql-requests
      resp.data = resp.data?.data; // proxy gql data object into axios data to avoid `resp.data.data`
      return resp;
    },
    (error: AxiosError) => {
      if (error.response?.status === 401) return Auth.signOut(); // SignOut if un auth
      return Promise.reject(error);
    }
  );

  axiosInstance.interceptors.request.use(async config => {
    // https://github.com/aws-amplify/amplify-js/issues/2560 Auth.currentSession() will refresh the session automatically
    const session = await Auth.currentSession();
    const authorization = session.getAccessToken().getJwtToken();
    return {...config, headers: {...config.headers, authorization}};
  });

  return axiosInstance;
};

/*
 * generateGQLClient
 *
 * return a gql client, example:
 * const {data} = gqlClient.request<IResponse, IVars>(GQL_QUERY, variables)
 */
const generateGQLClient = (axiosInstance: AxiosInstance) => {
  // interceptor
  attachInterceptors(axiosInstance);
  return {
    request: <Response, Variables>(query: DocumentNode, variables: Variables) => {
      return axiosInstance.post<Response, AxiosResponse<Response>, AxiosGQLArgs<Variables>>(
        config.APP_SYNC_URL + getOperationUrlQueryInformation(query),
        {
          operationName: (query.definitions?.[0] as OperationDefinitionNode)?.name?.value ?? '',
          query: query.loc?.source.body ?? '',
          variables
        }
      );
    }
  };
};

export const GQLClientContext = React.createContext<Context>({gqlClient: generateGQLClient(axios)});
const GQLClientProvider: React.FC<React.PropsWithChildren<unknown>> = React.memo(({children}) => {
  const authorization = useRecoilValue(authTokenState);
  const instance = useMemo(
    () => ({
      gqlClient: generateGQLClient(axios.create({headers: {authorization, [INTERACTIVE_HEADER_NAME]: 'true'}}))
    }),
    [authorization]
  );
  return <GQLClientContext.Provider value={instance}>{children}</GQLClientContext.Provider>;
});

export {GQLClientProvider};
