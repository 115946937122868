import React from 'react';
import {styledTheme} from 'providers/theme';

const Types = ['ALERT', 'DISABLED'] as const;

// Note: Ignoring line because build complains about prettier on wrapping (typeof Types) in parens,
// but removes them when you save. This causes the build to break in Amplify.
// prettier-ignore
type Type = (typeof Types)[number];

interface Props {
  variant: Type;
}
const Tag: React.FC<React.PropsWithChildren<Props>> = props => {
  const style = tagTypes[props.variant];
  return <div style={{...commonStyle, ...style}} {...props}></div>;
};

const commonStyle: React.CSSProperties = {
  padding: '8px 16px',
  borderRadius: styledTheme.shape.borderRadius
};

const tagTypes: {
  [key in Type]: React.CSSProperties;
} = {
  ALERT: {color: '#ffffff', backgroundColor: '#d25551', border: ''},
  DISABLED: {color: '#222222', backgroundColor: '#ffffff', border: 'solid 1px #dddddd'}
};

export {Tag};
export default Tag;
