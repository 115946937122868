import * as Sentry from '@sentry/react';
import {useMutation, UseMutationResult} from '@tanstack/react-query';
import {CREATE_PURCHASE_TRANSACTION_SYNC} from 'data/graphql/mutations';
import {useBusinessContext, useClinicContext, useNotifications} from 'hooks';
import useAnalytics from 'hooks/analytics/useAnalytics';
import {GQLClientContext} from 'providers/gqlClient';
import {useContext} from 'react';
import {useRecoilValue} from 'recoil';
import {userState} from 'state';
import {
  CREATE_PURCHASE_TRANSACTION_SYNCMutation,
  CREATE_PURCHASE_TRANSACTION_SYNCMutationVariables
} from 'types/ApiModel';
import {TGQLCustomErrorsViaReactQuery} from 'types/errors';
import {getAnalyticsAttributes} from 'utils';
import {isPhorestRoundingError} from 'utils/errors';

type CreateTransactionResult = CREATE_PURCHASE_TRANSACTION_SYNCMutation['createPurchaseTransactionSync'];
type Output = UseMutationResult<
  CreateTransactionResult,
  unknown,
  CREATE_PURCHASE_TRANSACTION_SYNCMutationVariables,
  unknown
>;

function useCreateTransaction(): Output {
  const {gqlClient} = useContext(GQLClientContext);
  const {notifySuccess, notifyError} = useNotifications();
  const {mutateAsync: sendAnalytics} = useAnalytics();

  const {clinicId} = useClinicContext();
  const {businessId} = useBusinessContext();
  const user = useRecoilValue(userState);

  return useMutation<
    CreateTransactionResult,
    TGQLCustomErrorsViaReactQuery,
    CREATE_PURCHASE_TRANSACTION_SYNCMutationVariables,
    unknown
  >(
    async createTransactionVariables => {
      if (!createTransactionVariables) return null;
      const resp = await gqlClient.request<
        CREATE_PURCHASE_TRANSACTION_SYNCMutation,
        CREATE_PURCHASE_TRANSACTION_SYNCMutationVariables
      >(CREATE_PURCHASE_TRANSACTION_SYNC, createTransactionVariables);
      return resp.data?.createPurchaseTransactionSync;
    },
    {
      mutationKey: [USE_CREATE_PURCHASE_TRANSACTION_SYNC_KEY],
      onError: (error, variables) => {
        const errorString = `Error Create Transaction Sync: ${error?.response?.errors?.[0]?.message}`;
        const isRoundingError = isPhorestRoundingError(errorString);
        Sentry.captureException(new Error(errorString), {extra: {payload: variables}});
        console.error(errorString, error);

        // For Stripe transactions where we trigger the 1c error it gets shown elsewhere in a Stripe payment dialog via useStripePaymentHandler
        if (!isRoundingError) {
          notifyError(errorString);
        }
      },
      onSuccess: (_, variables) => {
        sendAnalytics(
          getAnalyticsAttributes({
            actionName: 'createTransactionSyncNew',
            user,
            details: {
              clinicId,
              businessId
            }
          })
        );
        notifySuccess(`Payment successfully processed`);
      }
    }
  );
}

export {useCreateTransaction};
export default useCreateTransaction;

export const USE_CREATE_PURCHASE_TRANSACTION_SYNC_KEY = 'USE_CREATE_PURCHASE_TRANSACTION_SYNC';
