import React from 'react';
import Skeleton from '@material-ui/lab/Skeleton';
import styled from 'styled-components';
import {PageContainerInnerFixedWidth} from 'components/Common/Layout/PageContainer';

export type SkeletonTuple = ['rect' | 'circle' | 'text', number | null, number | null];

/* 
  Sample props for setting your skeleton layout:
  [["rect"], ["rect", 20, 20],["rect", 10]]
  https://material-ui.com/api/skeleton/
*/
interface Props {
  shape?: SkeletonTuple[];
  padding?: number;
  fixedWidth?: boolean; // Use our common PageContainerInnerFixedWidth wrapper
}

const LoadingPlaceholder: React.FC<Props> = ({shape = ShapeDefault, padding = 12, fixedWidth = false}) =>
  fixedWidth ? (
    <PageContainerInnerFixedWidth style={{padding: padding, flex: 1}}>
      {shape.map(([variant, height, margin], index) => (
        <StyledSkeleton key={index} variant={variant} height={height || 24} style={{marginBottom: margin ?? 12}} />
      ))}
    </PageContainerInnerFixedWidth>
  ) : (
    <div style={{padding: padding, flex: 1}}>
      {shape.map(([variant, height, margin], index) => (
        <StyledSkeleton key={index} variant={variant} height={height || 24} style={{marginBottom: margin ?? 12}} />
      ))}
    </div>
  );
export default LoadingPlaceholder;

export const ShapeOne: SkeletonTuple[] = [
  ['rect', 36, 4],
  ['rect', 12, 18],
  ['rect', 36, 4],
  ['rect', 12, 18],
  ['rect', 36, 4],
  ['rect', 12, 18]
];

export const ShapeSearchResults: SkeletonTuple[] = [
  ['rect', 70, 2],
  ['rect', 70, 2],
  ['rect', 70, 2],
  ['rect', 70, 2],
  ['rect', 70, 2]
];

export const ShapeTreatmentTileSingle: SkeletonTuple[] = [['rect', 72, 0]];

export const ShapeTableRows: SkeletonTuple[] = [
  ['rect', 38, 5],
  ['rect', 38, 5],
  ['rect', 38, 5],
  ['rect', 38, 5],
  ['rect', 38, 5]
];

export const ShapeDefault: SkeletonTuple[] = [
  ['rect', 10, 10],
  ['rect', 80, 10],
  ['rect', 20, 10],
  ['rect', 20, 10]
];

export const ShapeGenericPage: SkeletonTuple[] = [
  ['rect', 74, 16],
  ['rect', 420, 24],
  ['rect', 150, 24],
  ['rect', 150, 24],
  ['rect', 150, 24]
];

export const ShapeSmallList: SkeletonTuple[] = [
  ['rect', 14, 4],
  ['rect', 14, 4],
  ['rect', 14, 4]
];

const StyledSkeleton = styled(Skeleton)`
  border-radius: 3px;
`;
