import {PaymentMethod, Stripe, StripeElements} from '@stripe/stripe-js';
import {Reader} from '@stripe/terminal-js';
import {StripeCustomerResult} from 'components/Purchase/hooks/Stripe/useCustomerStripeId';
import {ClientModelForPurchase} from 'components/Purchase/types';
import {STRIPE_TEST_CARD_NUMBER} from 'constants/payments';
import {ChannelSource, CREATE_PURCHASEMutation} from 'types/ApiModel';
import {IUseStripeMotoFlowOutput, StripeAndElementsInput} from '../hooks/Stripe/useStripeMotoFlow';
import {IUseStripeTerminalFlowOutput} from '../hooks/Stripe/useStripeTerminalFlow';

export enum FLOW_TYPE {
  MOTO = 'MOTO',
  TERMINAL = 'TERMINAL',
  LINK = 'LINK'
}

export const PAYMENT_METHOD_TYPE: Record<FLOW_TYPE, keyof PaymentMethod> = {
  MOTO: 'card',
  TERMINAL: 'card_present',
  LINK: 'card'
};

export type DIALOG_STATUS = 'CHOOSE_READER' | 'COLLECTING' | 'CANCELLING' | 'ERROR' | 'LOADING' | 'SUCCESS';
export type StripeDialogInfoVariants = 'loading' | 'success' | 'error' | 'card' | 'dialpad';
export type TStripeDialogContent = IStripeDialogInfo | undefined;
export type IPhorestPaymentHandler = () => Promise<CREATE_PURCHASEMutation['createPurchase']>;

export interface IStripeDialogState {
  amount?: number;
  clientSecret?: string;
  errorMessage?: string;
  infoMessage?: string;
  loadingMessage?: string;
  onCancel?: () => Promise<void>;
  status?: DIALOG_STATUS;
}
export interface IStripeTerminalDialogState extends IStripeDialogState {
  locationId?: string;
  onReaderSelect?: (reader: Reader) => void;
  onCardNumberSelect?: (testCardNumber: STRIPE_TEST_CARD_NUMBER) => void;
  readers?: Reader[];
  reader?: Reader;
}
export interface IStripeMotoDialogState extends IStripeDialogState {
  client?: ClientModelForPurchase;
  onPayClick?: (input: StripeAndElementsInput) => Promise<void>;
}

export interface IStripeLinkDialogState extends IStripeDialogState {
  paymentLinkId?: string;
  onSuccess?: () => Promise<void>;
}

export interface LinkModel {
  id: string;
  url: string;
  status: 'cancelled' | 'pending' | 'success';
  active: boolean;
}

export interface IStripeDialogInfo {
  dialogTitle: string;
  variant: StripeDialogInfoVariants;
  text: string;
  subtext?: string;
}

export interface PaymentIntentMetadata {
  clinicId?: string | null;
  clinicName?: string | null;
  purchaseId?: string | null;
  source: ChannelSource;
  transactionNumber?: string | null;
}

export interface IStripePaymentHandlerProps {
  clinicId: string;
  clinicName: string | null;
  client: ClientModelForPurchase;
  redirectToPreviousSection: () => void;
  isVirtualTerminalPayment: boolean;
}

export interface IStripePaymentHandler {
  cancelFlowPayments: () => Promise<void>;
  clientSecret?: string;
  isLoadingCreateTransaction: boolean;
  restartFlow: () => Promise<void>;
  pay: () => Promise<boolean | undefined>;
  createPaymentMethod: (stripe: Stripe, elements: StripeElements) => Promise<string>;
  stripeTerminalFlow: IUseStripeTerminalFlowOutput;
  stripeMotoFlow: IUseStripeMotoFlowOutput;
  switchToMotoPayment: () => Promise<void>;
}

export interface CreatePaymentIntentProps {
  stripeCustomer?: StripeCustomerResult;
  amount: number;
  captureMethod?: string;
  confirm?: true;
  metadata?: PaymentIntentMetadata;
  paymentMethod?: string;
  paymentMethodTypes: string[];
  paymentMethodOptions?: {card: {moto: boolean}};
}

export namespace MotoDialog {
  export interface ElementError {
    cardNumber?: string;
    cardExpiry?: string;
    cardCvc?: string;
  }

  export interface ElementComplete {
    cardNumber?: boolean;
    cardExpiry?: boolean;
    cardCvc?: boolean;
  }

  export interface FormState {
    elementError: ElementError;
    elementComplete: ElementComplete;
  }
}
