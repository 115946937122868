import {WALKIN_CLIENT} from 'constants/purchase';
import {CtxStorage} from 'utils/storage/contextStorage';
import {KLARNA_CLINICS, KLARNA_TRIAL_PERIOD} from 'config';

function setKlarnaAvailability() {

var params = new URLSearchParams(window.location.search);
var clientId = params.get('clientId');

var klarnaClinic = (KLARNA_CLINICS.includes(CtxStorage.clinicId));
var klarnaTrialPeriod = KLARNA_TRIAL_PERIOD; 
var walkIn = ((clientId == WALKIN_CLIENT) || (clientId == null));

// if KLARNA_TRIAL_PERIOD is true and clinic is a trial clinic or client is not a walkin and set, allow Klarna

if (walkIn)
 {
    return false;
 }
 else if (klarnaTrialPeriod && klarnaClinic && !walkIn )
 {
    return true;
 }
else if (!klarnaTrialPeriod && !walkIn )
 {
    return true;
 }
}

export {setKlarnaAvailability}