import {EmptyState} from '@therapie-ui';
import {PageContainer} from 'components/Common/Layout/PageContainer';
import {AppSection} from 'constants/permissions';
import {SIZES} from 'constants/sizes';
import usePermission from 'hooks/permission/usePermission';
import React from 'react';
import {useLocation} from 'react-router-dom';

interface Props {
  permission: AppSection;
}

const RouteHasPermission: React.FC<React.PropsWithChildren<Props>> = ({permission, children}) => {
  const hasPermission = usePermission();
  const {pathname} = useLocation();
  if (hasPermission(permission)) return <>{children}</>;

  return (
    <PageContainer>
      <EmptyState
        icon="lock"
        title={`You do not have the permission to access this section`}
        details={pathname}
        showGoHomeOption
        size={SIZES.XXL}
      />
    </PageContainer>
  );
};

export default RouteHasPermission;
