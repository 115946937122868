import PersonAddOutlinedIcon from '@material-ui/icons/PersonAddOutlined';
import {Button, EmptyState, LoadingPlaceholder} from '@therapie-ui';
import {ShapeGenericPage} from '@therapie-ui/LoadingPlaceholder/LoadingPlaceholder';
import {ROUTES} from 'constants/routes';
import {SIZES} from 'constants/sizes';
import {useBusinessContext, useClient, useUrlParams} from 'hooks';
import {useAppNavigation} from 'hooks/navigation/useAppNavigation';
import React, {useMemo} from 'react';
import styled from 'styled-components';
import CustomerView from './CustomerView';

interface Props {
  clientId?: string | null;
  hasAttemptedSearch: boolean;
}

const CustomerViewContainer: React.FC<React.PropsWithChildren<Props>> = ({clientId, hasAttemptedSearch}) => {
  const {navigate} = useAppNavigation();
  const {businessId} = useBusinessContext();
  const urlParams = useUrlParams();
  const clientQuery = useMemo(() => ({clientId: clientId || '', businessId}), [clientId, businessId]);
  const {data: client, isInitialLoading: loading, error} = useClient(clientQuery);

  if (loading) return <LoadingPlaceholder shape={ShapeGenericPage} />;
  if (error) return <EmptyState icon="warning" title="Something went wrong." size={SIZES.L} fillHeight={true} />;
  if (client) return <CustomerView client={client} />;
  if (hasAttemptedSearch && !clientId)
    return (
      <SpacedEmptyState
        icon="search"
        title="No Client Found."
        actionButton={
          <Button
            children="Create Client"
            variant="outlined"
            onClick={() => navigate({toRoute: ROUTES.CLIENTS_CREATE_CLIENT, urlSearchParams: urlParams})}
            iconLeading={<PersonAddOutlinedIcon />}
          />
        }
        size={SIZES.L}
      />
    );
  return <SpacedEmptyState icon="face" title="Select a task or search for a client" size={SIZES.L} />;
};

export default CustomerViewContainer;

const SpacedEmptyState = styled(EmptyState)`
  margin: ${({theme}) => theme.spacing(3, 0, 7)};
`;
