import {TableRow as MuiTableRow, TableRowProps as MuiTableRowProps} from '@material-ui/core';
import React from 'react';
import styled, {keyframes} from 'styled-components';

interface TableHeadProps extends MuiTableRowProps {
  animateOnEnter?: boolean;
}

const TableRow: React.FC<React.PropsWithChildren<TableHeadProps>> = props =>
  props.animateOnEnter ? <AnimatedRow {...props} /> : <MuiTableRow {...props} />;

export default TableRow;
export {TableRow};

const enterAnimation = keyframes`
   0% { opacity: 0; background: #c2c2c2 }
  100% { opacity: 1; background: white }
`;

const AnimatedRow = styled(MuiTableRow)`
  animation-name: ${enterAnimation};
  animation-duration: 0.8s;
  animation-iteration-count: 1;
`;
