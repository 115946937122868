import {FormControl, InputLabel, TextField} from '@material-ui/core';
import {Autocomplete} from '@material-ui/lab';
import COLORS from 'constants/colors';
import React from 'react';
import styled from 'styled-components';
import {Option} from 'types/common';
import {invertedSelectionMixin} from 'utils/css/mixins';

interface Props {
  clinicOptions: Option[];
  clinicValue: Option;
  onChangeClinic: (clinicId: string) => void;
}

const ClinicSelect: React.FC<React.PropsWithChildren<Props>> = React.memo(
  ({clinicOptions, clinicValue, onChangeClinic}) => (
    <ClinicSelectControl>
      <Autocomplete
        id="clinic-select"
        disableClearable
        disablePortal
        blurOnSelect
        value={clinicValue}
        options={clinicOptions}
        getOptionLabel={option => option.label || ''}
        onChange={(_, clinic) => onChangeClinic(clinic.id ?? '')}
        getOptionSelected={({id: optionId}, {id: valueId}) => optionId === valueId}
        renderInput={params => (
          <AutocompleteTextField {...params} margin="normal" data-value={clinicValue.label}>
            <InputLabel>Clinic</InputLabel>
          </AutocompleteTextField>
        )}
      />
    </ClinicSelectControl>
  )
);

export {ClinicSelect};

const POPUP_ANCHOR_TOP = 34;

const ClinicSelectControl = styled(FormControl)`
  .MuiFormControl-root {
    border-radius: ${({theme}) => theme.shape.borderRadius}px;
    margin: 0;
    padding: ${({theme}) => theme.spacing(0, 1, 0, 1.5)};
    input {
      ${invertedSelectionMixin}
    }

    // This is a little UI trick for setting the  width of the
    // autocomplete to match the currently selected clinic.
    // We duplicate the clinic name into data-value and then use
    // this pseudo element to take its content, which will stretch
    // the formControl to accommodate it.
    ::after {
      content: attr(data-value);
      white-space: pre-wrap;
      visibility: hidden;
      height: 0;
      padding-right: ${({theme}) => theme.spacing(4.5)};
    }
  }

  .MuiAutocomplete-root {
    border-radius: ${({theme}) => theme.shape.borderRadius * 1.5}px;
    box-shadow: 0 0 0 3px transparent;
    transition: box-shadow 150ms ease;

    &.Mui-focused {
      background: ${COLORS.therapiePurpleD10};
      box-shadow: 0 0 0 3px ${COLORS.therapiePurpleL20};
    }
    .MuiAutocomplete-popupIndicator {
      margin: 0;
    }
  }

  // This just lets us have an independent width for the displayed clinic options
  // popup intead of having it match the length of the clinic name.
  .MuiAutocomplete-popper {
    min-width: 300px;
    max-width: 80vw;
    top: ${POPUP_ANCHOR_TOP}px;
  }

  // The little up/down arrow
  svg {
    fill: ${COLORS.therapiePurpleL90};
  }

  // Hide MUI underline on this variant of Selects
  .MuiInput-underline:before,
  .MuiInput-underline:after {
    display: none;
  }
`;

const AutocompleteTextField = styled(TextField)`
  border-radius: ${({theme}) => theme.shape.borderRadius * 2}px;
  transition: background 200ms ease;
  cursor: pointer;
  .MuiInputBase-input {
    color: ${COLORS.therapiePurpleL90};
  }
  &:hover {
    background: ${COLORS.therapiePurple};
  }
`;
