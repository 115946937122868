import JiraInlineEdit from '@atlaskit/inline-edit';
import {TextFieldProps} from '@material-ui/core';
import {Button} from '@therapie-ui/Buttons/Button';
import React, {useCallback, useId, useState} from 'react';
import styled, {css} from 'styled-components';
import {appearSlideDown} from 'utils/css/animations';
import CheckIcon from '@material-ui/icons/Check';
import ClearIcon from '@material-ui/icons/Clear';

interface Props {
  InputComponent: React.FC<React.PropsWithChildren<TextFieldProps>>;
  inputProps?: TextFieldProps;
  onChangeValue: (value: string) => void;
  value?: string | number;
  dataTestId?: string;
}

const InlineEdit: React.FC<React.PropsWithChildren<Props>> = ({
  inputProps,
  InputComponent,
  value,
  dataTestId,
  onChangeValue
}) => {
  const [isEditing, setIsEditing] = useState(false);
  const inputId = useId();

  const getButtonWrapperOffsets = useCallback(() => {
    const wrapperElement = isEditing
      ? document.getElementById(INPUT_EDIT_VIEW_PREFIX_ID + inputId)?.parentElement
      : null;
    return {
      height: wrapperElement?.offsetHeight ?? 0,
      width: wrapperElement?.offsetWidth ?? 0
    };
  }, [inputId, isEditing]);

  const confirmChange = useCallback(
    (value: string) => {
      onChangeValue(value);
      setIsEditing(false);
    },
    [onChangeValue]
  );

  return (
    <AdditionalStyle hideNativeJiraReadViewButton={!isEditing || !!inputProps?.disabled}>
      <JiraInlineEdit
        defaultValue={value}
        isEditing={!inputProps?.disabled && isEditing}
        onEdit={() => !inputProps?.disabled && setIsEditing(true)}
        onCancel={() => setIsEditing(false)}
        onConfirm={() => setIsEditing(false)} // This onConfirm is the callback fired on blur and on Jira confirm action button we're not using
        editView={fieldProps => (
          <>
            <InputComponent
              {...inputProps}
              id={INPUT_EDIT_VIEW_PREFIX_ID + inputId}
              inputProps={{step: 'any'}} // Prevents warnings when input is a float with decimals
              value={fieldProps.value}
              onKeyDown={({code}) => code === 'Enter' && confirmChange(fieldProps.value)}
              onChange={value => fieldProps.onChange(value as React.FormEvent<HTMLInputElement>)}
              autoFocus
            />
            <ButtonWrapper {...getButtonWrapperOffsets()}>
              <Button size="small" icon={<CheckIcon />} onClick={() => confirmChange(fieldProps.value)} />
              <Button size="small" icon={<ClearIcon />} onClick={() => setIsEditing(false)} />
            </ButtonWrapper>
          </>
        )}
        readView={() => (
          <InputComponent
            {...inputProps}
            data-test-id={dataTestId}
            value={value || 'Click to enter a value'}
            onFocus={() => !inputProps?.disabled && setIsEditing(true)}
          />
        )}
        hideActionButtons
      />
    </AdditionalStyle>
  );
};

export default InlineEdit;

const INPUT_EDIT_VIEW_PREFIX_ID = 'edit-view-input';

const AdditionalStyle = styled.span<{hideNativeJiraReadViewButton: boolean}>`
  form > div {
    margin: 0;
    position: relative;
    > div > div {
      border: none;
      &:focus {
        border: none;
      }
    }
  }

  ${({hideNativeJiraReadViewButton}) =>
    hideNativeJiraReadViewButton &&
    css`
      button {
        display: none;
      }
    `}
`;
const ButtonWrapper = styled.div<{height?: number; width?: number}>`
  position: absolute;
  width: ${({width}) => width}px;
  top: ${({height}) => height}px;
  display: flex;
  justify-content: end;
  z-index: 1;
  button {
    margin: ${({theme}) => theme.spacing(1, 0, 0, 1)};
    box-shadow: 1px 1px 4px 0 #aaa;
  }

  // Animation related
  animation: ${appearSlideDown} 150ms ease-in-out;
  animation-fill-mode: forwards;
`;
