import {FormikContextType, useFormikContext} from 'formik';
import {useMemo} from 'react';
import {PurchaseItemInput, PurchasePaymentInput} from 'types/ApiModel';
import {notNil} from 'utils/general';
import {IPurchaseFormValidationSchema} from './usePurchaseValidation';

interface Output extends FormikContextType<IPurchaseFormValidationSchema> {
  purchaseItemsErrors?: TFormikErrorsNormalised;
  paymentTypeInstancesErrors?: TFormikErrorsNormalised;
  firstErrorMessage?: string;
}

/**
 * usePurchaseFormikContext
 * Wrapper around useFormikContext for any additional transforms we might want on the data
 */
function usePurchaseFormikContext(): Output {
  const formikContext = useFormikContext<IPurchaseFormValidationSchema>();
  const {errors} = formikContext;

  /**
  Note:
  -----
  With Yup, this errors.payments value can either be a string, an array of strings, or an array of objects; with string values relating to errors in the payment item..
  If it's a general error where we have no payment items added, then it'll be a string saying "there's no items", but when we have items and one of those has an error, this would be an array with that error at the index matching the item.
  **/

  const flattenedPurchaseItemsErrors = useMemo(
    () => flattenFormikErrorRecords(errors.items as unknown as TFormikErrorsProxy),
    [errors.items]
  );

  const flattenedPaymentInstancesErrors = useMemo(
    () => flattenFormikErrorRecords(errors.payments as unknown as TFormikErrorsProxy),
    [errors.payments]
  );

  const firstErrorMessage = useMemo(
    () =>
      [
        errors.clientId,
        errors.staffId,
        errors.outstandingBalance,
        ...(flattenedPurchaseItemsErrors ? flattenedPurchaseItemsErrors : []),
        ...(flattenedPaymentInstancesErrors ? flattenedPaymentInstancesErrors : [])
      ].filter(notNil)[0],
    [errors, flattenedPurchaseItemsErrors, flattenedPaymentInstancesErrors]
  );

  return {
    ...formikContext,
    purchaseItemsErrors: flattenedPurchaseItemsErrors,
    paymentTypeInstancesErrors: flattenedPaymentInstancesErrors,
    firstErrorMessage
  };
}
export {usePurchaseFormikContext};

type TErrorKeys = Partial<keyof PurchasePaymentInput | keyof PurchaseItemInput>;
type TFormikErrorsProxy = (Record<TErrorKeys, string> | string | undefined)[]; // What FormikErrors actually gives us for this use case
type TFormikErrorsNormalised = (string | undefined)[];

// Convert Formiks polymorphic error type into a simple array.
// FormikErrors can be nested objects as well as strings.
// In this case they could be any key/value from PurchaseItemInput or PurchasePaymentInput.
function flattenFormikErrorRecords(formikErrors?: TFormikErrorsProxy): TFormikErrorsNormalised | undefined {
  if (!formikErrors) return;
  if (typeof formikErrors === 'string') return [formikErrors];
  if (Array.isArray(formikErrors))
    // Important: We need to preserve the indexes here with undefined elements so that we keep consistent with what Formik returns.
    // The indexes need to map 1:1 to the elements that we're validating in the UI so that the errors align with their respective items.
    return formikErrors.map(errorStringOrObject => {
      // Simple errored item string e.g. "This voucher has insufficient funds for this amount."
      if (typeof errorStringOrObject === 'string') return errorStringOrObject;

      // Item with 1..N errored properties - Joins them into a single string.
      // It's an edge case that this will be the type of error we get, it would indicate something wrong with our own data rather than the user input.
      // e.g. "payments[2].paymentTypeId must be a `string` type, but the final value was: `null`.
      if (typeof errorStringOrObject === 'object') return Object.values(errorStringOrObject).join('. ');

      return undefined;
    });
}
