import {atom} from 'recoil';
import {BranchCourseModel} from 'types/DerivedApiModel';

interface AtomState {
  loading: boolean;
  data: BranchCourseModel[];
  error?: unknown;
}

const clinicCoursesState = atom<AtomState>({key: 'clinicCoursesState', default: {loading: false, data: []}});

export {clinicCoursesState};
