import {captureException} from '@sentry/react';
import {GET_ADMIN_SERVICE} from 'data/graphql/queries/adminServices';
import {GQLClientContext} from 'providers/gqlClient';
import {useContext} from 'react';
import {useQuery, UseQueryResult} from '@tanstack/react-query';
import {GET_ADMIN_SERVICEQuery, GET_ADMIN_SERVICEQueryVariables} from 'types/ApiModel';
import {TGQLCustomErrorsViaReactQuery} from 'types/errors';

export type IUseAdminServiceOutput = UseQueryResult<
  GET_ADMIN_SERVICEQuery['getAdminService'],
  TGQLCustomErrorsViaReactQuery
>;

function useAdminService(variables: GET_ADMIN_SERVICEQueryVariables): IUseAdminServiceOutput {
  const {gqlClient} = useContext(GQLClientContext);
  const {businessId, id} = variables;

  return useQuery<GET_ADMIN_SERVICEQuery['getAdminService'], TGQLCustomErrorsViaReactQuery>(
    [GET_ADMIN_SERVICE_KEY, variables],
    async () => {
      const resp = await gqlClient.request<GET_ADMIN_SERVICEQuery, GET_ADMIN_SERVICEQueryVariables>(
        GET_ADMIN_SERVICE,
        variables
      );
      return resp.data?.getAdminService ?? null;
    },
    {
      enabled: !!(id && businessId),
      onError: errors => {
        const errorString = errors?.response?.errors?.[0]?.message ?? 'Error getting the service';
        captureException(new Error(errorString));
      }
    }
  );
}

export {useAdminService};

export const GET_ADMIN_SERVICE_KEY = 'GET_ADMIN_SERVICE';
