import {BUSINESS_UIDS} from 'constants/business';
import {useEffect, useState} from 'react';
import {GET_CLIENTSQueryVariables} from 'types/ApiModel';
import {mergeClientsQueryProps} from 'utils/query';

function useQueryVariablesWithPhonePrefixed(
  queryVariables?: GET_CLIENTSQueryVariables
): GET_CLIENTSQueryVariables | undefined {
  const [state, setState] = useState<GET_CLIENTSQueryVariables | undefined>(
    mergeClientsQueryProps({
      ...queryVariables?.query,
      phone: superAIAppendPrefixAndClean(queryVariables?.query?.businessId, queryVariables?.query?.phone)
    })
  );

  useEffect(() => {
    setState(
      mergeClientsQueryProps({
        ...queryVariables?.query,
        phone: superAIAppendPrefixAndClean(queryVariables?.query?.businessId, queryVariables?.query?.phone)
      })
    );
  }, [queryVariables]);

  return state;
}

export default useQueryVariablesWithPhonePrefixed;
export {useQueryVariablesWithPhonePrefixed};

const superAIAppendPrefixAndClean = (businessId?: string, phone?: string | null): string =>
  (!phone
    ? ''
    : !phone?.startsWith('0')
    ? phone
    : businessId === BUSINESS_UIDS.uk
    ? phone.replace('0', '44')
    : phone.replace('0', '353')
  ).replace(/[^0-9]/g, '');
