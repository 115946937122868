import {useMemo} from 'react';
import {useRecoilValue} from 'recoil';
import {clinicListState} from 'state/atoms/clinic';
import {Option} from 'types/common';

export interface UseClinicFilterOptionsOutput {
  clinicOptions: Option[];
}

function useClinicFilterOptions(): UseClinicFilterOptionsOutput {
  const dataBranches = useRecoilValue(clinicListState);

  return useMemo(
    () => ({clinicOptions: dataBranches?.map(clinic => ({id: clinic.id, label: clinic.name} as Option)) ?? []}),
    [dataBranches]
  );
}

export {useClinicFilterOptions};
export default useClinicFilterOptions;
