import React, {useEffect} from 'react';
import * as serviceWorkerRegistration from '../serviceWorkerRegistration';

const ServiceWorkerProvider: React.FC<React.PropsWithChildren<unknown>> = ({children}) => {
  useEffect(() => {
    console.log('serviceWorkerRegistration.register');
    serviceWorkerRegistration.register();
  }, []);

  return <>{children}</>;
};

export {ServiceWorkerProvider};
