import {PaymentIntent} from '@stripe/stripe-js';
import config from '../config';

export enum PAYMENT_TYPES {
  CASH = 'CASH',
  VOUCHER = 'VOUCHER',
  OTHER = 'OTHER'
}

export enum PAYMENT_TYPES_NAMES {
  CASH = 'CASH',
  CARD_PAYMENT = 'CARD PAYMENT',
  VIRTUAL_TERMINAL = 'VIRTUAL TERMINAL',
  VOUCHER = 'VOUCHER',
  KLARNA = 'KLARNA'
}

export const DEFAULT_PAYMENT_TYPES: PAYMENT_TYPES_NAMES[] = [
  PAYMENT_TYPES_NAMES.CARD_PAYMENT,
  PAYMENT_TYPES_NAMES.VIRTUAL_TERMINAL,
  PAYMENT_TYPES_NAMES.VOUCHER
];

export enum PAYMENT_TYPE_CODES {
  BCH = 'BCH',
  BT = 'BT',
  C = 'C',
  CP = 'CP',
  FL = 'FL',
  NPP = 'NPP',
  NUA = 'NUA',
  TGC = 'TGC',
  VI = 'VI',
  KL = 'KL'
}

// V for EU Voucher, GC for Gift Card
export const VOUCHER_PAYMENT_TYPE_CODE = config.VOUCHER_PAYMENT_TYPE_CODE as 'V' | 'GC';
export const VIRTUAL_TERMINAL_TYPE_CODE = PAYMENT_TYPE_CODES.VI;
export const CARD_PAYMENT_TYPE_CODE = PAYMENT_TYPE_CODES.CP;
export const KLARNA_PAYMENT_TYPE_CODE = PAYMENT_TYPE_CODES.KL;

export enum PURCHASE_ITEM_TYPE {
  TREATMENT = 'TREATMENT',
  COURSE = 'COURSE',
  PRODUCT = 'PRODUCT',
  VOUCHER = 'VOUCHER'
}

export enum PURCHASE_DISCOUNT_TYPE {
  NONE = 'NONE',
  AMOUNT = 'AMOUNT',
  PERCENT = 'PERCENT'
}

export const PENDING_CARD_FLOW_STATUSES: PaymentIntent.Status[] = [
  'processing',
  'requires_action',
  'requires_confirmation',
  'requires_payment_method'
];
export const REQUIRES_CAPTURE_STATUS: PaymentIntent.Status = 'requires_capture';
export const SUCCEEDED_STATUS: PaymentIntent.Status = 'succeeded';

// https://stripe.com/docs/terminal/references/testing
export enum STRIPE_TEST_CARD_NUMBER {
  SUCCESS = '4242424242424242',
  ERROR = '4000000000000002'
}

export const TERMINAL_PROCESSING_PAYMENT_STATUS = 'processing';

export const PHOREST_ROUNDING_AMOUNT_ERROR_STRING =
  'Insufficient funds in this purchase for fulfilling payment of items. Please add at least '; // We only want to use a snippet of this error message as the amount might change on the scenario

export const PHOREST_VOUCHER_ERROR_STRING =
  'Voucher payment amount total cannot exceed purchase total for discounted vouchers. Please reduce the voucher payment amount.';
