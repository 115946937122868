import {PAYMENT_TYPES_NAMES} from 'constants/payments';
import {WALKIN_CLIENT} from 'constants/purchase';
import {QUERY_KEYS} from 'constants/query';
import {ROUTES} from 'constants/routes';
import {useUrlParams} from 'hooks';
import useClinicContext from 'hooks/clinic/useClinicContext';
import {useAppNavigation} from 'hooks/navigation/useAppNavigation';
import useCreateTransaction from 'hooks/payments/useCreateTransaction';
import {useFeatureFlag} from 'hooks/utils/useFeatureFlag';
import {useCallback, useMemo} from 'react';
import {useRecoilValue} from 'recoil';
import {createPurchaseSelector, paymentTypesPayloadSelector} from 'state/selectors/purchase';
import {StateSetter} from 'types/common';
import {TGQLCustomErrorsViaReactQuery} from 'types/errors';
import {generateErrorMessageForPhorestRoundingError, isPhorestRoundingError} from 'utils/errors';
import {windowIsIFramed} from 'utils/window';
import {IStripePaymentHandler} from '../Stripe/types';
import {ClientModelForPurchase} from '../types';
import {checkPurchaseIsAllowed} from '../utils/purchase';
import {useStripePaymentHandler} from './Stripe/useStripePaymentHandler';
import {IUsePaymentAmountsCalculations} from './usePaymentAmountsCalculations';

interface Input extends Pick<IUsePaymentAmountsCalculations, 'setAmountOfChangeDueFromPhorestApi'> {
  client?: ClientModelForPurchase;
  setRoundingErrorDialogMessage: StateSetter<string | undefined>;
}

interface Output {
  isLoadingCreatePurchase: boolean;
  payHandler: () => Promise<boolean | void>;
  redirectToPreviousSection: () => void;
  stripePaymentHandler: IStripePaymentHandler;
}

function usePaymentFlow({client, setAmountOfChangeDueFromPhorestApi, setRoundingErrorDialogMessage}: Input): Output {
  const {clinic, clinicId} = useClinicContext();
  const {navigate} = useAppNavigation();
  const urlParams = useUrlParams();
  const appointmentDateString = urlParams.get(QUERY_KEYS.APPOINTMENT_DATE) ?? '';
  const {CARD_PAYMENT, VIRTUAL_TERMINAL} = PAYMENT_TYPES_NAMES;

  // Feature Flag
  const isPurchaseForProdClinicEnabled = useFeatureFlag('purchaseForProdClinic');
  const isPurchaseForTestClinicEnabled = useFeatureFlag('purchaseForTestClinic');
  const isPaymentStripeLinkEnabled = useFeatureFlag('paymentStripeLink');
  const isPaymentStripeTerminalEnabled = useFeatureFlag('paymentStripeTerminal');
  const isPaymentStripeMotoFormEnabled = useFeatureFlag('paymentStripeMotoForm');
  const isAnyStripeFeatureFlagEnabled =
    isPaymentStripeLinkEnabled || isPaymentStripeTerminalEnabled || isPaymentStripeMotoFormEnabled;
  // TODO: no need to reset because we redirect(destroy) every time, implement resetId once we allow multiple transaction in same page
  const createPurchaseVariables = useRecoilValue(createPurchaseSelector);
  const paymentTypesPayload = useRecoilValue(paymentTypesPayloadSelector);

  // Custom hooks

  const {mutateAsync: createTransaction, isLoading: isLoadingCreateTransaction} = useCreateTransaction();

  // Memos
  const isUsingStripePaymentMethod = useMemo(
    () =>
      paymentTypesPayload.some(payment =>
        [CARD_PAYMENT, VIRTUAL_TERMINAL].includes(payment.name?.toUpperCase() as PAYMENT_TYPES_NAMES)
      ),
    [paymentTypesPayload, CARD_PAYMENT, VIRTUAL_TERMINAL]
  );

  const isVirtualTerminalPayment = useMemo(
    () => paymentTypesPayload.some(payment => payment.name?.toUpperCase() === VIRTUAL_TERMINAL),
    [paymentTypesPayload, VIRTUAL_TERMINAL]
  );

  const isStripePaymentEnabled = isUsingStripePaymentMethod && isAnyStripeFeatureFlagEnabled;

  const redirectToPreviousSection = useCallback(() => {
    if (!windowIsIFramed || client === WALKIN_CLIENT)
      return navigate({
        toRoute: ROUTES.DIARY,
        urlSearchParams: new URLSearchParams({[QUERY_KEYS.DATE_KEY]: appointmentDateString})
      });

    if (client)
      navigate({
        toRoute: ROUTES.CLIENTS,
        urlSearchParams: new URLSearchParams({
          [QUERY_KEYS.SHOW_ONLY_CLIENT_VIEW]: 'true',
          [QUERY_KEYS.CLIENT_ID]: client.id ?? ''
        })
      });
  }, [appointmentDateString, navigate, client]);

  const stripePaymentHandler = useStripePaymentHandler({
    clinicId,
    clinicName: clinic?.name ?? '',
    client,
    redirectToPreviousSection,
    isVirtualTerminalPayment
  });

  const payHandler = useCallback(async () => {
    checkPurchaseIsAllowed({clinicId, isPurchaseForProdClinicEnabled, isPurchaseForTestClinicEnabled});
    try {
      if (isStripePaymentEnabled) {
        const result = await stripePaymentHandler.pay();
        if (result === true) redirectToPreviousSection();
        return;
      }
      const response = await createTransaction({
        payload: {phorest: createPurchaseVariables, stripe: {paymentIntents: []}}
      });
      const changeAmount = response?.phorest?.response?.details?.changeAmount;
      changeAmount ? setAmountOfChangeDueFromPhorestApi(changeAmount) : redirectToPreviousSection();
    } catch (error) {
      const errorMessage = (error as TGQLCustomErrorsViaReactQuery)?.response?.errors?.[0]?.message;
      if (isPhorestRoundingError(errorMessage))
        setRoundingErrorDialogMessage(generateErrorMessageForPhorestRoundingError(errorMessage));
    }
  }, [
    clinicId,
    isPurchaseForProdClinicEnabled,
    isPurchaseForTestClinicEnabled,
    isStripePaymentEnabled,
    createTransaction,
    createPurchaseVariables,
    setAmountOfChangeDueFromPhorestApi,
    redirectToPreviousSection,
    stripePaymentHandler,
    setRoundingErrorDialogMessage
  ]);

  return useMemo(
    () => ({
      isLoadingCreatePurchase: isLoadingCreateTransaction,
      payHandler,
      redirectToPreviousSection,
      stripePaymentHandler
    }),
    [isLoadingCreateTransaction, payHandler, redirectToPreviousSection, stripePaymentHandler]
  );
}

export {usePaymentFlow};
