import {useQuery} from '@tanstack/react-query';
import {RQ_CACHE_TIMES} from 'constants/reactquery';
import {GET_APPOINTMENT_AVAILABILITY} from 'data/graphql/queries';
import {parseISO} from 'date-fns';
import {GQLClientContext} from 'providers/gqlClient';
import {useContext, useMemo} from 'react';
import {
  ClientServiceSelectionsInput,
  GET_APPOINTMENT_AVAILABILITYQuery,
  GET_APPOINTMENT_AVAILABILITYQueryVariables
} from 'types/ApiModel';
import {AvailableAppointmentModel} from 'types/DerivedApiModel';
import {TGQLCustomErrorsViaReactQuery} from 'types/errors';
import {formatDate, getDatesForUpcomingDays} from 'utils';

export interface SlotDayMap {
  [key: string]: {
    [key: string]: AvailableAppointmentModel;
  };
}
export interface IUseAvailableAppointmentsOutput {
  availableDays: string[];
  availableSlotsPerDay: Record<string, number>;
  isLoading: boolean;
  error?: unknown;
  filteredAvailability: SlotDayMap;
  hasAvailabilityAfterFiltering: boolean;
  firstAvailableSlot?: AvailableAppointmentModel;
  firstAvailableSlotAnyStaff?: AvailableAppointmentModel;
  firstAvailableSlotDateTimeAfterFiltering: Date | null;
}

export interface SimplifiedAvailableAppointmentsQueryVariables {
  branchId: string;
  businessId: string;
  clientId: string;
  fromDateTime: string;
  staffId?: string | null | undefined;
  toDateTime: string;
  treatmentIds: string[];
}

/**
 * Available Appointments Hook
 *
 * Gives us
 * - Simple list of Y/N availability for dates
 * - Simple list of dates with a count of slots per date
 * - Full slots with associated data mapped to a date [Less performant - only use if necessary]
 *
 * @param simplifiedAvailableAppointmentsQueryVariables
 * @param {boolean} [generateSlots=true] - If you just need boolean availability or a count of slots for days, don't generate slots
 * @param {boolean} [showEmptyDays=false] - Show empty days in the UI. Tends to just take up space and be useless
 * @returns
 */
function useAvailableAppointments(
  simplifiedAvailableAppointmentsQueryVariables: SimplifiedAvailableAppointmentsQueryVariables,
  generateSlots = true,
  showEmptyDays = false
): IUseAvailableAppointmentsOutput {
  const {gqlClient} = useContext(GQLClientContext);
  const {staffId, fromDateTime, toDateTime} = simplifiedAvailableAppointmentsQueryVariables;

  const variables: GET_APPOINTMENT_AVAILABILITYQueryVariables = useMemo(() => {
    const {staffId, treatmentIds, clientId} = simplifiedAvailableAppointmentsQueryVariables;
    const clientServiceSelection: ClientServiceSelectionsInput = {
      serviceSelections: treatmentIds.map(treatmentId => ({serviceId: treatmentId, ...(staffId && {staffId})})),
      clientId
    };
    return {
      ...simplifiedAvailableAppointmentsQueryVariables,
      clientServiceSelections: [clientServiceSelection]
    };
  }, [simplifiedAvailableAppointmentsQueryVariables]);

  const {data, isLoading, error} = useQuery<GET_APPOINTMENT_AVAILABILITYQuery, TGQLCustomErrorsViaReactQuery>({
    cacheTime: RQ_CACHE_TIMES.useAvailableAppointments,
    queryKey: [GET_APPOINTMENT_AVAILABILITY_KEY, variables],
    queryFn: async () => {
      const {data} = await gqlClient.request<
        GET_APPOINTMENT_AVAILABILITYQuery,
        GET_APPOINTMENT_AVAILABILITYQueryVariables
      >(GET_APPOINTMENT_AVAILABILITY, variables);
      return data;
    }
  });

  // 0. Filter the results by staffId if preferred staff was selected
  const dataFilteredByStaffSelection = staffId
    ? data?.getAppointmentAvailability?.filter(
        slot => slot?.clientSchedules?.[0]?.serviceSchedules?.[0]?.staffId === staffId
      )
    : data?.getAppointmentAvailability;

  // 0.b Expose first of any availability, regardless of staff
  const firstAvailableSlotAnyStaff = data?.getAppointmentAvailability?.[0];

  // Simplified available days to be used where we just need to know if the day has slots but don't need their data
  const availableDays = new Set<string>(); // Just a list of dates that have availability
  const availableSlotsPerDay: Record<string, number> = {}; // Maps days to the count of slots that day
  if (dataFilteredByStaffSelection) {
    dataFilteredByStaffSelection
      .map(slot => slot?.startTime?.split('T')[0])
      .forEach(yyyymmdd => {
        if (yyyymmdd) {
          availableDays.add(yyyymmdd);
          availableSlotsPerDay[yyyymmdd] = availableSlotsPerDay[yyyymmdd] ? availableSlotsPerDay[yyyymmdd] + 1 : 1;
        }
      });
  }

  const {filteredAvailability, firstAvailableSlotDateTimeAfterFiltering, firstAvailableSlot} = useMemo(() => {
    // Short-circuit because we only need the basics.
    if (!generateSlots) return {filteredAvailability: {}, firstAvailableSlotDateTimeAfterFiltering: null};

    // 1. Store the first slot, and the time of the very first slot here to save messy digging in the filteredAvailability Object later
    const firstAvailableSlot = dataFilteredByStaffSelection?.[0];
    const firstAvailableSlotDateTimeAfterFiltering = dataFilteredByStaffSelection?.[0]?.startTime
      ? new Date(dataFilteredByStaffSelection?.[0]?.startTime)
      : null;

    // 2. Map the upcoming availability slots to the date they're for
    const filteredAvailability: SlotDayMap =
      error || !dataFilteredByStaffSelection ? {} : mapDailyAppointmentSlots(dataFilteredByStaffSelection);

    // 3. Get an array of dates representing the days we want
    const upcomingDaysList = getDatesForUpcomingDays({
      fromDate: parseISO(fromDateTime),
      toDate: parseISO(toDateTime)
    });

    // 4. filteredAvailability can have gaps if there were no availability items on a day, so this backfills the days with an empty object so we can show them as empty days.
    upcomingDaysList.forEach(day => {
      const key = formatDate(day, 'YEAR_MONTH_DAY');
      if ((showEmptyDays && !filteredAvailability[key]) || isLoading) {
        filteredAvailability[key] = {};
      }
    });

    return {filteredAvailability, firstAvailableSlotDateTimeAfterFiltering, firstAvailableSlot};
  }, [error, fromDateTime, toDateTime, showEmptyDays, isLoading, generateSlots, dataFilteredByStaffSelection]);

  return {
    availableDays: Array.from(availableDays),
    availableSlotsPerDay,
    filteredAvailability,
    hasAvailabilityAfterFiltering: !!Array.from(availableDays).length,
    firstAvailableSlot,
    firstAvailableSlotAnyStaff,
    firstAvailableSlotDateTimeAfterFiltering,
    isLoading,
    error
  };
}

export {useAvailableAppointments};
export default useAvailableAppointments;

const mapDailyAppointmentSlots = (data: Array<AvailableAppointmentModel | null>): SlotDayMap => {
  const slots: SlotDayMap = {};
  return (
    data?.reduce((slotAccumulator, slot) => {
      if (slot?.startTime) {
        const [date, time] = slot.startTime.split('T');
        const [hour, minute] = time.split(':');
        if (!slotAccumulator[date]) slotAccumulator[date] = {};
        slotAccumulator[date][hour + ':' + minute] = slot;
      }
      return slotAccumulator;
    }, slots) ?? {}
  );
};

export const GET_APPOINTMENT_AVAILABILITY_KEY = 'GET_APPOINTMENT_AVAILABILITY';
