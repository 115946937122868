// Pastel colors

const COLORS_PASTELS = {
  amberP: '#FEF5ED',
  apple300P: '#EBFBF2',
  appleP: '#E9F5EE',
  azure300P: '#F0F6FE',
  azureP: '#E8F1FD',
  canaryP: '#FEFAED',
  grape100P: '#FCFCFE',
  grape300P: '#F7F6FC',
  grape600P: '#EFEBF8',
  grapeP: '#F1EEFA',
  mist300P: '#FBFBFB',
  mist400P: '#F9F9F9',
  mist600P: '#F4F4F4',
  night300P: '#EEEEEE',
  nightP: '#E9E9E9',
  scarletP: '#FDEEEE'
} as const;

export default COLORS_PASTELS;
export {COLORS_PASTELS};
