import config from 'config';

export type BusinessId = 'sbwKfUMZGuoWkYox87nkgg' | 'oqJQBFgRdAEy-ZuGXpPQVA' | 'TfMeXWKLIT8d8ERnd8Dh-g';
export type BusinessCode = 'roi' | 'uk' | 'us';
export interface BusinessInterface {
  code: BusinessCode;
  id: BusinessId;
}

export const BUSINESS_CODE = config.BUSINESS_CODE as BusinessCode;
export const BUSINESS_ID = config.BUSINESS_ID as BusinessId;
export const BUSINESS_UIDS: Record<BusinessCode, BusinessId> = {
  roi: 'sbwKfUMZGuoWkYox87nkgg',
  uk: 'oqJQBFgRdAEy-ZuGXpPQVA',
  us: 'TfMeXWKLIT8d8ERnd8Dh-g'
};
export const BUSINESS_CODES: Record<BusinessId, BusinessCode> = {
  sbwKfUMZGuoWkYox87nkgg: 'roi',
  'oqJQBFgRdAEy-ZuGXpPQVA': 'uk',
  'TfMeXWKLIT8d8ERnd8Dh-g': 'us'
};
export const BUSINESS_NAMES = {
  roi: 'Ireland',
  uk: 'United Kingdom',
  us: 'United States'
};

export const BUSINESSES_IN_CALL_CENTRE: BusinessCode[] = ['roi', 'uk'];

export const BUSINESS_CODE_INTERFACE_MAP: Record<string, BusinessInterface> = {
  roi: {
    code: 'roi',
    id: BUSINESS_UIDS['roi']
  },
  uk: {
    code: 'uk',
    id: BUSINESS_UIDS['uk']
  },
  us: {
    code: 'us',
    id: BUSINESS_UIDS['us']
  }
};
