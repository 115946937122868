import {captureException} from '@sentry/react';
import {GET_ALL_ADMIN_SERVICE_CATEGORIES} from 'data/graphql/queries/adminServices';
import {useBusinessContext, useNotifications} from 'hooks';
import {GQLClientContext} from 'providers/gqlClient';
import {useContext} from 'react';
import {useQuery, UseQueryResult} from '@tanstack/react-query';
import {GET_ALL_ADMIN_SERVICE_CATEGORIESQuery, GET_ALL_ADMIN_SERVICE_CATEGORIESQueryVariables} from 'types/ApiModel';

export type IUseAdminServiceCategories = UseQueryResult<
  GET_ALL_ADMIN_SERVICE_CATEGORIESQuery['getAllAdminServiceCategories'],
  Error
>;

function useAdminServiceCategories(): IUseAdminServiceCategories {
  const {gqlClient} = useContext(GQLClientContext);
  const {notifyError} = useNotifications();
  const {businessId} = useBusinessContext();

  return useQuery<GET_ALL_ADMIN_SERVICE_CATEGORIESQuery['getAllAdminServiceCategories'], Error>(
    [USE_ADMIN_SERVICE_CATEGORIES_KEY],
    async () => {
      const resp = await gqlClient.request<
        GET_ALL_ADMIN_SERVICE_CATEGORIESQuery,
        GET_ALL_ADMIN_SERVICE_CATEGORIESQueryVariables
      >(GET_ALL_ADMIN_SERVICE_CATEGORIES, {businessId});

      return resp.data.getAllAdminServiceCategories;
    },
    {
      enabled: !!businessId,
      onError: error => {
        const errorString = error.message;
        notifyError(`Error getting service categories: ${errorString}`);
        captureException(new Error(errorString));
      }
    }
  );
}

export {useAdminServiceCategories};

export const USE_ADMIN_SERVICE_CATEGORIES_KEY = 'GET_ADMIN_SERVICE_CATEGORIES';
