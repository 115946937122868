import config from 'config';
import {withLDProvider} from 'launchdarkly-react-client-sdk';
import {FeatureFlags} from 'types/featureFlags';

const Provider: React.FC<React.PropsWithChildren<unknown>> = ({children}) => <>{children}</>;

// LD needs REACT_APP_LAUNCH_DARKLY_CLIENT_SIDE_ID in the env vars to connect with remove FF searvice
// comment out REACT_APP_LAUNCH_DARKLY_CLIENT_SIDE_ID and use this bootstrap to play with FF variantions
const bootstrap: FeatureFlags = {
  adminSection: false,
  buildNumber: true,
  deleteClient: false,
  forceUpdateBuildsOlderThanTimestamp: '2000-01-01T00:00:00.000Z',
  fullstoryRecording: false,
  paymentStripeLink: false,
  paymentStripeMotoForm: true,
  paymentStripeTerminal: true,
  paymentTillSessionSetup: false,
  paymentVoucherNuaAsADefault: true,
  productFruits: false,
  purchaseForProdClinic: true,
  purchaseForTestClinic: false,
  // NB: When paymentStripeStandalone is true payment gateway is Stripe only. this is a safe mechanism to disable internal Phorest API
  paymentStripeStandalone: false,
  refreshDiaryIntervalDelay: 0
};

const LaunchDarklyProvider = withLDProvider({
  clientSideID: config.LAUNCH_DARKLY_CLIENT_SIDE_ID,
  options: {bootstrap: config.ENVIRONMENT === 'local' ? bootstrap : undefined}
})(Provider) as React.FC<React.PropsWithChildren<unknown>>; // TODO: This is nasty - do it better

export {LaunchDarklyProvider};
export default LaunchDarklyProvider;
