import * as Sentry from '@sentry/react';
import {UseMutationResult, useMutation} from '@tanstack/react-query';
import {CREATE_CLIENT} from 'data/graphql/mutations/clients';
import {useNotifications} from 'hooks';
import useAnalytics from 'hooks/analytics/useAnalytics';
import {GQLClientContext} from 'providers/gqlClient';
import {useContext} from 'react';
import {useRecoilValue} from 'recoil';
import {userState} from 'state';
import {CREATE_CLIENTMutation, CREATE_CLIENTMutationVariables} from 'types/ApiModel';
import {TGQLCustomErrorsViaReactQuery} from 'types/errors';
import {getAnalyticsAttributes} from 'utils/tracing';

type MutationResult = CREATE_CLIENTMutation['createClient'];
type IUseCreateClientOutput = UseMutationResult<
  MutationResult,
  TGQLCustomErrorsViaReactQuery,
  CREATE_CLIENTMutationVariables
>;

function useCreateClient(): IUseCreateClientOutput {
  const {notifySuccess, notifyError} = useNotifications();
  const {mutateAsync: sendAnalytics} = useAnalytics();
  const {gqlClient} = useContext(GQLClientContext);

  // Analytics Context
  const user = useRecoilValue(userState);

  // Mutation Call
  return useMutation<MutationResult, TGQLCustomErrorsViaReactQuery, CREATE_CLIENTMutationVariables>({
    mutationFn: async variables => {
      if (!variables) return null;
      const resp = await gqlClient.request<CREATE_CLIENTMutation, CREATE_CLIENTMutationVariables>(
        CREATE_CLIENT,
        variables
      );
      return resp.data.createClient;
    },
    mutationKey: [USE_CREATE_CLIENT_KEY],
    onError: (error, variables) => {
      const errorMessage = `Error Create Client: ${error?.response?.errors?.[0].message}`;
      notifyError(errorMessage);
      Sentry.captureException(new Error(errorMessage), {extra: {payload: variables}});
      console.error(errorMessage, error);
    },
    onSuccess: () => {
      notifySuccess(`Client has been created`);
      sendAnalytics(getAnalyticsAttributes({actionName: 'createClient', user}));
    }
  });
}

export {useCreateClient};
export default useCreateClient;

export const USE_CREATE_CLIENT_KEY = 'CREATE_CLIENT';
