import {TablePaginationProps} from '@material-ui/core';
import {BaseItem, Column, Table} from '@therapie-ui/Table/Table';
import {DEFAULT_PAGE_SIZE} from 'constants/pagination';
import {useBusinessContext} from 'hooks';
import useClientProductHistory from 'hooks/client/useClientProductHistory';
import {useCurrency} from 'hooks/currency/useCurrency';
import {LocaleContext} from 'providers/locale';
import React, {useCallback, useContext, useMemo, useState} from 'react';
import {useRecoilValue} from 'recoil';
import {branchesMapState} from 'state';
import {ClientProductHistory} from 'types/DerivedApiModel';
import {formatDate} from 'utils';

interface Props {
  clientId: string;
}

const ProductHistoryTable: React.FC<React.PropsWithChildren<Props>> = ({clientId}) => {
  const {businessId} = useBusinessContext();
  const {formatCurrency} = useCurrency();
  const {locale} = useContext(LocaleContext);
  const [pageNumber, setPageNumber] = useState(0);
  const branchMap = useRecoilValue(branchesMapState);
  const {data, isFetching} = useClientProductHistory({
    query: {businessId, clientId},
    page: {page: pageNumber, size: DEFAULT_PAGE_SIZE}
  });
  const {products: clientProducts, page: pageData} = data ?? {};

  const tableItems: ProductTypeTableItem[] = useMemo(
    () => clientProducts?.map(product => ({...product, id: product.id})) ?? [],
    [clientProducts]
  );

  const handleChangePage = useCallback<TablePaginationProps['onPageChange']>(
    (_, newPage) => {
      setPageNumber(newPage);
    },
    [setPageNumber]
  );

  const renderCell = useCallback(
    (item: ProductTypeTableItem, column: Column) => {
      const productIndex = column.id as keyof ProductTypeTableItem;
      switch (column.id) {
        case 'date':
          return <>{formatDate(item.purchaseDate as string, 'DATE_MONTH_YEAR_LOCALISED', locale)}</>;
        case 'clinic':
          return <>{branchMap[item.branchId as string]?.name}</>;
        case 'staff':
          return <>{`${item.staffFirstName} ${item.staffLastName}`}</>;
        case 'description':
          return <>{item.description}</>;
        case 'quantity':
          return <>{item.quantity}</>;
        case 'price':
          return <>{formatCurrency({value: item.price})}</>;
        default:
          return <>{item[productIndex]}</>;
      }
    },
    [branchMap, formatCurrency, locale]
  );

  return (
    <Table<ProductTypeTableItem>
      columns={columns}
      isLoading={isFetching}
      items={tableItems}
      renderCell={renderCell}
      size={'small'}
      stickyHeader
      stickyFooter
      emptyStateProps={{
        title: 'No Products',
        details: 'This client has no product history'
      }}
      paginationProps={{
        count: pageData?.totalElements ?? 0,
        page: pageData?.number ?? 0,
        rowsPerPage: DEFAULT_PAGE_SIZE,
        onPageChange: handleChangePage,
        rowsPerPageOptions: []
      }}
    />
  );
};

interface ProductTypeTableItem extends ClientProductHistory, BaseItem {}

const columns: Column[] = [
  {id: 'date', label: 'Date'},
  {id: 'clinic', label: 'Clinic'},
  {id: 'staff', label: 'Staff'},
  {id: 'description', label: 'Description'},
  {id: 'quantity', label: 'Quantity', style: {textAlign: 'right'}},
  {id: 'price', label: 'Price', style: {textAlign: 'right'}}
];

export default ProductHistoryTable;
