import {addDays, subDays} from 'date-fns';
import {useClientAppointments, useClinicAppointments, useStaff} from 'hooks';
import {useMemo} from 'react';
import {GET_APPOINTMENTSQueryVariables} from 'types/ApiModel';
import {BranchModel, ClinicAppointmentModel} from 'types/DerivedApiModel';
import {formatUTCDate} from 'utils/time';

// Temp - will be deleted with booking dialog
import {ServiceInstanceDialogData} from 'components/BookingAppointment/BookingDialog/BookingDialog';

interface Output {
  appointmentToReschedule: ClinicAppointmentModel | null | undefined;
  dialogDataForReschedulableAppointment: ServiceInstanceDialogData | null | undefined;
}

interface Props {
  appointmentToRescheduleId?: string | null | undefined;
  businessId: string;
  clientId: string;
  clinicId: string;
  dataClinics?: BranchModel[];
  fromDate: Date;
}

function useReschedulableAppointment({
  appointmentToRescheduleId,
  businessId,
  clientId,
  clinicId,
  dataClinics = [],
  fromDate
}: Props): Output {
  const {futureAppointments} = useClientAppointments({clientId});
  const clientAppointmentHistoryDataForReschedulableAppointment = futureAppointments?.find(
    ({appointmentId}) => appointmentId === appointmentToRescheduleId
  );

  const existingAppointmentQuery = useMemo<GET_APPOINTMENTSQueryVariables | null>(
    () =>
      appointmentToRescheduleId
        ? {
            // All appointments for the branch + day + client
            query: {
              businessId,
              clientId,
              branchId: clinicId,
              fromDate: subDays(fromDate, 2).toISOString().split('T')[0],
              toDate: addDays(fromDate, 7).toISOString().split('T')[0],
              fetchCancelled: true
            }
          }
        : null,
    [appointmentToRescheduleId, clinicId, businessId, clientId, fromDate]
  );

  const {data: dataExistingClientAppointmentsFromSelectedDate} = useClinicAppointments(existingAppointmentQuery);

  const appointmentToReschedule = dataExistingClientAppointmentsFromSelectedDate?.find(
    ({appointmentId}) => appointmentToRescheduleId === appointmentId
  );

  const {data: oldAppointmentStaff} = useStaff({
    query: {
      businessId: businessId ?? '',
      branchId: clientAppointmentHistoryDataForReschedulableAppointment?.branchId ?? '',
      staffId: clientAppointmentHistoryDataForReschedulableAppointment?.staffId ?? ''
    }
  });

  const dialogDataForReschedulableAppointment: ServiceInstanceDialogData | null = appointmentToRescheduleId
    ? {
        dateTime: clientAppointmentHistoryDataForReschedulableAppointment?.time
          ? formatUTCDate(
              new Date(clientAppointmentHistoryDataForReschedulableAppointment?.time),
              'DAY_DATE_MONTH_AM_PM'
            )
          : null,
        clinicName: dataClinics?.find(
          clinic => clinic.id === clientAppointmentHistoryDataForReschedulableAppointment?.branchId
        )?.name,
        staffName: oldAppointmentStaff ? oldAppointmentStaff.firstName + ' ' + oldAppointmentStaff.lastName : null
      }
    : null;

  return {
    appointmentToReschedule,
    dialogDataForReschedulableAppointment
  };
}

export {useReschedulableAppointment};
export default useReschedulableAppointment;
