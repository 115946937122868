import {atom} from 'recoil';
import {BreakModel} from 'types/DerivedApiModel';

export interface AtomStafBreakStatusState {
  loading: boolean;
  fetching: boolean;
  mutating: boolean;
}

const staffBreaksState = atom<BreakModel[]>({
  key: 'staffBreaksState',
  default: []
});

const staffBreaksStatusState = atom<AtomStafBreakStatusState>({
  key: 'staffBreaksStatusState',
  default: {loading: false, fetching: false, mutating: false}
});

export {staffBreaksState, staffBreaksStatusState};
