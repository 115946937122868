declare global {
  interface Window {
    processChildMessage: (msg: string) => void;
    _fs_initialized?: boolean;
    _fs_is_outer_script?: boolean;
    THERAPIE_CRM_LAST_CREATED_APPOINTMENT_ID_KEY: string;
  }
}
window.THERAPIE_CRM_LAST_CREATED_APPOINTMENT_ID_KEY = 'therapie-clinic.crm.last-created-appointment-id';

export {};
