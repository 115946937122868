import * as Sentry from '@sentry/react';
import {UPDATE_APPOINTMENTS} from 'data/graphql/mutations/appointments';
import {useNotifications, USE_ALL_CLINIC_APPOINTMENTS_KEY} from 'hooks';
import useAnalytics from 'hooks/analytics/useAnalytics';
import {GQLClientContext} from 'providers/gqlClient';
import {useContext} from 'react';
import {useMutation, UseMutationResult, useQueryClient} from '@tanstack/react-query';
import {useRecoilValue} from 'recoil';
import {userState} from 'state';
import {UpdateAppointmentsMutation, UpdateAppointmentsMutationVariables} from 'types/ApiModel';
import {parseCustomGQLErrorsForReactQuery} from 'utils/api';
import {confirmCRUDinDEV} from 'utils/devTools';
import {getAnalyticsAttributes} from 'utils/tracing';

interface Input {
  isDiaryContext?: boolean;
  showNotifications?: boolean;
}

type Variables = UpdateAppointmentsMutationVariables;
type Result = UpdateAppointmentsMutation['updateAppointments'];
type Output = UseMutationResult<Result, unknown, Variables, unknown>;

function useUpdateAppointments(props?: Input): Output {
  const {gqlClient} = useContext(GQLClientContext);
  const {notifyError} = useNotifications();
  const {mutateAsync: sendAnalytics} = useAnalytics();
  const queryClient = useQueryClient();
  const user = useRecoilValue(userState);

  return useMutation<Result, Error, Variables, unknown>(
    async variables => {
      const {payload} = variables;
      try {
        confirmCRUDinDEV(payload.clinicId);
        const resp = await gqlClient.request<UpdateAppointmentsMutation, Variables>(UPDATE_APPOINTMENTS, variables);
        return resp.data?.updateAppointments;
      } catch (error) {
        const {errorMessage, overrideableErrorCodes} = parseCustomGQLErrorsForReactQuery(error as Error);
        const errorString = `Error Updating Appointment: ${errorMessage}`;
        console.error(errorString, error);
        Sentry.captureException(new Error(errorString), {extra: {variables}});
        if (errorMessage && payload.shouldCommit) notifyError(getErrorMessage(errorMessage, errorString));
        if (errorMessage === 'VERSION_CONFLICT') queryClient.invalidateQueries([USE_ALL_CLINIC_APPOINTMENTS_KEY]);
        return Promise.reject(overrideableErrorCodes);
      }
    },
    {
      mutationKey: [USE_UPDATE_APPOINTMENTS_KEY],
      onSuccess: () => {
        sendAnalytics(
          getAnalyticsAttributes({
            actionName: 'updateAppointment',
            user,
            details: {isDiaryContext: props?.isDiaryContext}
          })
        );
      }
    }
  );
}

export {useUpdateAppointments};

export const USE_UPDATE_APPOINTMENTS_KEY = 'USE_UPDATE_APPOINTMENTS';

const getErrorMessage = (errorMessage: string, errorString: string) =>
  errorMessage === 'VERSION_CONFLICT' ? 'Error: Data has expired and has been updated - please try again' : errorString;
