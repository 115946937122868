import React from 'react';
import {Table as MuiTable, TableProps as MuiTableProps} from '@material-ui/core';

type TableProps = MuiTableProps;

/**
 * @deprecated This component and siblings are deprecated. Please use src/@therapie-ui/Table/Table
 */
const Table: React.FC<React.PropsWithChildren<TableProps>> = props => <MuiTable {...props} />;

export default Table;
export {Table};
