import React from 'react';
import {TableHead as MuiTableHead, TableHeadProps as MuiTableHeadProps} from '@material-ui/core';
import styled, {css} from 'styled-components';

export interface TableHeadStyleProps {
  border?: boolean;
  rounded?: boolean;
}

type TableHeadProps = MuiTableHeadProps & TableHeadStyleProps;

const TableHead: React.FC<React.PropsWithChildren<TableHeadProps>> = ({border = true, rounded = false, ...props}) => (
  <StyledMuiTableHead border={border} rounded={rounded} {...props} />
);

export default TableHead;
export {TableHead};

const StyledMuiTableHead = styled(MuiTableHead)<TableHeadStyleProps>`
  th {
    padding-top: ${({theme}) => theme.spacing(1)};
    padding-bottom: ${({theme}) => theme.spacing(1)};
    font-size: ${({theme}) => theme.fontSize.sm};
    color: #525252;
    font-weight: bold;
    background: #f4f4f4;
    border: 0;
    line-height: ${({theme}) => theme.fontSize.md};
    box-shadow: ${({border}) => (border ? 'inset 0 -1px 0 0 #e0e0e0, inset 0 1px 0 0 #e0e0e0;' : 'none')};
  }

  ${({
    rounded,
    theme: {
      shape: {borderRadius}
    }
  }) =>
    rounded &&
    css`
      th:first-child {
        border-top-left-radius: ${borderRadius * 2}px;
        border-bottom-left-radius: ${borderRadius * 2}px;
      }
      th:last-child {
        border-top-right-radius: ${borderRadius * 2}px;
        border-bottom-right-radius: ${borderRadius * 2}px;
      }
    `}
`;
