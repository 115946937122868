import COLORS from './index';

export const FALLBACK_CATEGORY_COLORS = [COLORS.mist500, COLORS.night600];

export const FORM_INPUT_COLORS: Record<string, string> = {
  DEFAULT: COLORS.night100,
  FOCUS: COLORS.therapiePurple,
  ERROR: COLORS.scarlet600
};

export const DIARY_CALENDAR_COLORS: Record<string, string> = {
  APPOINTMENT_BOOKED_AND_IN_CURRENT_TIME: COLORS.amber600,
  APPOINTMENT_BOOKED_AND_IN_PAST: COLORS.mist400,
  APPOINTMENT_CHECKED_IN: COLORS.phorestYellow,
  BREAK_NOTE: COLORS.night300,
  CLINIC_NOT_WORKING_BACKGROUND: COLORS.mist600P,
  LUNCH_BREAK: COLORS.night300,
  SELECTED_CELL_BACKGROUND: COLORS.canary,
  SELECTED_ROW_BACKGROUND: COLORS.canary200,
  STAFF_CATEGORY_DEFAULT: COLORS.mist600,
  STAFF_NOT_WORKING_BACKGROUND: COLORS.mist300,

  // Non DS for now
  APPOINTMENT_PAID: '#008000'
};
