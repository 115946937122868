import config from 'config';
import {DIARY_LABELS} from 'constants/diary';
import {addMinutes, compareDesc, differenceInMinutes} from 'date-fns';
import {zonedTimeToUtc} from 'date-fns-tz';
import {IClinicStaffSchedule} from 'hooks/clinic/useClinicSchedule';
import {
  ClinicAppointmentModel,
  ClinicAppointmentWithClientAndServiceModel,
  SingleServiceHistory
} from 'types/DerivedApiModel';
import {getZonedDate} from './time';

interface GetAppointmentStartAndEndDatesOutput {
  startDate: Date;
  endDate: Date;
}

export const getAppointmentUniqueKey = (appointment?: SingleServiceHistory, uniqueNumber?: number): string =>
  `${appointment?.time}_${appointment?.appointmentId}_${appointment?.serviceId}_${appointment?.cancelled}_${uniqueNumber}`;

export const getAppointmentServiceName = (appointment?: ClinicAppointmentWithClientAndServiceModel): string =>
  appointment?.service?.name ?? appointment?.serviceName ?? DIARY_LABELS.UNKNOWN_TREATMENT;

export const getAppointmentStartAndEndDates = (
  appointment: ClinicAppointmentWithClientAndServiceModel
): GetAppointmentStartAndEndDatesOutput => ({
  startDate: getZonedDate(appointment.appointmentDate, appointment.startTime),
  endDate: getZonedDate(appointment.appointmentDate, appointment.endTime)
});

export const getDateAndAppointmentOffsetInMinutes = (
  newTime: Date,
  originalAppointment: ClinicAppointmentWithClientAndServiceModel
): number => differenceInMinutes(newTime, getAppointmentStartAndEndDates(originalAppointment).startDate);

export const getAppointmentMinuteDuration = (appointment: ClinicAppointmentWithClientAndServiceModel): number => {
  if (!appointment.endTime || !appointment.startTime) return 0;
  return differenceInMinutes(
    getAppointmentStartAndEndDates(appointment).endDate,
    getAppointmentStartAndEndDates(appointment).startDate
  );
};

export const getAppointmentObjectFromId = (
  appointmentId: string,
  staffSchedules: IClinicStaffSchedule[] | undefined
): ClinicAppointmentWithClientAndServiceModel | undefined => {
  const reducedAppointmentsArrayToSelectedAppointment = staffSchedules
    ?.map(staffSchedule => staffSchedule.appointments.find(appointment => appointment.appointmentId === appointmentId))
    .filter(staffSchedule => !!staffSchedule?.appointmentId);
  return reducedAppointmentsArrayToSelectedAppointment?.[0] ?? undefined;
};

export const sortAppointmentsByStartTimeAscending = (
  a: ClinicAppointmentWithClientAndServiceModel,
  b: ClinicAppointmentWithClientAndServiceModel
): number => {
  if (!a.startTime || !b.startTime) return 0;
  return a.startTime < b.startTime ? -1 : a.startTime > b.startTime ? 1 : 0;
};

export const getInitialAppointmentNewStartAndEndTime = (
  originalAppointment: ClinicAppointmentWithClientAndServiceModel,
  timeOffsetInMinutes: number
): GetAppointmentStartAndEndDatesOutput => ({
  startDate: addMinutes(getAppointmentStartAndEndDates(originalAppointment).startDate, timeOffsetInMinutes),
  endDate: addMinutes(getAppointmentStartAndEndDates(originalAppointment).endDate, timeOffsetInMinutes)
});

/**
 * getLastEndTimeFromListOfZonedAppointments
 * This will iterate over an array of appointments (or any object with appointmentDate and endTime),
 * with the assumption that the time and date string is in an environment zoned Timezone.
 * Will return a UTC date for the final end time of the appointment collection.
 *
 * @param appointments: ClinicAppointmentModel-like Array
 * @returns Date: Time the appointments end
 */
export const getLastEndTimeFromListOfZonedAppointments = (
  appointments: Pick<ClinicAppointmentModel, 'appointmentDate' | 'endTime'>[]
): Date | null => {
  if (!appointments?.[0]?.appointmentDate) return null;
  const appointmentEndTimesSortedDescending = appointments
    .filter(appointment => appointment.endTime && appointment.appointmentDate)
    .map(({appointmentDate, endTime}) => zonedTimeToUtc(appointmentDate + ' ' + endTime, config.TIMEZONE))
    .sort(compareDesc);
  return appointmentEndTimesSortedDescending[0];
};
