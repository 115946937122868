import {useBusinessContext, useClient} from 'hooks';
import React from 'react';
import CustomerHistory from './CustomerHistory/CustomerHistory';
import CustomerUpcomingAppointmentsGroupedByDate from './CustomerUpcomingAppointmentsGroupedByDate/CustomerUpcomingAppointmentsGroupedByDate';
import CustomerCourses from './CustomerCourses';
import CustomerNotes from './CustomerNotes';

interface Props {
  clientId: string;
}

export const ClientOverview = React.memo<Props>(({clientId}) => {
  const {businessId} = useBusinessContext();
  const {data: client} = useClient({businessId, clientId});
  if (!client) return null;

  return (
    <>
      <CustomerNotes clientId={clientId} />
      <CustomerUpcomingAppointmentsGroupedByDate clientId={clientId} />
      <CustomerCourses clientId={clientId} />
      <CustomerHistory clientId={clientId} />
    </>
  );
});
