import {gql} from 'utils/graphql';

export const GET_ADMIN_COURSES = gql`
  query GET_ADMIN_COURSES($query: GetAdminCoursesInput, $page: PageInput) {
    getAdminCourses(query: $query, page: $page) {
      page {
        number
        size
        totalElements
        totalPages
      }
      courses {
        archived
        compareAtPrice
        deleted
        descriptionHTML
        id
        name
        onlineName
        onlineStatus
        totalPrice
        items {
          id
          name
          price
          serviceId
          totalUnits
          unitType
        }
        urlHandle
      }
    }
  }
`;

export const GET_ADMIN_COURSE = gql`
  query GET_ADMIN_COURSE($businessId: String!, $id: String!) {
    getAdminCourse(businessId: $businessId, id: $id) {
      archived
      compareAtPrice
      deleted
      descriptionHTML
      id
      name
      onlineName
      onlineStatus
      totalPrice
      items {
        id
        name
        price
        serviceId
        totalUnits
        unitType
      }
      urlHandle
    }
  }
`;
