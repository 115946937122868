import {captureException} from '@sentry/react';
import {GET_ADMIN_PRODUCTS} from 'data/graphql/queries/adminProducts';
import useNotifications from 'hooks/notifications/useNotifications';
import {GQLClientContext} from 'providers/gqlClient';
import {useContext, useMemo} from 'react';
import {useQuery, UseQueryResult} from '@tanstack/react-query';
import {GET_ADMIN_PRODUCTSQuery, GET_ADMIN_PRODUCTSQueryVariables} from 'types/ApiModel';
import {Nullable} from 'types/common';

export type IUseAdminProductsOutput = UseQueryResult<GET_ADMIN_PRODUCTSQuery['getAdminProducts'], Error>;

function useAdminProducts(variables: Nullable<GET_ADMIN_PRODUCTSQueryVariables>): IUseAdminProductsOutput {
  const {gqlClient} = useContext(GQLClientContext);
  const {businessId, brandId, nameOrSkuCode, onlineStatus} = variables?.query || {};
  const {notifyError} = useNotifications();

  const adminGetProductsQuery = useMemo<Nullable<GET_ADMIN_PRODUCTSQueryVariables>>(
    () => ({query: {businessId: businessId!, brandId, nameOrSkuCode, onlineStatus}, page: variables?.page}),
    [businessId, brandId, nameOrSkuCode, onlineStatus, variables?.page]
  );

  return useQuery<GET_ADMIN_PRODUCTSQuery['getAdminProducts'], Error>(
    [USE_ADMIN_PRODUCTS_KEY, adminGetProductsQuery],
    async () => {
      const resp = await gqlClient.request<GET_ADMIN_PRODUCTSQuery, Nullable<GET_ADMIN_PRODUCTSQueryVariables>>(
        GET_ADMIN_PRODUCTS,
        adminGetProductsQuery
      );
      return resp.data?.getAdminProducts ?? null;
    },
    {
      enabled: !!businessId,
      onError: error => {
        const errorString = error.message;
        notifyError(`Error getting products: ${errorString}`);
        captureException(new Error(errorString));
      }
    }
  );
}

export {useAdminProducts};

export const USE_ADMIN_PRODUCTS_KEY = 'GET_ADMIN_PRODUCTS';
