import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import ErrorOutlineOutlinedIcon from '@material-ui/icons/ErrorOutlineOutlined';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import WarningIcon from '@material-ui/icons/Warning';
import {Alert as MUIAlert, AlertProps} from '@material-ui/lab';
import {SEVERITY} from 'constants/notifications';
import React from 'react';
import styled from 'styled-components';

export interface InfoBarProps extends AlertProps {
  customIcon?: React.ReactNode;
  status?: SEVERITY;
}

const InfoBar: React.FC<React.PropsWithChildren<InfoBarProps>> = ({
  action,
  children,
  status,
  customIcon,
  variant = 'standard',
  ...props
}) => {
  const notification = status ? NotificationOptionsStyles[status] : NotificationOptionsStyles.default;
  const {backgroundColor, borderColor, iconColor, textColor, icon} = notification;

  return (
    <StyledAlert
      action={action}
      backgroundColor={backgroundColor}
      borderColor={borderColor}
      icon={customIcon ?? icon}
      iconColor={iconColor}
      textColor={textColor}
      variant={variant}
      {...props}
    >
      {children}
    </StyledAlert>
  );
};
export default InfoBar;
interface StyleProps {
  readonly backgroundColor?: string;
  readonly textColor?: string;
  readonly borderColor?: string;
  readonly iconColor?: string;
}

const StyledAlert = styled(MUIAlert)<StyleProps>`
  align-items: center;
  background-color: ${({backgroundColor}) => backgroundColor};
  border-color: ${({borderColor}) => borderColor};
  color: ${({textColor}) => textColor};
  font-size: ${14 / 16}rem;
  svg {
    color: ${({iconColor}) => iconColor};
  }
  button > span {
    line-height: 1.1;
    font-size: ${12 / 16}rem;
  }
`;

const NotificationOptionsStyles = {
  success: {
    backgroundColor: '#f0fcf4',
    textColor: '#00431d',
    iconColor: '#219653',
    borderColor: '#9fcf9f',
    icon: <CheckCircleOutlineIcon />
  },
  error: {
    backgroundColor: '#fef5f5',
    textColor: '#730000',
    iconColor: '#eb5757',
    borderColor: '#fca7a7',
    icon: <ErrorOutlineOutlinedIcon />
  },
  default: {
    backgroundColor: '#f7f5f5',
    textColor: '#585858',
    iconColor: '#2f3941',
    borderColor: '#d8dcde',
    icon: <InfoOutlinedIcon />
  },
  info: {
    backgroundColor: '#e9f6fc',
    textColor: '#003550',
    iconColor: '#01aaff',
    borderColor: '#77d2ff',
    icon: <InfoOutlinedIcon />
  },
  warning: {
    backgroundColor: '#fff7e7',
    textColor: '#9b6501',
    iconColor: '#ffa600',
    borderColor: '#ffda95',
    icon: <WarningIcon />
  }
};
