import {PURCHASE_ATOM_PREFIX} from 'constants/purchase';
import {atom} from 'recoil';

export interface StripeLocationIdStateOutput {
  data: string | null;
  loading: boolean;
}

const stripeLocationIdState = atom<StripeLocationIdStateOutput>({
  key: `${PURCHASE_ATOM_PREFIX}StripeLocationIdState`,
  default: {
    data: null,
    loading: false
  }
});

export {stripeLocationIdState};
