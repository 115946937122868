import {PURCHASE_ATOM_PREFIX} from 'constants/purchase';
import {selector} from 'recoil';
import {purchaseStaffListState, purchaseStaffListStatusState} from 'state/atoms/purchase';
import {AtomStatusState} from 'state/atoms/purchase/types';
import {Option} from 'types/common';

interface PurchaseStaffSelectorOutput<T> extends AtomStatusState {
  data: T[];
}

const purchaseStaffOptionsSelector = selector<PurchaseStaffSelectorOutput<Option>>({
  key: `${PURCHASE_ATOM_PREFIX}StaffOptionsSelector`,
  get: ({get}) => ({
    data: get(purchaseStaffListState).map(staff => ({id: staff?.id!, label: `${staff?.firstName} ${staff?.lastName}`})),
    ...get(purchaseStaffListStatusState)
  })
});

export {purchaseStaffOptionsSelector};
