import useTreatments, {TreatmentProps, UseTreatmentProps, UseTreatmentsResponse} from 'hooks/treatment/useTreatments';
import {useMemo} from 'react';
import {AllClientCoursesModel} from 'types/DerivedApiModel';

export interface UseEntityMapsProps {
  businessId: string;
  clientCourses: AllClientCoursesModel[];
}

function useEntityMapsHack({businessId, clientCourses}: UseEntityMapsProps): UseTreatmentsResponse {
  // Prepare Queries
  const treatmentQuery = useMemo(() => {
    if (!clientCourses?.length) return;
    const treatmentsQuery = clientCourses.flatMap(course =>
      course?.clientCourseItems?.map(
        treatment => ({treatmentId: treatment.serviceId, branchId: course.purchasingBranchId} as TreatmentProps)
      )
    );
    return {businessId, treatments: treatmentsQuery};
  }, [businessId, clientCourses]);

  // Compose Simpler Hooks
  const {
    treatmentsMap,
    loading: loadingTreatments,
    error: errorTreatment
  } = useTreatments(treatmentQuery as UseTreatmentProps);

  return {
    loading: loadingTreatments,
    error: errorTreatment,
    treatmentsMap
  };
}

export {useEntityMapsHack};
export default useEntityMapsHack;
