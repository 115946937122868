import {ActiveClientCoursesModel} from 'types/DerivedApiModel';

// Get course names from course item IDs
export const getMapOfClientCourseItemIdsToCourseNames = (
  data?: ActiveClientCoursesModel[] | null
): Record<string, string> =>
  data?.reduce((nameMap, {courseName, clientCourseItems}) => {
    clientCourseItems?.forEach(({clientCourseItemId}) =>
      clientCourseItemId && courseName ? (nameMap[clientCourseItemId] = courseName) : null
    );
    return nameMap;
  }, {} as Record<string, string>) ?? {};

// Allows us to map client course items to selected services so we can deduct from course
export const getMapOfServiceIdsToClientCourseItemIdsWithRemainingUnits = (
  data?: ActiveClientCoursesModel[] | null
): Record<string, string> =>
  data?.reduce((nameMap, {clientCourseItems}) => {
    clientCourseItems?.forEach(({clientCourseItemId, serviceId, remainingUnits}) =>
      clientCourseItemId && serviceId && remainingUnits && remainingUnits > 0
        ? (nameMap[serviceId] = clientCourseItemId)
        : null
    );
    return nameMap;
  }, {} as Record<string, string>) ?? {};
