import {GET_ALL_BREAKS} from 'data/graphql/queries/breaks';
import {GQLClientContext} from 'providers/gqlClient';
import {useContext, useMemo} from 'react';
import {useQuery as useQueryRQ, UseQueryResult} from '@tanstack/react-query';
import {GET_ALL_BREAKSQuery, GET_ALL_BREAKSQueryVariables} from 'types/ApiModel';
import {Nullable} from 'types/common';

interface Input {
  businessId: string;
  clinicId: string;
  date: string;
}

type Output = UseQueryResult<NonNullable<GET_ALL_BREAKSQuery['getAllBreaks']>>;

function useAllBreaks({date, businessId, clinicId}: Input): Output {
  const allBreaksQuery = useMemo<Nullable<GET_ALL_BREAKSQueryVariables>>(
    () => (clinicId && businessId && date ? {query: {businessId, clinicId, date}} : null),
    [clinicId, businessId, date]
  );
  const {gqlClient} = useContext(GQLClientContext);
  return useQueryRQ(
    [GET_ALL_BREAKS_KEY, allBreaksQuery],
    async () => {
      const resp = await gqlClient.request<GET_ALL_BREAKSQuery, Nullable<GET_ALL_BREAKSQueryVariables>>(
        GET_ALL_BREAKS,
        allBreaksQuery
      );
      return resp.data?.getAllBreaks;
    },
    {enabled: !!allBreaksQuery}
  );
}

export {useAllBreaks};
export default useAllBreaks;

export const GET_ALL_BREAKS_KEY = 'GET_ALL_BREAKS';
