import {SIZES} from 'constants/sizes';
import React from 'react';

// MUI Icons
import EmojiObjectsOutlinedIcon from '@material-ui/icons/EmojiObjectsOutlined';
import FaceIcon from '@material-ui/icons/Face';
import HelpIcon from '@material-ui/icons/Help';
import InfoIcon from '@material-ui/icons/Info';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import NoEventIcon from '@material-ui/icons/EventBusyRounded';
import PersonIcon from '@material-ui/icons/Person';
import RefreshIcon from '@material-ui/icons/Refresh';
import SearchIcon from '@material-ui/icons/Search';
import SentimentDissatisfiedIcon from '@material-ui/icons/SentimentDissatisfied';
import SportsKabaddiOutlinedIcon from '@material-ui/icons/SportsKabaddiOutlined';
import ErrorOutlineOutlinedIcon from '@material-ui/icons/ErrorOutlineOutlined';

// Custom SVGs as React components (Create React App gives us SVGR)
import {ReactComponent as TileGridIcon} from '../Icons/tilegrid.svg';
import LoadingLotusIcon from '../LoadingPlaceholder/LoadingLotus';
import COLORS_BASE from 'constants/colors/base';
import {BarcodeScanIcon} from '@therapie-ui/Icons';

interface Props {
  icon?: EmptyStateIconKey;
  size?: SIZES;
}

export type EmptyStateIconKey =
  | 'barcode'
  | 'canttouchthis'
  | 'face'
  | 'help'
  | 'info'
  | 'lightbulb'
  | 'loading'
  | 'lock'
  | 'noevent'
  | 'person'
  | 'sadface'
  | 'search'
  | 'tilegrid'
  | 'warning'
  | 'refresh';

const iconSizes = {
  [SIZES.XXS]: '20px',
  [SIZES.XS]: '40px',
  [SIZES.S]: '60px',
  [SIZES.M]: '80px',
  [SIZES.L]: '100px',
  [SIZES.XL]: '120px',
  [SIZES.XXL]: '140px'
};

const EmptyStateIcon: React.FC<React.PropsWithChildren<Props>> = ({icon, size = SIZES.M}) => {
  const icons = {
    barcode: <BarcodeScanIcon style={{fontSize: iconSizes[size]}} />,
    canttouchthis: <SportsKabaddiOutlinedIcon style={{fontSize: iconSizes[size]}} />,
    face: <FaceIcon style={{fontSize: iconSizes[size]}} />,
    help: <HelpIcon style={{fontSize: iconSizes[size]}} />,
    info: <InfoIcon style={{fontSize: iconSizes[size]}} />,
    lightbulb: <EmojiObjectsOutlinedIcon style={{fontSize: iconSizes[size]}} />,
    loading: <LoadingLotusIcon style={{fontSize: iconSizes[size], color: COLORS_BASE.grape400}} />,
    lock: <LockOutlinedIcon style={{fontSize: iconSizes[size]}} />,
    noevent: <NoEventIcon style={{fontSize: iconSizes[size]}} />,
    person: <PersonIcon style={{fontSize: iconSizes[size]}} />,
    refresh: <RefreshIcon style={{fontSize: iconSizes[size]}} />,
    sadface: <SentimentDissatisfiedIcon style={{fontSize: iconSizes[size]}} />,
    search: <SearchIcon style={{fontSize: iconSizes[size]}} />,
    tilegrid: <TileGridIcon style={{fontSize: iconSizes[size]}} />,
    warning: <ErrorOutlineOutlinedIcon style={{fontSize: iconSizes[size]}} />
  };

  return icon ? icons[icon] : null;
};

export default EmptyStateIcon;
