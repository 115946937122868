import {BUSINESS_CODES, BusinessCode, BusinessId} from 'constants/business';
import {useIsAdminContext} from 'hooks/router/useIsAdminContext';
import {useCallback} from 'react';
import {useNavigate} from 'react-router-dom';
import {useRecoilValue} from 'recoil';
import {getBusinessCode, getBusinessId} from 'state/selectors/business';

export interface BusinessOutput {
  businessId: BusinessId;
  businessCode: BusinessCode;
  setBusinessByCode: (code: BusinessCode) => void;
  setBusinessById: (code: BusinessId) => void;
}

function useBusinessContext(): BusinessOutput {
  const businessId = useRecoilValue<BusinessId>(getBusinessId);
  const businessCode = useRecoilValue<BusinessCode>(getBusinessCode);
  const navigate = useNavigate();
  const isAdminContext = useIsAdminContext();

  // If is in admin context, bring to admin section.
  // If is clinic context, rely on CRM redirection path
  const setBusinessByCode = useCallback(
    (code: BusinessCode) => navigate(`/${code}${isAdminContext ? `/admin` : ''}`),
    [isAdminContext, navigate]
  );
  const setBusinessById = useCallback((id: BusinessId) => setBusinessByCode(BUSINESS_CODES[id]), [setBusinessByCode]);

  return {businessId, businessCode, setBusinessByCode, setBusinessById};
}

export {useBusinessContext};
