import React from 'react';
import styled from 'styled-components';

interface TabPanelProps {
  children: React.ReactNode;
  value: number;
  index: number;
}

const TabPanel: React.FC<React.PropsWithChildren<TabPanelProps>> = React.memo(props => {
  const {children, value, index} = props;
  if (value !== index) return null;
  return (
    <Container role="tabpanel" id={`tabpanel-${index}`} aria-labelledby={`tab-${index}`}>
      <ChildrenContainer>{children}</ChildrenContainer>
    </Container>
  );
});

export {TabPanel};

const Container = styled.div`
  overflow-y: scroll;
  display: flex;
  flex: 1 1 auto;
`;

const ChildrenContainer = styled.div`
  flex: 1;
`;
