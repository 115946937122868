const ROUTE_DIARY = 'diary';
const ROUTE_CLIENTS = 'clients';

export const ROUTES = {
  ROOT: '/',
  // BUSINESS
  ADMIN: 'admin',
  BUSINESS: 'business',
  CLINIC: 'clinic',
  ADMIN_PRODUCTS: 'products',
  ADMIN_PRODUCT_EDIT: 'products/:productId/edit',
  ADMIN_SERVICES: 'services',
  ADMIN_SERVICE_EDIT: 'services/:serviceId/edit',
  ADMIN_COURSES: 'courses',
  ADMIN_COURSE_EDIT: 'courses/:courseId/edit',
  ADMIN_ONLINE_GROUPS: 'online-groups',
  ADMIN_ONLINE_SUB_GROUPS: 'online-groups/:groupId',
  ADMIN_ONLINE_SUBGROUP_EDIT: 'online-groups/:groupId/:subgroupId/edit',
  // CLIENTS
  CLIENTS_CREATE_BOOKING: 'clients/create-booking',
  CLIENTS_CREATE_CLIENT: 'clients/create',
  CLIENTS_MULTI_RESCHEDULE_BOOKING: 'clients/multi-reschedule-booking',
  CLIENTS_SELECT_TREATMENTS: 'clients/select-treatments',
  CLIENTS: ROUTE_CLIENTS,
  CREATE_BOOKING: 'create-booking',
  CREATE_CLIENT: 'create',
  EDIT_CLIENT: ':clientId/edit',
  EDIT: 'edit',
  LOGIN_REDIRECT: 'login-redirect',
  MULTI_RESCHEDULE_BOOKING: 'multi-reschedule-booking',
  SEARCH_CLIENT: 'search-client',
  SELECT_TREATMENTS: 'select-treatments',
  // DIARY
  DIARY: ROUTE_DIARY,
  THERAPIST_VIEW: 'therapist-view',
  CALENDAR_VIEW: 'calendar-view',
  // PURCHASING
  PURCHASE: 'purchase',
  PAYMENT_STRIPE_STANDALONE: 'payment-stripe-standalone',

  // HOME
  DEFAULT_HOME_ROUTE: ROUTE_DIARY,
  AGENTS_HOME_ROUTE: ROUTE_CLIENTS
};

export const ROUTE_REGEX = {
  BUSINESS: new RegExp(`/${ROUTES.BUSINESS}/[\\w-]+`),
  CLINIC: new RegExp(`(/${ROUTES.CLINIC}/[\\w-]+|/${ROUTES.CLINIC}/)`) // match also malformed link like empty clinic id string
};
