import PersonOutlinedIcon from '@material-ui/icons/PersonOutlined';
import ShoppingCartOutlinedIcon from '@material-ui/icons/ShoppingCartOutlined';
import PaymentOutlinedIcon from '@material-ui/icons/PaymentOutlined';
import {ReactComponent as DiaryIcon} from '@therapie-ui/Icons/diary.svg';
import {ReactComponent as SerivcesIcon} from '@therapie-ui/Icons/services.svg';
import {ReactComponent as CoursesIcon} from '@therapie-ui/Icons/courses.svg';
import {ReactComponent as OnlineGroupsIcon} from '@therapie-ui/Icons/online-groups.svg';
import {AppSection} from 'constants/permissions';
import ListAltIcon from '@material-ui/icons/ListAlt';

interface ISections {
  name: AppSection;
  label: string;
  icon?: JSX.Element;
  topSeparator?: boolean;
}

export const SECTIONS: ISections[] = [
  {name: 'DIARY', label: 'Diary', icon: <DiaryIcon />},
  {name: 'CLIENTS', label: 'Clients', icon: <PersonOutlinedIcon />},
  {name: 'PURCHASE', label: 'Purchase', icon: <ShoppingCartOutlinedIcon />},
  {name: 'PAYMENT_STRIPE_STANDALONE', label: 'Stripe Payment', icon: <PaymentOutlinedIcon />}
];

export const ADMIN_SECTIONS: ISections[] = [
  {name: 'ADMIN_PRODUCTS', label: 'Products', icon: <ListAltIcon />},
  {name: 'ADMIN_SERVICES', label: 'Services', icon: <SerivcesIcon />},
  {name: 'ADMIN_COURSES', label: 'Courses', icon: <CoursesIcon />},
  {name: 'ADMIN_ONLINE_GROUPS', label: 'Groups', icon: <OnlineGroupsIcon />, topSeparator: true}
  // TODO: Change these to use a 2D Array and group nav items, using a Separator ^^ between any groups instead of
  // attaching them to sections. More futureproof and removes need for flags on individual items.
  // Dividers shouldn't be attached to section imo - would be better if sections were grouped
];
