import config from 'config';

interface UseCurrencyOutput {
  currencySymbol: string;
  currencyName: string;
  currencyNameLowerCase: string;
  formatCurrency: (args: CurrencyFormatArgs) => string;
}

interface CurrencyFormatArgs {
  value?: number | string | null;
  minimumFractionDigits?: number;
}

function useCurrency(): UseCurrencyOutput {
  const formatCurrency = ({value, minimumFractionDigits = 2}: CurrencyFormatArgs): string => {
    return new Intl.NumberFormat('en', {
      style: 'currency',
      minimumFractionDigits,
      currency: config.CURRENCY
    }).format(value as number);
  };

  return {
    currencySymbol: config.CURRENCY_SYMBOL!,
    currencyName: config.CURRENCY!,
    currencyNameLowerCase: config.CURRENCY!.toLocaleLowerCase(),
    formatCurrency
  };
}
export {useCurrency};
