import COLORS from 'constants/colors';
import {css} from 'styled-components';

// Subtle top and bottom shadows when box is overflown
const scrollShadowMixin = css`
  background: linear-gradient(white 30%, rgba(255, 255, 255, 0)),
    linear-gradient(rgba(255, 255, 255, 0), white 70%) 0 100%,
    radial-gradient(50% 0, farthest-side, rgba(0, 0, 0, 0.05), rgba(0, 0, 0, 0)),
    radial-gradient(50% 100%, farthest-side, rgba(0, 0, 0, 0.05), rgba(0, 0, 0, 0)) 0 100%;
  background: linear-gradient(white 10%, rgba(255, 255, 255, 0)),
    linear-gradient(rgba(255, 255, 255, 0), white 90%) 0 100%,
    radial-gradient(farthest-side at 50% 0, rgba(0, 0, 0, 0.05), rgba(0, 0, 0, 0)),
    radial-gradient(farthest-side at 50% 100%, rgba(0, 0, 0, 0.05), rgba(0, 0, 0, 0)) 0 100%;
  background-repeat: no-repeat;
  background-color: white;
  background-size: 100% 15px, 100% 30px, 100% 5px, 100% 5px;
  background-attachment: local, local, scroll, scroll;
`;

// Just an inversion of the selection styles in index.css. Limited use-cases
const invertedSelectionMixin = css`
  &::selection {
    color: ${COLORS.therapiePurpleD30};
    background: ${COLORS.amber200};
  }
`;

export {scrollShadowMixin, invertedSelectionMixin};
