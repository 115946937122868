import {AxiosResponse} from 'axios';
import {getTotalPrice} from 'utils/currency';
import {isTestClinic} from 'utils/devTools';

export function checkPurchaseIsAllowed(args: {
  clinicId: string;
  isPurchaseForTestClinicEnabled: boolean;
  isPurchaseForProdClinicEnabled: boolean;
}): void {
  // Test Clinic
  if (isTestClinic(args.clinicId) && !args.isPurchaseForTestClinicEnabled) {
    window.alert('Purchase feature is turned off for test clinic.');
    throw new Error('Operation aborted: test clinic purchase');
  }

  // Prod Clinic - throwing error removed as throwing error for unknown reasons
  if (!isTestClinic(args.clinicId) && !args.isPurchaseForProdClinicEnabled) {
    window.alert('Test resumption of production payments.');
  }
}

export const mapAmountToStripeAmount = (amount: number) => getTotalPrice([amount * 100]);

export const mapStripeAmountToAmount = (amount: number) => getTotalPrice([amount / 100]);

export const fetchStripeErrorHandler = (response: AxiosResponse, data: unknown) => {
  if (response.status !== 200) throw new Error((data as Record<'message', string>)?.message ?? 'Unknown error');
};
