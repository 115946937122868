import {UseQueryResult, useQuery} from '@tanstack/react-query';
import {MAX_PAGE_SIZE} from 'constants/pagination';
import {GET_CLIENT_COURSES} from 'data/graphql/queries/courses';
import {useBusinessContext} from 'hooks/business/useBusinessContext';
import {GQLClientContext} from 'providers/gqlClient';
import {useContext, useMemo} from 'react';
import {GET_CLIENT_COURSESQuery, GET_CLIENT_COURSESQueryVariables} from 'types/ApiModel';
import {ActiveClientCoursesModel} from 'types/DerivedApiModel';
import {Nullable} from 'types/common';

interface IUseClientCoursesProps {
  clientId?: string | null;
}

export type IUseActiveClientCoursesOutput = UseQueryResult<ActiveClientCoursesModel[] | undefined | null>;

function useActiveClientCourses({clientId}: IUseClientCoursesProps): IUseActiveClientCoursesOutput {
  const {businessId} = useBusinessContext();
  const clientCoursesQuery = useMemo<Nullable<GET_CLIENT_COURSESQueryVariables>>(
    () => (businessId ? {query: {businessId, clientId}, page: {size: MAX_PAGE_SIZE}} : undefined),
    [businessId, clientId]
  );
  const {gqlClient} = useContext(GQLClientContext);
  return useQuery<ActiveClientCoursesModel[] | undefined>(
    [USE_ACTIVE_CLIENT_COURSES_KEY, clientCoursesQuery],
    async () => {
      const resp = await gqlClient.request<GET_CLIENT_COURSESQuery, Nullable<GET_CLIENT_COURSESQueryVariables>>(
        GET_CLIENT_COURSES,
        clientCoursesQuery
      );
      return resp.data?.getClientCourses?.clientCourses ?? [];
    },
    {enabled: !!clientCoursesQuery && !!clientId}
  );
}

export const USE_ACTIVE_CLIENT_COURSES_KEY = 'USE_ACTIVE_CLIENT_COURSES';

export {useActiveClientCourses};
export default useActiveClientCourses;
