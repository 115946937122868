import React from 'react';
import styled from 'styled-components';

const BulletSeparator: React.FC<React.PropsWithChildren<unknown>> = () => {
  return <StyledSpan>&bull;</StyledSpan>;
};

export default BulletSeparator;

const StyledSpan = styled.span`
  font-size: 0.7em;
  margin: 0 0.62em;
  display: inline-block;
  vertical-align: top;
`;
