import {TabsProps, useMediaQuery, useTheme} from '@material-ui/core';
import {TabPanel, Tabs} from '@therapie-ui';
import CustomerDetails from 'components/Common/Clients/CustomerDetails';
import NewBookingDialog from 'components/NewBookingDialog/NewBookingDialog';
import {PAGE_CONTAINER_WIDTH_PX} from 'constants/layout';
import {styledTheme} from 'providers/theme';
import React, {useState} from 'react';
import styled from 'styled-components';
import {Client} from 'types/DerivedApiModel';
import CustomerProductHistory from './CustomerProductHistory';
import {ClientOverview} from './Tabs/ClientOverview';
import CustomerCourses from './Tabs/CustomerCourses';
import CustomerHistory from './Tabs/CustomerHistory/CustomerHistory';
import CustomerNotes from './Tabs/CustomerNotes';
import CustomerUpcomingAppointmentsGroupedByDate from './Tabs/CustomerUpcomingAppointmentsGroupedByDate/CustomerUpcomingAppointmentsGroupedByDate';
import CustomerVouchers from './Tabs/CustomerVouchers';

interface Props {
  client: Client;
}

const CustomerView: React.FC<React.PropsWithChildren<Props>> = ({client}) => {
  const theme = useTheme();
  const tabsOrientation: TabsProps['orientation'] = useMediaQuery(theme.breakpoints.up('md'))
    ? 'vertical'
    : 'horizontal';
  const [activeTabIndex, setActiveTabIndex] = useState<number>(0);
  const clientId = client.clientId ?? '';
  const tabs = Object.entries({
    Overview: <ClientOverview clientId={clientId} />,
    Notes: <CustomerNotes clientId={clientId} fullHeight />,
    Appointments: <CustomerUpcomingAppointmentsGroupedByDate clientId={clientId} />,
    'Client Courses': <CustomerCourses clientId={clientId} />,
    Vouchers: <CustomerVouchers client={client} />,
    'Service History': <CustomerHistory clientId={clientId} />,
    'Product History': <CustomerProductHistory clientId={clientId} />
  });
  return (
    <>
      <ClientHeader>
        <CustomerDetails client={client} isEditable={true} />
      </ClientHeader>
      <ClientContentContainer>
        <TabContainer>
          <Tabs
            labels={tabs.map(([label]) => label)}
            activeTabIndex={activeTabIndex}
            onChange={(_, index) => setActiveTabIndex(index)}
            tabsProps={{orientation: tabsOrientation, variant: 'scrollable'}}
          />
        </TabContainer>
        <TabPanelContainer>
          {tabs.map(([_, content], index) => (
            <TabPanel value={activeTabIndex} index={index} children={content} />
          ))}
        </TabPanelContainer>
      </ClientContentContainer>
      <NewBookingDialog />
    </>
  );
};

export default CustomerView;

const TAB_SIDEBAR_WIDTH = '220px';

const ClientHeader = styled.div`
  padding: ${({theme}) => theme.spacing(3)};
`;

const ClientContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  width: 100%;
  @media only screen and (min-width: ${styledTheme.breakpoints.values.md}px) {
    flex-direction: row;
    gap: ${({theme}) => theme.spacing(2)};
  }
`;

const TabContainer = styled.div`
  @media only screen and (min-width: ${styledTheme.breakpoints.values.md}px) {
    padding: ${({theme}) => theme.spacing(1.5, 0, 10)};
    flex-basis: ${TAB_SIDEBAR_WIDTH};
    flex-shrink: 0;
    flex-grow: 0;
  }
`;

const TabPanelContainer = styled.div`
  display: flex;
  flex-grow: 1;
  @media only screen and (min-width: ${styledTheme.breakpoints.values.md}px) {
    flex-basis: calc(${PAGE_CONTAINER_WIDTH_PX} - ${({theme}) => theme.spacing(4)} - ${TAB_SIDEBAR_WIDTH});
  }
`;
