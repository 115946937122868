import {COLORS} from 'constants/colors';
import {TOP_BAR_HEIGHT, SIDE_NAV_WIDTH, PAGE_CONTAINER_WIDTH_PX} from 'constants/layout';
import styled from 'styled-components';
import {windowIsIFramed} from 'utils/window';

export const PageContainer = styled.div`
  width: ${windowIsIFramed ? '100vw' : `calc(100vw - ${SIDE_NAV_WIDTH})`};
  height: ${`calc(100vh - ${TOP_BAR_HEIGHT})`};
  overflow: hidden; // if you need to scroll use a child of this with overflow: scroll
  background-color: ${COLORS.ice400};
`;

export const PageContainerScrollable = styled(PageContainer)`
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
`;

/**
 * Use as an immediate child of PageContainerScrollable to give consistent inner content width across the app.
 * When using with a page footer, the footer should be used as a sibling of this inner wrapper so that it doesn't inherit the same width.
 */
export const PageContainerInnerFixedWidth = styled.div`
  align-self: center;
  padding: ${({theme}) => theme.spacing(0, 2)};
  margin: 0 auto auto auto;
  width: 100%;
  max-width: ${PAGE_CONTAINER_WIDTH_PX};
`;
