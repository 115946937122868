import {useMutation, UseMutationResult} from '@tanstack/react-query';
import {ClientModelForPurchase} from 'components/Purchase/types';
import {WALKIN_CLIENT} from 'constants/purchase';
import usePaymentGatewayClient from 'hooks/axios/usePaymentGatewayClient';

type Output = UseMutationResult<StripeCustomerResult, Error, ClientModelForPurchase, unknown>;

export type StripeCustomerResult = {id: string} | undefined;

const useCustomerStripeId = (): Output => {
  const {paymentGatewayClient} = usePaymentGatewayClient();

  return useMutation<StripeCustomerResult, Error, ClientModelForPurchase, unknown>({
    mutationFn: async client => {
      if (!client || client === WALKIN_CLIENT) return;
      const name = `${client.firstName} ${client.lastName}`;
      const response = await paymentGatewayClient.put(`/customers`, JSON.stringify({name, phorestId: client.id!}));
      return response.data;
    },
    onError: e => {
      console.error('Error getting or creating Stripe Customer', e);
      throw new Error(e.message);
    },
    mutationKey: [USE_CUSTOMER_STRIPE_ID_KEY]
  });
};

export {useCustomerStripeId};

const USE_CUSTOMER_STRIPE_ID_KEY = 'USE_CUSTOMER_STRIPE_ID';
