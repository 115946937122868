// TODO: play with this and GET_QUERY_AVAILABILTY_Variables like ex:
// QUERY_KEYS: {[key: string]: keyof GET_QUERY_AVAILABILTY_Variables}
// the values could be the once used inthe availabilty api

export enum QUERY_KEYS {
  APPOINTMENT_DATE = 'appointmentDate',
  APPOINTMENT_CLINIC_ID = 'appointmentClinicId',
  APPOINTMENT_ID = 'appointmentId',
  BOOKING_ID = 'bookingId',
  BRANCH_ID = 'branchId',
  BRAND_ID = 'brandId',
  BUSINESS_ID = 'businessId',
  CATEGORY_ID = 'categoryId',
  CLIENT_COURSE_ITEM = 'clientCourseItemId',
  CLIENT_ID = 'clientId',
  CLINIC_KEY = 'clinicId',
  COURSE_ID = 'courseId',
  DATE_KEY = 'date',
  EDIT_SCOPE = 'editScope',
  EMAIL = 'email',
  FIRST_NAME = 'firstName',
  FROM_DATE_KEY = 'fromDate',
  LAST_NAME = 'lastName',
  NAME = 'name',
  NAME_OR_SKU_CODE = 'nameOrSkuCode',
  OPEN_COURSES_TAB = 'openCoursesTab',
  ORIGIN = 'origin',
  PAGE_NUMBER = 'pageNumber',
  PHONE_NUMBER = 'phone',
  SHOW_ONLY_CLIENT_VIEW = 'showOnlyClientView',
  STAFF_KEY = 'staffId',
  ONLINE_STATUS = 'onlineStatus'
}
