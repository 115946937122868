export enum ERROR_STATUS {
  NOT_FOUND = 'NOT_FOUND',
  SLOT_UNAVAILABLE = 'SLOT_UNAVAILABLE',
  STAFF_DOUBLE_BOOKED = 'STAFF_DOUBLE_BOOKED',
  STAFF_NOT_WORKING = 'STAFF_NOT_WORKING',
  CLIENT_ALREADY_BOOKED_THIS_TIME = 'CLIENT_ALREADY_BOOKED_THIS_TIME'
}

export enum STAFF_PREFERENCE_STATUS {
  NO_STAFF_PREFERENCE = 'NO_STAFF_PREFERENCE',
  PREFERRED_STAFF_BOOKING_SUCCESSFUL = 'PREFERRED_STAFF_BOOKING_SUCCESSFUL',
  PREFERRED_STAFF_BOOKING_UNSUCCESSFUL = 'PREFERRED_STAFF_BOOKING_UNSUCCESSFUL'
}

const SILLY_LOADING_MESSAGES = [
  'Collecting samples...',
  'Emptying internal sharps bin...',
  'Freezing data samples...',
  'Injecting client data...',
  'Loading...',
  'Organising treatments...',
  'Powering up...',
  'Preheating the lasers...',
  'Rejuvenating interface buttons...',
  'Reticulating Splines...',
  'Sculpting user interface nodes...',
  'Smoothing excess data wrinkles...',
  'Synchronising clinics...'
];
export const RANDOM_LOADING_MESSAGE = SILLY_LOADING_MESSAGES[Math.floor(Math.random() * SILLY_LOADING_MESSAGES.length)];
