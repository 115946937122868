import {PaymentIntent} from '@stripe/stripe-js';
import {useMutation, UseMutationResult} from '@tanstack/react-query';
import {CreatePaymentIntentProps} from 'components/Purchase/Stripe/types';
import {fetchStripeErrorHandler} from 'components/Purchase/utils/purchase';
import {useCurrency} from 'hooks';
import usePaymentGatewayClient from 'hooks/axios/usePaymentGatewayClient';

type Output = UseMutationResult<PaymentIntent, Error, CreatePaymentIntentProps>;

const useCreatePaymentIntent = (): Output => {
  const {currencyNameLowerCase} = useCurrency();
  const {paymentGatewayClient} = usePaymentGatewayClient();

  return useMutation<PaymentIntent, Error, CreatePaymentIntentProps>({
    mutationFn: async props => {
      const {
        amount,
        captureMethod,
        confirm,
        metadata,
        paymentMethod,
        paymentMethodOptions,
        paymentMethodTypes,
        stripeCustomer
      } = props;

      const response = await paymentGatewayClient.post(
        '/payments',
        JSON.stringify({
          amount,
          captureMethod,
          confirm,
          currency: currencyNameLowerCase,
          customerId: stripeCustomer?.id,
          metadata,
          paymentMethod,
          paymentMethodTypes,
          paymentMethodOptions
        })
      );
      const paymentIntent = response.data;
      fetchStripeErrorHandler(response, paymentIntent);
      return paymentIntent;
    },
    onError: e => {
      console.error(`Error getting or creating Stripe Customer`, e);
      throw new Error(e.message);
    },
    mutationKey: [USE_CREATE_PAYMENT_INTENT_KEY]
  });
};

export {useCreatePaymentIntent};

const USE_CREATE_PAYMENT_INTENT_KEY = 'USE_CREATE_PAYMENT_INTENT';
