import {FLOW_TYPE} from 'components/Purchase/Stripe/types';
import {PaymentTypeInstance} from 'components/Purchase/types';
import {fromPaymentListTypeToPaymentTypeInstance} from 'components/Purchase/utils/mappers';
import {
  DEFAULT_PAYMENT_TYPES,
  PAYMENT_TYPE_CODES,
  VIRTUAL_TERMINAL_TYPE_CODE,
  VOUCHER_PAYMENT_TYPE_CODE,
  KLARNA_PAYMENT_TYPE_CODE
} from 'constants/payments';
import {PURCHASE_ATOM_PREFIX} from 'constants/purchase';
import {selector} from 'recoil';
import {paymentTypeInstancesState, paymentTypeListState, paymentTypeListStatusState} from 'state/atoms/purchase';
import {AtomStatusState} from 'state/atoms/purchase/types';
import {PurchasePaymentInput} from 'types/ApiModel';
import {PaymentType} from 'types/DerivedApiModel';
import {cardPaymentFlowSelector} from './cardPaymentFlow';

export interface PaymentTypesSelectorOutput<T> extends AtomStatusState {
  data: T[];
}

const paymentTypeListSelector = selector<PaymentTypesSelectorOutput<PaymentType>>({
  key: `${PURCHASE_ATOM_PREFIX}PaymentTypeListSelector`,
  get: ({get}) => ({
    data: get(paymentTypeListState),
    ...get(paymentTypeListStatusState)
  })
});

const paymentTypesPayloadSelector = selector<PurchasePaymentInput[]>({
  key: `${PURCHASE_ATOM_PREFIX}PaymentTypesPayloadSelector`,
  get: ({get}) => {
    const virtualTerminalPaymentType = get(paymentTypeListState).find(({code}) => code === VIRTUAL_TERMINAL_TYPE_CODE);
    const cardPaymentSteps = get(cardPaymentFlowSelector).cardPaymentSteps;
    return get(paymentTypeInstancesState)
      .filter(({amount}) => amount && amount > 0)
      .map(payment => {
        const isMoto =
          cardPaymentSteps.find(({paymentInstanceId}) => paymentInstanceId === payment.id)?.type === FLOW_TYPE.MOTO;
        return {
          amount: payment.amount ?? 0,
          name: (isMoto ? virtualTerminalPaymentType?.name : payment.name) ?? '',
          paymentTypeId: (isMoto ? virtualTerminalPaymentType?.paymentTypeId : payment.paymentTypeId) ?? '',
          ...(payment.voucherId ? {voucherId: payment.voucherId} : {})
        };
      });
  }
});

const stripePaymentInstancesSelector = selector<PaymentTypeInstance[]>({
  key: `${PURCHASE_ATOM_PREFIX}PaymentTypesStripeSelector`,
  get: ({get}) =>
    get(paymentTypeInstancesState).filter(
      ({code, amount, name}) =>
        (name && name != 'Klarna') && (amount && amount > 0) && [PAYMENT_TYPE_CODES.CP, PAYMENT_TYPE_CODES.VI].includes(code as PAYMENT_TYPE_CODES)
    )
});

const defaultPaymentInstancesSelector = selector<PaymentTypeInstance[]>({
  key: `${PURCHASE_ATOM_PREFIX}defaultPaymentInstancesSelector`,
  get: ({get}) => {
    const sortedDefaults: PaymentTypeInstance[] = [];
    const paymentTypeList = get(paymentTypeListState);

    DEFAULT_PAYMENT_TYPES.forEach(defaultType => {
      const paymentType = paymentTypeList.find(({name}) => name && name.toUpperCase() === defaultType.toUpperCase());
      if (paymentType) sortedDefaults.push(fromPaymentListTypeToPaymentTypeInstance(paymentType));
    });
    return sortedDefaults;
  }
});

const paymentTypeInstancesSelector = selector<PaymentTypeInstance[]>({
  key: `${PURCHASE_ATOM_PREFIX}paymentTypeInstancesSelector`,
  get: ({get}) => {
    const instances = get(paymentTypeInstancesState);
    const instancesIncludeNonNuaVouchers = instances.some(
      ({voucherSerialNumber, isVoucherNua}) => !!voucherSerialNumber && !isVoucherNua
    );

    // Enriches with voucherPlaceholder flag if it's a voucher type instance without a serial
    return (
      instances
        .map(instance => ({
          ...instance,
          isVoucherPlaceholder:
            instance.code === VOUCHER_PAYMENT_TYPE_CODE && !instance.amount && !instance.voucherSerialNumber
        }))
        // Filters out voucher placeholder if we've already got some vouchers added -
        // we only show it if there's no vouchers that aren't Nua. We show Nua as a default if client has credit.
        //.filter(({isVoucherPlaceholder}) => (isVoucherPlaceholder ? !instancesIncludeNonNuaVouchers : true))
        // sort the table instead to see if it looks better
    );
  }
});


export {
  paymentTypeListSelector,
  stripePaymentInstancesSelector,
  paymentTypesPayloadSelector,
  defaultPaymentInstancesSelector,
  paymentTypeInstancesSelector
};
