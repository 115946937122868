import {TablePaginationProps} from '@material-ui/core/TablePagination';
import {Badge} from '@therapie-ui';
import {BaseItem, Column, Table} from '@therapie-ui/Table/Table';
import {SEVERITY} from 'constants/notifications';
import {DEFAULT_PAGE_SIZE} from 'constants/pagination';
import {isFuture} from 'date-fns';
import {useClientAppointments} from 'hooks';
import {useCurrency} from 'hooks/currency/useCurrency';
import React, {Fragment, useCallback, useContext, useMemo, useState} from 'react';
import {useRecoilValue} from 'recoil';
import {branchesMapState} from 'state';
import styled from 'styled-components';
import {ServiceHistoryModel, SingleServiceHistory} from 'types/DerivedApiModel';
import {formatUTCDate, getTotalPrice} from 'utils';
import {LocaleContext} from 'providers/locale';

interface Props {
  clientId: string;
}

const ServiceRecordsTable: React.FC<React.PropsWithChildren<Props>> = ({clientId}) => {
  const {formatCurrency} = useCurrency();
  const [pageNumber, setPageNumber] = useState(0);
  const branchMap = useRecoilValue(branchesMapState);
  const {locale} = useContext(LocaleContext);

  const {
    allServiceHistories,
    loading: servicesLoading,
    data
  } = useClientAppointments({
    clientId,
    page: {page: pageNumber, size: DEFAULT_PAGE_SIZE}
  });

  const tableItems: ServiceHistoryTableItem[] = useMemo(
    () => allServiceHistories?.map(service => ({...service, id: service?.services?.[0]?.appointmentId ?? ''})) ?? [],
    [allServiceHistories]
  );

  const handleChangePage = useCallback<TablePaginationProps['onPageChange']>(
    (_, newPage) => {
      setPageNumber(newPage);
    },
    [setPageNumber]
  );

  const getStaffCellText = useCallback(
    (services: SingleServiceHistory[] = []) =>
      services.map(service => {
        const {firstName, lastName} = service?.staff ?? {};
        const staffName = firstName ? [firstName, lastName].join(' ') : '-';
        return (
          <Fragment key={service?.appointmentId + 'staff'}>
            {staffName}
            <br />
          </Fragment>
        );
      }),
    []
  );

  const getDescriptionCellText = useCallback(
    (services: SingleServiceHistory[] = []) =>
      services.map(service => (
        <Fragment key={service?.appointmentId + 'description'}>
          {service?.description ?? '-'}
          <br />
        </Fragment>
      )),
    []
  );

  const getPriceCellText = useCallback(
    (services: SingleServiceHistory[] = []) =>
      services.map(service => (
        <Fragment key={service?.appointmentId + 'price'}>
          {service?.cancelled
            ? 'Cancelled'
            : service?.fee
            ? formatCurrency({value: getTotalPrice([service.fee, -(service.discount ?? 0)])})
            : '-'}
          <br />
        </Fragment>
      )),
    [formatCurrency]
  );

  const renderCell = useCallback(
    (item: ServiceHistoryTableItem, column: Column) => {
      const serviceIndex = column.id as keyof ServiceHistoryTableItem;
      const purchaseDateTime = formatUTCDate(item?.services?.[0]?.time!, 'DATE_MONTH_YEAR_AM_PM_LOCALISED', locale);
      const timeOfFinalService = item?.services?.at(-1)?.time;
      const isUpcoming = !!timeOfFinalService && isFuture(new Date(timeOfFinalService));
      switch (column.id) {
        case 'date':
          return (
            <>
              {purchaseDateTime}{' '}
              {isUpcoming && (
                <UpcomingBadgeWrap>
                  <Badge variant={SEVERITY.WARNING} children={'Upcoming'} size={'small'} />
                </UpcomingBadgeWrap>
              )}
            </>
          );
        case 'clinic':
          return <>{branchMap[item.branchId as string]?.name}</>;
        case 'staff':
          return getStaffCellText(item.services ?? []);
        case 'description':
          return getDescriptionCellText(item.services ?? []);
        case 'price':
          return getPriceCellText(item.services ?? []);
        default:
          return <>{item[serviceIndex]}</>;
      }
    },
    [branchMap, getDescriptionCellText, getPriceCellText, getStaffCellText, locale]
  );

  // TODO: Check, We're using a selector here for data, so page data probably doesn't align with the filtered data
  // Can be seen on tables where there's only future appts, pagination AND emptystate both visible
  // Bug: https://therapie.atlassian.net/browse/CC-2814

  return (
    <Table<ServiceHistoryTableItem>
      columns={columns}
      items={tableItems}
      isLoading={servicesLoading}
      renderCell={renderCell}
      size={'small'}
      stickyHeader
      stickyFooter
      emptyStateProps={{
        title: 'No Services',
        details: 'This client has no service history'
      }}
      paginationProps={{
        count: data?.getServiceHistory?.page?.totalElements ?? 0,
        page: pageNumber,
        rowsPerPage: DEFAULT_PAGE_SIZE,
        rowsPerPageOptions: [],
        onPageChange: handleChangePage
      }}
    />
  );
};

interface ServiceHistoryTableItem extends ServiceHistoryModel, BaseItem {}

const columns: Column[] = [
  {id: 'date', label: 'Date', style: {verticalAlign: 'top', width: '180px'}},
  {id: 'clinic', label: 'Clinic', style: {verticalAlign: 'top'}},
  {id: 'staff', label: 'Staff', style: {verticalAlign: 'top'}},
  {id: 'description', label: 'Description', style: {verticalAlign: 'top'}},
  {id: 'price', label: 'Price', style: {textAlign: 'right', verticalAlign: 'top'}}
];

export default ServiceRecordsTable;

const UpcomingBadgeWrap = styled.span`
  margin: ${({theme}) => theme.spacing(0.5, 0)};
  display: block;
`;
