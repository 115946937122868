// Custom colors

const COLORS_CUSTOM = {
  grapeFruit: '#D5C3FF',
  grapeJuice: '#6D37FF',
  iconicGrey: '#767783',
  iconicGreyD20: '#5E5F69',
  iconicGreyD40: '#47474F',
  purpleL9064P: '#F6F6FB',
  purpleL90D4: '#E7E7EE',
  red100: '#FBEFF0',
  red200: '#F5D6D9',
  red300: '#E35B66',
  red400: '#CC3340',
  red500: '#8C232C',
  red600: '#681219'
} as const;

export default COLORS_CUSTOM;
export {COLORS_CUSTOM};
