import {InputAdornment, Tooltip} from '@material-ui/core';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import {Button} from '@therapie-ui';
import InlineEdit from '@therapie-ui/Forms/InlineEdit';
import NumberInput from '@therapie-ui/Forms/NumberInput';
import COLORS from 'constants/colors/index';
import {useCurrency} from 'hooks/currency/useCurrency';
import React, {FocusEventHandler, useCallback} from 'react';
import styled from 'styled-components';
import {PaymentTypeTableItem} from './PaymentTypesSection';

interface Props {
  amountOfPaymentDueAfterDiscounts: number;
  getInputDisableStatus: (item: PaymentTypeTableItem) => boolean;
  isErrored: boolean;
  isFormDisabled: boolean;
  item: PaymentTypeTableItem;
  rowIndex: number;
  updatePaymentTypeAmount: (item: PaymentTypeTableItem, amount: string) => void;
  updatePaymentTypeAmountWithAllIn: (item: PaymentTypeTableItem) => void;
  resetPaymentTypeAmount: (item: PaymentTypeTableItem) => void;
}

export const PaymentTableInputCell: React.FC<React.PropsWithChildren<Props>> = React.memo(
  ({
    amountOfPaymentDueAfterDiscounts,
    getInputDisableStatus,
    isErrored,
    isFormDisabled,
    item,
    rowIndex,
    updatePaymentTypeAmount,
    updatePaymentTypeAmountWithAllIn,
    resetPaymentTypeAmount
  }) => {
    const {currencySymbol} = useCurrency();
    const itemShouldShowValidationErrors = !!item?.amount && item.amount > 0; // Stops us showing errors on empty voucher inputs
    const testUid = item.name?.split(' ')[0].toLowerCase();
    const value = item.amount ?? '';

    const updateAmount = useCallback(
  
          (amount: string) => (amount.length === 0 ? resetPaymentTypeAmount(item) : updatePaymentTypeAmount(item, amount)),
        
      [item, resetPaymentTypeAmount, updatePaymentTypeAmount]
    );

    const resetFalsyAmount: FocusEventHandler<HTMLInputElement> = useCallback(
      amount => {
        // If it's not a voucher placeholder and the value is 0 or undefined, set amount as undefined
          
          if (!item.isVoucherPlaceholder && !parseFloat(amount.target.value)) resetPaymentTypeAmount(item);
          
      },
      [item, resetPaymentTypeAmount]
    );

    return (
      <AmountCellContainer>
        <Tooltip title="Apply full amount" placement="top">
          <span>
            <TableButton
              icon={<ArrowForwardIcon />}
              variant="outlined"
              onClick={() => updatePaymentTypeAmountWithAllIn(item)}
              disabled={!amountOfPaymentDueAfterDiscounts || isFormDisabled}
              data-test-id={`btn-apply-full-amount-${testUid}`}
            />
          </span>
        </Tooltip>
        <InlineEdit
          InputComponent={AmountInput}
          inputProps={{
            error: itemShouldShowValidationErrors && isErrored,
            helperText: itemShouldShowValidationErrors && isErrored && item.error,
            disabled: getInputDisableStatus(item),
            size: 'small',
            name: `payment[${rowIndex}].amount`,
            variant: 'outlined',
            onBlur: resetFalsyAmount,
            InputProps: {startAdornment: <InputAdornment position="start" children={currencySymbol} />}
          }}
          value={value}
          onChangeValue={updateAmount}
          dataTestId={`input-amount-${testUid}`}
        />
      </AmountCellContainer>
    );
  }
);

const AmountCellContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: flex-start;
  input {
    text-align: end;
  }
`;

const TableButton = styled(Button)`
  margin-right: ${({theme}) => theme.spacing(2)};
`;

const AmountInput = styled(NumberInput)`
  width: 215px;
  border-radius: ${({theme}) => theme.shape.borderRadius}px;
  fieldset {
    border-radius: ${({theme}) => theme.shape.borderRadius}px;
  }
  > div {
    background-color: ${COLORS.ice100};
  }
`;