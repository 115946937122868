import React from 'react';
import {Button} from '@therapie-ui';
import COLORS from 'constants/colors';
import styled, {css} from 'styled-components';
import {ButtonProps} from '@therapie-ui/Buttons/Button';
import {TOP_BAR_INNER_HEIGHT} from 'constants/layout';

interface AppBarButtonProps extends ButtonProps {
  isActive?: boolean;
  isFocused?: boolean; // Forced focus styles, eg for when UserContext menu is active
}

const AppBarButton: React.FC<React.PropsWithChildren<AppBarButtonProps>> = React.memo(props => (
  <StyledButton variant="ghost" {...props} />
));

const StyledButton = styled(Button)<AppBarButtonProps>`
  height: ${TOP_BAR_INNER_HEIGHT};
  min-height: unset;
  color: ${COLORS.therapiePurpleL90};
  padding: 0; // handle spacing with children because of awkward edge case - CC-2242
  box-shadow: 0 0 0 3px transparent;
  transition: box-shadow 200ms ease;

  &:focus {
    background: ${COLORS.therapiePurpleD10};
    box-shadow: 0 0 0 3px ${COLORS.therapiePurpleL20};
  }

  &:active {
    background: ${COLORS.therapiePurpleL10};
    color: ${COLORS.ice100};
  }
  &:hover {
    background: ${COLORS.therapiePurple};
    color: ${COLORS.ice100};
  }

  ${({isActive}) =>
    isActive &&
    css`
      background: ${COLORS.therapiePurpleL10};
      color: ${COLORS.ice100};
    `}

  ${({isFocused}) =>
    isFocused &&
    css`
      background: ${COLORS.therapiePurpleD10};
      box-shadow: 0 0 0 3px ${COLORS.therapiePurpleL20};
    `}

  // Icon (user account button)
  & > :first-child {
    margin: ${({theme}) => theme.spacing(0, 1, 0, 1)};
  }
  // Text (user account button)
  & > :last-child {
    margin: ${({theme}) => theme.spacing(0, 1, 0, 0)};
  }
  // Text only (Flex nav)
  & > :only-child {
    margin: ${({theme}) => theme.spacing(0, 1.5)};
  }

  svg {
    color: ${COLORS.therapiePurpleL90};
  }
`;

export default AppBarButton;
export {AppBarButton};
