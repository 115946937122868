import {useTheme} from 'styled-components';
const DEFAULT_BASE_FONT_SIZE = 16; // Most common native default font size in browsers.

/**
 * Gives you the base font size from the theme if available.
 * Falls back to 16px.
 * @returns string: PX based font size which all rems will be based on
 */
function useBaseFontSize() {
  const {baseFontSize} = useTheme() ?? {};
  return baseFontSize ?? DEFAULT_BASE_FONT_SIZE;
}

export {useBaseFontSize};
export default useBaseFontSize;
