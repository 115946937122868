import config from 'config';
import {BUSINESS_UIDS, BusinessId, BUSINESSES_IN_CALL_CENTRE, BusinessCode} from 'constants/business';

const callCentreFilter = ([businessCode]: [string, BusinessId]) =>
  !config.IS_CALL_CENTRE || BUSINESSES_IN_CALL_CENTRE.includes(businessCode as BusinessCode);

interface VisibleBusinesses {
  businessCode: string;
  businessId: BusinessId;
  businessAcronym: string;
}

const visibleBusinesses: VisibleBusinesses[] = Object.entries(BUSINESS_UIDS)
  .filter(callCentreFilter)
  .map(([businessCode, businessId]) => ({businessCode, businessId, businessAcronym: businessCode.toUpperCase()}));

export {visibleBusinesses};
