import {IconButton} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import {SnackbarProvider as NoiStackSnackbarProvider} from 'notistack';
import React from 'react';

const SnackbarProvider: React.FC<React.PropsWithChildren<unknown>> = ({children}) => {
  const notistackRef = React.createRef<NoiStackSnackbarProvider>();
  const onClickDismiss = (key: string | number) => () => notistackRef?.current?.closeSnackbar(key);

  return (
    <NoiStackSnackbarProvider
      ref={notistackRef}
      action={key => (
        <React.Fragment>
          <IconButton size="small" aria-label="close" color="inherit" onClick={onClickDismiss(key)}>
            <CloseIcon fontSize="small" />
          </IconButton>
        </React.Fragment>
      )}
      maxSnack={2}
    >
      {children}
    </NoiStackSnackbarProvider>
  );
};
export {SnackbarProvider};
export default SnackbarProvider;
