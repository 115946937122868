import {captureException} from '@sentry/react';
import {useQuery} from '@tanstack/react-query';
import {RQ_CACHE_TIMES, RQ_STALE_TIMES} from 'constants/reactquery';
import {GET_ALL_BRANCH_SERVICES} from 'data/graphql/queries/treatments';
import {useBusinessContext} from 'hooks/business/useBusinessContext';
import useNotifications from 'hooks/notifications/useNotifications';
import {GQLClientContext} from 'providers/gqlClient';
import {useContext} from 'react';
import {GET_ALL_BRANCH_SERVICESQuery, GET_ALL_BRANCH_SERVICESQueryVariables} from 'types/ApiModel';
import {TreatmentModel} from 'types/DerivedApiModel';

interface Output {
  services: TreatmentModel[];
  isLoading: boolean;
  error?: unknown;
}

function useAllBranchServices({branchId, includeArchived}: GET_ALL_BRANCH_SERVICESQueryVariables['query']): Output {
  const {gqlClient} = useContext(GQLClientContext);
  const {notifyError} = useNotifications();
  const {businessId} = useBusinessContext();

  const {data, isLoading, isFetching, error} = useQuery<GET_ALL_BRANCH_SERVICESQuery['getAllBranchServices'], Error>(
    [GET_ALL_BRANCH_SERVICES_KEY, branchId],
    async () => {
      const resp = await gqlClient.request<GET_ALL_BRANCH_SERVICESQuery, GET_ALL_BRANCH_SERVICESQueryVariables>(
        GET_ALL_BRANCH_SERVICES,
        {
          query: {
            businessId,
            branchId,
            includeArchived
          }
        }
      );
      return resp.data.getAllBranchServices;
    },
    {
      cacheTime: RQ_CACHE_TIMES.useAllBranchServiceCategories,
      staleTime: RQ_STALE_TIMES.useAllBranchServiceCategories,
      //
      enabled: !!businessId && !!branchId,
      onError: error => {
        const errorString = error.message;
        notifyError(`Error getting Branch Services data: ${errorString}`);
        captureException(new Error(errorString));
      }
    }
  );

  return {
    services: data ?? [],
    isLoading: isLoading || isFetching,
    error: error
  };
}

export {useAllBranchServices};
export default useAllBranchServices;

export const GET_ALL_BRANCH_SERVICES_KEY = 'GET_ALL_BRANCH_SERVICES';
