import React from 'react';
import {QueryClient, QueryClientProvider} from '@tanstack/react-query';
import {ReactQueryDevtools} from '@tanstack/react-query-devtools';

const queryClient = new QueryClient({
  defaultOptions: {queries: {refetchInterval: 0, refetchOnWindowFocus: false, retry: false}}
});
const CustomQueryClientProvider: React.FC<React.PropsWithChildren<unknown>> = React.memo(({children}) => {
  return (
    <QueryClientProvider client={queryClient}>
      {children}
      <ReactQueryDevtools initialIsOpen={false} />
    </QueryClientProvider>
  );
});

export {CustomQueryClientProvider as ReactQueryProvider};
