import {Button, Dialog, DialogActions, DialogContent, DialogHeader} from '@therapie-ui';
import React from 'react';
import styled from 'styled-components';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';

interface Props {
  buttonLabel?: string;
  contentSideIcon?: JSX.Element;
  onClose?: () => void;
  message: string;
  title: string;
}

const InfoDialog: React.FC<React.PropsWithChildren<Props>> = React.memo(
  ({buttonLabel = 'Done', contentSideIcon, onClose, message, title}) => {
    return (
      <Dialog open={true} transitionDuration={120} fullWidth={true} maxWidth="xs">
        <DialogHeader title={title} hasBorder />
        <Content>
          {contentSideIcon ?? <InfoOutlinedIcon />}
          {message}
        </Content>
        <DialogActions>
          <Button variant="filled" onClick={onClose} children={buttonLabel} />
        </DialogActions>
      </Dialog>
    );
  }
);

export default InfoDialog;

const Content = styled(DialogContent)`
  display: flex;
  align-items: center;
  padding: ${({theme}) => theme.spacing(6, 3)};
  line-height: 1.1875rem;
  svg {
    margin-right: ${({theme}) => theme.spacing(1.625)};
    color: #7a7a7a;
  }
`;
