import queryString from 'query-string';
import {ClientsInput, GET_CLIENTSQueryVariables} from 'types/ApiModel';

const removeNils = (obj: Partial<ClientsInput>): ClientsInput => {
  const entries = Object.entries(obj).filter(el => !!el[1]); // keep the not empty
  return Object.fromEntries(entries) as ClientsInput;
};

export const mergeClientsQueryProps = (props: Partial<ClientsInput>): GET_CLIENTSQueryVariables => ({
  page: {size: 5},
  query: removeNils(props)
});

export const parseUrlParams = (value: string): unknown => queryString.parse(value, {arrayFormat: 'bracket'});
export const stringifyUrlParams = (obj: unknown): string =>
  queryString.stringify(obj as Record<string, unknown>, {arrayFormat: 'bracket'});
