import {UseQueryResult, useQuery} from '@tanstack/react-query';
import config from 'config';
import usePaymentGatewayClient from 'hooks/axios/usePaymentGatewayClient';
import useClinicContext from 'hooks/clinic/useClinicContext';
import {useMemo} from 'react';
import {CartCalculation, CartCalculationInput} from './types';

export type CartCalculationInputProps = Omit<CartCalculationInput, 'clinicId' | 'isPriceTaxInclusive'>;

type Output = UseQueryResult<CartCalculation, Error>;

const useCartCalculation = ({items, payments}: CartCalculationInputProps): Output => {
  const {paymentGatewayClient} = usePaymentGatewayClient();
  const {clinicId} = useClinicContext();
  const isPriceTaxInclusive = config.IS_PRICE_TAX_INCLUSIVE;

  const requestBody = useMemo(
    () => JSON.stringify({items, payments, clinicId, isPriceTaxInclusive}),
    [clinicId, isPriceTaxInclusive, items, payments]
  );

  return useQuery<CartCalculation, Error>({
    queryKey: [USE_CART_CALCULATION_KEY, requestBody],
    queryFn: async () => {
      if (!JSON.parse(requestBody)?.items?.length) return null;
      const {data} = await paymentGatewayClient.post(`/calculateCart`, requestBody);
      return data;
    },
    onError: e => {
      console.error('Error getting tax calulation', e);
      throw new Error(e.message);
    }
  });
};

export {useCartCalculation};

const USE_CART_CALCULATION_KEY = 'USE_CART_CALCULATION';
