import {useClient, useUpdateClient} from 'hooks';
import {useCallback} from 'react';
import {ClientInput} from 'types/ApiModel';
import {TGQLCustomErrorsViaReactQuery} from 'types/errors';

interface IUseClientNotesProps {
  businessId: string;
  clientId?: string;
}

interface IUseClientNotesOutput {
  update(note: string): void;
  loading: boolean;
  error: TGQLCustomErrorsViaReactQuery | null;
}

function useUpdateClientNotes({businessId, clientId}: IUseClientNotesProps): IUseClientNotesOutput {
  const {mutateAsync: updateClient, isLoading: loadingUpdateClient, error: errorUpdate} = useUpdateClient();

  // Refetch is required here so we can avoid mismatched version numbers when users
  // are updating the same user in Phorest or another instance of therapiecrm.
  const {refetch: refetchClient} = useClient({
    businessId,
    clientId: clientId ?? '',
    enabled: false // We dont want to fire the default request here, we only want access to the refetch method
  });

  const update = useCallback(
    async (newNote: string) => {
      const {data: latestClient} = await refetchClient(); // To avoid 409 errors

      try {
        const payload: ClientInput = {
          firstName: latestClient?.firstName || '',
          lastName: latestClient?.lastName || '',
          version: latestClient?.version || -1,
          clientId: latestClient?.clientId || '',
          notes: newNote
        };

        await updateClient({businessId, client: payload});
        refetchClient();
      } catch (error) {
        return Promise.reject(error);
      }
    },
    [businessId, refetchClient, updateClient]
  );

  return {
    loading: loadingUpdateClient,
    error: errorUpdate,
    update
  };
}

export {useUpdateClientNotes};
export default useUpdateClientNotes;
