import {PURCHASE_ITEM_TYPE} from 'constants/payments';
import {PURCHASE_ATOM_PREFIX, WALKIN_CLIENT} from 'constants/purchase';
import {selector} from 'recoil';
import {clinicState} from 'state/atoms';
import {purchaseClientState, purchaseItemsState} from 'state/atoms/purchase';
import {PurchaseItemInput} from 'types/ApiModel';

const purchaseItemsPayloadSelector = selector<PurchaseItemInput[]>({
  key: `${PURCHASE_ATOM_PREFIX}PurchaseItemsPayloadSelector`,
  get: ({get}) => {
    const client = get(purchaseClientState);
    return get(purchaseItemsState).map(pItem => ({
      appointmentId: pItem.appointmentId,
      serviceId: pItem.type === PURCHASE_ITEM_TYPE.TREATMENT ? pItem.serviceId : undefined,
      branchProductId: pItem.type === PURCHASE_ITEM_TYPE.PRODUCT ? pItem.productId : undefined,
      courseId: pItem.type === PURCHASE_ITEM_TYPE.COURSE ? pItem.courseId : undefined,
      voucher:
        pItem.type === PURCHASE_ITEM_TYPE.VOUCHER
          ? {
              clientId: client !== WALKIN_CLIENT ? client?.clientId : undefined,
              creatingBranchId: get(clinicState)?.id ?? '',
              originalBalance: pItem.price ?? 0,
              serialNumber: pItem.voucherId ?? ''
            }
          : undefined,
      description: pItem.title,
      price: pItem.price,
      discount: pItem.discount,
      quantity: pItem.quantity,
      staffId: pItem.staffId as string
    }));
  }
});

export {purchaseItemsPayloadSelector};
