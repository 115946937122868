import {gql} from 'utils/graphql';

export const CREATE_STAFF_BREAK = gql`
  mutation CREATE_STAFF_BREAK($query: CreateStaffBreakInput!) {
    createStaffBreak(payload: $query) {
      endTimeISO
      label
      staffBreakId
      staffId
      startTimeISO
    }
  }
`;

export const UPDATE_STAFF_BREAK = gql`
  mutation UPDATE_STAFF_BREAK($payload: UpdateStaffBreakInput!) {
    updateStaffBreak(payload: $payload) {
      staffBreakId
      staffId
      startTimeISO
      endTimeISO
      label
    }
  }
`;

export const DELETE_STAFF_BREAK = gql`
  mutation DELETE_STAFF_BREAK($payload: DeleteStaffBreakInput!) {
    deleteStaffBreak(payload: $payload)
  }
`;
