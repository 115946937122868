import {BusinessCode} from 'constants/business';
import React, {useEffect} from 'react';
import {useNavigate} from 'react-router';
import {CtxStorage} from 'utils/storage/contextStorage';

interface Props {
  businessCode?: BusinessCode;
}

const ClinicRedirect: React.FC<Props> = React.memo(() => {
  const navigate = useNavigate();
  useEffect(() => {
    navigate(CtxStorage.clinicId);
  }, [navigate]);
  return null;
});

export {ClinicRedirect};
