import {captureException} from '@sentry/react';
import {useQuery, UseQueryResult} from '@tanstack/react-query';
import {RQ_CACHE_TIMES, RQ_STALE_TIMES} from 'constants/reactquery';
import {GET_ALL_BRANCH_STAFF} from 'data/graphql/queries';
import {useBusinessContext} from 'hooks/business/useBusinessContext';
import {GQLClientContext} from 'providers/gqlClient';
import {useContext, useMemo} from 'react';
import {GET_ALL_BRANCH_STAFFQuery, GET_ALL_BRANCH_STAFFQueryVariables} from 'types/ApiModel';
import {StaffModel} from 'types/DerivedApiModel';

interface Props {
  clinicId?: string | null;
}

export type IUseAllClinicStaff = UseQueryResult<QueryResult, Error>;

type QueryResult = StaffModel[] | undefined;

function useAllClinicStaff({clinicId}: Props): IUseAllClinicStaff {
  const {businessId} = useBusinessContext();
  const {gqlClient} = useContext(GQLClientContext);

  const query = useMemo(() => ({query: {businessId, branchId: clinicId ?? ''}}), [businessId, clinicId]);

  return useQuery<QueryResult, Error>({
    enabled: !!(businessId && clinicId),
    cacheTime: RQ_CACHE_TIMES.useAllClinicStaff,
    staleTime: RQ_STALE_TIMES.useAllClinicStaff,
    queryKey: [USE_ALL_CLINIC_STAFF_KEY, query],
    queryFn: async () => {
      const {data} = await gqlClient.request<GET_ALL_BRANCH_STAFFQuery, GET_ALL_BRANCH_STAFFQueryVariables>(
        GET_ALL_BRANCH_STAFF,
        query
      );
      const staff = data?.getAllBranchStaff ?? undefined;
      return staff?.filter(filterOnlineBookingStaff);
    },
    onError: ({message}) => captureException(new Error(message))
  });
}

export {useAllClinicStaff};
export default useAllClinicStaff;

const filterOnlineBookingStaff = (staff?: StaffModel | null): boolean => !staff?.hideFromOnlineBookings;

export const USE_ALL_CLINIC_STAFF_KEY = 'GET_ALL_CLINIC_STAFF';
