import React, {CSSProperties} from 'react';
import styled from 'styled-components';

type TextVariant =
  | 'heading'
  | 'default'
  | 'label'
  | 'bold'
  | 'title'
  | 'smallTitle'
  | 'pill'
  | 'pillbold'
  | 'pillLight'
  | 'sectionTitle';

interface Props {
  variant?: TextVariant;
  style?: CSSProperties;
  className?: string;
}

// TODO: Map these to the Design System sizes and replace across the board
const Text: React.FC<React.PropsWithChildren<Props>> = ({variant = 'default', ...rest}) => {
  switch (variant) {
    case 'heading':
      return <StyledHeading {...rest} />;
    case 'title':
      return <StyledTitle {...rest} />;
    case 'sectionTitle':
      return <StyledSection {...rest} />;
    case 'smallTitle':
      return <StyledSmallTitle {...rest} />;
    case 'pill':
      return <StylePill {...rest} />;
    case 'pillbold':
      return <StylePillBold {...rest} />;
    case 'pillLight':
      return <StylePillLight {...rest} />;
    case 'bold':
      return <StyledBold {...rest} />;
    default:
      return <div {...rest} />;
  }
};

export default Text;

const StyledHeading = styled.div`
  font-size: ${({theme}) => theme.fontSize.xxl};
  font-weight: bold;
`;

const StyledBold = styled.div`
  font-weight: 700;
`;

const StyledTitle = styled.div`
  font-size: 1.125rem;
  font-weight: 700;
  line-height: 22px;
`;

const StyledSmallTitle = styled.div`
  font-size: 1rem;
  font-weight: 700;
`;

const StylePill = styled.div`
  font-size: 0.875rem;
  line-height: 19px;
`;
const StylePillBold = styled.div`
  font-size: 0.875rem;
  font-weight: bold;
`;
const StylePillLight = styled.div`
  font-size: 0.875rem;
  opacity: 0.6;
`;
const StyledSection = styled.div`
  font-size: ${({theme}) => theme.fontSize.lg};
  font-weight: bold;
`;
