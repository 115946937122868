import axios from 'axios';
import config from 'config';
import {useMemo} from 'react';

const usePaymentGatewayClient = () => {
  const paymentGatewayClient = axios.create({
    baseURL: config.PAYMENT_CONFIG.url,
    headers: {
      'Content-Type': 'application/json',
      'x-api-key': config.PAYMENT_CONFIG.pgApiKey
    }
  });

  return useMemo(() => ({paymentGatewayClient}), [paymentGatewayClient]);
};

export default usePaymentGatewayClient;
export {usePaymentGatewayClient};
