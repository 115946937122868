import {gql} from 'utils/graphql';

export const GET_BRANCH_STAFF_LIST = gql`
  query GET_BRANCH_STAFF_LIST($query: BusinessBranchInput!, $page: PageInput) {
    getBranchStaffList(query: $query, page: $page) {
      staff {
        id
        firstName
        lastName
        selfEmployed
        mobile
        email
        staffCategoryId
        staffCategoryName
        hideFromAppointmentScreen
        hideFromOnlineBookings
      }
      page {
        number
        size
        totalElements
        number
        totalPages
        totalElements
      }
    }
  }
`;

export const GET_STAFF = gql`
  query GET_STAFF($businessId: String!, $branchId: String!, $staffId: String!) {
    getStaff(businessId: $businessId, branchId: $branchId, staffId: $staffId) {
      id
      firstName
      lastName
      selfEmployed
      mobile
      email
      staffCategoryId
      staffCategoryName
      hideFromAppointmentScreen
      hideFromOnlineBookings
    }
  }
`;

export const GET_ALL_BRANCH_STAFF = gql`
  query GET_ALL_BRANCH_STAFF($query: BusinessBranchInput!) {
    getAllBranchStaff(query: $query) {
      id
      firstName
      lastName
      selfEmployed
      mobile
      email
      staffCategoryId
      staffCategoryName
      hideFromAppointmentScreen
      hideFromOnlineBookings
    }
  }
`;

export const GET_ALL_BRANCH_STAFF_CALENDAR = gql`
  query GET_ALL_BRANCH_STAFF_CALENDAR($query: BusinessBranchInput!) {
    getAllBranchStaffCalendar(query: $query) {
      id
      firstName
      lastName
      selfEmployed
      mobile
      email
      staffCategoryId
      staffCategoryName
      hideFromAppointmentScreen
      hideFromOnlineBookings
      color
      calendarPosition
    }
  }
`;

export const GET_STAFF_ROSTER = gql`
  query GET_STAFF_ROSTER($query: StaffRosterInput!, $page: PageInput) {
    getStaffRoster(query: $query, page: $page) {
      roster {
        staffId
        timeSlots {
          date
          startTime
          endTime
          type
        }
      }
      page {
        number
        size
        totalElements
        totalPages
      }
    }
  }
`;
