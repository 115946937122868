import {captureException} from '@sentry/react';
import {UseQueryResult, useQuery} from '@tanstack/react-query';
import {RQ_CACHE_TIMES, RQ_STALE_TIMES} from 'constants/reactquery';
import {GET_CLINIC_WORK_TIMETABLES} from 'data/graphql/queries';
import {GQLClientContext} from 'providers/gqlClient';
import {useContext, useMemo} from 'react';
import {GET_CLINIC_WORK_TIMETABLESQuery, GET_CLINIC_WORK_TIMETABLESQueryVariables} from 'types/ApiModel';
import {ClinicTimeSlotsModel} from 'types/DerivedApiModel';

interface Props {
  variables: GET_CLINIC_WORK_TIMETABLESQueryVariables;
}

type QueryResult = ClinicTimeSlotsModel[] | undefined;

function useClinicWorkTimetables({variables: {query: queryVars}}: Props): UseQueryResult<QueryResult, Error> {
  const {businessId, clinicId, fromDate, toDate} = queryVars;
  const {gqlClient} = useContext(GQLClientContext);

  const query = useMemo(
    () => ({query: {businessId, clinicId, fromDate, toDate}}),
    [businessId, clinicId, fromDate, toDate]
  );

  return useQuery<QueryResult, Error>({
    enabled: !!(businessId && clinicId && fromDate && toDate),
    cacheTime: RQ_CACHE_TIMES.useClinicWorkTimetables,
    staleTime: RQ_STALE_TIMES.useClinicWorkTimetables,
    queryKey: [USE_CLINIC_WORK_TIMETABLES_KEY, query],
    queryFn: async () => {
      const {data} = await gqlClient.request<GET_CLINIC_WORK_TIMETABLESQuery, GET_CLINIC_WORK_TIMETABLESQueryVariables>(
        GET_CLINIC_WORK_TIMETABLES,
        query
      );
      return data?.getClinicWorkTimetables?.timeSlots ?? undefined;
    },
    onError: ({message}) => captureException(new Error(message))
  });
}

export {useClinicWorkTimetables};
export default useClinicWorkTimetables;

export const USE_CLINIC_WORK_TIMETABLES_KEY = 'GET_ALL_CLINIC_WORK_TIMETABLES';
