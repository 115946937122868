import React from 'react';
import styled from 'styled-components';

type Size = 's' | 'm' | 'l';
type Variant = 'vertical' | 'horizontal';

interface Props {
  variant?: Variant;
  size?: Size;
  text?: string;
  className?: string;
}
const Divider: React.FC<React.PropsWithChildren<Props>> = ({variant = 'vertical', size = 's', text, className}) => {
  const sizeBorder = size === 's' ? '1px' : size === 'm' ? '2px' : '3px';
  if (text) return <TextDivider sizeBorder={sizeBorder} children={text} className={className} />;
  if (variant === 'vertical') return <VerticalDivider sizeBorder={sizeBorder} className={className} />;
  return <HorizontalDivider sizeBorder={sizeBorder} className={className} />;
};

export {Divider};
export default Divider;

interface StyledProps {
  readonly sizeBorder: string;
}

const VerticalDivider = styled.div<StyledProps>`
  border-top: ${({sizeBorder}) => sizeBorder} solid #e6e6e6;
  margin-top: ${({theme}) => theme.spacing(2)};
  margin-bottom: ${({theme}) => theme.spacing(2)};
`;

const HorizontalDivider = styled.div<StyledProps>`
  border-left: ${({sizeBorder}) => sizeBorder} solid #e6e6e6;
  height: 28px;
  margin-left: ${({theme}) => theme.spacing(2)};
  margin-right: ${({theme}) => theme.spacing(2)};
`;

const TextDivider = styled.div<StyledProps>`
  display: block;
  width: 100%;
  text-align: center;
  color: #7a7a7a;
  font-size: ${14 / 16}rem;
  margin: ${({theme}) => theme.spacing(3, 0)};
  &::before,
  &::after {
    content: '';
    background-color: #d6d6d6;
    display: inline-block;
    height: ${({sizeBorder}) => sizeBorder};
    margin: ${({theme}) => theme.spacing(0, 1)};
    transform: translateY(-0.1em);
    vertical-align: middle;
    width: 40px;
  }
`;
