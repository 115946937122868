import React from 'react';
import {useRecoilValue} from 'recoil';
import {userState} from 'state';

const Public: React.FC<React.PropsWithChildren<unknown>> = ({children}) => {
  const user = useRecoilValue(userState);
  if (!user) return <React.Fragment>{children}</React.Fragment>;
  return null;
};

export {Public};
export default Public;
