import countryPhones from 'constants/forms/countryPhones';

type Regions = 'america' | 'europe' | 'asia' | 'oceania' | 'africa';
type SubRegions =
  | 'north-america'
  | 'south-america'
  | 'central-america'
  | 'carribean'
  | 'eu-union'
  | 'ex-ussr'
  | 'ex-yugos'
  | 'baltic'
  | 'middle-east'
  | 'north-africa';
interface CountryModel {
  countryName: string;
  region: [Regions, SubRegions];
  isoCode: string;
  dialCode: string;
}

const getCountryPhoneInfo = (countryName: string): CountryModel => {
  const informationArray = countryPhones.filter(country => country[0] === countryName)[0];
  return {
    countryName: informationArray[0] as string,
    region: informationArray[1] as [Regions, SubRegions],
    isoCode: informationArray[2] as string,
    dialCode: informationArray[3] as string
  };
};

/**
 * Phone number formatting. Only ROI for now - extend as required
 * @param string phoneNumber
 * @returns string (phoneNumber | Formatted phoneNumber)
 */
const formatPhoneNumber = (phoneNumber?: string | null): string => {
  if (!phoneNumber) return '';
  if (phoneNumber.indexOf('353') !== 0) return phoneNumber;

  const match = phoneNumber.match(/^(\d{3})(\d{2})(\d{3})(\d{4})$/);

  if (match) {
    return `+${match[1]} ${match[2]} ${match[3]} ${match[4]}`;
  }

  return phoneNumber;
};

export {formatPhoneNumber, getCountryPhoneInfo};
