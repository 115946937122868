// Phorest colors

const COLORS_PHOREST = {
  phorestAmber: '#FFD700',
  phorestBespoke: '#212837',
  phorestBlue: '#4169E1',
  phorestBurgundy: '#993065',
  phorestCool: '#6495ED',
  phorestCustom: '#CD7692',
  phorestGreen: '#68C668',
  phorestGrey: '#F0F0F0',
  phorestGreyDark: '#595959',
  phorestGreyLight: '#BEBEBE',
  phorestHydra: '#BA55D3',
  phorestInject: '#F08080',
  phorestMustard: '#F0E68C',
  phorestOrange: '#F4A460',
  phorestPink: '#FF69B4',
  phorestPurple: '#CC99FF',
  phorestRed: '#FF5050',
  phorestSculpt: '#708090',
  phorestYellow: '#FFFF00'
} as const;

export default COLORS_PHOREST;
export {COLORS_PHOREST};
