import {CourseSelectionModel} from 'components/Treatments/TreatmentTiles/TreatmentTiles';
import {PURCHASE_ITEM_TYPE} from 'constants/payments';
import {
  ClinicAppointmentModelWithServiceModel,
  PaymentTypeModel,
  ProductType,
  TreatmentModel
} from 'types/DerivedApiModel';
import {getUUID} from 'utils/general';
import {getRandomInteger} from 'utils/numbers';
import {PurchaseVoucher} from '../Dialogs/CreateClientVoucherDialog';
import {PaymentTypeTableItem} from '../PaymentTypesSection/PaymentTypesSection';
import {PaymentTypeInstance, PurchaseItem} from '../types';

interface IPaymentTableMapperInput {
  instance: PaymentTypeInstance;
  currencySymbol?: string;
  error?: string;
}

export const fromPaymentTypeInstanceToPaymentTypeTableItem = ({
  instance,
  currencySymbol,
  error
}: IPaymentTableMapperInput): PaymentTypeTableItem => {
  const infoSegments = [];
  if (instance?.voucherSerialNumber) infoSegments.push(instance.voucherSerialNumber);
  if (instance?.voucherBalance) infoSegments.push(`${currencySymbol}${instance.voucherBalance.toFixed(2)}`);
  return {
    id: instance?.id ?? '',
    amount: instance?.amount,
    applyFullAmount: true,
    name: instance?.name ?? '',
    info: infoSegments.length > 0 ? infoSegments.join(' | ') : undefined,
    isVoucherPlaceholder: instance.isVoucherPlaceholder,
    error
  };
};

export const fromPaymentListTypeToPaymentTypeInstance = (item: PaymentTypeModel): PaymentTypeInstance => ({
  amount: undefined,
  archived: item.archived,
  banked: item.banked,
  builtIn: item.builtIn,
  code: item.code,
  id: getRandomInteger().toString(),
  name: item.name,
  paymentTypeId: item.paymentTypeId
});

// Treatment -> Purchase
export const fromTreatmentModelToPurchaseItem = (treatment: TreatmentModel): PurchaseItem => ({
  id: getUUID(),
  serviceId: treatment?.id ?? '',
  price: treatment.price ?? 0,
  quantity: 1,
  title: treatment.name ?? '',
  type: PURCHASE_ITEM_TYPE.TREATMENT
});
export const fromTreatmentModelsToPurchaseItems = (treatments: TreatmentModel[]): PurchaseItem[] =>
  treatments.map(fromTreatmentModelToPurchaseItem);
// Product -> Purchase
export const fromProductTypeToPurchaseItem = (product: ProductType): PurchaseItem => ({
  id: getUUID(),
  productId: product.id ?? undefined,
  price: product.price ?? 0,
  quantity: 1,
  title: product.name ?? '',
  type: PURCHASE_ITEM_TYPE.PRODUCT
});
// Purchase -> Treatment
const fromPurchaseModelToTreatmentItem = (item: PurchaseItem): TreatmentModel => ({
  __typename: 'Service',
  id: item.id,
  name: item.title,
  price: item.price,
  duration: null,
  categoryId: null,
  categoryName: null,
  department: null,
  archived: null,
  branchId: null,
  internetEnabled: null,
  disqualifiedStaff: null,
  machines: null,
  rooms: null,
  taxRates: null
});
export const fromPurchaseModelsToTreatmentItems = (item: PurchaseItem[]): TreatmentModel[] =>
  item.map(fromPurchaseModelToTreatmentItem);

// Course -> Purchase
const fromCourseModelToPurchaseItem = (course: CourseSelectionModel): PurchaseItem => ({
  id: getUUID(),
  courseId: course.courseId ?? undefined,
  price: course.totalPrice ?? 0,
  quantity: 1,
  title: course.courseName ?? '',
  type: PURCHASE_ITEM_TYPE.COURSE
});
export const fromCourseModelsToPurchaseItems = (courses: CourseSelectionModel[]): PurchaseItem[] =>
  courses.map(fromCourseModelToPurchaseItem);

// Purchase -> Course
const fromPurchaseModelToCourseItem = (course: PurchaseItem): CourseSelectionModel => ({
  courseId: course.id,
  totalPrice: course.price,
  courseName: course.title
});
export const fromPurchaseModelsToCourseItems = (courses: PurchaseItem[]): CourseSelectionModel[] =>
  courses?.map(fromPurchaseModelToCourseItem);

export const fromVoucherModelToPurchaseItem = (voucher: PurchaseVoucher): PurchaseItem => ({
  id: getUUID(),
  price: voucher.price ?? 0,
  quantity: 1,
  title: `Voucher ${voucher.serialNumber}`,
  type: PURCHASE_ITEM_TYPE.VOUCHER,
  voucherId: voucher.serialNumber
});

export const mapAppointmentsToPurchaseItem = (appointment: ClinicAppointmentModelWithServiceModel): PurchaseItem => ({
  appointmentId: appointment?.appointmentId ?? '',
  id: getUUID(),
  price: appointment.price ?? 0,
  quantity: 1,
  serviceId: appointment?.serviceId ?? '',
  staffId: appointment.staffId ?? '',
  title: appointment.service?.name ?? appointment.serviceId ?? 'N/A',
  type: PURCHASE_ITEM_TYPE.TREATMENT
});
