import {captureException} from '@sentry/react';
import {useQuery, UseQueryResult} from '@tanstack/react-query';
import {MAX_PAGE_SIZE} from 'constants/pagination';
import {RQ_CACHE_TIMES} from 'constants/reactquery';
import {GET_SERVICE_HISTORY} from 'data/graphql/queries';
import {useBusinessContext} from 'hooks/business/useBusinessContext';
import {GQLClientContext} from 'providers/gqlClient';
import {useContext, useMemo} from 'react';
import {GET_SERVICE_HISTORYQuery, GET_SERVICE_HISTORYQueryVariables, PageInput} from 'types/ApiModel';

interface IUseClientAppointmentsProps {
  clientId: string;
  page?: PageInput;
}

export type IUseClientAppointmentsOutput = UseQueryResult<GET_SERVICE_HISTORYQuery, Error>;

function useClientServiceHistory({
  clientId,
  page = {page: 0, size: MAX_PAGE_SIZE}
}: IUseClientAppointmentsProps): IUseClientAppointmentsOutput {
  const {businessId} = useBusinessContext();
  const {gqlClient} = useContext(GQLClientContext);

  const variables = useMemo(() => ({query: {businessId, clientId}, page}), [businessId, clientId, page]);

  return useQuery<GET_SERVICE_HISTORYQuery, Error>({
    cacheTime: RQ_CACHE_TIMES.useClientAppointments,
    queryKey: [USE_CLIENT_SERVICE_HISTORY_KEY, variables],
    queryFn: async () => {
      const {data} = await gqlClient.request<GET_SERVICE_HISTORYQuery, GET_SERVICE_HISTORYQueryVariables>(
        GET_SERVICE_HISTORY,
        variables
      );
      return data;
    },
    onError: ({message}) => captureException(new Error(message), {extra: {businessId, clientId}})
  });
}

export {useClientServiceHistory};
export default useClientServiceHistory;

export const USE_CLIENT_SERVICE_HISTORY_KEY = 'GET_CLIENT_SERVICE_HISTORY';
