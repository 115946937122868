import {AppBarButton} from '@therapie-ui/AppBar/controls/AppBarButton';
import {TOP_BAR_INNER_HEIGHT} from 'constants/layout';
import {SECTIONS} from 'constants/navigation';
import {AppSection} from 'constants/permissions';
import {useNavBarNavigation} from 'hooks/navigation/useNavBarNavigation';
import React, {useCallback, useEffect, useMemo, useState} from 'react';
import styled from 'styled-components';

const NavButtons: React.FC<React.PropsWithChildren<unknown>> = () => {
  /**
   * NOTE: Bug on active nav if we inject the value from router, probably due lazy loading
   * the following local state is needed to show the correct active nav
   */
  const {handleNavClick, activeSection, sections} = useNavBarNavigation();
  const navLabels = useMemo(() => sections.map(section => section.label), [sections]);
  const [activeNavIndex, setActiveNavIndex] = useState<number | null>();
  const handleNav = useCallback(
    (index: number) => {
      const selectedSection = sections.find(section => section.label === navLabels[index])?.name;
      if (selectedSection) {
        handleNavClick(selectedSection);
        setActiveNavIndex(index);
      }
    },
    [handleNavClick, sections, navLabels]
  );

  useEffect(() => {
    activeSection && setActiveNavIndex(getSectionIndex(activeSection, navLabels)); // Sync Router state with local
  }, [activeSection, navLabels]);

  return (
    <NavButtonGroup>
      {navLabels.map((nav, index) => (
        <AppBarButton
          children={nav}
          key={nav}
          variant="ghost"
          onClick={() => handleNav(index)}
          isActive={activeNavIndex === index}
        />
      ))}
    </NavButtonGroup>
  );
};

export {NavButtons};

const getSectionLabel = (appSection: AppSection) => SECTIONS.find(section => section.name === appSection)?.label ?? '';
const getSectionIndex = (appSection: AppSection, labels: string[]) => labels.indexOf(getSectionLabel(appSection));

const NavButtonGroup = styled.nav`
  height: ${TOP_BAR_INNER_HEIGHT};
  display: flex;
  gap: ${({theme}) => theme.spacing(1)};
  margin: : ${({theme}) => theme.spacing(0, 1)};
`;
