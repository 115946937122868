import cookie from 'cookie';
import {KEYS} from 'constants/storage';

class ConfigStorage {
  set apiUrl(url: string) {
    document.cookie = cookie.serialize(KEYS.API_URL, url);
  }
  get apiUrl() {
    const cookies = cookie.parse(document.cookie);
    return cookies[KEYS.API_URL];
  }
}

const CfgStorage = new ConfigStorage();
export {CfgStorage};
