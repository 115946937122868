import {ClientInput, CreateClientObjectInput} from 'types/ApiModel';

export enum GENDERS {
  FEMALE = 'FEMALE',
  MALE = 'MALE'
}

export const CREATE_CLIENT_INIT_VALUES: CreateClientObjectInput = {
  address: {
    city: undefined,
    country: undefined,
    postalCode: undefined,
    state: undefined,
    streetAddress1: undefined,
    streetAddress2: undefined
  },
  banned: false,
  birthDate: undefined,
  email: '',
  emailMarketingConsent: false,
  emailReminderConsent: true,
  firstName: '',
  gender: GENDERS.FEMALE,
  lastName: '',
  mobile: '',
  smsMarketingConsent: false,
  smsReminderConsent: true
};
export const UPDATE_CLIENT_INIT_VALUES: ClientInput = {
  ...CREATE_CLIENT_INIT_VALUES,
  clientCategoryIds: undefined,
  clientId: '',
  version: 0
};
