import {SEVERITY} from 'constants/notifications';
import COLORS from 'constants/colors/index';
import React from 'react';
import styled from 'styled-components';

interface BadgeProps {
  variant?: SEVERITY;
  size?: 'small' | 'medium';
}

const Badge: React.FC<React.PropsWithChildren<BadgeProps>> = ({
  variant = SEVERITY.DEFAULT,
  size = 'medium',
  children
}) => <TherapieBadge variant={variant} size={size} children={children} />;

export default Badge;
export {Badge};

const TherapieBadge = styled.span<{variant: SEVERITY; size: BadgeProps['size']}>`
  background-color: ${({variant}) => severityColorBindings[variant]};
  border-radius: ${({theme}) => theme.shape.borderRadius * 2}px;
  padding: ${({theme}) => theme.spacing(0.25, 1)};
  font-size: ${({size}) => (size === 'small' ? '0.75rem' : '0.813rem')};
`;

const severityColorBindings: Record<SEVERITY, string> = {
  default: COLORS.mist200,
  error: COLORS.scarlet200,
  info: COLORS.azure200,
  success: COLORS.chromeWhite,
  warning: COLORS.amber200
};
