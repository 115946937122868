import * as Sentry from '@sentry/react';
import {Auth} from 'aws-amplify';
import {UserData, userMapper} from 'components/Auth/utils';
import {useNotifications} from 'hooks';
import {useLDClient} from 'launchdarkly-react-client-sdk';
import React, {useCallback, useEffect} from 'react';
import {useRecoilState, useSetRecoilState} from 'recoil';
import {userIsLoadingState, userState} from 'state';
import {ErrorWithMessage} from 'types/errors';
import useFSClient from '../../hooks/tracing/useFSClient';

export const FetchUser: React.FC<React.PropsWithChildren<unknown>> = React.memo(() => {
  const [user, setUser] = useRecoilState(userState);
  const setUserIsLoading = useSetRecoilState(userIsLoadingState);
  const {notifyError} = useNotifications();
  const mapAndSetUser = useCallback(
    (user?: UserData) => (user ? setUser(userMapper(user)) : setUser(undefined)),
    [setUser]
  );
  const client = useLDClient();
  useFSClient();

  const fetchUser = useCallback(async () => {
    setUserIsLoading(true);

    try {
      /**
       * https://github.com/aws-amplify/amplify-js/issues/2560
       * Auth.currentSession() returns a CognitoUserSession object which contains JWT accessToken, idToken, and refreshToken.
       * This method will automatically refresh the accessToken and idToken if tokens are expired and a valid refreshToken presented. So you can use this method to refresh the session if needed.
       */
      const session = await Auth.currentSession();
      const idToken = session.getIdToken();
      const user: UserData = {
        signInUserSession: session,
        attributes: {'custom:roles': idToken.payload?.['custom:roles'], email: idToken.payload?.email}
      };
      mapAndSetUser(user);
    } catch (error) {
      mapAndSetUser();
      const message = parseError(error);
      if (!IGNORE_ERRORS.includes(message)) {
        console.error(error);
        notifyError(message);
        Sentry.captureException(new Error(message));
      }
    }

    setUserIsLoading(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    // identify User to Launch darkly after logins
    const email = user?.attributes?.email;
    if (!email) return undefined;
    const roles = user?.attributes?.roles?.join(',') ?? '';
    client?.identify({key: email, email, custom: {roles}});
    Sentry.setUser({email});
  }, [user, client]);

  useEffect(() => {
    fetchUser();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return null;
});

const parseError = (error: any): string => {
  try {
    return typeof error === 'string' ? error : (error as ErrorWithMessage).message ?? 'Something went wrong.';
  } catch {
    return '';
  }
};
const IGNORE_ERRORS = ['The user is not authenticated', 'No current user'];
