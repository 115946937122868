import {PaymentTypeInstance} from 'components/Purchase/types';
import {PURCHASE_ATOM_PREFIX} from 'constants/purchase';
import {atom} from 'recoil';
import {PaymentTypeModel} from 'types/DerivedApiModel';
import {AtomStatusState} from './types';

const paymentTypeListState = atom<PaymentTypeModel[]>({
  key: `${PURCHASE_ATOM_PREFIX}PaymentTypeListState`,
  default: []
});

const paymentTypeListStatusState = atom<AtomStatusState>({
  key: `${PURCHASE_ATOM_PREFIX}PaymentTypeListStatusState`,
  default: {loading: false, fetching: false}
});

const paymentTypeInstancesState = atom<PaymentTypeInstance[]>({
  key: `${PURCHASE_ATOM_PREFIX}PaymentTypeInstancesState`,
  default: []
});

export {paymentTypeListState, paymentTypeListStatusState, paymentTypeInstancesState};
