import cookie from 'cookie';

/**
 * These need to match the cookies we set in the DataDog test suite
 */
const KEYS = {
  SIMULATED_TERMINAL: 'e2e-use-simulated-stripe-terminal',
  STRIPE_LOCATION: 'e2e-stripe-location-email-override'
};

/**
 * Utilities for DataDog synthetic tests
 */
class EndToEndUtils {
  isUsingSimulatedTerminal: boolean;
  stripeLocationEmailOverride: string | undefined;
  constructor() {
    const cookies = cookie.parse(document.cookie);
    this.isUsingSimulatedTerminal = !!cookies[KEYS.SIMULATED_TERMINAL];
    this.stripeLocationEmailOverride = cookies[KEYS.STRIPE_LOCATION];
  }
}

const endToEndUtils = new EndToEndUtils();
export {endToEndUtils};
