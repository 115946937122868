const ZERO = 0;
const SECOND = 1000;
const MINUTE = SECOND * 60;
const HOUR = MINUTE * 60;

// Info: https://tanstack.com/query/v4/docs/react/guides/caching
// RQ default cacheTime = 5 minutes
// RQ default staleTime = 0

export const RQ_CACHE_TIMES = {
  useAllBranchServiceCategories: HOUR,
  useAvailableAppointments: ZERO,
  useClients: ZERO,
  useClinicWorkTimetables: HOUR,
  useClinicAppointments: ZERO,
  useAllClinicStaff: HOUR,
  useClinics: HOUR,
  useStaff: HOUR,
  useStaffRoster: HOUR,
  useClientAppointments: ZERO
};

// Always keep your StaleTimes less than your CacheTimes
export const RQ_STALE_TIMES = {
  useAllBranchServiceCategories: RQ_CACHE_TIMES.useAllBranchServiceCategories / 2,
  useClinicWorkTimetables: RQ_CACHE_TIMES.useClinicWorkTimetables / 2,
  useAllClinicStaff: RQ_CACHE_TIMES.useAllClinicStaff / 2,
  useClinics: RQ_CACHE_TIMES.useClinics / 2,
  useStaff: RQ_CACHE_TIMES.useStaff / 2,
  useStaffRoster: RQ_CACHE_TIMES.useStaffRoster / 2
};
