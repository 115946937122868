import useStaffRoster from 'hooks/staff/useStaffRoster';
import {useMemo} from 'react';
import {useRecoilValue} from 'recoil';
import {clinicServiceCategoriesState, clinicStaffStatusState, clinicTreatmentsState} from 'state/atoms';
import {clinicStaffMapSelector} from 'state/selectors';
import {clinicAppointmentsSelector} from 'state/selectors/clinic/clinicAppointments';
import {staffBreaksSelector} from 'state/selectors/clinic/staffBreaks';
import {
  BreakModel,
  ClinicAppointmentWithClientAndServiceModel,
  ClinicTimeSlotsModel,
  StaffCalendarModel,
  TimeSlotModel,
  TreatmentModelWithColor
} from 'types/DerivedApiModel';
import {fromColorIntToHex} from 'utils/treatments';
import useClinicWorkTimetables from './useClinicWorkTimetables';

interface IClinicStaffSchedulesProps {
  businessId: string;
  clinicId: string;
  date: string;
}

export interface IClinicStaffSchedule {
  staff?: StaffCalendarModel;
  timeSlots: TimeSlotModel[];
  appointments: ClinicAppointmentWithClientAndServiceModel[];
}

export interface IClinicSchedule {
  clinicTimeSlots: ClinicTimeSlotsModel[];
  staffSchedules: IClinicStaffSchedule[];
  staffBreaks: BreakModel[];
}

export interface IClinicScheduleOutput {
  loading: boolean;
  error?: unknown;
  data?: IClinicSchedule;
}

/**
 * Creates the data required for the calendar view
 * @param string businessId
 * @param string clinicId
 * @param Date date
 * @returns array data - Array
 * @returns boolean loading
 * @returns unknown error
 */
function useClinicSchedule({businessId, clinicId, date}: IClinicStaffSchedulesProps): IClinicScheduleOutput {
  const {
    data: dataAllServiceCategories,
    loading: loadingAllServiceCategories,
    error: errorAllServiceCategories
  } = useRecoilValue(clinicServiceCategoriesState);
  const {
    data: dataAllBranchServices,
    loading: loadingAllBranchServices,
    error: errorAllBranchServices
  } = useRecoilValue(clinicTreatmentsState);

  const {data: dataClinicAppointments, loading: loadingClinicAppointments} = useRecoilValue(clinicAppointmentsSelector);
  const {data: staffBreaks, loading: loadingStaffBreaks} = useRecoilValue(staffBreaksSelector);
  const staffMap = useRecoilValue(clinicStaffMapSelector);
  const {loading: loadingClinicStaff} = useRecoilValue(clinicStaffStatusState);

  const serviceMap: Map<string, TreatmentModelWithColor> | undefined = useMemo(() => {
    if (!dataAllBranchServices || !dataAllServiceCategories) return undefined;
    const colorMap = new Map<string, string>(
      dataAllServiceCategories.map(el => [el.id ?? '', fromColorIntToHex(el.color)])
    );
    return new Map<string, TreatmentModelWithColor>(
      dataAllBranchServices.map(service => [
        service.id ?? '',
        {...service, categoryHexColor: colorMap.get(service.categoryId ?? '') ?? ''}
      ])
    );
  }, [dataAllBranchServices, dataAllServiceCategories]);

  // ---- Daily dependencies

  const fromDate = date,
    toDate = date;

  const variables = useMemo(
    () => ({query: {businessId, clinicId, fromDate, toDate}}),
    [businessId, clinicId, fromDate, toDate]
  );

  const {
    data: staffRoster,
    isLoading: loadingStaffRoster,
    isFetching: fetchingStaffRoster,
    error: errorStaffRoster
  } = useStaffRoster(variables);

  // Get clinic opening hours
  const {
    data: dataClinicTimetables,
    isLoading: loadingClinicTimeTables,
    error: errorClinicTimeTable
  } = useClinicWorkTimetables({variables});

  // Create Diary from the above
  const data: IClinicSchedule | undefined = useMemo(() => {
    if (!dataClinicTimetables || !staffRoster || !dataClinicAppointments || !staffMap) return;
    return {
      clinicTimeSlots: dataClinicTimetables,
      staffSchedules:
        staffRoster
          .filter(timeSlot => staffMap.get(timeSlot.staffId)?.hideFromAppointmentScreen === false)
          .map<IClinicStaffSchedule>(timeSlot => ({
            staff: staffMap.get(timeSlot.staffId),
            timeSlots: timeSlot?.timeSlots ?? [],
            appointments: dataClinicAppointments
              // remove the ones in waiting list
              .filter(({staffId, waitingListDateTime}) => staffId === timeSlot.staffId && !waitingListDateTime)
              .map(appointment => ({...appointment, service: serviceMap?.get(appointment.serviceId ?? '')}))
          })) ?? [],
      staffBreaks: staffBreaks ?? []
    };
  }, [dataClinicTimetables, staffBreaks, staffRoster, dataClinicAppointments, staffMap, serviceMap]);

  const loading =
    loadingAllBranchServices ||
    loadingAllServiceCategories ||
    loadingClinicAppointments ||
    loadingStaffBreaks ||
    loadingClinicTimeTables ||
    loadingStaffRoster ||
    fetchingStaffRoster ||
    loadingClinicStaff;

  const error = errorAllBranchServices || errorAllServiceCategories || errorClinicTimeTable || errorStaffRoster;

  return useMemo(() => ({data, loading, error}), [data, loading, error]);
}

export {useClinicSchedule};
export default useClinicSchedule;
