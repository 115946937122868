import {useFlags} from 'launchdarkly-react-client-sdk';
import React from 'react';
import {FeatureFlagName} from 'types/featureFlags';

interface Props {
  featureFlagName: FeatureFlagName;
}
export const ShowIfFeatureFlag: React.FC<React.PropsWithChildren<Props>> = React.memo(({children, featureFlagName}) => {
  const flag = useFlags();
  if (flag[featureFlagName]) return <>{children}</>;
  return null;
});
