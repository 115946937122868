import React from 'react';
import {DialogActions as MUIDialogActions, DialogActionsProps as MuiDialogActionsProps} from '@material-ui/core';

type DialogActionsProps = MuiDialogActionsProps;

const DialogActions: React.FC<React.PropsWithChildren<DialogActionsProps>> = props => (
  <MUIDialogActions style={{borderTop: '1px solid #0000002b', padding: '16px'}} {...props} />
);

export default DialogActions;
