// NOTE: This returns NUMBER, so a trailing 0 in the decimal will not persist if you're trying to use it for UI
// TODO: Review these, there's no point using Intl.NumberFormat.format() if we cast to and return a number
export const getTotalPrice = (amounts: number[]): number => {
  return Number(
    new Intl.NumberFormat('en', {minimumFractionDigits: 0, useGrouping: false}).format(
      amounts.reduce((acc, next) => acc + next, 0)
    )
  );
};

// NOTE: This returns NUMBER, so a trailing 0 in the decimal will not persist if you're trying to use it for UI
// TODO: Review these, there's no point using Intl.NumberFormat.format() if we cast to and return a number
export const getTotalPriceToTwoDecimalPlaces = (amounts: number[]): number => {
  return Number(
    new Intl.NumberFormat('en', {minimumFractionDigits: 0, maximumFractionDigits: 2, useGrouping: false}).format(
      amounts.reduce((acc, next) => acc + next, 0)
    )
  );
};

// NOTE: This returns STRING, use it for showing numbers in UI but NOT for calculations
export const getTotalPriceToTwoDecimalPlacesToString = (amounts: number[]): string => {
  const total = amounts.reduce((acc, next) => acc + next, 0);
  // Use modulus to check if it's a round number, adjust the formatter accordingly
  const hasDecimal = !!(total % 1);
  const formatter = new Intl.NumberFormat('en', {
    minimumFractionDigits: hasDecimal ? 2 : 0,
    maximumFractionDigits: hasDecimal ? 2 : 0,
    useGrouping: false
  });
  return formatter.format(total);
};
