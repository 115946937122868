import {COLORS_BASE} from './base';
import {COLORS_CUSTOM} from './custom';
import {COLORS_PASTELS} from './pastels';
import {COLORS_PHOREST} from './phorest';
import {COLORS_PRIMARY} from './primary';
import {COLORS_SECONDARY} from './secondary';
import {COLORS_THERAPIE} from './therapie';

const COLORS = {
  ...COLORS_BASE,
  ...COLORS_CUSTOM,
  ...COLORS_PASTELS,
  ...COLORS_PHOREST,
  ...COLORS_PRIMARY,
  ...COLORS_SECONDARY,
  ...COLORS_THERAPIE
};

const COLOR_NAMES = Object.keys(COLORS);

export {
  COLORS,
  COLORS_BASE,
  COLORS_CUSTOM,
  COLORS_PASTELS,
  COLORS_PHOREST,
  COLORS_PRIMARY,
  COLORS_SECONDARY,
  COLORS_THERAPIE,
  COLOR_NAMES
};

export default COLORS;
export type T_COLOR_NAMES = keyof typeof COLORS;
