import React, {ButtonHTMLAttributes, ReactNode} from 'react';
import styled, {css, FlattenSimpleInterpolation} from 'styled-components';
import COLORS from 'constants/colors/index';
import useTypographyUtils, {UseTypographyUtilsOutput} from 'hooks/utils/useTypographyUtils';

interface Props extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  /**
   * The text for the button
   */
  children?: string;
  /**
   * Variant
   */
  variant?: Variants;
  /**
   * Size
   */
  size?: Sizes;
  /**
   * IconRound - used for circular IconButtons
   */
  iconRound?: boolean;
  /**
   * Type - Optional, but we need to specify 'button' by default or else all buttons will submit any forms they reside inside.
   */
  type?: ButtonHTMLAttributes<HTMLButtonElement>['type'];
  /**
   * Fill the width of the container
   */
  fullWidth?: boolean;
  /**
   * Disabled
   */
  disabled?: boolean;
  /**
   * Loading
   * Makes the text transparent so we retain the same button size, while showing a basic CSS-only loading spinner.
   * Ideally combine with 'disabled' or maybe internally intercept the onClick prop while in a loading state.
   */
  loading?: boolean;
  /**
   * Icon (For icon-only buttons, but will work the same as icon leading if children is set)
   */
  icon?: ReactNode; // TODO: Limit these to DS Icons?
  /**
   * Leading Icon
   * Icon for the left side
   */
  iconLeading?: ReactNode;
  /**
   * Trailing Icon
   * Icon for the right side
   */
  iconTrailing?: ReactNode;
}

interface StyleProps extends Pick<Props, 'iconRound' | 'size' | 'loading' | 'variant' | 'fullWidth'> {
  pxToRem: UseTypographyUtilsOutput['pxToRem'];
  hasIconOnly?: boolean;
}
interface IconStyleProps extends Pick<Props, 'iconRound' | 'size' | 'loading'> {
  pxToRem: UseTypographyUtilsOutput['pxToRem'];
  hasIconOnly?: boolean;
}

type Sizes = keyof typeof sizesCss;
type Variants = keyof typeof variantsCss;

/**
 * #### Button component
 */
export const Button: React.FC<React.PropsWithChildren<Props>> = React.memo(
  ({
    children,
    className,
    variant = 'filled',
    iconRound = false,
    size = 'medium',
    type = 'button',
    icon,
    iconLeading,
    iconTrailing,
    ...rest
  }) => {
    const {pxToRem} = useTypographyUtils();
    const hasIcon = !!(icon || iconLeading || iconTrailing);
    const hasIconOnly = hasIcon && !children;
    const props = {variant, iconRound, size, pxToRem, hasIconOnly, type, ...rest};
    return (
      <StyledButton {...props} className={className}>
        {(icon || iconLeading) && (
          <StyledIcon iconRound={props.iconRound} loading={props.loading} pxToRem={props.pxToRem}>
            {icon ?? iconLeading}
          </StyledIcon>
        )}
        {children && <span>{children}</span>}
        {iconTrailing && (
          <StyledIcon iconRound={props.iconRound} loading={props.loading} pxToRem={props.pxToRem}>
            {iconTrailing}
          </StyledIcon>
        )}
      </StyledButton>
    );
  }
);
export type {Props as ButtonProps};

const StyledButton = styled.button<StyleProps>`
  align-items: center;
  border-radius: ${({hasIconOnly, iconRound, theme}) =>
    iconRound && hasIconOnly ? '50%' : `${theme.shape?.borderRadius * 1.5}px`};
  border: none;
  cursor: pointer;
  display: flex;
  justify-content: center;
  min-height: 32px;
  min-width: 32px;
  padding-left: ${({theme}) => theme.spacing(2.5)};
  padding-right: ${({theme}) => theme.spacing(2.5)};
  transition: background 120ms ease-out;
  position: relative;

  &:focus,
  &:focus-visible {
    outline: unset;
  }

  ${({variant}) => (variant ? variantsCss[variant as Variants] : null)}
  ${({size, pxToRem}) => (size ? sizesCss[size as Sizes](pxToRem) : null)}
 
 ${({fullWidth}) =>
    fullWidth &&
    css`
      width: 100%;
    `}
 
 ${({disabled}) =>
    disabled &&
    css`
      cursor: unset;
      background: ${COLORS.ice600};
      color: ${COLORS.therapiePurpleL50};
      box-shadow: none;
      pointer-events: none; // Make tooltips work properly. https://github.com/react-component/tooltip/issues/18#issuecomment-411476678

      &:hover,
      &:active,
      &:focus-visible {
        background: ${COLORS.ice600};
        color: ${COLORS.therapiePurpleL50};
        box-shadow: none;
      }
    `}

  // We can extend this to make it work for larger square icon buttons, 
  // but they'll possibly be another variant or a separate component
  ${({hasIconOnly, size, pxToRem}) =>
    hasIconOnly &&
    css`
      padding-left: 0;
      padding-right: 0;
      height: ${size ? `${sizeNumbers[size]}px` : null};
      width: ${size ? `${sizeNumbers[size]}px` : null};
      font-size: ${pxToRem(size === 'small' ? 14 : 16)};
    `}
    
  ${({loading, variant, disabled}) =>
    loading &&
    loadingCss(
      // Filled and Danger buttons should have white spinners, unless they're disabled. Other should have Purple
      ['filled', 'danger'].includes(variant as Variants) && !disabled ? COLORS.ice100 : COLORS.therapiePurpleD20
    )} // Contrast colour for spinner depending on BG
`;

const StyledIcon = styled.span<IconStyleProps>`
  opacity: ${({loading}) => (loading ? 0 : 1)};
  display: flex;
  align-items: center;
  justify-content: center;

  // Leading icon
  &:first-child {
    margin-left: 0;
    margin-right: ${({theme, size}) => theme.spacing(size === 'small' ? 1 : 1.5)};
  }
  // Trailing icon
  &:last-child {
    margin-right: 0;
    margin-left: ${({theme, size}) => theme.spacing(size === 'small' ? 1 : 1.5)};
  }
  // Icon only
  &:only-child {
    margin: 0;
  }

  // Actual icon
  svg {
    max-width: 100%;
    max-height: 100%;
    fill: currentcolor;
    path: currentcolor;
  }
`;

// CSS Partials/Mixins
const sizeNumbers: Record<'small' | 'medium' | 'large' | 'xlarge' | 'xxlarge', number> = {
  small: 32,
  medium: 40,
  large: 48,
  xlarge: 56,
  xxlarge: 64
} as const;

const sizesCss: Record<
  'small' | 'medium' | 'large' | 'xlarge' | 'xxlarge',
  (pxToRem: StyleProps['pxToRem']) => () => FlattenSimpleInterpolation
> = {
  small: pxToRem => () =>
    css`
      padding-left: 16px;
      padding-right: 16px;
      font-size: ${pxToRem(14)};
    `,
  medium: pxToRem => () =>
    css`
      min-height: 40px;
      min-width: 40px;
      font-size: ${pxToRem(16)};
    `,
  large: pxToRem => () =>
    css`
      min-height: 48px;
      min-width: 48px;
      font-size: ${pxToRem(16)};
    `,
  xlarge: pxToRem => () =>
    css`
      min-height: 56px;
      min-width: 56px;
      font-size: ${pxToRem(16)};
    `,
  xxlarge: pxToRem => () =>
    css`
      min-height: 64px;
      min-width: 64px;
      font-size: ${pxToRem(16)};
    `
} as const;

const variantsCss: Record<'filled' | 'outlined' | 'ghost' | 'danger', () => FlattenSimpleInterpolation> = {
  filled: () => css`
    background: ${COLORS.therapiePurpleD10};
    color: ${COLORS.ice500};
    &:hover {
      background: ${COLORS.therapiePurpleD20};
      color: ${COLORS.ice100};
    }
    &:active {
      background: ${COLORS.therapiePurpleD30};
      color: ${COLORS.ice100};
    }
    &:focus-visible {
      box-shadow: 0 0 0 3px ${COLORS.therapiePurpleD10 + 40}; // TODO: Better opacity util
    }
  `,
  outlined: () => css`
    background: ${COLORS.ice100};
    color: ${COLORS.therapiePurpleD30};
    box-shadow: inset 0 0 0 2px ${COLORS.therapiePurpleL10};
    &:hover {
      background: ${COLORS.therapiePurpleL90};
      color: ${COLORS.therapiePurpleD40};
      box-shadow: inset 0 0 0 2px ${COLORS.therapiePurpleD10};
    }
    &:active {
      background: ${COLORS.therapiePurpleL70};
      box-shadow: inset 0 0 0 2px ${COLORS.therapiePurpleL20};
      color: ${COLORS.therapiePurpleD50};
    }
    &:focus-visible {
      background: ${COLORS.ice100};
      box-shadow: inset 0 0 0 2px ${COLORS.therapiePurpleL20}, 0 0 0 3px ${COLORS.therapiePurpleD10 + 40}; // TODO: Better opacity util
    }
  `,
  ghost: () => css`
    background: transparent;
    color: ${COLORS.therapiePurpleD30};
    &:hover {
      background: ${COLORS.therapiePurpleL90};
      color: ${COLORS.therapiePurpleD40};
    }
    &:active {
      background: ${COLORS.therapiePurpleL70};
      color: ${COLORS.therapiePurpleD50};
    }
    &:focus-visible {
      background: transparent;
      box-shadow: 0 0 0 3px ${COLORS.therapiePurpleD10 + 40}; // TODO: Better opacity util
    }
  `,
  danger: () => css`
    background: ${COLORS.red400};
    color: ${COLORS.ice300};
    &:hover {
      background: ${COLORS.red500};
      color: ${COLORS.ice100};
    }
    &:active {
      background: ${COLORS.red600};
      color: ${COLORS.ice100};
    }
    &:focus-visible {
      background: ${COLORS.red400};
      box-shadow: 0 0 0 3px ${COLORS.red400 + 40}; // TODO: Better opacity util
    }
  `
} as const;

const loadingCss = (color: string) => css`
  color: transparent;
  &:hover {
    color: transparent;
  }
  &:before {
    content: '';
    box-sizing: border-box;
    position: absolute;
    top: 50%;
    left: 50%;
    width: 20px;
    height: 20px;
    margin-top: -10px;
    margin-left: -10px;
    border-radius: 50%;
    border: 2px solid transparent;
    border-top-color: ${color};
    animation: spinner 0.4s linear infinite;
  }
  @keyframes spinner {
    to {
      transform: rotate(360deg);
    }
  }
`;
