import {CardHeader as MUICardHeader, CardHeaderProps, createStyles, makeStyles} from '@material-ui/core';
import React from 'react';

const CardHeader: React.FC<React.PropsWithChildren<CardHeaderProps>> = props => {
  const classes = useStyles();
  return <MUICardHeader classes={classes} {...props} />;
};

export default CardHeader;

const useStyles = makeStyles(({spacing}) =>
  createStyles({
    root: {padding: spacing(3)},
    title: {fontSize: '1.125rem', fontWeight: 600}
  })
);
