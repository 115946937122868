import {selector} from 'recoil';
import {AtomClinicAppointmentsStatusState, clinicAppointmentsState, clinicAppointmentsStatusState} from 'state/atoms';
import {ClinicAppointmentWithClientAndServiceModel} from 'types/DerivedApiModel';

interface SelectorOutput extends AtomClinicAppointmentsStatusState {
  data: ClinicAppointmentWithClientAndServiceModel[];
}

/**
 * This is just a convenience selector allowing us to combine our 'split-atom' into a single hook,
 * while letting our DiaryStateHydrate component only mutate the data Atom when the
 * data from ReactQuery is updated
 */
const clinicAppointmentsSelector = selector<SelectorOutput>({
  key: 'clinicAppointmentsSelector',
  get: ({get}) => ({
    data: get(clinicAppointmentsState),
    ...get(clinicAppointmentsStatusState)
  })
});

export {clinicAppointmentsSelector};
