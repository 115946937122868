import {GET_VOUCHERS} from 'data/graphql/queries';
import {GQLClientContext} from 'providers/gqlClient';
import {useContext, useMemo} from 'react';
import {useQuery, UseQueryResult} from '@tanstack/react-query';
import {GET_VOUCHERSQuery, GET_VOUCHERSQueryVariables} from 'types/ApiModel';
import {Nullable} from 'types/common';
import {MAX_PAGE_SIZE_WITH_PHOREST_CONSTRAINT} from 'hooks/appointment/useAllClinicAppointments';

interface Input extends GET_VOUCHERSQueryVariables {
  enabled?: boolean;
}

type Output = UseQueryResult<GET_VOUCHERSQuery['getVouchers'], Error>;

function useAllVouchers({enabled, query: {businessId, clientId, serialNumber, includeUsed = false}}: Input): Output {
  const {gqlClient} = useContext(GQLClientContext);
  const isArgsValid = useMemo(() => !!(businessId && (clientId || serialNumber)), [businessId, clientId, serialNumber]);

  const vouchersQuery = useMemo(
    () => ({
      query: {businessId, clientId, serialNumber, includeUsed}
    }),
    [businessId, clientId, serialNumber, includeUsed]
  );

  return useQuery<GET_VOUCHERSQuery['getVouchers'], Error>(
    [USE_ALL_VOUCHERS_KEY, vouchersQuery],
    async () => {
      if (!vouchersQuery?.query) throw new Error('Missing vouchersQuery');

      const firstPage = await gqlClient.request<GET_VOUCHERSQuery, Nullable<GET_VOUCHERSQueryVariables>>(GET_VOUCHERS, {
        query: vouchersQuery.query,
        page: {size: MAX_PAGE_SIZE_WITH_PHOREST_CONSTRAINT, page: 0}
      });
      const pageData = firstPage.data?.getVouchers?.page;
      const totalPages = pageData?.totalPages ?? 0;
      const queryFns = [];

      for (let pageNumber = 1; pageNumber < totalPages; pageNumber++) {
        queryFns.push(
          gqlClient.request<GET_VOUCHERSQuery, Nullable<GET_VOUCHERSQueryVariables>>(GET_VOUCHERS, {
            query: vouchersQuery?.query!,
            page: {size: MAX_PAGE_SIZE_WITH_PHOREST_CONSTRAINT, page: pageNumber}
          })
        );
      }

      const firstPageVouchers = firstPage.data.getVouchers?.vouchers ?? [];
      const otherPageVouchers =
        queryFns.length > 0 ? (await Promise.all(queryFns)).flatMap(({data}) => data?.getVouchers?.vouchers ?? []) : [];

      return {
        vouchers: [...firstPageVouchers, ...otherPageVouchers],
        page: pageData
      } as GET_VOUCHERSQuery['getVouchers'];
    },
    {enabled: isArgsValid && enabled}
  );
}

export {useAllVouchers};
export default useAllVouchers;

export const USE_ALL_VOUCHERS_KEY = 'USE_ALL_VOUCHERS';
