import React from 'react';
import {TableContainer as MuiTableContainer, TableContainerProps as MuiTableContainerProps} from '@material-ui/core';

type TableContainerProps = MuiTableContainerProps;

const TableContainer: React.FC<React.PropsWithChildren<TableContainerProps>> = props => (
  <MuiTableContainer {...props} />
);

export default TableContainer;
export {TableContainer};
