import config from 'config';
import zonedTimeToUtc from 'date-fns-tz/zonedTimeToUtc';
import {selector} from 'recoil';
import {appointments} from 'state/atoms';
import {SingleServiceHistory} from 'types/DerivedApiModel';
import {formatUTCDate} from 'utils';

export type ServiceInstance = {
  bookingId: string | null;
  branchId: string | null;
  clientId: string | null;
} & SingleServiceHistory;

/*
 * Return all service history items, as they're shape from Phorest
 */
const allServiceHistoriesResourceSelector = selector({
  key: 'allServiceHistoriesResourceSelector',
  get: ({get}) => get(appointments)
});

/*
 * Return all future "service history" items, as they're shaped from Phorest
 */
const futureServiceHistoriesResourceSelector = selector({
  key: 'futureServiceHistoriesResourceSelector',
  get: ({get}) => get(appointments).filter(({date}) => date && new Date(date).getTime() > new Date().getTime())
});

/*
 * Return all past "service history" items, as they're shaped from Phorest
 */
const pastServiceHistoriesResourceSelector = selector({
  key: 'pastServiceHistoriesResourceSelector',
  get: ({get}) =>
    get(appointments).filter(
      serviceHistoriesResource =>
        serviceHistoriesResource.date && new Date(serviceHistoriesResource.date).getTime() < new Date().getTime()
    )
});

/*
 * Return a flattened and sorted [asc] array of appointments happening after 'now'
 */
const sortedFlattenedFutureAppointmentsSelector = selector({
  key: 'sortedFlattenedFutureAppointmentsSelector',
  get: ({get}) =>
    get(sortedAscendingFlattenedAppointmentsSelector)
      .filter(({time}) => time && new Date(time).getTime() > new Date().getTime())
      .filter(el => !el.cancelled)
});

/*
 * Return a flattened and sorted [asc] array of appointments happening from today onwards
 */
const sortedFlattenedFutureAppointmentsIncludingTodaySelector = selector({
  key: 'sortedFlattenedFutureAppointmentsIncludingTodaySelector',
  get: ({get}) => {
    const today = formatUTCDate(new Date(), 'YEAR_MONTH_DAY');
    const utcDate = zonedTimeToUtc(`${today} 00:00:00.000`, config.TIMEZONE);

    return get(sortedAscendingFlattenedAppointmentsSelector)
      .filter(({time}) => {
        return time && new Date(time).getTime() >= utcDate.getTime();
      })
      .filter(el => !el.cancelled);
  }
});

/*
 * Return a flattened and sorted [desc] array of appointments happening before 'now'
 */
const sortedFlattenedPastAppointmentsSelector = selector({
  key: 'sortedFlattenedPastAppointmentsSelector',
  get: ({get}) =>
    get(sortedDescendingFlattenedAppointmentsSelector).filter(
      ({time}) => time && new Date(time).getTime() < new Date().getTime()
    )
});

/**
 * Booking Appointments flattened into a single dimension (appointment[] instead of booking[].appointment)
 */
const flattenedAppointmentsSelector = selector({
  key: 'flattenedAppointmentsSelector',
  get: ({get}) =>
    get(appointments).reduce((flattenedServicesAccumulator, appointment, index) => {
      if (!appointment.services) {
        return flattenedServicesAccumulator;
      }

      const services = appointment.services.reduce((servicesAccumulator, service) => {
        return service?.time
          ? [
              ...servicesAccumulator,
              {
                bookingId: appointment.bookingId,
                branchId: appointment.branchId,
                clientId: appointment.clientId,
                ...service
              }
            ]
          : servicesAccumulator;
      }, [] as ServiceInstance[]);

      return [...flattenedServicesAccumulator, ...services];
    }, [] as ServiceInstance[])
});

/**
 * Appointments flattened into a single dimension and sorted-ascending
 */
const sortedAscendingFlattenedAppointmentsSelector = selector({
  key: 'sortedAscendingFlattenedAppointmentsSelector',
  get: ({get}) =>
    [...get(flattenedAppointmentsSelector)].sort(({time: a}, {time: b}) =>
      a && b ? new Date(a).getTime() - new Date(b).getTime() : 0
    )
});

/**
 * Appointments flattened into a single dimension and sorted-descending
 */
const sortedDescendingFlattenedAppointmentsSelector = selector({
  key: 'sortedDescendingFlattenedAppointmentsSelector',
  get: ({get}) =>
    [...get(flattenedAppointmentsSelector)].sort(({time: a}, {time: b}) =>
      a && b ? new Date(b).getTime() - new Date(a).getTime() : 0
    )
});

export {
  allServiceHistoriesResourceSelector,
  futureServiceHistoriesResourceSelector,
  pastServiceHistoriesResourceSelector,
  flattenedAppointmentsSelector,
  sortedAscendingFlattenedAppointmentsSelector,
  sortedDescendingFlattenedAppointmentsSelector,
  sortedFlattenedPastAppointmentsSelector,
  sortedFlattenedFutureAppointmentsSelector,
  sortedFlattenedFutureAppointmentsIncludingTodaySelector
};
