import {StylesProvider, ThemeProvider as MuiThemeProvider} from '@material-ui/core/styles';
import React from 'react';
import {BrowserRouter} from 'react-router-dom';
import {RecoilRoot} from 'recoil';
import {ThemeProvider as StyledThemeProvider} from 'styled-components';
import {GQLClientProvider} from './gqlClient';
import {LaunchDarklyProvider} from './launchDarkly';
import {LocaleProvider} from './locale';
import MuiProvider from './mui';
import {ReactQueryProvider} from './reactQuery';
import {ServiceWorkerProvider} from './serviceWorker';
import {SnackbarProvider} from './snackbar';
import {muiTheme, styledTheme} from './theme';

const Providers: React.FC<React.PropsWithChildren<unknown>> = ({children}) => (
  <LaunchDarklyProvider>
    <StylesProvider injectFirst>
      <LocaleProvider>
        <StyledThemeProvider theme={styledTheme}>
          <MuiThemeProvider theme={muiTheme}>
            <MuiProvider>
              <RecoilRoot>
                <SnackbarProvider>
                  <ServiceWorkerProvider>
                    <GQLClientProvider>
                      <ReactQueryProvider>
                        <BrowserRouter>{children}</BrowserRouter>
                      </ReactQueryProvider>
                    </GQLClientProvider>
                  </ServiceWorkerProvider>
                </SnackbarProvider>
              </RecoilRoot>
            </MuiProvider>
          </MuiThemeProvider>
        </StyledThemeProvider>
      </LocaleProvider>
    </StylesProvider>
  </LaunchDarklyProvider>
);

export default Providers;
