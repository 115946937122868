import {GET_CLIENT_PRODUCT_HISTORY} from 'data/graphql/queries';
import {GQLClientContext} from 'providers/gqlClient';

import {useContext, useMemo} from 'react';
import {useQuery, UseQueryResult} from '@tanstack/react-query';
import {GET_CLIENT_PRODUCT_HISTORYQuery, GET_CLIENT_PRODUCT_HISTORYQueryVariables} from 'types/ApiModel';

interface IUseGetClientProductsProps extends GET_CLIENT_PRODUCT_HISTORYQueryVariables {
  enabled?: boolean;
}

type Output = UseQueryResult<GET_CLIENT_PRODUCT_HISTORYQuery['getClientProductHistory'], Error>;

function useClientProductHistory({
  query: {clientId, businessId},
  page,
  enabled = true
}: IUseGetClientProductsProps): Output {
  const {gqlClient} = useContext(GQLClientContext);
  const pageNumber = page?.page;
  const size = page?.size;
  const clientProductsQuery = useMemo<GET_CLIENT_PRODUCT_HISTORYQueryVariables>(
    () => ({query: {businessId, clientId}, page: {page: pageNumber, size}}),
    [businessId, clientId, pageNumber, size]
  );
  return useQuery<GET_CLIENT_PRODUCT_HISTORYQuery['getClientProductHistory'], Error>(
    [USE_CLIENT_PRODUCTS_KEY, clientProductsQuery],
    async () => {
      const resp = await gqlClient.request<GET_CLIENT_PRODUCT_HISTORYQuery, GET_CLIENT_PRODUCT_HISTORYQueryVariables>(
        GET_CLIENT_PRODUCT_HISTORY,
        clientProductsQuery
      );

      return resp.data?.getClientProductHistory ?? null;
    },
    {
      enabled: !!clientProductsQuery && enabled
    }
  );
}

export {useClientProductHistory};
export default useClientProductHistory;

export const USE_CLIENT_PRODUCTS_KEY = 'USE_CLIENT_PRODUCTS';
