import {EmptyState} from '@therapie-ui';
import React from 'react';
import {TreatmentModel} from 'types/DerivedApiModel';
import ServicesTable, {IColumn, ServiceFields} from './ServicesTable';

interface Props {
  services: TreatmentModel[];
  isLoading: boolean;
  onServiceClick(service: TreatmentModel): void;
}

const AvailableTreatmentsTable: React.FC<React.PropsWithChildren<Props>> = ({services, isLoading, onServiceClick}) => {
  return (
    <ServicesTable
      services={services}
      columns={columns}
      isLoading={isLoading}
      onServiceClick={onServiceClick}
      EmptyState={
        <EmptyState
          title="No available treatments"
          details="Select preferred clinic to display its available treatments."
        />
      }
    />
  );
};

export {AvailableTreatmentsTable};
export default AvailableTreatmentsTable;

const columns: IColumn[] = [
  {id: ServiceFields.name, label: 'Available treatments', style: {paddingLeft: '1.5rem'}},
  {id: ServiceFields.duration, label: 'Duration', align: 'right', style: {width: 60}},
  {id: ServiceFields.price, label: 'Price', align: 'right', style: {width: 60}},
  {id: 'CTA', label: '', width: 120, align: 'right', ctaLabel: 'Add', style: {paddingRight: '1.5rem'}}
];
