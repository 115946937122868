import {gql} from 'utils/graphql';

export const GET_ALL_CLIENT_COURSES = gql`
  query GetAllClientCourses($query: ClientCoursesInput!, $page: PageInput) {
    getAllClientCourses(query: $query, page: $page) {
      clientCourses {
        archived
        clientCourseId
        courseName
        clientId
        purchasingBranchId
        courseId
        purchaseDate
        expiryDate
        grossPrice
        netPrice
        clientCourseItems {
          clientCourseItemId
          courseItemId
          serviceId
          initialUnits
          remainingUnits
          grossPrice
          netPrice
        }
      }
      page {
        number
        size
        totalElements
        totalPages
      }
    }
  }
`;

export const GET_CLIENT_COURSES = gql`
  query GET_CLIENT_COURSES($query: ClientCoursesInput!, $page: PageInput) {
    getClientCourses(query: $query, page: $page) {
      clientCourses {
        clientCourseId
        clientId
        courseName
        purchasingBranchId
        courseId
        purchaseDate
        purchaseDate
        expiryDate
        expiryDate
        grossPrice
        netPrice
        clientCourseItems {
          clientCourseItemId
          courseItemId
          serviceId
          initialUnits
          remainingUnits
          remainingUnits
          grossPrice
          netPrice
        }
      }
      page {
        number
        size
        totalElements
        totalPages
      }
    }
  }
`;

export const GET_COURSES = gql`
  query GET_COURSES($query: BusinessBranchInput!, $page: PageInput) {
    getCourses(query: $query, page: $page) {
      courses {
        courseId
        id
        courseName
        name
        availableOnline
        multiBranchCourse
        totalPrice
        items {
          id
          unitType
          totalUnits
          totalPrice
          serviceId
        }
        courseItems {
          courseItemId
          id
          totalPrice
          serviceId
        }
      }
      page {
        number
        size
        totalElements
        number
        totalPages
        totalElements
      }
    }
  }
`;

export const GET_ALL_BRANCH_COURSES = gql`
  query GET_ALL_BRANCH_COURSES($query: BusinessBranchInput!) {
    getAllBranchCourses(query: $query) {
      archived
      availableOnline
      branchId
      totalPrice
      name
      multiBranchCourse
      items {
        courseItemId
        totalPrice
        totalUnits
        unitType
        serviceId
        id
      }
      id
      courseName
      courseItems {
        courseItemId
        id
        totalUnits
        unitType
        totalPrice
        serviceId
      }
      courseId
    }
  }
`;
