import {LinearProgress} from '@material-ui/core';
import React from 'react';
import styled from 'styled-components';

interface Props {
  children: React.ReactNode;
  isOpen?: boolean;
  isLoading?: boolean;
}

const Footer: React.FC<React.PropsWithChildren<Props>> = ({children, isOpen = true, isLoading = false}) => (
  <StyledFooter isOpen={isOpen}>
    {isLoading && <LinearProgress />}
    {children}
  </StyledFooter>
);

export default Footer;

interface StyleProps {
  readonly isOpen?: boolean;
}

const StyledFooter = styled.footer<StyleProps>`
  position: sticky;
  bottom: 0;
  z-index: 100;
  width: 100%;

  background-color: white;
  box-shadow: 0 -2px 5px 0px #0002;

  transition: transform 250ms cubic-bezier(0.33, 1, 0.68, 1); // easeOutCubic
  transform: ${({isOpen}) => (isOpen ? 'translateY(0)' : 'translateY(80px)')};
`;
