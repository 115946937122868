import {Container} from '@material-ui/core';
import {maxMobileLength} from 'components/Clients/Form/validationSchema';
import {FORM_INPUT_COLORS} from 'constants/colors/legacy';
import React, {useEffect, useState} from 'react';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/material.css';
import styled from 'styled-components';

interface Props {
  country?: string;
  value: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  error?: string;
}

const PhoneFormInput: React.FC<React.PropsWithChildren<Props>> = ({country, value, onChange, error}) => {
  const [specialLabelColor, setSpecialLabelColor] = useState<string>(FORM_INPUT_COLORS.DEFAULT);
  useEffect(() => {
    if (error) setSpecialLabelColor(FORM_INPUT_COLORS.ERROR);
    if (!error) setSpecialLabelColor(FORM_INPUT_COLORS.DEFAULT);
  }, [error]);
  return (
    <StyledContainer color={specialLabelColor}>
      <PhoneInput
        country={country}
        value={value}
        enableLongNumbers={true}
        inputProps={{onChange: onChange, maxlength: maxMobileLength}}
        containerStyle={inputStyle}
        inputStyle={inputStyle}
        specialLabel={`Phone number *`}
        placeholder="+"
        onBlur={() => {
          if (!error) setSpecialLabelColor(FORM_INPUT_COLORS.DEFAULT);
        }}
        onFocus={() => {
          if (!error) setSpecialLabelColor(FORM_INPUT_COLORS.FOCUS);
        }}
      />
      {error ? <StyledErrorHelper>{error}</StyledErrorHelper> : null}
    </StyledContainer>
  );
};

export default PhoneFormInput;

const StyledContainer = styled(Container)`
  width: 48%;
  margin: 0;
  padding: 0;
  .react-tel-input .special-label {
    color: ${props => props.color};
    pointer-events: none;
    font-size: 0.8em;
  }
  .react-tel-input .form-control {
    border-radius: 8px;
    transition: none;
    border-color: ${props => (props.color === FORM_INPUT_COLORS.ERROR ? props.color : undefined)};
    :focus {
      border-color: ${props => props.color};
      box-shadow: ${props => 'inset 0 0 0 1px' + props.color};
    }
  }
`;

const StyledErrorHelper = styled.div`
  font-size: 0.75rem;
  pointer-events: none;
  color: ${FORM_INPUT_COLORS.ERROR};
  padding-left: ${({theme}) => theme.spacing(1.75)};
  padding-top: ${({theme}) => theme.spacing(0.5)};
`;

const inputStyle = {
  width: '100%',
  fontFamily: 'inherit'
};
