import * as Sentry from '@sentry/react';
import {
  AppointmentComparisionItem,
  DialogHeaderData
} from 'components/Common/MultiRescheduleDialog/MultiRescheduleDialog';
import {useAllClinicStaff, useTreatments} from 'hooks';
import useActiveClientCourses from 'hooks/courses/useActiveClientCourses';
import {useCallback, useMemo} from 'react';
import {
  ActivationState,
  BookingState,
  RescheduleBookingObjectInput,
  RescheduleServiceScheduleInput,
  RESCHEDULE_BOOKINGMutationVariables
} from 'types/ApiModel';
import {
  AvailableAppointmentModel,
  ClinicAppointmentModel,
  RescheduleServiceScheduleInputWithState,
  ServiceScheduleModel,
  StaffModel
} from 'types/DerivedApiModel';
import {getMapOfClientCourseItemIdsToCourseNames} from 'utils/courses';
import {formatZonedToUTCISO, formatUTCDate} from 'utils/time';

export interface RescheduleDialogData {
  headerBefore?: DialogHeaderData;
  headerAfter?: DialogHeaderData;
  appointmentsBefore: AppointmentComparisionItem[];
  appointmentsAfter: AppointmentComparisionItem[];
}

interface Props {
  businessId: string;
  clientId: string;
  clinicId: string;
  clientName: string;
  clinicName: string;
  originalClinicName: string;
  originalAppointmentClinicId?: string;
  originalAppointments?: OriginalAppointmentModel[] | null;
  selectedAppointmentSlot?: AvailableAppointmentModel;
}

interface Output {
  loading: boolean;
  dialogData: RescheduleDialogData;
  rescheduleBookingMutationVariables: RESCHEDULE_BOOKINGMutationVariables | null;
}

function useReschedulableBookingData({
  businessId,
  clientId,
  clinicId,
  originalAppointmentClinicId,
  originalAppointments,
  selectedAppointmentSlot,
  clientName = '',
  clinicName = '',
  originalClinicName = ''
}: Props): Output {
  const serviceSchedules = selectedAppointmentSlot?.clientSchedules?.[0]?.serviceSchedules;
  const {data: dataClientCourses, isFetching: fetchingCourses} = useActiveClientCourses({clientId});
  const mapOfClientCourseItemIdsToCourseNames = useMemo(
    () => getMapOfClientCourseItemIdsToCourseNames(dataClientCourses),
    [dataClientCourses]
  );

  const {
    data: dataAllStaffFromOriginalClinic,
    isLoading: loadingAllStaffFromOriginalClinic,
    isFetching: fetchingAllStaffFromOriginalClinic
  } = useAllClinicStaff({
    clinicId: originalAppointmentClinicId
  });

  const {
    data: dataAllStaffFromSelectedClinic,
    isLoading: loadingAllStaffFromSelectedClinic,
    isFetching: fetchingAllStaffFromSelectedClinic
  } = useAllClinicStaff({
    clinicId
  });

  const allStaffFromOriginalAndSelectedClinics = useMemo<StaffModel[]>(
    () => [...(dataAllStaffFromOriginalClinic ?? []), ...(dataAllStaffFromSelectedClinic ?? [])],
    [dataAllStaffFromOriginalClinic, dataAllStaffFromSelectedClinic]
  );

  const {treatmentsMap, loading: loadingTreatments} = useTreatments({
    businessId,
    treatments: originalAppointments?.map(({serviceId}) => ({treatmentId: serviceId || '', branchId: clinicId})) ?? []
  });

  // -- Appointment mutation payload for sending to Phorest

  const rescheduleBookingMutationVariables = useMemo<RESCHEDULE_BOOKINGMutationVariables | null>(() => {
    if (!serviceSchedules) return null;
    if (!originalAppointments?.[0]) return null;
    const DEFULT_BOOKING_STATE = '' as unknown as BookingState; // Let BE fail rather then passing a proper state

    const oldBookingId = originalAppointments?.find(appointment => !!appointment?.bookingId)?.bookingId ?? '';
    const allOriginalAppointmentsHaveSameBookingId = originalAppointments.every(
      ({bookingId}) => bookingId === oldBookingId
    );

    if (!originalAppointmentClinicId) return null;
    if (!allOriginalAppointmentsHaveSameBookingId)
      console.warn(
        `Some appointments are missing the bookingID [${oldBookingId}]`,
        JSON.stringify(originalAppointments)
      );

    const oldServiceSchedules: RescheduleServiceScheduleInput[] = originalAppointments?.map(originalAppointment => ({
      appointmentId: originalAppointment.appointmentId ?? '',
      appointmentDate: originalAppointment.appointmentDate ?? '',
      clientCourseItemId: originalAppointment.clientCourseItemId,
      serviceId: originalAppointment.serviceId ?? '',
      staffId: originalAppointment.staffId ?? '',
      staffRequest: originalAppointment.staffRequest ?? false,
      version: originalAppointment.version ?? -1,
      state: originalAppointment.state ?? DEFULT_BOOKING_STATE,
      startDateTimeISO: formatZonedToUTCISO(originalAppointment.appointmentDate, originalAppointment.startTime),
      endDateTimeISO: formatZonedToUTCISO(originalAppointment.appointmentDate, originalAppointment.endTime)
    }));

    const newServiceSchedules =
      serviceSchedules?.reduce<RescheduleServiceScheduleInputWithState[]>((result, serviceSchedule) => {
        if (!serviceSchedule) {
          console.log('newServiceSchedules: !serviceSchedule', serviceSchedule);
          return result;
        }

        // Move Feature in Diary uses this generate payload, which is incorrect as it is not a slot
        // in next refactor of copy/move appointment in diary we are going to re-write the logic
        // CC-2120
        const originalAppointment = originalAppointments.find(app =>
          serviceSchedule.appointmentId
            ? matchFromPrevAppointment(app, serviceSchedule)
            : matchFromAvailability(app, serviceSchedule)
        );

        // version could also be integer 0!
        if (!originalAppointment?.appointmentId || typeof originalAppointment?.version !== 'number') {
          Sentry.captureException(new Error('Cound not match original appointment for reschedule'), {
            extra: {originalAppointment, serviceSchedule}
          });
          return result;
        }

        result.push({
          appointmentId: originalAppointment?.appointmentId,
          clientCourseItemId: originalAppointment?.clientCourseItemId ?? undefined,
          serviceId: serviceSchedule.serviceId ?? '',
          staffId: serviceSchedule.staffId ?? '',
          staffRequest: serviceSchedule.staffRequest ?? false,
          version: originalAppointment?.version,
          machineId: serviceSchedule.machineId ?? undefined,
          roomId: serviceSchedule.roomId ?? undefined,
          startDateTimeISO: serviceSchedule.startTime ?? '',
          endDateTimeISO: serviceSchedule.endTime ?? '',
          appointmentDate: formatUTCDate(serviceSchedule.startTime, 'YEAR_MONTH_DAY') ?? '',
          state: originalAppointment.state ?? DEFULT_BOOKING_STATE
        });
        return result;
      }, []) ?? [];

    if (!newServiceSchedules.length)
      Sentry.captureException(
        new Error('newServiceSchedules in useReschedulableBookingData is empty after being declared'),
        {
          extra: serviceSchedules
            ? {serviceSchedules, clientSchedules: selectedAppointmentSlot?.clientSchedules ?? []}
            : undefined
        }
      );

    const oldBooking: RescheduleBookingObjectInput = {
      bookingStatus: ActivationState.ACTIVE,
      clientId,
      clinicId: originalAppointmentClinicId,
      clientAppointmentSchedules: [{clientId, serviceSchedules: oldServiceSchedules}]
    };

    const newBooking = {
      bookingStatus: ActivationState.ACTIVE,
      clientId,
      clinicId,
      clientAppointmentSchedules: [{clientId, serviceSchedules: newServiceSchedules}]
    };

    return {payload: {businessId, clientId, oldBooking, newBooking}};
  }, [
    serviceSchedules,
    originalAppointments,
    originalAppointmentClinicId,
    selectedAppointmentSlot?.clientSchedules,
    clientId,
    clinicId,
    businessId
  ]);

  const oldBookingServiceSchedules = useMemo(
    () =>
      rescheduleBookingMutationVariables?.payload?.oldBooking?.clientAppointmentSchedules?.[0].serviceSchedules ?? [],
    [rescheduleBookingMutationVariables?.payload?.oldBooking?.clientAppointmentSchedules]
  );

  const newBookingServiceSchedules = useMemo(
    () =>
      rescheduleBookingMutationVariables?.payload?.newBooking?.clientAppointmentSchedules?.[0].serviceSchedules ?? [],
    [rescheduleBookingMutationVariables?.payload?.newBooking?.clientAppointmentSchedules]
  );

  // --- Dialog Header before and after data

  const dialogHeader = useMemo(() => {
    const getFirstAndLastItems = (schedules: RescheduleServiceScheduleInput[]): RescheduleServiceScheduleInput[] => [
      schedules[0],
      schedules[schedules.length - 1]
    ];
    const [previousFirstSchedule, previousLastSchedule] = getFirstAndLastItems(oldBookingServiceSchedules);
    const [updatedFirstSchedule, updatedLastSchedule] = getFirstAndLastItems(newBookingServiceSchedules);

    const formattedBeforeDate = previousFirstSchedule
      ? formatUTCDate(previousFirstSchedule?.startDateTimeISO, 'DAY_DATE_MONTH_AM_PM') +
        ' - ' +
        formatUTCDate(previousLastSchedule?.endDateTimeISO, 'AM_PM')
      : '';

    const formattedAfterDate = updatedFirstSchedule
      ? formatUTCDate(updatedFirstSchedule?.startDateTimeISO, 'DAY_DATE_MONTH_AM_PM') +
        ' - ' +
        formatUTCDate(updatedLastSchedule?.endDateTimeISO, 'AM_PM')
      : '';

    return {
      before: {
        clientName,
        clinicName: originalClinicName,
        dateAndTime: formattedBeforeDate
      },
      after: {
        clientName,
        clinicName,
        dateAndTime: formattedAfterDate
      }
    };
  }, [clientName, clinicName, originalClinicName, newBookingServiceSchedules, oldBookingServiceSchedules]);

  // --- Dialog Treatment list

  const getDialogAppointmentsData = useCallback(
    (serviceSchedules: RescheduleServiceScheduleInput[]): AppointmentComparisionItem[] =>
      serviceSchedules?.reduce((result, serviceSchedule) => {
        const dateTime = new Date(serviceSchedule.startDateTimeISO);
        const staff = allStaffFromOriginalAndSelectedClinics.find(({id}) => id === serviceSchedule.staffId);
        const treatment = serviceSchedule.serviceId ? treatmentsMap?.[serviceSchedule.serviceId] : null;
        const treatmentName = treatment?.name ?? '';
        const duration = treatment?.duration ?? 0;
        const staffName = [staff?.firstName, staff?.lastName].join(' ');
        const startTime = dateTime ? formatUTCDate(dateTime, 'AM_PM') : '';

        const courseName = serviceSchedule?.clientCourseItemId
          ? mapOfClientCourseItemIdsToCourseNames[serviceSchedule.clientCourseItemId]
          : null;

        result.push({
          clinicName,
          courseName,
          duration,
          staffName,
          startTime,
          treatmentName,
          dateTime
        });

        return result;
      }, [] as AppointmentComparisionItem[]) ?? [],
    [allStaffFromOriginalAndSelectedClinics, clinicName, mapOfClientCourseItemIdsToCourseNames, treatmentsMap]
  );

  const loading =
    loadingAllStaffFromOriginalClinic ||
    loadingAllStaffFromSelectedClinic ||
    fetchingCourses ||
    fetchingAllStaffFromSelectedClinic ||
    fetchingAllStaffFromOriginalClinic ||
    loadingTreatments;

  return useMemo(
    () => ({
      rescheduleBookingMutationVariables,
      loading,
      dialogData: {
        headerBefore: dialogHeader.before,
        headerAfter: dialogHeader.after,
        appointmentsBefore: getDialogAppointmentsData(oldBookingServiceSchedules),
        appointmentsAfter: getDialogAppointmentsData(newBookingServiceSchedules)
      }
    }),
    [
      dialogHeader,
      getDialogAppointmentsData,
      loading,
      newBookingServiceSchedules,
      oldBookingServiceSchedules,
      rescheduleBookingMutationVariables
    ]
  );
}

export {useReschedulableBookingData};
export default useReschedulableBookingData;

type OriginalAppointmentModel = Pick<
  ClinicAppointmentModel,
  | 'appointmentDate'
  | 'appointmentId'
  | 'bookingId'
  | 'clientCourseItemId'
  | 'endTime'
  | 'serviceId'
  | 'staffId'
  | 'staffRequest'
  | 'startTime'
  | 'state'
  | 'version'
>;

const matchFromPrevAppointment = (app: OriginalAppointmentModel, service: ServiceScheduleModel): boolean =>
  app.appointmentId === service.appointmentId;

const matchFromAvailability = (app: OriginalAppointmentModel, service: ServiceScheduleModel): boolean =>
  app.serviceId === service.serviceId;
