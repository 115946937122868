import config from 'config';
import {BusinessCode, BusinessId, BusinessInterface} from 'constants/business';
import {atom} from 'recoil';

const businessState = atom<BusinessInterface>({
  key: 'businessState',
  default: {
    code: config.BUSINESS_CODE as BusinessCode,
    id: config.BUSINESS_ID as BusinessId
  }
});

export {businessState};
