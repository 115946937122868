import InfoDialog from 'components/Common/Dialogs/InfoDialog';
import {isValid} from 'date-fns/esm';
import {useFeatureFlag} from 'hooks/utils/useFeatureFlag';
import React, {useMemo} from 'react';
import config from 'config';
import {isAfter} from 'date-fns';
import SettingsIcon from '@material-ui/icons/Settings';

const VersionManager: React.FC<React.PropsWithChildren<unknown>> = ({children}) => {
  const forceUpdateBuildsOlderThanTimestamp = useFeatureFlag('forceUpdateBuildsOlderThanTimestamp');

  const isRefreshNeeded = useMemo(() => {
    if (!config.BUILD_TIMESTAMP || !forceUpdateBuildsOlderThanTimestamp) return false;
    const buildDate = new Date(config.BUILD_TIMESTAMP);
    const forceUpdateBuildsOlderThanDate = new Date(forceUpdateBuildsOlderThanTimestamp);
    return (
      isValid(forceUpdateBuildsOlderThanDate) &&
      isValid(buildDate) &&
      isAfter(forceUpdateBuildsOlderThanDate, buildDate)
    );
  }, [forceUpdateBuildsOlderThanTimestamp]);

  if (isRefreshNeeded)
    return (
      <InfoDialog
        buttonLabel="Update"
        contentSideIcon={<SettingsIcon />}
        title="Update required"
        message="Important updates and fixes have been released. To continue, you must update."
        onClose={() => window.location.reload()}
      />
    );
  return null;
};

export {VersionManager};
