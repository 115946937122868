import {Tooltip} from '@material-ui/core';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import {Divider} from '@therapie-ui';
import {PrimaryNavButton} from '@therapie-ui/Buttons';
import config from 'config';
import COLORS from 'constants/colors';
import {SIDE_NAV_WIDTH} from 'constants/layout';
import {AppSection} from 'constants/permissions';
import React, {useMemo} from 'react';
import styled from 'styled-components';
import {ShowIfFeatureFlag} from 'utils/featureFlags';
import {windowIsIFramed} from 'utils/window';
import {useNavBarNavigation} from '../../../hooks/navigation/useNavBarNavigation';
import { setKlarnaAmount } from 'components/Purchase/Dialogs/PayWithKlarnaDialog';

const NavSideBar: React.FC = () => {
  const {handleNavClick, activeSection, sections} = useNavBarNavigation();
  const buildTimestamp = useMemo(() => config.BUILD_TIMESTAMP && new Date(config.BUILD_TIMESTAMP).toUTCString(), []);

  if (windowIsIFramed) return null;

  const handleChange = (section: AppSection) => {handleNavClick(section); setKlarnaAmount(0);}
  const isActive = (section: AppSection) => activeSection === section;

  return (
    <Container>
      <Navigation>
        {sections.map(({name, icon, label, topSeparator}) => (
          <>
            {topSeparator && <NavDivider key={`${name}-divider`} size="l" />}
            <PrimaryNavButton
              key={`${name}`}
              icon={icon}
              onClick={() => handleChange(name)}
              isActive={isActive(name)}
              children={label}
            />
          </>
        ))}
      </Navigation>
      <Footer>
        <ShowIfFeatureFlag featureFlagName="buildNumber">
          <Tooltip
            title={
              <>
                <div>{`Build Number: ${config.APP_COMMIT_ID}`}</div>
                <div>{`Build Timestamp: ${buildTimestamp}`}</div>
              </>
            }
            children={<InfoOutlinedIcon style={{margin: 'auto', marginTop: '24px', color: 'gray'}} />}
          />
        </ShowIfFeatureFlag>
      </Footer>
    </Container>
  );
};

export default NavSideBar;

const Container = styled.div`
  width: ${SIDE_NAV_WIDTH};
  background: ${COLORS.ice100};
  border-right: 1px solid ${COLORS.ice500};
  padding: ${({theme}) => theme.spacing(2, 1)};
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const Footer = styled.footer`
  display: flex;
  flex-direction: column;
  margin-bottom: ${({theme}) => theme.spacing(2)};
`;

const Navigation = styled.nav`
  display: flex;
  flex-direction: column;
  gap: ${({theme}) => theme.spacing(1)};
`;

const NavDivider = styled(Divider)`
  margin: ${({theme}) => theme.spacing(1, 3)};
  border-color: ${COLORS.therapieSoftGrey};
`;
