import {useClinicAppointments} from 'hooks';
import {useMemo} from 'react';
import {GET_APPOINTMENTSQueryVariables} from 'types/ApiModel';
import {ClinicAppointmentModel} from 'types/DerivedApiModel';

interface Output {
  appointments?: ClinicAppointmentModel[];
  hasValidBookingId: boolean;
  cancellationPolicyPreventsCancellingBooking: boolean;
  isLoading: boolean;
}

interface Props {
  date?: string;
  businessId: string;
  clientId: string;
  clinicId: string;
}

const CANCELLATION_POLICY_HOURS = 24;
const CANCELLATION_POLICY_BUFFER = 10 / 60; // Allow for few minutes extra so we never hit the server version of the error

/**
 * Get a list of all of the appointments for this client, on this day
 */
function useClientsAppointmentsOnDate({date, businessId, clientId, clinicId}: Props): Output {
  const existingAppointmentQuery = useMemo<GET_APPOINTMENTSQueryVariables | null>(
    () =>
      date && clientId && businessId && clinicId
        ? {query: {businessId, clientId, branchId: clinicId, fromDate: date, toDate: date, fetchCancelled: false}}
        : null,
    [clinicId, businessId, clientId, date]
  );

  const {
    data: dataExistingClientAppointmentsFromSelectedDate,
    isInitialLoading,
    isFetching
  } = useClinicAppointments(existingAppointmentQuery);
  const hasValidBookingId = !!dataExistingClientAppointmentsFromSelectedDate?.some(({bookingId}) => !!bookingId);

  const earliestAppointmentDateTime = getEarliestDateTimeFromAppointments(
    dataExistingClientAppointmentsFromSelectedDate
  );

  const timeDiffFromNowToFirstAppointment = earliestAppointmentDateTime
    ? earliestAppointmentDateTime.getTime() - new Date().getTime()
    : null;

  const cancellationPolicyPreventsCancellingBooking = timeDiffFromNowToFirstAppointment
    ? millisecondsToHours(timeDiffFromNowToFirstAppointment) < CANCELLATION_POLICY_HOURS + CANCELLATION_POLICY_BUFFER
    : false;

  dataExistingClientAppointmentsFromSelectedDate?.forEach(({bookingId}) => {
    if (!bookingId) {
      console.warn(
        'Missing some Booking IDs for this appointment - some appointments will remain if todays booking is cancelled'
      ); // TODO: Log this out to observability
    }
  });

  return useMemo(
    () => ({
      appointments: dataExistingClientAppointmentsFromSelectedDate,
      isLoading: isFetching || isInitialLoading,
      hasValidBookingId,
      cancellationPolicyPreventsCancellingBooking
    }),
    [
      dataExistingClientAppointmentsFromSelectedDate,
      hasValidBookingId,
      cancellationPolicyPreventsCancellingBooking,
      isInitialLoading,
      isFetching
    ]
  );
}

export {useClientsAppointmentsOnDate};
export default useClientsAppointmentsOnDate;

const getEarliestDateTimeFromAppointments = (appointments?: ClinicAppointmentModel[] | null): Date | undefined => {
  const dateTimeOfFirstAppointment =
    appointments?.[0]?.appointmentDate && appointments?.[0]?.startTime
      ? new Date(appointments[0].appointmentDate + 'T' + appointments[0].startTime)
      : new Date();

  return appointments?.reduce((earliestDate, {appointmentDate, startTime}) => {
    const appointmentDateTime = new Date(appointmentDate + 'T' + startTime);
    return earliestDate.getTime() < appointmentDateTime.getTime() ? earliestDate : appointmentDateTime;
  }, dateTimeOfFirstAppointment);
};

const millisecondsToHours = (ms: number): number => ms / 1000 / 60 / 60;
