import {Tab, TabProps, Tabs as MuiTabs, TabsProps} from '@material-ui/core';
import React from 'react';
import styled from 'styled-components';
import {appearSlideDown} from 'utils/css/animations';

export interface ITabsProps {
  activeTabIndex: number;
  labels: string[];
  onChange: (e: React.ChangeEvent<unknown>, value: number) => void;
  tabProps?: TabProps;
  tabsProps?: TabsProps;
  size?: 'small' | 'medium';
}

const Tabs: React.FC<React.PropsWithChildren<ITabsProps>> = props => {
  return (
    <CenteredMuiTabs
      indicatorColor="primary"
      onChange={props.onChange}
      value={props.activeTabIndex}
      TabIndicatorProps={{style: {display: 'none'}}}
      textColor="primary"
      {...props.tabsProps}
    >
      {props.labels.map((label, index) => (
        <StyledTab
          index={index}
          isActive={props.activeTabIndex === index}
          key={label}
          label={label}
          orientation={props.tabsProps?.orientation}
          size={props.size}
          {...props.tabProps}
        />
      ))}
    </CenteredMuiTabs>
  );
};

export {Tabs};

interface StyledTabProps {
  index: number;
  orientation: TabsProps['orientation'];
  isActive?: boolean;
  size?: ITabsProps['size'];
}

const StyledTab = styled(Tab)<StyledTabProps>`
  height: ${({size}) => (size === 'small' ? '40px' : '48px')};
  min-height: 40px;
  min-width: ${({orientation}) => (orientation === 'vertical' ? '200px' : '0px')};
  font-size: ${({size}) => (size === 'small' ? '0.875rem' : '1rem')};
  color: rgba(0, 0, 0, 0.54);
  background-color: ${({isActive}) => (isActive ? '#F7F6FC' : undefined)};
  border-radius: ${({theme}) => theme.shape.borderRadius * 1.5}px;
  margin: ${({theme, size}) => theme.spacing(0, 0, size === 'small' ? 0 : 0.5, 1)};

  // Animation related
  animation: ${appearSlideDown} 150ms ease-in-out;
  animation-fill-mode: forwards;
  animation-delay: ${({index}) => index * 50}ms;
  transform: translateY(-1em);
  opacity: 0;

  span {
    color: ${({isActive}) => (isActive ? '#5B37BE' : undefined)};
    align-items: ${({orientation}) => (orientation === 'vertical' ? 'flex-start' : 'center')};
    padding: ${({theme}) => theme.spacing(0, 0.5)};
    text-transform: none;
  }
  :hover {
    background: ${({isActive}) => (isActive ? '#efebf8' : '#f8f8fb')};
  }
`;

const CenteredMuiTabs = styled(MuiTabs)`
  display: flex;
  align-items: center;
`;
