import COLORS, {COLORS_BASE, T_COLOR_NAMES} from 'constants/colors/index';
import React from 'react';
import styled, {css, useTheme} from 'styled-components';

const DEFAULT_BASE_FONT_SIZE = 16;

type TextHTMLTag =
  | 'h1'
  | 'h2'
  | 'h3'
  | 'h4'
  | 'h5'
  | 'h6'
  | 'div'
  | 'p'
  | 'a'
  | 'b'
  | 'del'
  | 'em'
  | 'figcaption'
  | 'i'
  | 'mark'
  | 'ins'
  | 'label'
  | 'small'
  | 'span'
  | 'strong'
  | 'sub'
  | 'sup'
  | 'time'
  | 'u';

interface Props {
  /**
   * The content that this should display
   */
  children?: string;
  /**
   * The HTML tag that should be rendered.
   * Will inherit all styling from the document unless variant or other props override them.
   */
  tag?: TextHTMLTag;
  /**
   * Variants represent the items defined in the Figma designs
   */
  variant?: Variant;
  /**
   * Will take any string - but if it matches one of the color names in our palette it will use it
   */
  color?: T_COLOR_NAMES | string;
  /**
   * The Leading (line height) defined in the designs ranges from 1.375 - 1.55, with the body 20/14 being implied as the document default
   * - H1: 44/32 = 1.375
   * - H2: 36/24 = 1.5
   * - H3: 32/20 = 1.6
   * - H4: 28/18 = 1.55
   * - H5: 24/16 = 1.5
   * - Body: 20/14 = 1.428
   *
   * For V1 we're going to offer 1.4, 1.5 and 1.6 as the lineheight options, aliased to 'default', 'small' and 'large'.
   */
  lineHeight?: 'default' | 'small' | 'large';
  /**
   * Font weight options
   */
  weight?: 'light' | 'regular' | 'medium' | 'semibold' | 'bold';
  /**
   * Renders the text as block instead of inline. Required for the centered option.
   */
  block?: boolean;
  /**
   * Center aligns the text. Requires block
   */
  centered?: boolean;
  /**
   * Enables Styled Components functionality on Typography component, e.g. `styled(Typography)...`
   */
  className?: string;
}

interface StyleProps extends Pick<Props, 'color' | 'lineHeight' | 'weight' | 'centered' | 'block' | 'tag' | 'variant'> {
  baseFontSize: number;
}

/**
 * #### Typography component for creating text in our UI constrained to the design spec.
 *
 * Font family: "Open Sans"
 *
 * Figma designs specify fonts, weights and sizes for [h1...h5, body], but
 * our component here should be flexible enough to let us implement
 * typography defined in the DS, but also extendable for when that gets
 * updated, as well as being dynamic enough to let us implement other
 * opinionated ad-hoc styles for our typography.
 */
export const Typography: React.FC<React.PropsWithChildren<Props>> = ({tag = 'span', children, ...rest}) => {
  const {baseFontSize} = useTheme() ?? {baseFontSize: DEFAULT_BASE_FONT_SIZE};
  return (
    <StyledText as={tag} tag={tag} baseFontSize={baseFontSize} {...rest}>
      {children}
    </StyledText>
  );
}; // https://styled-components.com/docs/api#as-polymorphic-prop

const weightMap = {
  light: 300,
  regular: 400,
  medium: 500,
  semibold: 600,
  bold: 700
};

const lineHeightMap = {
  small: 1.4,
  default: 1.5,
  large: 1.6
};

// Variants are designed to match 1:1 with the Figma spec for these specific tags and can be extended
type Variant = keyof typeof variants;
const variants = {
  default: null,
  h1: (baseFontSize = DEFAULT_BASE_FONT_SIZE) => css`
    font-size: ${32 / baseFontSize}rem;
    line-height: ${lineHeightMap['small']}; //  H1: 44/32 = 1.375
  `,
  h2: (baseFontSize = DEFAULT_BASE_FONT_SIZE) => css`
    font-size: ${24 / baseFontSize}rem;
    line-height: ${lineHeightMap['default']}; //  H2: 36/24 = 1.5
  `,
  h3: (baseFontSize = DEFAULT_BASE_FONT_SIZE) => css`
    font-size: ${20 / baseFontSize}rem;
    line-height: ${lineHeightMap['large']}; //  H3: 32/20 = 1.6
  `,
  h4: (baseFontSize = DEFAULT_BASE_FONT_SIZE) => css`
    font-size: ${18 / baseFontSize}rem;
    line-height: ${lineHeightMap['large']}; //  H4: 28/18 = 1.55
  `,
  h5: (baseFontSize = DEFAULT_BASE_FONT_SIZE) => css`
    font-size: ${16 / baseFontSize}rem;
    line-height: ${lineHeightMap['default']}; //  H5: 24/16 = 1.5
  `,
  // This should be the actual default document styling for html/body/etc. Variant is just to map to Figma design
  body: (baseFontSize = DEFAULT_BASE_FONT_SIZE) => css`
    font-size: ${14 / baseFontSize}rem;
    line-height: ${lineHeightMap['small']}; //  Body: 20/14 = 1.428
  `,
  error: (baseFontSize = DEFAULT_BASE_FONT_SIZE) => css`
    font-size: ${12 / baseFontSize}rem;
    line-height: ${lineHeightMap['small']}; //  Body: 20/14 = 1.428
    color: ${COLORS_BASE.scarlet600};
  `
};

const StyledText = styled.span<StyleProps>`
  // Variant styling overrides Tag if it has been specified.
  // Tag will still be used to set which HTML tag gets rendered.
  // This lets us set tag='H1', for example, and take on the H1
  // variant styling without having to also specify the variant prop.
  // Variant styles should always be respected. E.g. sometimes you'll
  // want it to look like a H1 but be rendered as a H2.
  ${({tag, variant, baseFontSize}) =>
    variant ? variants[variant as Variant]?.(baseFontSize) ?? null : variants[tag as Variant]?.(baseFontSize) ?? null}

  // If a color name matches any of the keys in our palette map, use that, else use the string directly
  color: ${({color}) => (color ? COLORS[color as T_COLOR_NAMES] ?? color : null)};

  display: ${({block}) => block && 'block'};
  font-weight: ${({weight}) => (weight ? weightMap[weight] : null)};
  line-height: ${({lineHeight}) => (lineHeight ? lineHeightMap[lineHeight] : null)};
  text-align: ${({centered}) => centered && 'center'};
`;

export {Typography as Text};
