import {PURCHASE_ATOM_PREFIX} from 'constants/purchase';
import {atom} from 'recoil';
import {AtomStatusState, TransactionStaff} from './types';

const purchaseStaffListState = atom<TransactionStaff[]>({
  key: `${PURCHASE_ATOM_PREFIX}StaffListState`,
  default: []
});

const purchaseStaffListStatusState = atom<AtomStatusState>({
  key: `${PURCHASE_ATOM_PREFIX}StaffListStatusState`,
  default: {loading: false}
});

const purchaseDefaultStaffIdState = atom<string>({
  key: `${PURCHASE_ATOM_PREFIX}DefaultStaffIdState`,
  default: undefined
});

export {purchaseDefaultStaffIdState, purchaseStaffListState, purchaseStaffListStatusState};
