import {UseQueryResult} from '@tanstack/react-query';
import {MAX_PAGE_SIZE} from 'constants/pagination';
import {useEffect} from 'react';
import {useRecoilValue, useSetRecoilState} from 'recoil';
import {
  allServiceHistoriesResourceSelector,
  appointments,
  futureServiceHistoriesResourceSelector,
  pastServiceHistoriesResourceSelector,
  ServiceInstance,
  sortedAscendingFlattenedAppointmentsSelector,
  sortedFlattenedFutureAppointmentsIncludingTodaySelector,
  sortedFlattenedFutureAppointmentsSelector,
  sortedFlattenedPastAppointmentsSelector
} from 'state';
import {GET_SERVICE_HISTORYQuery, PageInput} from 'types/ApiModel';
import {ServiceHistoryModel} from 'types/DerivedApiModel';
import useClientServiceHistory from './useClientServiceHistory';

interface IUseClientAppointmentsProps {
  clientId: string;
  page?: PageInput;
}

export interface IUseClientAppointments {
  pastServiceHistories?: ServiceHistoryModel[] | null;
  futureServiceHistories?: ServiceHistoryModel[] | null;
  allServiceHistories?: ServiceHistoryModel[] | null;
  pastAppointments?: ServiceInstance[] | null;
  futureAppointments?: ServiceInstance[] | null;
  futureAppointmentsIncludingToday?: ServiceInstance[] | null;
  allAppointments?: ServiceInstance[] | null;
  loading?: boolean;
  error?: Error | null;
  data?: GET_SERVICE_HISTORYQuery;
}

export type IUseClientAppointmentsOutput = UseQueryResult<IUseClientAppointments, Error>;

function useClientAppointments({
  clientId,
  page = {page: 0, size: MAX_PAGE_SIZE}
}: IUseClientAppointmentsProps): IUseClientAppointments {
  const {data, isLoading, isFetching, error} = useClientServiceHistory({clientId, page});
  const setServiceHistory = useSetRecoilState(appointments);
  useEffect(() => setServiceHistory(data?.getServiceHistory?.clientServiceHistories ?? []), [data, setServiceHistory]);

  const pastServiceHistories = useRecoilValue<ServiceHistoryModel[]>(pastServiceHistoriesResourceSelector);
  const futureServiceHistories = useRecoilValue<ServiceHistoryModel[]>(futureServiceHistoriesResourceSelector);
  const allServiceHistories = useRecoilValue<ServiceHistoryModel[]>(allServiceHistoriesResourceSelector);
  const pastAppointments = useRecoilValue<ServiceInstance[]>(sortedFlattenedPastAppointmentsSelector);
  const futureAppointments = useRecoilValue<ServiceInstance[]>(sortedFlattenedFutureAppointmentsSelector);
  const futureAppointmentsIncludingToday = useRecoilValue<ServiceInstance[]>(
    sortedFlattenedFutureAppointmentsIncludingTodaySelector
  );
  const allAppointments = useRecoilValue<ServiceInstance[]>(sortedAscendingFlattenedAppointmentsSelector);

  return {
    pastServiceHistories,
    futureServiceHistories,
    allServiceHistories,
    pastAppointments,
    futureAppointments,
    futureAppointmentsIncludingToday,
    allAppointments,
    loading: isLoading || isFetching,
    error,
    data
  };
}

export {useClientAppointments};
export default useClientAppointments;
