import {gql} from 'utils/graphql';

export const GET_BREAKS = gql`
  query GET_BREAKS($query: BreaksInput!, $page: PageInput) {
    getBreaks(query: $query, page: $page) {
      breaks {
        breakId
        breakDate
        startTime
        endTime
        label
        staffId
        machineId
        roomId
        paidBreak
      }
      page {
        number
        size
        totalElements
        totalPages
      }
    }
  }
`;

export const GET_ALL_BREAKS = gql`
  query GET_ALL_BREAKS($query: AllBreaksInput!) {
    getAllBreaks(query: $query) {
      breakId
      breakDate
      startTime
      endTime
      label
      staffId
      machineId
      roomId
      paidBreak
    }
  }
`;
