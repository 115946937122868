import {atom} from 'recoil';
import {ClinicAppointmentWithClientAndServiceModel} from 'types/DerivedApiModel';

export interface AtomClinicAppointmentsStatusState {
  loading: boolean;
  fetching: boolean;
  mutating: boolean;
}

// Data hydrates Recoil only on data change
const clinicAppointmentsState = atom<ClinicAppointmentWithClientAndServiceModel[]>({
  key: 'clinicAppointmentsState',
  default: []
});

// Status hydrates Recoil on any change
const clinicAppointmentsStatusState = atom<AtomClinicAppointmentsStatusState>({
  key: 'clinicAppointmentsStatusState',
  default: {loading: false, fetching: false, mutating: false}
});

export {clinicAppointmentsState, clinicAppointmentsStatusState};
