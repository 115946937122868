import useAllClinicStaff from 'hooks/staff/useAllClinicStaff';
import {useMemo} from 'react';
import {Option} from 'types/common';

interface UseStaffFilterOptionsProps {
  clinicId?: string | null;
}

export interface UseStaffFilterOptionsOutput {
  error: unknown;
  loadingStaff: boolean;
  staffOptions: Option[];
}

function useStaffFilterOptions({clinicId}: UseStaffFilterOptionsProps): UseStaffFilterOptionsOutput {
  const {data: allStaff, isLoading: loadingStaff, isFetching: fetchingStaff, error} = useAllClinicStaff({clinicId});

  const staffOptions = useMemo(() => {
    const mappedOptions =
      allStaff?.map(staff => ({id: staff.id, label: `${staff.firstName} ${staff.lastName}`} as Option)) ?? [];
    mappedOptions.unshift({id: '', label: 'Any available'});
    return mappedOptions;
  }, [allStaff]);

  return {
    error,
    loadingStaff: loadingStaff || fetchingStaff,
    staffOptions
  };
}

export {useStaffFilterOptions};
export default useStaffFilterOptions;
