import {captureException} from '@sentry/react';
import {GET_ALL_ADMIN_PRODUCT_TYPES} from 'data/graphql/queries/adminProducts';
import {useBusinessContext, useNotifications} from 'hooks';
import {GQLClientContext} from 'providers/gqlClient';
import {useContext} from 'react';
import {useQuery, UseQueryResult} from '@tanstack/react-query';
import {GET_ALL_ADMIN_PRODUCT_TYPESQuery, GET_ALL_ADMIN_PRODUCT_TYPESQueryVariables} from 'types/ApiModel';

export type IUseAdminProductTypes = UseQueryResult<GET_ALL_ADMIN_PRODUCT_TYPESQuery['getAllAdminProductTypes'], Error>;

function useAdminProductTypes(): IUseAdminProductTypes {
  const {gqlClient} = useContext(GQLClientContext);
  const {notifyError} = useNotifications();
  const {businessId} = useBusinessContext();

  return useQuery<GET_ALL_ADMIN_PRODUCT_TYPESQuery['getAllAdminProductTypes'], Error>(
    [USE_ADMIN_PRODUCT_TYPES_KEY],
    async () => {
      const resp = await gqlClient.request<GET_ALL_ADMIN_PRODUCT_TYPESQuery, GET_ALL_ADMIN_PRODUCT_TYPESQueryVariables>(
        GET_ALL_ADMIN_PRODUCT_TYPES,
        {businessId}
      );

      return resp.data.getAllAdminProductTypes;
    },
    {
      enabled: !!businessId,
      onError: error => {
        const errorString = error.message;
        notifyError(`Error getting Product Types data: ${errorString}`);
        captureException(new Error(errorString));
      }
    }
  );
}

export {useAdminProductTypes};

export const USE_ADMIN_PRODUCT_TYPES_KEY = 'GET_ADMIN_PRODUCT_TYPES';
