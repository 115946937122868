import {CardCvcElement, CardExpiryElement, CardNumberElement} from '@stripe/react-stripe-js';
import {Stripe, StripeElements} from '@stripe/stripe-js';
import {useMutation} from '@tanstack/react-query';
import {IStripePaymentHandler} from 'components/Purchase/Stripe/types';
import {ClientModelForPurchase} from 'components/Purchase/types';
import {fetchStripeErrorHandler} from 'components/Purchase/utils/purchase';
import usePaymentGatewayClient from 'hooks/axios/usePaymentGatewayClient';
import {useCallback, useMemo} from 'react';
import {useCardPaymentFlow} from './useCardPaymentFlow';

export interface StripeAndElementsInput {
  stripe: Stripe | null;
  elements: StripeElements | null;
}

export interface IUseStripeMotoFlowOutput {
  runMotoPayment: () => Promise<void>;
  onClickDialogCancel: (additionalCallback?: () => Promise<void>) => Promise<void>;
}

interface Props {
  client: ClientModelForPurchase;
  cancelFlowPayments: IStripePaymentHandler['cancelFlowPayments'];
}

const useStripeMotoFlow = ({cancelFlowPayments, client}: Props): IUseStripeMotoFlowOutput => {
  const {paymentGatewayClient} = usePaymentGatewayClient();

  const {updateCurrentCardPaymentIntent, updateCurrentCardPaymentDialogState, getCardPaymentFlowInfo} =
    useCardPaymentFlow();

  const createPaymentMethod = useCallback(async ({stripe, elements}: StripeAndElementsInput) => {
    if (!stripe || !elements) return;
    const {error, paymentMethod} = await stripe.createPaymentMethod({
      type: 'card',
      card: elements.getElement(CardNumberElement)!
    });
    if (error) throw new Error(error.message);
    return paymentMethod?.id;
  }, []);

  const {mutateAsync: confirmPayment} = useMutation({
    mutationFn: async (paymentMethod: string) => {
      const paymentIntentId = getCardPaymentFlowInfo().currentStep?.paymentIntent.id;
      const response = await paymentGatewayClient.post(
        `/payments/${paymentIntentId}/confirm`,
        JSON.stringify({
          paymentMethod,
          paymentMethodOptions: {card: {moto: true}}
        })
      );
      const paymentIntent = response.data;
      fetchStripeErrorHandler(response, paymentIntent);
      updateCurrentCardPaymentIntent(paymentIntent);
    }
  });

  const clearElements = useCallback((elements: StripeElements) => {
    elements.getElement(CardNumberElement)?.clear();
    elements.getElement(CardExpiryElement)?.clear();
    elements.getElement(CardCvcElement)?.clear();
  }, []);

  const handleMotoPayment = useCallback(
    async ({stripe, elements}: StripeAndElementsInput) => {
      if (!(stripe && elements && client)) throw new Error('Missing stripe or elements or client object');
      updateCurrentCardPaymentDialogState({status: 'LOADING'});
      const paymentMethod = await createPaymentMethod({stripe, elements});
      if (!paymentMethod) throw new Error('There was a problem validating entered card details');
      await confirmPayment(paymentMethod);
      clearElements(elements);
    },
    [clearElements, client, confirmPayment, createPaymentMethod, updateCurrentCardPaymentDialogState]
  );

  const runMotoPayment: IUseStripeMotoFlowOutput['runMotoPayment'] = useCallback(() => {
    updateCurrentCardPaymentDialogState({status: 'COLLECTING'});
    return new Promise((resolve, reject) =>
      updateCurrentCardPaymentDialogState({
        onPayClick: async (input: StripeAndElementsInput) => {
          try {
            await handleMotoPayment(input);
            resolve();
          } catch (error) {
            reject(error);
          }
        }
      })
    );
  }, [handleMotoPayment, updateCurrentCardPaymentDialogState]);

  const onClickDialogCancel = useCallback(async () => {
    updateCurrentCardPaymentDialogState({status: 'CANCELLING'});
    await cancelFlowPayments();
  }, [cancelFlowPayments, updateCurrentCardPaymentDialogState]);

  return useMemo(() => ({onClickDialogCancel, runMotoPayment}), [runMotoPayment, onClickDialogCancel]);
};

export {useStripeMotoFlow};
