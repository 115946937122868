import {PAYMENT_TYPES_NAMES, PURCHASE_ITEM_TYPE} from 'constants/payments';
import {useCurrency} from 'hooks/currency/useCurrency';
import {CartItem, CreatePurchaseRequestPayment} from 'hooks/payments/types';
import {CartCalculationInputProps, useCartCalculation} from 'hooks/payments/useCartCalculation';
import {useMemo, useState} from 'react';
import {StateSetter} from 'types/common';
import {getTotalPrice, getTotalPriceToTwoDecimalPlaces as getTotal} from 'utils';
import {usePaymentTypes} from './usePaymentTypes';
import {usePurchaseSummaryItems} from './usePurchaseSummaryItems';

export interface IUsePaymentAmountsCalculations {
  amountOfChangeDueFromPhorestApi: number;
  totalTax: number;
  totalNet: number;
  amountOfPaymentDueBeforeDiscounts: number;
  amountOfPaymentDueAfterDiscounts: number;
  amountOfPaymentDueOutstanding: number;
  amountOfChangeDue: number;
  isLoading: boolean;
  sumOfDiscounts: number;
  sumOfPaymentInstances: number;
  sumOfPaymentInstancesStripeOnly: number;
  textAmountOfChangeDue: string;
  setAmountOfChangeDueFromPhorestApi: StateSetter<number>;
}

/**
 * Calculates various Purchase related totals based on the
 * selected purchase items, discounts and payment instances.
 * Loosely grouped by amounts and sums, where "amount" is a
 * singular value and a "sum" is a sum of multiple values -
 * e.g. payment instances or purchase items
 *
 * @returns
 * - amountOfChangeDueFromPhorestApi - Change due to the customer when the transaction is completed, comes from Phorest
 * - amountOfPaymentDueBeforeDiscounts - Cost of all products & services added, regardless of discounts applied
 * - amountOfPaymentDueAfterDiscounts - Cost of all products & services added, minus discounts
 * - amountOfPaymentDueOutstanding - Total less discounts and payments, with a minimum value of zero
 * - amountOfChangeDue - Amount that payments exceeds the amountOfPaymentDueAfterDiscounts
 * - sumOfDiscounts - The sum, of the discounts
 * - sumOfPaymentInstances - You get the idea
 * - sumOfPaymentInstancesStripeOnly - (e.g. only Card and Virtual Terminal amounts)
 * - textAmountOfChangeDue - Change label for showing to the user
 * - setAmountOfChangeDueFromPhorestApi - Setter for setting the change due amount we get from Phorest (not sure why we need to use Phorest to give us this...)
 */
function usePaymentAmountsCalculations(): IUsePaymentAmountsCalculations {
  const {formatCurrency} = useCurrency();
  const {instances: paymentTypeInstances} = usePaymentTypes();
  const {CARD_PAYMENT, VIRTUAL_TERMINAL} = PAYMENT_TYPES_NAMES;
  const {purchaseItems} = usePurchaseSummaryItems();
  const [amountOfChangeDueFromPhorestApi, setAmountOfChangeDueFromPhorestApi] = useState<number>(0);

  const cartCalulationProps: CartCalculationInputProps = useMemo(() => {
    const items: CartItem[] = purchaseItems.map(item => ({
      type: item.type === PURCHASE_ITEM_TYPE.TREATMENT ? 'SERVICE' : item.type,
      id: item.id,
      quantity: item.quantity,
      price: item.price,
      discountInput: item.discount ? {value: item.discount.value, type: item.discount.type} : undefined
    }));

    const payments: CreatePurchaseRequestPayment[] = paymentTypeInstances
      .filter(({amount}) => !!amount)
      .map(payment => ({
        amount: payment.amount ?? 0,
        customPaymentTypeId: payment.paymentTypeId ?? '',
        type: payment.name ?? ''
      }));

    return {items, payments};
  }, [paymentTypeInstances, purchaseItems]);

  const {data: cartCalculation, isLoading} = useCartCalculation(cartCalulationProps);

  const sumOfPaymentInstancesStripeOnly = useMemo(
    () =>
      getTotal(
        paymentTypeInstances.reduce((acc: number[], payment) => {
          const paymentName = payment.name?.toUpperCase() as PAYMENT_TYPES_NAMES;
          if (payment.amount !== 0 && [CARD_PAYMENT, VIRTUAL_TERMINAL].includes(paymentName)) {
            acc.push(payment.amount ?? 0);
          }
          return acc;
        }, [])
      ),
    [paymentTypeInstances, CARD_PAYMENT, VIRTUAL_TERMINAL]
  );

  const totalCart = useMemo(
    () => ({
      gross: cartCalculation?.total.gross ?? 0,
      net: cartCalculation?.total.net ?? 0,
      tax: cartCalculation?.total.tax ?? 0,
      payments: cartCalculation?.total.payments ?? 0,
      discount: cartCalculation?.total.discount ?? 0
    }),
    [cartCalculation?.total]
  );

  const sumOfPaymentInstances = useMemo(() => totalCart.payments, [totalCart.payments]);

  const amountOfPaymentDueAfterDiscounts = useMemo(() => totalCart.gross, [totalCart.gross]);

  const amountOfPaymentDueBeforeDiscounts = useMemo(
    () => sumOfPaymentInstances - totalCart.discount,
    [sumOfPaymentInstances, totalCart.discount]
  );

  // Outstanding balance, cuts off at zero - see amountOfChangeDue for the difference when payment exceeds dues
  const amountOfPaymentDueOutstanding = useMemo(
    () => Math.max(getTotalPrice([amountOfPaymentDueAfterDiscounts, -sumOfPaymentInstances]), 0),
    [amountOfPaymentDueAfterDiscounts, sumOfPaymentInstances]
  );

  const amountOfChangeDue = useMemo(() => cartCalculation?.changeDue ?? 0, [cartCalculation?.changeDue]);

  const textAmountOfChangeDue = useMemo(
    () => `Change due to the client: ${formatCurrency({value: amountOfChangeDueFromPhorestApi})}`,
    [amountOfChangeDueFromPhorestApi, formatCurrency]
  );

  return {
    amountOfChangeDueFromPhorestApi,
    amountOfPaymentDueBeforeDiscounts,
    amountOfPaymentDueAfterDiscounts,
    amountOfPaymentDueOutstanding,
    amountOfChangeDue,
    isLoading,
    sumOfDiscounts: totalCart.discount,
    sumOfPaymentInstances,
    sumOfPaymentInstancesStripeOnly,
    textAmountOfChangeDue,
    totalTax: totalCart.tax,
    totalNet: totalCart.net,
    setAmountOfChangeDueFromPhorestApi
  };
}

export {usePaymentAmountsCalculations};
