import React from 'react';
import {makeStyles} from '@material-ui/core';
import {ReactComponent as TherapieLotusIcon} from '../Icons/therapie-lotus-loader.svg';

const animationDuration = 1000;
const animationDirection = 'alternate-reverse';
const animationFillMode = 'both';
const animationIterationCount = 'infinite';
const animationEasing = 'ease-out';

const useStyles = makeStyles(() => ({
  '@keyframes topleaf': {
    '0%': {
      'stroke-dashoffset': 700
    },
    '100%': {
      'stroke-dashoffset': 0
    }
  },

  '@keyframes sideleaves1': {
    '0%': {
      'stroke-dashoffset': 550
    },
    '100%': {
      'stroke-dashoffset': 0
    }
  },

  '@keyframes sideleaves2': {
    '0%': {
      'stroke-dashoffset': 530
    },
    '100%': {
      'stroke-dashoffset': 0
    }
  },

  '@keyframes sideleaves3': {
    '0%': {
      'stroke-dashoffset': 410
    },
    '100%': {
      'stroke-dashoffset': 0
    }
  },

  '@keyframes sideleaves4': {
    '0%': {
      'stroke-dashoffset': 250
    },
    '100%': {
      'stroke-dashoffset': 0
    }
  },

  '@keyframes bottomleaf': {
    '0%': {
      'stroke-dashoffset': 270
    },
    '100%': {
      'stroke-dashoffset': 0
    }
  },

  lotusLoader: {
    fill: 'transparent',
    width: '100%',
    stroke: 'currentColor',
    'stroke-width': 3,

    '& .top': {
      'stroke-dasharray': 700,
      animation: `$topleaf ${animationDuration}ms ${animationEasing} ${animationDirection}`,
      'animation-fill-mode': animationFillMode,
      'animation-iteration-count': animationIterationCount
    },
    '& .left-1, & .right-1': {
      'stroke-dasharray': 550,
      animation: `$sideleaves1 ${animationDuration}ms ${animationEasing} ${animationDirection}`,
      'animation-fill-mode': animationFillMode,
      'animation-iteration-count': animationIterationCount
    },
    '& .left-2, & .right-2': {
      'stroke-dasharray': 530,
      animation: `$sideleaves2 ${animationDuration}ms ${animationEasing} ${animationDirection}`,
      'animation-fill-mode': animationFillMode,
      'animation-iteration-count': animationIterationCount
    },
    '& .left-3, & .right-3': {
      'stroke-dasharray': 410,
      animation: `$sideleaves3 ${animationDuration}ms ${animationEasing} ${animationDirection}`,
      'animation-fill-mode': animationFillMode,
      'animation-iteration-count': animationIterationCount
    },
    '& .left-4, & .right-4': {
      'stroke-dasharray': 250,
      animation: `$sideleaves4 ${animationDuration}ms ${animationEasing} ${animationDirection}`,
      'animation-fill-mode': animationFillMode,
      'animation-iteration-count': animationIterationCount
    },
    '& .bottom': {
      'stroke-dasharray': 270,
      animation: `$bottomleaf ${animationDuration}ms ${animationEasing} ${animationDirection}`,
      'animation-fill-mode': animationFillMode,
      'animation-iteration-count': animationIterationCount
    },

    '& .left-4': {
      'animation-delay': animationDuration * 0.1 * 1 + 'ms'
    },

    '& .left-3': {
      'animation-delay': animationDuration * 0.1 * 2 + 'ms'
    },

    '& .left-2': {
      'animation-delay': animationDuration * 0.1 * 3 + 'ms'
    },

    '& .left-1': {
      'animation-delay': animationDuration * 0.1 * 4 + 'ms'
    },

    '& .top, .top': {
      'animation-delay': animationDuration * 0.1 * 5 + 'ms'
    },

    '& .right-1': {
      'animation-delay': animationDuration * 0.1 * 6 + 'ms'
    },

    '& .right-2': {
      'animation-delay': animationDuration * 0.1 * 7 + 'ms'
    },

    '& .right-3': {
      'animation-delay': animationDuration * 0.1 * 8 + 'ms'
    },

    '& .right-4': {
      'animation-delay': animationDuration * 0.1 * 9 + 'ms'
    }
  }
}));

interface Props {
  style: React.CSSProperties;
}

const LoadingLotus: React.FC<React.PropsWithChildren<Props>> = ({style}) => {
  const {lotusLoader} = useStyles();
  return (
    <div className={lotusLoader} style={style}>
      <TherapieLotusIcon />
    </div>
  );
};

export default LoadingLotus;
