import {PERMISSIONS, AppSection, Role} from 'constants/permissions';
import {useCallback, useMemo} from 'react';
import {useRecoilValue} from 'recoil';
import {userState} from 'state';

function usePermission(): (appSection: AppSection) => boolean {
  const user = useRecoilValue(userState);
  const userRoles = user?.attributes?.roles;

  const permissionsArray = useMemo(() => {
    const permissionsArray: string[] = [];
    userRoles?.forEach((role: string) => {
      if (Object.keys(PERMISSIONS).includes(role)) permissionsArray.push(...PERMISSIONS[role as Role]);
    });
    return permissionsArray;
  }, [userRoles]);

  const hasPermission = useCallback(
    (appSection: AppSection) => permissionsArray.includes(appSection) || permissionsArray.includes('*'),
    [permissionsArray]
  );

  return hasPermission;
}

export {usePermission};
export default usePermission;
