import {captureException} from '@sentry/react';
import {GET_ADMIN_COURSES} from 'data/graphql/queries/adminCourses';
import useNotifications from 'hooks/notifications/useNotifications';
import {GQLClientContext} from 'providers/gqlClient';
import {useContext, useMemo} from 'react';
import {useQuery, UseQueryResult} from '@tanstack/react-query';
import {GET_ADMIN_COURSESQuery, GET_ADMIN_COURSESQueryVariables} from 'types/ApiModel';
import {Nullable} from 'types/common';

export type IUseAdminCoursesOutput = UseQueryResult<GET_ADMIN_COURSESQuery['getAdminCourses'], Error>;

function useAdminCourses(variables: Nullable<GET_ADMIN_COURSESQueryVariables>): IUseAdminCoursesOutput {
  const {gqlClient} = useContext(GQLClientContext);
  const {businessId, name, onlineStatus} = variables?.query || {};
  const {notifyError} = useNotifications();

  const adminGetCoursesQuery = useMemo<Nullable<GET_ADMIN_COURSESQueryVariables>>(
    () => ({query: {businessId: businessId!, name, onlineStatus}, page: variables?.page}),
    [businessId, name, onlineStatus, variables?.page]
  );

  return useQuery<GET_ADMIN_COURSESQuery['getAdminCourses'], Error>(
    [USE_ADMIN_COURSES_KEY, adminGetCoursesQuery],
    async () => {
      const resp = await gqlClient.request<GET_ADMIN_COURSESQuery, Nullable<GET_ADMIN_COURSESQueryVariables>>(
        GET_ADMIN_COURSES,
        adminGetCoursesQuery
      );
      return resp.data.getAdminCourses;
    },
    {
      enabled: !!businessId,
      onError: (error: Error) => {
        const errorString = error.message;
        notifyError(`Error getting courses: ${errorString}`);
        captureException(new Error(errorString));
      }
    }
  );
}

export {useAdminCourses};

export const USE_ADMIN_COURSES_KEY = 'GET_ADMIN_COURSES';
