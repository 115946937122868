import {Button} from '@therapie-ui';
import Fade from '@material-ui/core/Fade';
import {SIZES} from 'constants/sizes';
import {muiTheme} from 'providers/theme';
import React from 'react';
import styled from 'styled-components';
import EmptyStateIcon, {EmptyStateIconKey} from './EmptyStateIcon';
import {Typography} from '@therapie-ui/Typography/Typography';

export interface EmptyStateProps {
  actionButton?: React.ReactNode;
  darkTitle?: boolean;
  details?: string | React.ReactNode;
  /**
   * In Call centre we are having a very naty bug https://therapie.atlassian.net/browse/CC-2192 related probably by react 18 + recoil on Login
   * apparently the flag does not get set properly and the app is stuck on a loop
   * allow to show some fallback button but not immediately, doing so after 5seconds some fall back button are shown and user can refresh the page/go home
   */
  delayShowButtons?: boolean;
  fillHeight?: boolean;
  icon?: EmptyStateIconKey;
  showGoHomeOption?: boolean;
  showReloadOption?: boolean;
  size?: SIZES;
  title?: string;
  className?: string; // Allowing for className lets us use styled(EmptyState) //// https://styled-components.com/docs/basics#styling-any-component
}

const EmptyState: React.FC<React.PropsWithChildren<EmptyStateProps>> = ({
  actionButton,
  className,
  darkTitle = false,
  delayShowButtons = false,
  details,
  fillHeight,
  icon,
  showGoHomeOption = false,
  showReloadOption = false,
  size = SIZES.M,
  title
}) => (
  <StyledEmptyState size={size} fillHeight={fillHeight} darkTitle={darkTitle} className={className}>
    {icon && <EmptyStateIcon icon={icon} size={size} />}
    {title && (
      <StyledHeader size={size} darkTitle={darkTitle} weight="semibold">
        {title}
      </StyledHeader>
    )}
    {details && <StyledDetails size={size}>{details}</StyledDetails>}

    {(actionButton || showReloadOption || showGoHomeOption) && (
      <Fade in={true} style={{transitionDelay: delayShowButtons ? '5s' : '0'}}>
        <StyledButtonGroup>
          {showReloadOption && (
            <Button
              variant="outlined"
              children={'Reload'}
              onClick={() => window.location.reload()}
              className={'reload-button'}
              size={'large'}
            />
          )}

          {showGoHomeOption && (
            <Button
              variant="outlined"
              children={'Go back home'}
              onClick={() => (window.location.href = '/')}
              className={'reload-button'}
              size={'large'}
            />
          )}

          {actionButton}
        </StyledButtonGroup>
      </Fade>
    )}
  </StyledEmptyState>
);

export default EmptyState;

const {pxToRem} = muiTheme.typography;

const headerFontSizeMap = {
  [SIZES.XXS]: pxToRem(12),
  [SIZES.XS]: pxToRem(14),
  [SIZES.S]: pxToRem(16),
  [SIZES.M]: pxToRem(18),
  [SIZES.L]: pxToRem(20),
  [SIZES.XL]: pxToRem(24),
  [SIZES.XXL]: pxToRem(28)
};

const bodyFontSizeMap = {
  [SIZES.XXS]: pxToRem(12),
  [SIZES.XS]: pxToRem(13),
  [SIZES.S]: pxToRem(14),
  [SIZES.M]: pxToRem(16),
  [SIZES.L]: pxToRem(16),
  [SIZES.XL]: pxToRem(18),
  [SIZES.XXL]: pxToRem(21)
};

interface StyleProps {
  readonly height?: number;
  readonly padding?: number;
  readonly size?: SIZES;
  readonly fillHeight?: boolean;
  readonly darkTitle?: boolean;
}

const StyledEmptyState = styled.div<StyleProps>`
  align-content: center;
  align-items: center;
  color: #7a7a7a;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  width: 100%;
  font-size: ${({size}) => size && bodyFontSizeMap[size]};
  flex-grow: ${({fillHeight}) => (fillHeight ? 1 : 0)};
  height: ${({fillHeight}) => (fillHeight ? '100%' : 'inherit')};
  padding: ${({padding}) => (padding ? padding + 'px' : undefined)};

  > svg {
    color: #aaa;
    font-size: 120px;
    margin-top: ${({fillHeight}) => (fillHeight ? 0 : '24px')};
  }
`;

const StyledHeader = styled(Typography)<StyleProps>`
  color: ${({darkTitle}) => (darkTitle ? '#3d3d3d' : 'inherit')};
  font-size: ${({size}) => size && headerFontSizeMap[size]};
  margin: 0.5em 0; // We use EMs here because it's always relative to current fontsize
`;

const StyledDetails = styled.main<StyleProps>`
  margin: 0 0 1em;
  max-width: 32em;
  line-height: 1.25;
`;

const StyledButtonGroup = styled.div`
  margin: 0.5em 0 1em;
  display: flex;
  justify-content: space-between;
  .reload-button {
    line-height: 1.1;
  }
  .reload-button + .reload-button {
    margin-left: 1em;
  }
`;
