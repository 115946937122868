import {
  IGQLCustomError,
  TGQLCustomErrors,
  TGQLCustomErrorsViaReactQuery,
  TGQLCustomErrorsViaReactQueryLocal
} from 'types/errors';

export interface ErrorCodesAndMessages {
  overrideableErrorCodes?: string[];
  errorMessage?: string;
}

export const parseCustomGQLErrors = (error: Error): ErrorCodesAndMessages => {
  try {
    const typedError = error as unknown as TGQLCustomErrors;
    const gqlCustomError: IGQLCustomError = JSON.parse(
      typedError?.graphQLErrors?.[0]?.message?.detail ?? typedError.message
    );

    return {
      errorMessage:
        gqlCustomError?.payload
          ?.map(el => el?.error as string)
          .join(', ')
          .split('_')
          .join(' ')
          .toLowerCase() ?? gqlCustomError.errorType,

      // Using reduce here to both filter and map in one loop. We only want the payloads with an error property for overrides
      overrideableErrorCodes: gqlCustomError?.payload?.reduce((errors, payload) => {
        if (payload?.error) errors.push(payload.error);
        return errors;
      }, [] as string[])
    };
  } catch {
    return {errorMessage: error.message ?? 'Unkown Error', overrideableErrorCodes: []};
  }
};

export const parseCustomGQLErrorsForReactQuery = (error: Error): ErrorCodesAndMessages => {
  try {
    const errorString = (error as unknown as TGQLCustomErrorsViaReactQuery)?.response?.errors?.[0]?.message;
    const errorStringFromLocal = (error as unknown as TGQLCustomErrorsViaReactQueryLocal)?.response?.errors?.[0]
      ?.message?.detail;
    const gqlCustomError: IGQLCustomError = JSON.parse(errorStringFromLocal ?? errorString);

    return {
      errorMessage:
        gqlCustomError?.payload
          ?.map(el => el?.error as string)
          .join(', ')
          .replaceAll('_', ' ')
          .toLowerCase() ?? gqlCustomError.errorType,

      // Using reduce here to both filter and map in one loop. We only want the payloads with an error property for overrides
      overrideableErrorCodes: gqlCustomError?.payload?.reduce((errors, payload) => {
        if (payload?.error) errors.push(payload.error);
        return errors;
      }, [] as string[])
    };
  } catch {
    return {errorMessage: error.message ?? 'Unkown Error', overrideableErrorCodes: []};
  }
};
