import {CardContent, LinearProgress} from '@material-ui/core';
import {Button} from '@therapie-ui';
import ClientNotes from 'components/Common/Clients/ClientNotes';
import {useBusinessContext, useClient, useUpdateClientNotes} from 'hooks';
import React, {useCallback, useEffect, useMemo, useState} from 'react';
import styled from 'styled-components';
import SectionHeader from '../SectionHeader';

interface Props {
  clientId: string;
  showHeader?: boolean;
  fullHeight?: boolean;
}

const CustomerNotes: React.FC<React.PropsWithChildren<Props>> = ({clientId, showHeader = true, fullHeight = false}) => {
  const {businessId} = useBusinessContext();
  const {
    data: client,
    isFetching: isFetchingClient,
    isInitialLoading: isLoadingClient
  } = useClient({businessId, clientId});
  const {loading: isLoadingClientUpdate, update: updateNotes} = useUpdateClientNotes({clientId, businessId});
  const [clientNotes, setClientNotes] = useState<string | undefined>();
  const hasUnsavedEdits = useMemo(() => clientNotes && clientNotes !== client?.notes, [client, clientNotes]);

  const saveNotes = useCallback(async () => {
    if (clientNotes && clientNotes !== client?.notes) await updateNotes(clientNotes);
  }, [client, clientNotes, updateNotes]);

  const cancelChanges = useCallback(() => client?.notes && setClientNotes(client.notes), [client]);

  useEffect(() => {
    setClientNotes(client?.notes ?? undefined);
  }, [client, setClientNotes]);

  const isLoading = isLoadingClientUpdate || isFetchingClient || isLoadingClient;

  return (
    <Container fullHeight={fullHeight}>
      {showHeader && (
        <SectionHeader>
          Notes
          {hasUnsavedEdits && (
            <ButtonContainer>
              <CancelButton key="Cancel" variant="ghost" color="primary" children="Cancel" onClick={cancelChanges} />
              <Button
                key="Save"
                data-test-id="save-client-notes-btn"
                color="primary"
                variant="filled"
                children="Save"
                disabled={isLoading}
                onClick={saveNotes}
              />
            </ButtonContainer>
          )}
        </SectionHeader>
      )}
      <NotesContent fullHeight={fullHeight}>
        <ClientNotes clientNotes={clientNotes} updateNotes={notes => setClientNotes(notes)} resizeable />
      </NotesContent>
      {isLoading && <LinearProgress />}
    </Container>
  );
};

export {CustomerNotes};
export default CustomerNotes;

const CancelButton = styled(Button)`
  margin-right: ${({theme}) => theme.spacing(2)};
`;

const NotesContent = styled(CardContent)<{fullHeight: boolean}>`
  flex-grow: ${({fullHeight}) => (fullHeight ? '1' : '0')};
`;

const Container = styled.div<{fullHeight: boolean}>`
  display: flex;
  flex-direction: column;
  height: ${({fullHeight}) => (fullHeight ? '100%' : 'inherit')};
`;

const ButtonContainer = styled.div`
  display: flex;
`;
