import TextField from '@material-ui/core/TextField';
import React, {useState} from 'react';
import styled from 'styled-components';
import {Button, Text} from '@therapie-ui';

export interface DevAuthState {
  username: string;
  password: string;
}

interface Props {
  onLogin: (auth: DevAuthState) => void;
}

const DevFormLogin: React.FC<React.PropsWithChildren<Props>> = ({onLogin}) => {
  const [authState, setAuthState] = useState<DevAuthState>({username: '', password: ''});

  const handleKeypress = (event: React.KeyboardEvent<HTMLFormElement>) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      onLogin(authState);
    }
  };

  return (
    <Container onKeyPress={handleKeypress}>
      <Text variant="smallTitle">Dev Login Form</Text>
      <TextField
        label="username"
        onChange={el => setAuthState({...authState, username: el.target.value})}
        variant="outlined"
      />
      <TextField
        label="password"
        type="password"
        onChange={el => setAuthState({...authState, password: el.target.value})}
        variant="outlined"
      />
      <Button variant="outlined" children="Login" onClick={() => onLogin(authState)} />
    </Container>
  );
};

export {DevFormLogin};
export default DevFormLogin;

const Container = styled.form`
  background-color: white;
  border-radius: 5px;
  width: 100%;
  border: 1px solid lightgrey;
  display: flex;
  flex-direction: column;
  margin-top: 2rem;
  padding: 2rem;
  > * {
    margin-bottom: 1rem;
  }
  button {
    margin: 0;
  }
`;
