import {keyframes} from 'styled-components';

export const appearSlideDown = keyframes`
from {
  transform: translateY(-1em);
  opacity: 0;
}
to {
  transform: translateY(0);
  opacity: 1;
}
`;
