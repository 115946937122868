import useBaseFontSize from './useBaseFontSize';

export interface UseTypographyUtilsOutput {
  pxToRem: (sizePx: number, basePx?: number) => string;
}

/**
 * Typography Utils
 * @returns pxToRem: Gives you a rem size based on an pixel size paramater and the theme base font size. Optionally pass a base as second param to override.
 */
function useTypographyUtils(): UseTypographyUtilsOutput {
  const baseFontSize = useBaseFontSize();
  const pxToRem: UseTypographyUtilsOutput['pxToRem'] = (sizePx, basePx) => sizePx / (basePx ?? baseFontSize) + 'rem';
  return {pxToRem};
}

export {useTypographyUtils};
export default useTypographyUtils;
