import {confirmCRUDinDEV} from 'utils/devTools';
import {getAnalyticsAttributes} from 'utils';
import {GQLClientContext} from 'providers/gqlClient';
import {TGQLCustomErrorsViaReactQuery} from 'types/errors';
import {DELETE_STAFF_BREAK} from 'data/graphql/mutations/breaks';
import {DELETE_STAFF_BREAKMutation, DELETE_STAFF_BREAKMutationVariables} from 'types/ApiModel';
import {useContext} from 'react';
import {useMutation, UseMutationResult} from '@tanstack/react-query';
import {useNotifications} from 'hooks';
import {useRecoilValue} from 'recoil';
import {userState} from 'state';
import * as Sentry from '@sentry/react';
import useAnalytics from 'hooks/analytics/useAnalytics';

interface Input {
  showNotifications: boolean;
}
type MutationResult = DELETE_STAFF_BREAKMutation['deleteStaffBreak'];
type DeleteStaffBreakOutput = UseMutationResult<
  MutationResult,
  TGQLCustomErrorsViaReactQuery,
  DELETE_STAFF_BREAKMutationVariables
>;

export const USE_DELETE_STAFF_BREAK_KEY = 'USE_DELETE_STAFF_BREAK';

function useDeleteStaffBreak({showNotifications = true}: Input): DeleteStaffBreakOutput {
  const {gqlClient} = useContext(GQLClientContext);
  const {notifySuccess, notifyError} = useNotifications();
  const {mutateAsync: sendAnalytics} = useAnalytics();
  const user = useRecoilValue(userState);

  return useMutation<MutationResult, TGQLCustomErrorsViaReactQuery, DELETE_STAFF_BREAKMutationVariables, unknown>(
    async variables => {
      if (!variables) return null;
      confirmCRUDinDEV(variables.payload.branchId);
      const resp = await gqlClient.request<DELETE_STAFF_BREAKMutation, DELETE_STAFF_BREAKMutationVariables>(
        DELETE_STAFF_BREAK,
        variables
      );
      return resp.data?.deleteStaffBreak;
    },
    {
      mutationKey: [USE_DELETE_STAFF_BREAK_KEY],
      onError: (error, variables) => {
        const errorMessage = `Error Deleting Break: ${error?.response?.errors?.[0].message}`;
        notifyError(errorMessage);
        Sentry.captureException(new Error(errorMessage), {extra: {payload: variables}});
        console.error(errorMessage, error);
      },
      onSuccess: (successfulDeletion, variables) => {
        if (!successfulDeletion) return notifyError(`There was an error deleting this break`);
        sendAnalytics(
          getAnalyticsAttributes({
            actionName: 'deleteBreak',
            user,
            details: {
              businessId: variables.payload.businessId,
              clinicId: variables.payload.branchId
            }
          })
        );
        showNotifications && notifySuccess(`Break deleted`);
      }
    }
  );
}

export {useDeleteStaffBreak};
export default useDeleteStaffBreak;
