import {useEffect, useMemo, useState} from 'react';
import {StateSetter} from 'types/common';
import {PaymentTypeModel, VoucherModel} from 'types/DerivedApiModel';

export interface VoucherState {
  createClientVoucherDialogOpen: boolean;
  payWithVoucherDialogOpen: boolean;
  payWithKlarnaDialogOpen: boolean;
  voucherAmount: number;
  voucherAsPaymentType: PaymentTypeModel | undefined;
  klarnaAsPaymentType: PaymentTypeModel | undefined;
  voucherFoundBySerialNumber: VoucherModel | undefined;
  voucherLoading: boolean;
  voucherSerialNumber: string;
  setCreateClientVoucherDialogOpen: StateSetter<boolean>;
  setPayWithVoucherDialogOpen: StateSetter<boolean>;
  setPayWithKlarnaDialogOpen: StateSetter<boolean>;
  setVoucherAmount: StateSetter<number>;
  setVoucherAsPaymentType: StateSetter<PaymentTypeModel | undefined>;
  setKlarnaAsPaymentType: StateSetter<PaymentTypeModel | undefined>;
  setVoucherFoundBySerialNumber: StateSetter<VoucherModel | undefined>;
  setVoucherLoading: StateSetter<boolean>;
  setVoucherSerialNumber: StateSetter<string>;
  setKlarnaPhoneNumber: StateSetter<string>;
  klarnaPhoneNumber: string;
  setKlarnaClientName: StateSetter<string>;
  klarnaClientName: string;
  setKlarnaSucceeded: StateSetter<boolean>;
  klarnaSucceeded: boolean;
}

const useVoucherDialogState = (): VoucherState => {
  const [createClientVoucherDialogOpen, setCreateClientVoucherDialogOpen] = useState<boolean>(false);
  const [payWithVoucherDialogOpen, setPayWithVoucherDialogOpen] = useState<boolean>(false);
  const [payWithKlarnaDialogOpen, setPayWithKlarnaDialogOpen] = useState<boolean>(false);
  const [voucherAmount, setVoucherAmount] = useState<number>(0);
  const [voucherAsPaymentType, setVoucherAsPaymentType] = useState<PaymentTypeModel | undefined>();
  const [klarnaAsPaymentType, setKlarnaAsPaymentType] = useState<PaymentTypeModel | undefined>();
  const [voucherFoundBySerialNumber, setVoucherFoundBySerialNumber] = useState<VoucherModel | undefined>();
  const [voucherLoading, setVoucherLoading] = useState(false);
  const [voucherSerialNumber, setVoucherSerialNumber] = useState<string>('');
  const [klarnaPhoneNumber, setKlarnaPhoneNumber] = useState<string>('');
  const [klarnaClientName, setKlarnaClientName] = useState<string>('');
  const [klarnaSucceeded, setKlarnaSucceeded] = useState<boolean>(false);

  useEffect(() => {
    if (!createClientVoucherDialogOpen || !payWithVoucherDialogOpen) {
      setVoucherAmount(0);
      setVoucherLoading(false);
      setVoucherSerialNumber('');
    }
  }, [createClientVoucherDialogOpen, payWithVoucherDialogOpen]);

  return useMemo(
    () => ({
      createClientVoucherDialogOpen,
      payWithVoucherDialogOpen,
      payWithKlarnaDialogOpen,
      voucherAmount,
      voucherAsPaymentType,
      klarnaAsPaymentType,
      voucherFoundBySerialNumber,
      voucherLoading,
      voucherSerialNumber,
      setCreateClientVoucherDialogOpen,
      setPayWithVoucherDialogOpen,
      setPayWithKlarnaDialogOpen,
      setVoucherAmount,
      setVoucherAsPaymentType,
      setKlarnaAsPaymentType,
      setVoucherFoundBySerialNumber,
      setVoucherLoading,
      setVoucherSerialNumber,
      klarnaPhoneNumber,
      setKlarnaPhoneNumber,
      klarnaClientName,
      setKlarnaClientName,
      klarnaSucceeded,
      setKlarnaSucceeded
    }),
    [
      createClientVoucherDialogOpen,
      payWithVoucherDialogOpen,
      payWithKlarnaDialogOpen,
      voucherAmount,
      voucherAsPaymentType,
      klarnaAsPaymentType,
      voucherFoundBySerialNumber,
      voucherLoading,
      voucherSerialNumber,
      setCreateClientVoucherDialogOpen,
      setPayWithVoucherDialogOpen,
      setPayWithKlarnaDialogOpen,
      setVoucherAmount,
      setVoucherAsPaymentType,
      setVoucherFoundBySerialNumber,
      setVoucherLoading,
      setVoucherSerialNumber,
      klarnaPhoneNumber,
      setKlarnaPhoneNumber,
      klarnaClientName,
      setKlarnaClientName,
      klarnaSucceeded,
      setKlarnaSucceeded
    ]
  );
};

export {useVoucherDialogState};
